import Login from '../views/security/Login'
import LostPassword from '../views/security/LostPassword'
import Register from '../views/security/Register'

export default [
  {
    path: '/login',
    name: 'security-login',
    component: Login,
    meta: {
      layout: 'empty',
      requireAuth: false,
      requireLogout: true,
      requireSelectedWebsite: false,
      title: 'Connexion'
    }
  },
  {
    path: '/register',
    name: 'security-register',
    component: Register,
    meta: {
      layout: 'empty',
      requireAuth: false,
      requireLogout: true,
      requireSelectedWebsite: false,
      title: 'Inscription'
    }
  },
  {
    path: '/lost-password',
    name: 'security-lost-password',
    component: LostPassword,
    meta: {
      layout: 'empty',
      requireAuth: false,
      requireLogout: true,
      requireSelectedWebsite: false,
      title: 'Mot de passe oublié'
    }
  }
]
