<template>
  <div class="module-image">
    <BModal
      id="select-image"
      size="xl"
      content-class="bg-light-grey"
      hide-header
      hide-footer
    >
      <!--suppress JSUnresolvedVariable -->
      <FileList
        :handle-close="closeSelection"
        :handle-select="selectImage"
        mime-type="image/*"
      />
    </BModal>

    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'image'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
        Choix de l'image
      </div>
      <div
        v-if="content.content.url"
        class="row justify-content-center"
      >
        <div class="col-7 d-flex justify-content-center mt-4">
          <!--suppress HtmlUnknownTarget -->
          <img
            :src="content.content.url"
            class="mw-100 img-thumbnail bg-white"
            alt="Selected image"
          >
        </div>
      </div>

      <div class="d-flex justify-content-center my-4">
        <button
          class="btn btn-outline-primary px-3 mb-4 rounded-pill"
          @click.prevent="$bvModal.show('select-image')"
        >
          {{ content.content.url ? 'Choisir une autre image' : 'Sélectionner une image' }}
        </button>
      </div>
    </div>

    <div v-if="tab === 'customization'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
        Nom de l'image et lien
      </div>
      <div class="px-4 pt-4">
        <div class="form-group mb-4">
          <div class="form-control-label">
            Nom de l'image
          </div>
          <b-form-checkbox
            v-model="content.content.showName"
            :value="true"
            :unchecked-value="false"
            switch
          >
            Afficher le nom de l'image
          </b-form-checkbox>
          <div
            v-if="!content.content.showName"
            class="f-14 font-weight-600 text-success"
          >
            Vous pouvez afficher sous l'image une description ou le nom de l'image.
          </div>
          <FormInput
            v-if="content.content.showName"
            v-model="content.content.name"
            class="mb-0 mt-1"
            placeholder="Nom de l'image"
          >
            <template v-slot:label />
          </FormInput>
        </div>

        <div class="form-group mb-4">
          <div class="form-control-label">
            Lien
          </div>
          <b-form-checkbox
            v-model="content.content.showLink"
            :value="true"
            :unchecked-value="false"
            switch
          >
            Ajouter un lien sur l'image
          </b-form-checkbox>
          <div
            v-if="!content.content.showLink"
            class="f-14 font-weight-600 text-success"
          >
            Vous pouvez ajouter un lien sur l'image. En cliquant sur l'image l'utilisateur sera redirigé vers ce lien dans un nouvel onglet.
          </div>
          <FormInput
            v-if="content.content.showLink"
            v-model="content.content.link"
            class="mb-0 mt-1"
            placeholder="Adresse du lien"
          >
            <template v-slot:label />
          </FormInput>
        </div>
      </div>

      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
        Options d'apparence
      </div>
      <div class="px-4 pt-4">
        <div class="form-group mb-4">
          <div class="form-control-label">
            Apparence de l'image
          </div>
          <b-form-radio
            v-model="content.content.rounded"
            name="image-round"
            value="default"
          >
            Afficher l'image sans arrondi
          </b-form-radio>
          <b-form-radio
            v-model="content.content.rounded"
            name="image-round"
            value="rounded"
          >
            Arrondir les coins de l'image
          </b-form-radio>
          <b-form-radio
            v-model="content.content.rounded"
            name="image-round"
            value="rounded-circle"
          >
            Afficher l'image en cercle
          </b-form-radio>
        </div>

        <div class="form-group mb-4">
          <div class="form-control-label">
            Taille de l'image
          </div>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="default"
          >
            Afficher l'image selon sa taille d'origine
          </b-form-radio>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="full"
          >
            Afficher l'image sur toute la largeur de la page
          </b-form-radio>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="container"
          >
            Afficher l'image sur toute la largeur du contenu de la page
          </b-form-radio>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="max"
          >
            Afficher l'image selon une largeur maximale
          </b-form-radio>
          <FormInput
            v-if="content.content.size === 'max'"
            v-model="content.content.maxSize"
            class="mb-0 mt-1"
            placeholder="Largeur maximale en pixels"
            type="number"
          >
            <template v-slot:label />
          </FormInput>
        </div>

        <div class="form-group mb-4">
          <div class="form-control-label">
            Alignement de l'image
          </div>
          <b-form-radio
            v-model="content.content.align"
            name="some-radios"
            value="start"
          >
            Aligner à gauche
          </b-form-radio>
          <b-form-radio
            v-model="content.content.align"
            name="some-radios"
            value="center"
          >
            Aligner au centre
          </b-form-radio>
          <b-form-radio
            v-model="content.content.align"
            name="some-radios"
            value="end"
          >
            Aligner à droite
          </b-form-radio>
        </div>
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import FileList from '../../file/List'
import FormInput from '../../applicationform/FormInput'
import SectionAppearance from './../SectionAppearance'
import { S3_ENTRYPOINT } from '../../../config/entrypoint'
import ProgressStep from '../../applicationcomponent/ProgressStep'
import ImagePreview from './../preview/ImagePreview'
import Tabs from '../../applicationcomponent/Tabs'

export default {
  components: {
    Tabs,
    ImagePreview,
    ProgressStep,
    FileList,
    FormInput,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 'image',
      tabs: [
        {
          name: 'image',
          title: 'Choix de l\'image'
        },
        {
          name: 'customization',
          title: 'Options de l\'image'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
      },
      selection: false
    }
  },

  methods: {
    closeSelection () {
      this.$bvModal.hide('select-image')
    },

    selectImage (image) {
      this.content.content.name = image.originalName
      this.content.content.url = S3_ENTRYPOINT + image.contentUrl
      this.closeSelection()
    }
  }
}
</script>
