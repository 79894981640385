export const options = {
  marginTop: '0',
  marginBottom: '0',
  marginLeft: '0',
  marginRight: '0',
  paddingTop: '0',
  paddingBottom: '0',
  paddingLeft: '0',
  paddingRight: '0',
  backgroundImage: null,
  backgroundColor: null,
  shadow: null,
  borderWidth: '0',
  borderColor: null,
  borderRadius: '0'
}
