<template>
  <div class="form-website">
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <div class="p-4">
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
          Nom et adresse du site
        </div>

        <FormInput
          v-model="item.name"
          :is-valid="isValid('name')"
          :is-pre-valid="isPreValid('name')"
          label="Nom du site"
          type="text"
          @input="updateValidation('name')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('name')"
              class="invalid-feedback"
            >
              {{ violations.name }}
            </div>
            <div
              v-if="!isPreValid('name')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.required">
                Le nom du site doit être indiqué
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.minLength">
                Le nom du site doit contenir au moins {{ $v.item.name.$params.minLength.min }} caractères
              </div>
            </div>
          </template>
        </FormInput>
      </div>

      <div class="p-4 bg-light-grey">
        <div
          v-if="initialItem.subdomain"
          class="f-14 font-weight-600 text-center"
        >
          Le site est actuellement visible à l'adresse :
          <a
            :href="'//' + initialItem.subdomain + '.' + WEBSITE_BASE_DOMAIN"
            target="_blank"
          >
            {{ initialItem.subdomain }}.{{ WEBSITE_BASE_DOMAIN }}
          </a>
        </div>
        <div
          v-if="initialItem.subdomain !== item.subdomain"
          class="f-14 font-weight-600 text-center"
        >
          Après enregistrement l'adresse du site sera : <span class="text-success">{{ item.subdomain }}.{{ WEBSITE_BASE_DOMAIN }}</span>
        </div>
        <div
          v-if="!editSubdomain"
          class="text-center pt-3"
        >
          <button
            class="btn btn-sm btn-outline-success px-3 rounded-pill"
            @click.prevent="editSubdomain = true"
          >
            Modifier l'adresse du site
          </button>

          <div
            v-if="!isValid('subdomain')"
            class="invalid-feedback d-block"
          >
            {{ violations.subdomain }}
          </div>
          <div
            v-if="!isPreValid('subdomain')"
            class="invalid-feedback d-block"
          >
            <!--suppress JSUnresolvedVariable -->
            <div v-if="!$v.item.subdomain.required">
              L'adresse du site doit être indiquée
            </div>
            <!--suppress JSUnresolvedVariable -->
            <div v-if="!$v.item.subdomain.minLength">
              L'adresse du site doit contenir au moins {{ $v.item.subdomain.$params.minLength.min }} caractères
            </div>
            <!--suppress JSUnresolvedVariable -->
            <div v-if="!$v.item.subdomain.subdomain">
              Cette adresse est invalide. L'adresse ne peut contenir que des lettres minuscules, des chiffres ou un tiret
            </div>
          </div>
        </div>
        <div
          v-if="editSubdomain"
          class="pt-3"
        >
          <FormInput
            v-model="item.subdomain"
            :is-valid="isValid('subdomain')"
            :is-pre-valid="isPreValid('subdomain')"
            :append="'.' + WEBSITE_BASE_DOMAIN"
            label="Adresse du site"
            type="text"
            placeholder="monsite"
            @input="updateValidation('subdomain')"
            @keypress.enter.native="editSubdomain = false"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('subdomain')"
                class="invalid-feedback"
              >
                {{ violations.subdomain }}
              </div>
              <div
                v-if="!isPreValid('subdomain')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.subdomain.required">
                  L'adresse du site doit être indiquée
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.subdomain.minLength">
                  L'adresse du site doit contenir au moins {{ $v.item.subdomain.$params.minLength.min }} caractères
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.subdomain.subdomain">
                  Cette adresse est invalide. L'adresse ne peut contenir que des lettres minuscules, des chiffres ou un tiret
                </div>
              </div>
            </template>
          </FormInput>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-primary px-3 rounded-pill"
        @click.prevent="submit()"
      >
        Mettre à jour le site
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import { required, minLength, helpers } from 'vuelidate/lib/validators'
import { WEBSITE_BASE_DOMAIN } from '../../config/entrypoint'
import FormInput from '../applicationform/FormInput'

const subdomain = helpers.regex('slug', /^[a-z0-9-]*$/)

export default {
  components: {
    FormInput
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      WEBSITE_BASE_DOMAIN,
      editSubdomain: false,
      initialItem: {}
    }
  },

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    },

    name () {
      return this.item.name
    }
  },

  mounted () {
    this.initialItem = _.cloneDeep(this.item)
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      name: {
        required,
        minLength: minLength(5)
      },

      subdomain: {
        required,
        minLength: minLength(5),
        subdomain
      }
    }
  }
}
</script>
