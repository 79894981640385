<template>
  <div class="page-user-profile">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion de mon compte
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Informations personnelles
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <b-alert
      :show="confirmationCountdown"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
      @dismissed="confirmationCountdown=0"
      @dismiss-count-down="confirmationCountdownUpdated"
    >
      Les modifications ont été enregistrées
    </b-alert>

    <!--suppress JSUnresolvedVariable -->
    <div :class="{ 'is-loading': isLoading }">
      <div class="my-3 p-3 bg-white rounded-lg shadow-sm">
        <div class="d-flex">
          <div class="mr-4">
            <div class="font-weight-600 text-soft">
              Nom d'utilisateur :
            </div>
            <div class="font-weight-600 text-soft">
              Adresse email :
            </div>
          </div>
          <div class="flex-grow-1">
            <div>
              {{ user.username }}
            </div>
            <div>
              {{ user.email }}
            </div>
          </div>
        </div>
      </div>

      <div class="my-3 bg-white rounded-lg shadow-sm">
        <div class="p-3 bg-light rounded-top-lg f-14 font-weight-600">
          Changer mon mot de passe
        </div>
        <div class="p-3">
          <FormInput
            v-model="item.currentPassword"
            :is-valid="isValid('oldPassword')"
            :is-pre-valid="isPreValid('currentPassword')"
            label="Mot de passe actuel"
            type="password"
            @input="updateValidation('currentPassword')"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('oldPassword')"
                class="invalid-feedback"
              >
                {{ violations.oldPassword }}
              </div>
              <div
                v-if="!isPreValid('currentPassword')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.currentPassword.required">
                  Le mot de passe doit être indiqué
                </div>
              </div>
            </template>
          </FormInput>

          <FormInput
            v-model="item.password"
            :is-valid="isValid('plainPassword')"
            :is-pre-valid="isPreValid('password')"
            label="Nouveau mot de passe"
            type="password"
            @input="updateValidation('password')"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('plainPassword')"
                class="invalid-feedback"
              >
                {{ violations.plainPassword }}
              </div>
              <div
                v-if="!isPreValid('password')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.password.required">
                  Le mot de passe doit être indiqué
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.password.minLength">
                  Le mot de passe doit contenir au moins {{ $v.item.password.$params.minLength.min }} caractères
                </div>
              </div>
            </template>
          </FormInput>

          <FormInput
            v-model="item.confirmPassword"
            :is-pre-valid="isPreValid('confirmPassword')"
            label="Confirmation du mot de passe"
            type="password"
            @input="updateValidation('confirmPassword')"
          >
            <template v-slot:validation>
              <div
                v-if="!isPreValid('confirmPassword')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.confirmPassword.required">
                  Le mot de passe doit être indiqué
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.confirmPassword.sameAsPassword">
                  Les mots de passe doivent être identiques
                </div>
              </div>
            </template>
          </FormInput>

          <div class="d-flex justify-content-center">
            <button
              class="btn btn-primary px-3 mt-3 mb-4 rounded-pill"
              @click.prevent="changePassword"
            >
              Enregistrer le nouveau mot de passe
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import FormInput from '../../components/applicationform/FormInput'
import { minLength, required, sameAs } from 'vuelidate/lib/validators'

export default {
  components: {
    FormInput
  },

  data () {
    return {
      confirmationSeconds: 10,
      confirmationCountdown: 0,
      item: {
        currentPassword: '',
        password: '',
        confirmPassword: ''
      }
    }
  },

  computed: {
    ...mapFields('user/update', {
      error: 'error',
      isLoading: 'isLoading',
      updated: 'updated',
      violations: 'violations'
    }),

    ...mapFields('application/auth', {
      user: 'user'
    })
  },

  watch: {
    violations (violations) {
      if (violations) {
        this.loading = false
      }
    },

    updated (updated) {
      if (updated) {
        this.confirmationCountdown = this.confirmationSeconds

        this.item = {
          currentPassword: '',
          password: '',
          confirmPassword: ''
        }

        this.$v.item.$reset()
      }
    }
  },

  beforeDestroy () {
    this.resetUpdateUser()
  },

  methods: {
    ...mapActions({
      updateUser: 'user/update/update',
      resetUpdateUser: 'user/update/reset'
    }),

    confirmationCountdownUpdated (value) {
      this.confirmationCountdown = value
    },

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    changePassword () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      let payload = {
        plainPassword: this.item.password,
        oldPassword: this.item.currentPassword
      }

      this.loading = true
      this.updateUser({
        id: 'change-password/' + this.user.id,
        payload
      })
    }
  },

  validations: {
    item: {
      currentPassword: {
        required
      },

      password: {
        required,
        minLength: minLength(6)
      },

      confirmPassword: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
