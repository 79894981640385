import List from '../views/design/List'
import Update from '../views/design/Update'
import Setting from '../views/design/Setting'

export default [
  {
    path: '/designs',
    name: 'design-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'design.list',
      title: 'Liste des thèmes'
    }
  },
  {
    path: '/designs/settings',
    name: 'design-setting',
    component: Setting,
    meta: {
      layout: 'dynamic',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'design.update',
      title: 'Personnalisation du thème'
    }
  },
  {
    path: '/designs/:id',
    name: 'design-update',
    component: Update,
    meta: {
      layout: 'fluid',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'design.update',
      title: 'Editer un thème'
    }
  }
]
