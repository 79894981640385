<template>
  <div>
    <div v-if="['text', 'email', 'url'].includes(field.field.type)">
      <FormInput
        v-model="field.value.value"
        :type="field.field.type"
        :label="field.field.name"
      />
    </div>

    <div v-if="['textarea'].includes(field.field.type)">
      <FormTextarea
        v-model="field.value.value"
        :label="field.field.name"
      />
    </div>

    <div v-if="['checkbox'].includes(field.field.type)">
      <FormCheckbox
        v-model="field.value.checked"
        :label="field.field.name"
        :is-switch="true"
      />
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/applicationform/FormInput'
import FormTextarea from '@/components/applicationform/FormTextarea'
import FormCheckbox from '@/components/applicationform/FormCheckbox'
export default {
  components: { FormCheckbox, FormTextarea, FormInput },
  props: {
    field: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
