<template>
  <div class="form-member-group">
    <div class="bg-white shadow-sm rounded-lg my-3 overflow-hidden">
      <div class="p-4">
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
          Informations du groupe
        </div>

        <FormInput
          v-model="item.name"
          :is-valid="isValid('name')"
          :is-pre-valid="isPreValid('name')"
          label="Nom du groupe"
          type="text"
          @input="updateValidation('name')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('name')"
              class="invalid-feedback"
            >
              {{ violations.name }}
            </div>
            <div
              v-if="!isPreValid('name')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.required">
                Le nom du groupe doit être indiqué
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.minLength">
                Le nom du groupe doit contenir au moins {{ $v.item.name.$params.minLength.min }} caractères
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.maxLength">
                Le nom du groupe doit contenir au maximum {{ $v.item.name.$params.maxLength.max }} caractères
              </div>
            </div>
          </template>
        </FormInput>

        <FormCheckbox
          v-model="item.display"
          :is-valid="isValid('display')"
          :is-switch="true"
          class="mb-0"
          label="Afficher le groupe sur le profil du membre"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('display')"
              class="invalid-feedback"
            >
              {{ violations.display }}
            </div>
          </template>
        </FormCheckbox>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormInput from '../applicationform/FormInput'
import { maxLength, minLength, required, email } from 'vuelidate/lib/validators'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import FormCheckbox from '@/components/applicationform/FormCheckbox'

export default {
  components: {
    FormCheckbox,
    FormInput
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    }
  },

  methods: {

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      }
    }
  }
}
</script>
