<template>
  <div class="form-weddinglistitem">
    <BModal
      id="select-image"
      size="xl"
      content-class="bg-light-grey"
      hide-header
      hide-footer
    >
      <!--suppress JSUnresolvedVariable -->
      <FileList
        :handle-close="closeSelection"
        :handle-select="addImage"
        mime-type="image/*"
      />
    </BModal>

    <div class="bg-white shadow-sm rounded-lg my-3">
      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Nom et informations
        </div>

        <FormInput
          v-model="item.name"
          :is-valid="isValid('name')"
          :is-pre-valid="isPreValid('name')"
          label="Nom du cadeau"
          type="text"
          class="mb-4"
          @input="updateValidation('name')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('name')"
              class="invalid-feedback"
            >
              {{ violations.name }}
            </div>
            <div
              v-if="!isPreValid('name')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.required">
                Le nom du cadeau doit être indiqué
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.minLength">
                Le nom du cadeau doit contenir au moins {{ $v.item.name.$params.minLength.min }} caractères
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.maxLength">
                Le nom du cadeau doit contenir au maximum {{ $v.item.name.$params.maxLength.max }} caractères
              </div>
            </div>
          </template>
        </FormInput>

        <FormTextarea
          v-model="item.description"
          :is-valid="isValid('description')"
          :is-pre-valid="isPreValid('description')"
          label="Description du cadeau"
          placeholder="La description du cadeau peut contenir jusqu'à 200 caractères."
          class="mb-4"
          @input="updateValidation('description')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('description')"
              class="invalid-feedback"
            >
              {{ violations.description }}
            </div>
            <div
              v-if="!isPreValid('description')"
              class="invalid-feedback"
            >
              <div v-if="!$v.item.description.maxLength">
                La description du cadeau doit contenir au maximum {{ $v.item.description.$params.maxLength.max }} caractères
              </div>
            </div>
          </template>
        </FormTextarea>

        <div class="form-control-label pb-2">
          Image du cadeau
        </div>

        <div class="pb-2">
          <button
            v-if="!item.image"
            class="btn btn-outline-success btn-sm rounded-pill px-3"
            @click.prevent="$bvModal.show('select-image')"
          >
            Ajouter une image
          </button>

          <div class="row">
            <div
              v-if="item.image"
              class="col-12 col-md-6 col-xl-4 position-relative mt-3"
            >
              <div
                class="image-remove rounded-circle border border-danger bg-danger-light bg-danger-hover text-danger text-white-hover"
                @click.prevent="removeImage"
              >
                <i class="far fa-trash" />
              </div>

              <img
                :src="item.image"
                alt="List item image"
                class="img-thumbnail mw-100"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Prix du cadeau
        </div>

        <div class="row">
          <div class="col">
            <FormInput
              v-model="item.price"
              :is-valid="isValid('price')"
              :is-pre-valid="isPreValid('price')"
              :append="'€'"
              label="Prix du cadeau"
              type="number"
              class="mb-4"
              @input="updateValidation('price')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('price')"
                  class="invalid-feedback"
                >
                  {{ violations.price }}
                </div>
                <div
                  v-if="!isPreValid('price')"
                  class="invalid-feedback"
                >
                  <div v-if="!$v.item.price.required">
                    Le prix du cadeau doit être indiqué
                  </div>
                  <div v-if="!$v.item.price.decimal">
                    Le prix du cadeau doit être un nombre
                  </div>
                  <div v-if="!$v.item.price.minValue">
                    Le prix du cadeau doit être supérieur à 0€
                  </div>
                </div>
              </template>
            </FormInput>
          </div>
        </div>
      </div>

      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Paramètres
        </div>

        <FormCheckbox
          v-model="item.canBeShared"
          :is-switch="true"
          label="Plusieurs personnes peuvent faire des virements pour partager ce cadeau"
          class="mb-2"
        />

        <FormCheckbox
          v-model="item.active"
          :is-switch="true"
          label="Activer ce produit"
          class="mb-2"
        />
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer le cadeau
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormInput from '../applicationform/FormInput'
import { minLength, maxLength, minValue, integer, decimal, required } from 'vuelidate/lib/validators'
import { mapFields } from 'vuex-map-fields'
import FormCheckbox from '../applicationform/FormCheckbox'
import permission from '../../utils/permission'
import FormTextarea from '../applicationform/FormTextarea'
import FileList from '../file/List'
import { S3_ENTRYPOINT } from '@/config/entrypoint'

export default {
  components: {
    FormTextarea,
    FormCheckbox,
    FormInput,
    FileList
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      initialItem: {},
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    item () {
      return this.values || this.initialValues
    },

    violations () {
      return this.errors || {}
    },

    title () {
      return this.item.title
    },

    validation () {
      return this.$v.item
    }
  },

  mounted () {
    // eslint-disable-next-line no-undef
    this.initialItem = _.cloneDeep(this.item)
  },

  methods: {
    checkPermission (toCheck) {
      return permission(toCheck)
    },

    closeSelection () {
      this.$bvModal.hide('select-image')
    },

    addImage (image) {
      this.item.image = S3_ENTRYPOINT + image.contentUrl

      this.closeSelection()
    },

    removeImage () {
      this.item.image = null
    },

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },

      description: {
        maxLength: maxLength(200)
      },

      price: {
        required,
        decimal,
        minValue: minValue(0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-remove {
  position: absolute;
  top: rem-calc(10);
  right: rem-calc(25);
  width: rem-calc(32);
  height: rem-calc(32);
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
</style>
