var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-carousel-preview"},[_c('div',{class:{ 'container': !_vm.content.content.fullWidth && _vm.hasContainer }},[_c('div',{class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('BCarousel',{attrs:{"controls":_vm.content.content.showControls,"indicators":_vm.content.content.showControls,"fade":_vm.content.content.fade,"interval":parseInt(_vm.content.content.delay)}},[(!_vm.content.content.slides.length)?_c('BCarouselSlide',{attrs:{"img-blank":""}}):_vm._e(),_vm._l((_vm.content.content.slides),function(slide,index){return _c('BCarouselSlide',{key:index,attrs:{"img-src":slide.image,"caption":slide.caption,"text":slide.text}},[_c('template',{slot:"img"},[(!_vm.content.content.ratio)?_c('img',{staticClass:"mw-100 w-100",attrs:{"src":slide.image}}):_vm._e(),(_vm.content.content.ratio)?_c('div',{class:[ 'embed-responsive embed-responsive-' + _vm.content.content.ratio ],style:('background-position: center; background-repeat: no-repeat; background-size: ' + ( _vm.content.content.cover ? 'cover' : 'contain' ) +'; background-image: url(' + slide.image + ');' + (_vm.content.content.ratio === 'height' ? 'padding-top:' + _vm.content.content.height + 'px;' : '' ))}):_vm._e()])],2)})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }