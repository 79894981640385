import { options } from './_default'

export const memberName = {
  type: 'member-name',
  position: null,
  variant: 'default',
  content: {
    alignment: 'left',
    textColor: '#000000',
    fontFamily: 'roboto',
    fontSize: '1',
    background: false,
    backgroundColor: '#f5208b',
    backgroundHeight: '25',
    backgroundMarginTop: '10',
    backgroundMarginLeft: '10',
    underline: false,
    underlineWidth: '50',
    underlineHeight: '5',
    underlineColor: '#F5A721',
    textShadow: false,
    textShadowHorizontal: '2',
    textShadowVertical: '2',
    textShadowBlur: '0',
    textShadowColor: '#00000052',
    showRegistrationDate: true,
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
