<template>
  <div class="form-group">
    <slot name="label">
      <label
        v-if="label"
        :for="id"
        class="form-control-label"
      >{{ label }}</label>
    </slot>

    <slot name="beforeInput" />

    <BFormDatepicker
      :id="id"
      v-model="content"
      size="sm"
      :class="['form-control rounded', !isValid || !isPreValid ? 'is-invalid' : '']"
      :value-as-date="true"
      :placeholder="placeholder"
      :reset-button="true"
      @input="updateValue"
    />

    <slot name="afterInput" />
    <slot name="validation" />
    <slot name="afterValidation" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      validator: prop => prop instanceof Date || prop === null,
      default: null
    },

    type: {
      type: String,
      default: 'text'
    },

    label: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    isValid: {
      type: Boolean,
      default: true
    },

    isPreValid: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      content: this.value
    }
  },

  computed: {
    id () {
      // noinspection JSUnresolvedVariable
      return 'input-' + this._uid
    }
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
