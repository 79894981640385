<template>
  <div class="form-invitation">
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <div class="p-3">
        <FormInput
          v-model="item.email"
          :is-valid="isValid('email')"
          :is-pre-valid="isPreValid('email')"
          :is-disabled="!!initialValues && !!initialValues.email"
          label="Adresse email"
          type="email"
          class="mb-0"
          @input="updateValidation('email')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('email')"
              class="invalid-feedback"
            >
              {{ violations.email }}
            </div>
            <div
              v-if="!isPreValid('email')"
              class="invalid-feedback"
            >
              <div v-if="!$v.item.email.required">
                L'adresse email doit être indiquée
              </div>
              <div v-if="!$v.item.email.email">
                Cette adresse email n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>
      </div>

      <div class="bg-light-grey p-3">
        <FormPermissions
          v-model="item.permissions"
          :is-valid="isValid('permissions')"
          :is-pre-valid="isPreValid('permissions')"
          label="Permissions de l'utilisateur"
          @input="updateValidation('permissions')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('permissions')"
              class="invalid-feedback"
            >
              {{ violations.permissions }}
            </div>
            <div
              v-if="!isPreValid('permissions')"
              class="invalid-feedback"
            />
          </template>
        </FormPermissions>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormInput from '../applicationform/FormInput'
import FormPermissions from '../applicationform/FormPermissions'
import { email, required } from 'vuelidate/lib/validators'

export default {
  components: {
    FormInput,
    FormPermissions
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    }
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      email: {
        required,
        email
      },

      permissions: {
      }
    }
  }
}
</script>
