var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-article-publication-preview"},[_c('div',{class:{ 'container': _vm.hasContainer }},[_c('div',{class:[
        'page-editor-preview',
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"position-relative mb-0",class:_vm.titleClass,style:(_vm.titleStyle)},[_c('span',{staticClass:"position-relative"},[(!_vm.content.content.sameLine)?_c('span',{staticClass:"z-index-2 position-relative"},[(_vm.content.content.showAuthor)?_c('span',[_vm._v(_vm._s(_vm.content.content.showPublicationWord ? 'Publié par xxxxxxx' : 'xxxxxxx'))]):_vm._e(),(_vm.content.content.showAuthor && _vm.content.content.showDate)?_c('br'):_vm._e(),(_vm.content.content.showDate)?_c('span',[_vm._v(_vm._s(_vm.content.content.showDateWord ? 'Le 00/00/0000' : '00/00/00'))]):_vm._e()]):_vm._e(),(_vm.content.content.sameLine)?_c('span',{staticClass:"z-index-2 position-relative"},[_vm._v("\n            "+_vm._s(_vm.content.content.showPublicationWord ? 'Publié' : '')+" "+_vm._s(_vm.content.content.showAuthor ? 'par xxxxxxx' : '')+" "+_vm._s(_vm.content.content.showDateWord ? 'le 00/00/0000' : '00/00/00')+"\n          ")]):_vm._e(),(_vm.content.content.background)?_c('span',{staticClass:"z-index-1 position-absolute w-100",style:(_vm.backgroundStyle)}):_vm._e()])]),(_vm.content.content.underline)?_c('div',{staticClass:"d-flex",class:{ 'justify-content-start': _vm.content.content.alignment === 'left', 'justify-content-center': _vm.content.content.alignment === 'center', 'justify-content-end': _vm.content.content.alignment === 'right' }},[_c('div',{staticClass:"rounded-pill mw-100",style:(_vm.underlineStyle)})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }