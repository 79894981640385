var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-separator-preview"},[_c('div',{class:{ 'container': !_vm.content.content.fullWidth && _vm.hasContainer }},[_c('div',{class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"min-height":"8px"}},[_c('div',{staticClass:"w-100",style:(_vm.separatorStyle)})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }