import { forEach } from 'lodash'
import { ENTRYPOINT } from '../config/entrypoint'
import SubmissionError from '../error/SubmissionError'

const MIME_TYPE = 'application/ld+json'

const transformQuery = (options) => {
  let query = ''

  if (options.query) {
    query = '?'

    forEach(options.query, (value, property) => {
      query = query + property + '=' + value + '&'
    })
  }

  return query
}

export default function (id, options = {}) {
  if (typeof options.headers === 'undefined') options.headers = new Headers()

  if (options.headers.get('Accept') === null) { options.headers.set('Accept', MIME_TYPE) }

  if (
    options.body !== 'undefined' &&
    !(options.body instanceof FormData) &&
    options.headers.get('Content-Type') === null
  ) { options.headers.set('Content-Type', MIME_TYPE) }

  if (localStorage.token) { options.headers.set('Authorization', 'Bearer ' + localStorage.token) }

  // const payload = options.body && JSON.parse(options.body)
  // if (isObject(payload) && payload['@id']) { options.body = JSON.stringify(transformRelationToIri(payload)) }

  id = id + transformQuery(options)

  return global.fetch(new URL('api/' + id, ENTRYPOINT), options).then(response => {
    if (response.ok) return response

    return response.json().then((json) => {
      const error = json['{{{hydraPrefix}}}description'] || response.statusText
      if (!json.violations) throw Error(error)

      let errors = { _error: error }
      // noinspection JSUnresolvedVariable
      json.violations.map(
        violation => (errors[violation.propertyPath] = violation.message)
      )

      throw new SubmissionError(errors)
    })
  })
}
