import { pageArticleDefault } from '@/config/pagebuilder/pages/article/default'
import { pageContactDefault } from '@/config/pagebuilder/pages/contact/default'
import { pageHomeDefault } from '@/config/pagebuilder/pages/home/default'
import { pageArchiveDefault } from '@/config/pagebuilder/pages/archive/default'
import { pageShopDefault } from '@/config/pagebuilder/pages/shop/default'
import { pageShopProductDefault } from '@/config/pagebuilder/pages/shopproduct/default'
import { pageProfileDefault } from '@/config/pagebuilder/pages/profile/default'

export const pages = {
  'home': pageHomeDefault,
  'articles': pageArchiveDefault,
  'article': pageArticleDefault,
  'contact': pageContactDefault,
  'shop': pageShopDefault,
  'shop_product': pageShopProductDefault,
  'member': pageProfileDefault,
}
