import Wedding from '../views/wedding/View'

export default [
  {
    path: '/wedding',
    name: 'wedding',
    component: Wedding,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'wedding.setting',
      title: 'Mariage'
    }
  }
]
