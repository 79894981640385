var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-member-groups-preview"},[_c('div',{class:[
      'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
      'mb-' + _vm.content.section.marginBottom.replace('-', 'n')
    ]},[_c('div',{class:[
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{class:{ 'container': _vm.hasContainer }},[_vm._m(0),_vm._m(1),_vm._m(2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-4"},[_c('h6',{staticClass:"mb-1"},[_vm._v("Champ personnalisé 1")]),_c('div',[_vm._v("\n            Valeur du champ\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-4"},[_c('h6',{staticClass:"mb-1"},[_vm._v("Champ personnalisé 2")]),_c('div',[_vm._v("\n            Valeur du champ\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-4"},[_c('h6',{staticClass:"mb-1"},[_vm._v("Champ personnalisé 3")]),_c('div',[_vm._v("\n            Valeur du champ\n          ")])])}]

export { render, staticRenderFns }