<template>
  <div class="col-12 col-md-6 col-xl-4">
    <div class="my-3">
      <div class="permission-title">
        <span class="permission-title-icon f-22">
          <i
            class="far"
            :class="icon"
          />
        </span>
        <span class="permission-title-text f-nunito f-15 font-weight-700">{{ label }}</span>
      </div>

      <div
        v-for="(permission, index) in permissions"
        :key="index"
        class="d-flex align-items-center font-weight-600"
        :class="[ hasPermission(permission.value) ? 'text-success' : 'text-soft' ]"
      >
        <div class="permission-icon d-flex justify-content-center pr-2">
          <i
            class="far"
            :class="[ hasPermission(permission.value) ? 'fa-check' : 'fa-times' ]"
          />
        </div>
        <div class="f-14">
          {{ permission.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      required: true
    },

    userPermissions: {
      type: Array,
      required: true
    },

    permissions: {
      type: Array,
      required: true
    }
  },

  methods: {
    hasPermission (permission) {
      if (this.userPermissions.includes('*.*')) {
        return true
      }

      return this.userPermissions.includes(permission)
    }
  }
}
</script>

<style lang="scss" scoped>
  .permission-title {
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-transition: color .3s, background-color .3s;
    transition: color .3s, background-color .3s;
    color: $primary;
    height: 3rem;

    &-icon {
      width: rem-calc(36);
    }

    &-text {
      letter-spacing: 0.00937rem;
    }
  }

  .permission-icon {
    width: rem-calc(26)
  }
</style>
