import List from '../views/invitation/List'
import Create from '../views/invitation/Create'
import Update from '../views/invitation/Update'

export default [
  {
    path: '/invitations',
    name: 'invitation-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'user.list',
      title: 'Liste des invitations'
    }
  },
  {
    path: '/invitations/create',
    name: 'invitation-create',
    component: Create,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'invitation.create',
      title: 'Ajouter une invitation'
    }
  },
  {
    path: '/invitations/:id',
    name: 'invitation-update',
    component: Update,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'invitation.create',
      title: 'Editer une invitation'
    }
  }
]
