<template>
  <div class="w-100 d-flex flex-column align-items-center justify-content-center py-5 my-5">
    <div class="f-22 text-primary f-nunito">
      Erreur 404
    </div>
    <div class="f-30 text-muted f-nunito">
      Cette page n'existe pas
    </div>
    <div class="mt-4">
      <RouterLink
        :to="{ name: 'dashboard-home' }"
        class="btn btn-primary px-3 rounded-pill"
      >
        Revenir à l'accueil
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>
