import { options } from './_default'

export const separator = {
  type: 'separator',
  position: null,
  variant: 'default',
  content: {
    color: '#0000001a',
    height: '1',
    fullWidth: false,
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
