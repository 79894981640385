<template>
  <div
    :id="'tooltip - ' + id"
    class="form-group"
  >
    <b-tooltip
      v-if="addon && !hasAddon"
      :disabled="addon === ''"
      :target="'tooltip - ' + id"
      placement="top"
      triggers="hover"
    >
      Cette fonctionnalité n'est accessible qu'avec l'option {{ addon === 'Premium' ? 'Premium ou Pro' : addon }}
    </b-tooltip>

    <slot name="label">
      <label
        v-if="label"
        :for="id"
        class="form-control-label"
      >
        {{ label }}
        <span
          v-if="addon && !hasAddon"
          class="ml-2 px-2 rounded-pill f-14 font-weight-600 cursor-pointer"
          :class="{ 'bg-warning': !hasAddon, 'bg-success-light': hasAddon }"
          @click="$router.push({ name: 'website-subscription' })"
        >{{ addon }}</span>
      </label>
    </slot>

    <slot name="beforeInput" />

    <div :class="{ 'input-group': !!append, 'is-invalid': !isValid || !isPreValid, 'disable': isDisabled }">
      <!--suppress HtmlFormInputWithoutLabel -->
      <input
        v-show="showInput"
        :id="id"
        v-model.trim="content"
        class="form-control"
        :class="{ 'is-invalid': !isValid || !isPreValid, 'disable': isDisabled }"
        :disabled="isDisabled"
        :type="type"
        :placeholder="placeholder"
        @input="updateValue($event.target.value)"
      >

      <div
        v-if="append"
        class="input-group-append"
      >
        <span class="input-group-text">{{ append }}</span>
      </div>
    </div>

    <slot name="afterInput" />
    <slot name="validation" />
    <slot name="afterValidation" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String|Number,
      default: ''
    },

    type: {
      type: String,
      default: 'text'
    },

    label: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    isValid: {
      type: Boolean,
      default: true
    },

    isPreValid: {
      type: Boolean,
      default: true
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    showInput: {
      type: Boolean,
      default: true
    },

    hasAddon: {
      type: Boolean,
      default: true
    },

    addon: {
      type: String,
      default: ''
    },

    append: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      content: this.value
    }
  },

  computed: {
    id () {
      // noinspection JSUnresolvedVariable
      return 'input-' + this._uid
    }
  },

  watch: {
    value (value) {
      if (value !== this.content) {
        this.content = value
      }
    }
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value.trim())
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
