<template>
  <div class="component-image-preview">
    <div :class="{ 'container': content.content.size !== 'full' && hasContainer }">
      <div
        :class="[
          'mt-' + content.section.marginTop.replace('-', 'n'),
          'mb-' + content.section.marginBottom.replace('-', 'n'),
          'ml-' + content.section.marginLeft.replace('-', 'n'),
          'mr-' + content.section.marginRight.replace('-', 'n'),
          'pt-' + content.section.paddingTop,
          'pb-' + content.section.paddingBottom,
          'pl-' + content.section.paddingLeft,
          'pr-' + content.section.paddingRight,
          moduleClass
        ]"
        :style="moduleStyle"
      >
        <div
          class="d-flex flex-column"
          :class="{
            'align-items-start': !content.content.align || content.content.align === 'start',
            'align-items-center': content.content.align === 'center',
            'align-items-end': content.content.align === 'end',
          }"
        >
          <div
            class="mw-100"
            :class="{ 'w-100': content.content.size === 'full' || content.content.size === 'container' }"
            :style="videoStyle"
          >
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                v-if="content.content.url"
                class="embed-responsive-item"
                :src="content.content.url"
                allowfullscreen
              />

              <div
                v-if="!content.content.url"
                class="embed-responsive-item bg-grey"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px;'
      }

      return style
    },

    videoStyle () {
      let style = ''

      if (this.content.content.size === 'max' && this.content.content.maxSize) {
        style = 'width: ' + this.content.content.maxSize + 'px;'
      }

      return style
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
