import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const login = ({ commit }, { id = 'login_check', payload }) => {
  commit(types.APPLICATION_LOGIN_SET_ERROR, '')
  commit(types.APPLICATION_LOGIN_TOGGLE_LOADING)

  return fetch(id, { method: 'POST', body: JSON.stringify(payload) })
    .then((response) => {
      commit(types.APPLICATION_LOGIN_TOGGLE_LOADING)

      return response.json()
    })
    .then((data) => {
      commit(types.APPLICATION_LOGIN_SET_USER, data)
    })
    .catch((e) => {
      commit(types.APPLICATION_LOGIN_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.APPLICATION_LOGIN_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.APPLICATION_LOGIN_SET_ERROR, e.errors._error)

        return
      }

      if (e.message === 'Unauthorized') {
        commit(types.APPLICATION_LOGIN_SET_VIOLATIONS, {
          email: 'Identifiants incorrects',
          password: 'Identifiants incorrects'
        })
      }

      commit(types.APPLICATION_LOGIN_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.APPLICATION_LOGIN_RESET)
}
