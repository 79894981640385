<template>
  <div class="layout-sidebar pb-5">
    <div class="sidebar-title mt-5 pt-4 f-11 text-soft font-weight-700 pb-3">
      MENU
    </div>

    <ul class="sidebar-menu">
      <li class="sidebar-menu-item">
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['dashboard-home'].includes(currentRoute) }"
          :to="{ name: 'dashboard-home' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-chart-bar" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Tableau de bord</span>
        </router-link>
      </li>

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="!hasWebsite"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['website-create'].includes(currentRoute) }"
          :to="{ name: 'website-create' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-layer-plus" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Créer un site</span>
        </router-link>
      </li>

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="hasWebsiteSelected && checkPermission('article.list')"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['article-list', 'article-create', 'article-update'].includes(currentRoute) }"
          :to="{ name: 'article-list' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-file-alt" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Articles</span>
        </router-link>
      </li>

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="hasWebsiteSelected && checkPermission('page.list')"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['page-list', 'page-create', 'page-update'].includes(currentRoute) }"
          :to="{ name: 'page-list' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-ballot" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Pages</span>
        </router-link>
      </li>

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="hasWebsiteSelected && checkPermission('menu.list')"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['menu-list'].includes(currentRoute) }"
          :to="{ name: 'menu-list' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-list" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Menus</span>
        </router-link>
      </li>

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="hasWebsiteSelected && checkPermission('file.list')"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['file-list'].includes(currentRoute) }"
          :to="{ name: 'file-list' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-image" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Fichiers</span>
        </router-link>
      </li>

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="hasWebsiteSelected && checkPermission('gallery.list')"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['gallery-list', 'gallery-create', 'gallery-update'].includes(currentRoute) }"
          :to="{ name: 'gallery-list' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-photo-video" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Galeries</span>
        </router-link>
      </li>

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="hasWebsiteSelected && checkPermission('design.list')"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['design-list', 'design-create', 'design-update', 'design-setting'].includes(currentRoute) }"
          :to="{ name: 'design-list' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-paint-roller" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700 flex-grow-1">Thèmes</span>
          <span
            class="sidebar-menu-item-carret f-22"
            @click.prevent="showDesignMenu = !showDesignMenu"
          >
            <i
              v-if="showDesignMenu"
              class="far fa-angle-up"
            />
            <i
              v-if="!showDesignMenu"
              class="far fa-angle-down"
            />
          </span>
        </router-link>

        <BCollapse
          id="menu-collapse-design"
          v-model="showDesignMenu"
        >
          <div class="sidebar-menu-item-submenu pl-5 pb-3">
            <router-link
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['design-list', 'design-create', 'design-update'].includes(currentRoute) }"
              :to="{ name: 'design-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Liste des thèmes</span>
            </router-link>
            <router-link
              v-if="checkPermission('design.update')"
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['design-setting'].includes(currentRoute) }"
              :to="{ name: 'design-setting' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Paramètres</span>
            </router-link>
          </div>
        </BCollapse>
      </li>

      <li
        v-if="hasWebsiteSelected && (checkPermission('member.list') || checkPermission('member_group.list'))"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['member-list', 'member-create', 'member-update', 'membergroup-list', 'membergroup-create', 'membergroup-update'].includes(currentRoute) }"
          :to="{ name: 'member-list' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-user" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700 flex-grow-1">Membres</span>
          <span
            class="sidebar-menu-item-carret f-22"
            @click.prevent="showMemberMenu = !showMemberMenu"
          >
            <i
              v-if="showMemberMenu"
              class="far fa-angle-up"
            />
            <i
              v-if="!showMemberMenu"
              class="far fa-angle-down"
            />
          </span>
        </router-link>

        <BCollapse
          id="menu-collapse-member"
          v-model="showMemberMenu"
        >
          <div class="sidebar-menu-item-submenu pl-5 pb-3">
            <router-link
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['member-list', 'member-create', 'member-update'].includes(currentRoute) }"
              :to="{ name: 'member-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Liste des membres</span>
            </router-link>
            <router-link
              v-if="checkPermission('design.update')"
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['membergroup-list', 'membergroup-create', 'membergroup-update'].includes(currentRoute) }"
              :to="{ name: 'membergroup-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Liste des groupes</span>
            </router-link>
          </div>
        </BCollapse>
      </li>

      <li
        v-if="hasWebsiteSelected && (checkPermission('shopproduct.list') || checkPermission('shoppayment.list') || checkPermission('shop.setting'))"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['shopproduct-list', 'shopproduct-create', 'shopproduct-update', 'shoppayment-list', 'shoppayment-view'].includes(currentRoute) }"
          :to="{ name: 'shoppayment-list' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-shopping-cart" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700 flex-grow-1">Boutique</span>
          <span
            class="sidebar-menu-item-carret f-22"
            @click.prevent="showShopMenu = !showShopMenu"
          >
            <i
              v-if="showShopMenu"
              class="far fa-angle-up"
            />
            <i
              v-if="!showShopMenu"
              class="far fa-angle-down"
            />
          </span>
        </router-link>

        <BCollapse
          id="menu-collapse-shop"
          v-model="showShopMenu"
        >
          <div class="sidebar-menu-item-submenu pl-5 pb-3">
            <router-link
              v-if="checkPermission('shopproduct.list')"
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['shopproduct-list', 'shopproduct-create', 'shopproduct-update'].includes(currentRoute) }"
              :to="{ name: 'shopproduct-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Produits</span>
            </router-link>
            <router-link
              v-if="checkPermission('shoppayment.list')"
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['shoppayment-list', 'shoppayment-view'].includes(currentRoute) }"
              :to="{ name: 'shoppayment-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Paiements</span>
            </router-link>
            <router-link
              v-if="checkPermission('shop.setting')"
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['shopsetting-list'].includes(currentRoute) }"
              :to="{ name: 'shopsetting-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Paramètres</span>
            </router-link>
          </div>
        </BCollapse>
      </li>

      <li
        v-if="hasWebsiteSelected && checkPermission('wedding.setting') && false"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['wedding', 'weddinglistitem-list', 'weddinglistitem-create', 'weddinglistitem-update', 'weddingpayment-list', 'weddingpayment-create', 'weddingpayment-update'].includes(currentRoute) }"
          :to="{ name: 'wedding' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-rings-wedding" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700 flex-grow-1">Mariage</span>
          <span
            class="sidebar-menu-item-carret f-22"
            @click.prevent="showWeddingMenu = !showWeddingMenu"
          >
            <i
              v-if="showWeddingMenu"
              class="far fa-angle-up"
            />
            <i
              v-if="!showWeddingMenu"
              class="far fa-angle-down"
            />
          </span>
        </router-link>

        <BCollapse
          id="menu-collapse-wedding"
          v-model="showWeddingMenu"
        >
          <div class="sidebar-menu-item-submenu pl-5 pb-3">
            <router-link
              v-if="checkPermission('wedding.setting')"
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['wedding'].includes(currentRoute) }"
              :to="{ name: 'wedding' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Paramètres</span>
            </router-link>
            <router-link
              v-if="checkPermission('wedding.setting')"
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['weddinglistitem-list', 'weddinglistitem-create', 'weddinglistitem-update'].includes(currentRoute) }"
              :to="{ name: 'weddinglistitem-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Liste de mariage</span>
            </router-link>
            <router-link
              v-if="checkPermission('wedding.setting')"
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['weddingpayment-list', 'weddingpayment-create', 'weddingpayment-update'].includes(currentRoute) }"
              :to="{ name: 'weddingpayment-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Paiements</span>
            </router-link>
          </div>
        </BCollapse>
      </li>

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="hasWebsiteSelected && checkPermission('website.settings')"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['website-settings', 'website-settings-informations', 'website-settings-domain'].includes(currentRoute) }"
          :to="{ name: 'website-settings' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-cog" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Paramètres</span>
        </router-link>
      </li>

      <li class="sidebar-menu-separator my-4" />

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="hasWebsiteSelected"
        class="sidebar-menu-item"
      >
        <!--suppress JSUnresolvedVariable -->
        <a
          class="sidebar-menu-item-link text-success"
          target="_blank"
          :href="'//' + user.selectedWebsite.url"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-external-link-square" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Voir le site</span>
        </a>
      </li>

      <!--suppress JSUnresolvedVariable -->
      <li
        v-if="hasWebsiteSelected && checkPermission('website.billing')"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['website-subscription'].includes(currentRoute) }"
          :to="{ name: 'website-subscription' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-credit-card" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Abonnement</span>
        </router-link>
      </li>

      <li
        v-if="hasWebsiteSelected && checkPermission('user.list')"
        class="sidebar-menu-item"
      >
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['user-list', 'user-update', 'invitation-list', 'invitation-create'].includes(currentRoute) }"
          :to="{ name: checkPermission('user.list') ? 'user-list' : 'invitation-list' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-users-cog" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700 flex-grow-1">Utilisateurs</span>
          <span
            class="sidebar-menu-item-carret f-22"
            @click.prevent="showUserMenu = !showUserMenu"
          >
            <i
              v-if="showUserMenu"
              class="far fa-angle-up"
            />
            <i
              v-if="!showUserMenu"
              class="far fa-angle-down"
            />
          </span>
        </router-link>

        <BCollapse
          id="menu-collapse-user"
          v-model="showUserMenu"
        >
          <div class="sidebar-menu-item-submenu pl-5 pb-3">
            <router-link
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['user-list', 'user-update'].includes(currentRoute) }"
              :to="{ name: 'user-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Liste des utilisateurs</span>
            </router-link>
            <router-link
              class="sidebar-menu-item-link submenu"
              :class="{ 'active' : ['invitation-list', 'invitation-create'].includes(currentRoute) }"
              :to="{ name: 'invitation-list' }"
            >
              <span class="sidebar-menu-item-text f-nunito f-13 font-weight-700">Liste des invitations</span>
            </router-link>
          </div>
        </BCollapse>
      </li>

      <li class="sidebar-menu-item">
        <router-link
          class="sidebar-menu-item-link"
          :class="{ 'active' : ['user-profile'].includes(currentRoute) }"
          :to="{ name: 'user-profile' }"
        >
          <span class="sidebar-menu-item-icon f-22"><i class="far fa-user-shield" /></span>
          <span class="sidebar-menu-item-text f-nunito f-15 font-weight-700">Mon compte</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import permission from '../../utils/permission'

export default {
  data () {
    return {
      showDesignMenu: false,
      showShopMenu: false,
      showBillingMenu: false,
      showUserMenu: false,
      showMemberMenu: false,
      showWeddingMenu: false,
    }
  },

  computed: {
    ...mapFields('application/auth', {
      isLoading: 'isLoading',
      isLogged: 'isLogged',
      hasWebsite: 'hasWebsite',
      hasWebsiteSelected: 'hasWebsiteSelected',
      user: 'user'
    }),

    currentRoute () {
      return this.$route.name
    }
  },

  watch: {
    currentRoute () {
      this.checkRoute()
    }
  },

  mounted () {
    this.checkRoute()
  },

  methods: {
    checkRoute () {
      this.showDesignMenu = ['design-list', 'design-create', 'design-update', 'design-setting'].includes(this.$route.name)
      this.showShopMenu = ['shopproduct-list', 'shopproduct-create', 'shopproduct-update', 'shopsetting-list', 'shoppayment-list', 'shoppayment-view'].includes(this.$route.name)
      this.showUserMenu = ['user-list', 'user-update', 'invitation-list', 'invitation-create'].includes(this.$route.name)
      this.showMemberMenu = ['member-list', 'member-create', 'member-update', 'membergroup-list', 'membergroup-create', 'membergroup-update'].includes(this.$route.name)
      this.showWeddingMenu = ['wedding', 'weddinglistitem-list', 'weddinglistitem-create', 'weddinglistitem-update', 'weddingpayment-list', 'weddingpayment-create', 'weddingpayment-update'].includes(this.$route.name)
    },

    checkPermission (toCheck) {
      return permission(toCheck)
    }
  }
}
</script>

<style lang="scss" scoped>
  .sidebar {
    &-title {
      letter-spacing: rem-calc(1.5);
    }

    &-menu {
      list-style: none;
      margin: 0;
      padding: 0;

      &-item {
        &-link {
          display: flex;
          position: relative;
          align-items: center;
          vertical-align: middle;
          transition: color .3s, background-color .3s;
          color: $tertiary;
          height: rem-calc(48);

          &.submenu {
            height: rem-calc(32);
          }

          &.active {
            color: $primary;
          }

          &:hover {
            text-decoration: none;
            color: $primary;
          }
        }

        &-icon {
          width: rem-calc(36);
        }

        &-carret {
          width: rem-calc(24);
          text-align: center;
        }

        &-text {
          letter-spacing: rem-calc(0.15);
        }
      }
    }

    &-logo {
      &-name {
      }

      &-beta {
        background-color: $primary;
      }
    }
  }
</style>
