<template>
  <div class="page-shopproduct-update">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <RouterLink
          :to="{ name: 'shopproduct-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des produits
        </RouterLink>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Éditer un produit
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading && !retrieved"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading && retrieved"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <BAlert
      :show="confirmationCountdown"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
      @dismissed="confirmationCountdown=0"
      @dismiss-count-down="confirmationCountdownUpdated"
    >
      Les modifications du produit ont été enregistrées
    </BAlert>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="isLoading && !retrieved">
      <div
        class="py-5 my-3 rounded-lg bg-grey"
        style="height: 320px"
      />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div :class="{ 'is-loading': isLoading }">
      <ProductForm
        v-if="retrieved"
        :handle-submit="onSendForm"
        :values="retrieved"
        :errors="violations"
        :initial-values="retrieved"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ProductForm from '../../components/shopproduct/Form'

export default {
  components: {
    ProductForm
  },

  data () {
    return {
      confirmationSeconds: 10,
      confirmationCountdown: 0
    }
  },

  computed: {
    ...mapFields('shopproduct/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    updated (updated) {
      if (updated) {
        this.confirmationCountdown = this.confirmationSeconds
      }
    },

    error (error) {
      if (error) {
        this.$router.push({ name: 'error-404' })
      }
    },

    retrieved (retrieved) {
      if (!retrieved) {
        this.$router.push({ name: 'notfound' })
      }
    }
  },

  created () {
    this.retrieve('shop_products/' + decodeURIComponent(this.$route.params.id))
  },

  beforeDestroy () {
    this.updateReset()
  },

  methods: {
    ...mapActions({
      retrieve: 'shopproduct/update/retrieve',
      updateReset: 'shopproduct/update/reset',
      update: 'shopproduct/update/update',
      updateRetrieved: 'shopproduct/update/updateRetrieved'
    }),

    confirmationCountdownUpdated (value) {
      this.confirmationCountdown = value
    },

    serializeModule (module) {
      let self = this
      // eslint-disable-next-line no-undef
      let item = _.cloneDeep(module)
      item = { ...item, ...item.section }

      item.submodules = []
      item.content = JSON.stringify(item.content)

      delete item.id
      delete item['@id']
      delete item['@type']
      delete item.section

      if (module.submodules && module.submodules.length > 1) {
        module.submodules.forEach((child) => {
          module.submodules.push(self.serializeModule(child))
        })
      }

      return item
    },

    onSendForm () {
      let self = this
      let modules = []
      let prices = []

      this.retrieved.modules.forEach((module) => {
        modules.push(self.serializeModule(module))
      })

      this.retrieved.prices.forEach((price) => {
        prices.push({
          label: price.label,
          price: price.price.toString(),
          quantity: (price.quantity || price.quantity === 0) ? parseInt(price.quantity) : null,
          images: price.images
        })
      })

      let payload = {
        name: this.retrieved.name,
        description: this.retrieved.description,
        image: this.retrieved.image,
        prices,
        quantity: (this.retrieved.quantity || this.retrievedquantity === 0) ? parseInt(this.retrieved.quantity) : null,
        requireShipping: this.retrieved.requireShipping,
        showIfEmpty: this.retrieved.showIfEmpty,
        active: this.retrieved.active,
        modules
      }

      this.update({
        id: 'shop_products/' + this.retrieved.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
