import List from '@/views/weddinglistitem/List'
import Update from '@/views/weddinglistitem/Update'
import Create from '@/views/weddinglistitem/Create'

export default [
  {
    path: '/wedding/list',
    name: 'weddinglistitem-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'wedding.setting',
      title: 'Liste de mariage'
    }
  },
  {
    path: '/wedding/list/create',
    name: 'weddinglistitem-create',
    component: Create,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'wedding.setting',
      title: 'Ajouter un cadeau'
    }
  },
  {
    path: '/wedding/list/:id',
    name: 'weddinglistitem-update',
    component: Update,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'wedding.setting',
      title: 'Editer un cadeau'
    }
  }
]
