import * as types from './mutation_types'

export default {
  [types.RESET] (state) {
    Object.assign(state, {
      updatingModule: null,
      builderType: null,
      pageType: null
    })
  },

  [types.SET_UPDATING_MODULE] (state, updatingModule) {
    Object.assign(state, { updatingModule })
  },

  [types.SET_BUILDER_TYPE] (state, builderType) {
    Object.assign(state, { builderType })
  },

  [types.SET_PAGE_TYPE] (state, pageType) {
    Object.assign(state, { pageType })
  }
}
