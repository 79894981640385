<template>
  <div class="page-website-create">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des sites
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Créer un site
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <div :class="{ 'is-loading' : isFormLoading }">
      <WebsiteForm
        :handle-submit="onSendForm"
        :values="item"
        :errors="violations"
      />
    </div>
  </div>
</template>

<script>
import WebsiteForm from '../../components/website/CreateForm'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    WebsiteForm
  },

  data () {
    return {
      item: {
        name: '',
        subdomain: '',
        topic: ''
      },
      isFormLoading: false
    }
  },

  computed: {
    ...mapFields('website/create', {
      error: 'error',
      isLoading: 'isLoading',
      created: 'created',
      violations: 'violations'
    }),

    ...mapFields('application/auth', {
      isUserLoading: 'isLoading',
      isLogged: 'isLogged',
      hasWebsite: 'hasWebsite',
      hasWebsiteSelected: 'hasWebsiteSelected',
      user: 'user'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    user (user) {
      if (!user) {
        return
      }

      this.isFormLoading = false

      this.$router.push({ name: 'dashboard-home' })
    },

    created (created) {
      if (!created) {
        return
      }

      this.refreshToken({
        toggleLoading: false,
        forceRefresh: true
      })
    }
  },

  methods: {
    ...mapActions({
      create: 'website/create/create',
      refreshToken: 'application/auth/refreshToken'
    }),

    onSendForm () {
      this.isFormLoading = true
      this.create(this.item)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
