<template>
  <div class="page-option-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion de l'abonnement
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Nos offres
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="!loadedBilling || !loadedSubscriptions || !loadedDelete"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="!loadedBilling || !loadedSubscriptions">
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <div
      v-if="loadedBilling && loadedSubscriptions && loadedAddons"
      class="position-relative"
    >
      <div class="bg-white rounded-lg shadow-sm overflow-x-auto mb-4">
        <div class="plan-list">
          <div class="row mx-0 align-items-stretch text-center border-bottom">
            <div class="col-3 d-flex flex-column align-items-center py-3 text-left" />
            <div class="col-3 d-flex flex-column align-items-center py-3 border-left">
              <div class="f-nunito text-uppercase text-soft font-weight-600 f-15">
                Offre gratuite
              </div>
              <div class="f-nunito text-uppercase f-24 font-weight-600 mt-n1">
                Gratuite
              </div>
              <div v-if="currentPlan === 'free'">
                <span class="bg-light rounded-pill f-14 px-2 font-weight-600">Sélectionné</span>
              </div>
            </div>
            <div class="col-3 d-flex flex-column align-items-center py-3 border-left">
              <div class="f-nunito text-uppercase text-soft font-weight-600 f-15">
                Offre premium
              </div>
              <div class="f-nunito text-uppercase f-24 font-weight-600 mt-n1">
                24.99€ / mois
              </div>
              <div v-if="currentPlan === 'premium'">
                <span class="bg-light rounded-pill f-14 px-2 font-weight-600">Sélectionné</span>
              </div>
            </div>
            <div class="col-3 d-flex flex-column align-items-center py-3 border-left">
              <div class="f-nunito text-uppercase text-soft font-weight-600 f-15">
                Offre pro
              </div>
              <div class="f-nunito text-uppercase f-24 font-weight-600 mt-n1">
                49.99€ / mois
              </div>
              <span class="bg-success-light rounded-pill f-14 px-2 font-weight-600">Bientôt disponible</span>
              <div v-if="currentPlan === 'pro'">
                <span class="bg-light rounded-pill f-14 px-2 font-weight-600">Sélectionné</span>
              </div>
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              Utilisateurs
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              1
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              Illimité
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              Illimité
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center bg-light-grey">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              Site en HTTPS
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              Sous-domaine
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center bg-light-grey">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              Domaine personnalisé
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-times text-soft" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              Pages
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              5
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              Illimité
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              Illimité
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center bg-light-grey">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              Thème personnalisable
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              Editeur de thème HTML
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-times text-soft" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center bg-light-grey">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              <div>
                <span class="mr-2">Boutique</span>
              </div>
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              Jusqu'à <span class="font-weight-600 text-soft">200€ / mois</span>
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              Jusqu'à <span class="font-weight-600 text-soft">2 000€ / mois</span>
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <span class="font-weight-600 text-primary">Aucune limite</span>
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              Stockage de fichiers
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              Jusqu'à <span class="font-weight-600 text-soft">100 Mo</span>
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              Jusqu'à <span class="font-weight-600 text-soft">1 Go</span>
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              Jusqu'à <span class="font-weight-600 text-primary">100 Go</span>
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center bg-light-grey">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              SEO avancé
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-times text-soft" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-times text-soft" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
          </div>
          <div class="row mx-0 align-items-stretch text-center">
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600">
              Statistiques de visites
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-times text-soft" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-times text-soft" />
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <i class="far fa-check text-primary" />
            </div>
          </div>
          <div
            v-if="currentPlan !== 'free'"
            class="d-flex justify-content-center border-top py-3"
          >
            <button
              class="btn btn-primary btn-sm px-3 rounded-pill"
              @click.prevent="manageSubscriptions"
            >
              Gérer l'abonnement
            </button>
          </div>

          <div
            v-if="currentPlan === 'free'"
            class="row mx-0 align-items-stretch text-center border-top"
          >
            <div class="col-3 d-flex flex-column justify-content-center text-left py-3 font-weight-600" />
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <button
                v-if="currentPlan !== 'premium'"
                class="btn btn-primary btn-sm px-3 rounded-pill"
                @click.prevent="subscribe(premiumPlan.priceId)"
              >
                Choisir cette offre
              </button>
            </div>
            <div class="col-3 d-flex flex-column justify-content-center align-items-center py-3 border-left">
              <span class="bg-success-light rounded-pill f-14 px-2 font-weight-600">Bientôt disponible</span>
              <button
                v-if="currentPlan !== 'pro' && false"
                class="btn btn-primary btn-sm px-3 rounded-pill"
                @click.prevent="subscribe(proPlan.priceId)"
              >
                Choisir cette offre
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { STRIPE_KEY } from '@/config/entrypoint'
import CreateCard from '../../components/stripe/CreateCard'
import FormInput from '../../components/applicationform/FormInput'

export default {
  components: {
    CreateCard,
    FormInput
  },

  data () {
    return {
      loadedAddons: false,
      loadedSubscriptions: false,
      loadedBilling: false,
      loadedDelete: true,
      stripe: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('stripeportalsession/show', {
      portalSessionIsLoading: 'isLoading',
      portalSession: 'retrieved'
    }),

    ...mapFields('websitebilling/show', {
      websiteBillingIsLoading: 'isLoading',
      websiteBilling: 'retrieved'
    }),

    ...mapFields('stripesubscription/list', {
      subscriptionsError: 'error',
      subscriptions: 'items',
      subscriptionsIsLoading: 'isLoading'
    }),

    ...mapFields('stripecheckoutsession/create', {
      checkoutSession: 'created'
    }),

    ...mapFields('addon/list', {
      addons: 'items',
      isLoading: 'isLoading'
    }),

    currentPlan () {
      let plan = 'free'
      let self = this

      if (this.activeSubscription) {
        this.activeSubscription.items.forEach((product) => {
          if (product.product === self.premiumPlan.productId) {
            plan = 'premium'
          }

          if (product.product === self.proPlan.productId) {
            plan = 'pro'
          }
        })
      }

      return plan
    },

    activeSubscription () {
      let activeSubscription = null
      this.subscriptions.forEach((subscription) => {
        if (subscription.status === 'active') {
          activeSubscription = subscription
        }
      })

      return activeSubscription
    },

    premiumPlan () {
      let plan = {}

      this.addons.forEach((item) => {
        if (item.slug === 'premium') {
          plan = item
        }
      })

      return plan
    },

    proPlan () {
      let plan = {}

      this.addons.forEach((item) => {
        if (item.slug === 'pro') {
          plan = item
        }
      })

      return plan
    },

    options () {
      return this.addons.filter((item) => {
        return item.slug !== 'pro' && item.slug !== 'premium'
      })
    }
  },

  watch: {
    checkoutSession (session) {
      if (session) {
        this.stripe.redirectToCheckout({ sessionId: session.sessionId });
      }
    },

    portalSession (session) {
      if (session && session.url) {
        window.location.href = session.url
      }
    },

    subscriptions (subscriptions) {
      if (subscriptions) {
        this.loadedSubscriptions = true
      }
    },

    addons (addons) {
      if (addons) {
        this.loadedAddons = true
      }
    },

    websiteBilling (billing) {
      if (billing) {
        this.loadedBilling = true
        // noinspection JSUnresolvedVariable
        this.getSubscriptions('stripe_customers/' + billing.stripeCustomerId + '/subscriptions')
      }
    }
  },

  mounted () {
    this.initStripe()
    this.getAddons()
    // noinspection JSUnresolvedVariable
    this.getWebsiteBilling('websites/' + this.user.selectedWebsite.id + '/billing')
  },

  beforeDestroy () {
    this.resetAddons()
    this.resetSubscriptions()
  },

  methods: {
    ...mapActions({
      refreshUserAddons: 'application/auth/refreshToken',
      getAddons: 'addon/list/default',
      resetAddons: 'stripesubscription/list/reset',
      getWebsiteBilling: 'websitebilling/show/retrieve',
      getPortalSession: 'stripeportalsession/show/retrieve',
      createSubscription: 'stripecheckoutsession/create/create',
      getSubscriptions: 'stripesubscription/list/default',
      resetSubscriptions: 'stripesubscription/list/reset'
    }),

    refreshToken ({ toggleLoading = false, forceRefresh = true }) {
      let self = this
      setTimeout(function () {
        self.refreshUserAddons({ toggleLoading, forceRefresh })
      }, 2000)

      setTimeout(function () {
        self.refreshUserAddons({ toggleLoading, forceRefresh })
      }, 5000)

      setTimeout(function () {
        self.refreshUserAddons({ toggleLoading, forceRefresh })
      }, 10000)

      setTimeout(function () {
        self.refreshUserAddons({ toggleLoading, forceRefresh })
      }, 20000)
    },

    initStripe () {
      // noinspection JSUnresolvedFunction
      this.stripe = window.Stripe(STRIPE_KEY)
    },

    subscribe (price) {

      // noinspection JSUnresolvedVariable
      let payload = {
        item: price
      }

      this.createSubscription(payload)
    },

    manageSubscriptions () {
      this.getPortalSession('stripe_portal_sessions/' + this.user.selectedWebsite.id)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
