<template>
  <div class="form-website-setting">
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <div class="p-4">
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
          Référencement
        </div>

        <FormTextarea
          v-model="item.description"
          :is-valid="isValid('description')"
          :is-pre-valid="isPreValid('description')"
          label="Description du site"
          placeholder="Cette description sera affichée sur les moteurs de recherche"
          @input="updateValidation('description')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('description')"
              class="invalid-feedback"
            >
              {{ violations.description }}
            </div>
            <div
              v-if="!isPreValid('description')"
              class="invalid-feedback"
            >
              <div v-if="!$v.item.description.maxLength">
                La description de l'article doit contenir au maximum {{ $v.item.description.$params.maxLength.max }} caractères
              </div>
            </div>
          </template>
        </FormTextarea>

        <div class="mb-3 mt-5 text-primary font-weight-700 text-uppercase f-14">
          Formulaire de contact
        </div>
        <FormInput
          v-model="item.contactEmail"
          :is-valid="isValid('contactEmail')"
          :is-pre-valid="isPreValid('contactEmail')"
          label="Adresse email de contact"
          type="email"
          @input="updateValidation('contactEmail')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('contactEmail')"
              class="invalid-feedback"
            >
              {{ violations.contactEmail }}
            </div>
            <div
              v-if="!isPreValid('contactEmail')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.contactEmail.required">
                L'adresse email de contact doit être indiquée
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.contactEmail.email">
                Cette adresse n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>

        <div class="mb-3 mt-5 text-primary font-weight-700 text-uppercase f-14">
          Commentaires
        </div>
        <FormCheckbox
          v-model="item.displayComments"
          :is-valid="isValid('displayComments')"
          :is-switch="true"
          class="mb-0"
          label="Afficher les commentaires des articles"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('displayComments')"
              class="invalid-feedback"
            >
              {{ violations.displayComments }}
            </div>
          </template>
        </FormCheckbox>
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-primary px-3 rounded-pill"
        @click.prevent="submit()"
      >
        Mettre à jour les paramètres
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import { required, email, maxLength } from 'vuelidate/lib/validators'
import FormCheckbox from '../applicationform/FormCheckbox'
import FormInput from '../applicationform/FormInput'
import FormTextarea from '../applicationform/FormTextarea'

export default {
  components: {
    FormTextarea,
    FormCheckbox,
    FormInput
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    },

    allowMember () {
      return this.item.allowMember
    }
  },

  watch: {
    allowMember (allowMember) {
      if (!allowMember) {
        this.item.allowRegister = false
      }
    }
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      contactEmail: {
        required,
        email
      },

      description: {
        maxLength: maxLength(200)
      }
    }
  }
}
</script>
