<template>
  <div
    v-if="loadedListItems"
    class="form-weddingpayment"
  >
    <div class="bg-white shadow-sm rounded-lg my-3 overflow-hidden">
      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Nom et informations
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <FormInput
              v-model="item.firstname"
              :is-valid="isValid('firstname')"
              :is-pre-valid="isPreValid('firstname')"
              label="Prénom"
              type="text"
              class="mb-4"
              @input="updateValidation('firstname')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('firstname')"
                  class="invalid-feedback"
                >
                  {{ violations.name }}
                </div>
                <div
                  v-if="!isPreValid('firstname')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.firstname.required">
                    Le prénom doit être indiqué
                  </div>
                </div>
              </template>
            </FormInput>
          </div>

          <div class="col-12 col-md-6">
            <FormInput
              v-model="item.lastname"
              :is-valid="isValid('lastname')"
              :is-pre-valid="isPreValid('lastname')"
              label="Nom"
              type="text"
              class="mb-4"
              @input="updateValidation('lastname')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('lastname')"
                  class="invalid-feedback"
                >
                  {{ violations.name }}
                </div>
                <div
                  v-if="!isPreValid('lastname')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.lastname.required">
                    Le nom de famille doit être indiqué
                  </div>
                </div>
              </template>
            </FormInput>
          </div>
        </div>
      </div>

      <div class="px-4 pb-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Cadeau et montant
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <FormInput
              v-model="item.amount"
              :is-valid="isValid('amount')"
              :is-pre-valid="isPreValid('amount')"
              :append="'€'"
              label="Montant"
              type="number"
              class="mb-4"
              @input="updateValidation('amount')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('amount')"
                  class="invalid-feedback"
                >
                  {{ violations.amount }}
                </div>
                <div
                  v-if="!isPreValid('amount')"
                  class="invalid-feedback"
                >
                  <div v-if="!$v.item.amount.required">
                    Le montant doit être indiqué
                  </div>
                  <div v-if="!$v.item.amount.decimal">
                    Le montant doit être un nombre
                  </div>
                  <div v-if="!$v.item.amount.minValue">
                    Le montant doit être supérieur à 0€
                  </div>
                </div>
              </template>
            </FormInput>
          </div>

          <div class="col-12 col-md-6">
            <FormSelect
              v-model="item.listItem"
              :is-valid="isValid('listItem')"
              :list="giftItems"
              label="Cadeau"
              placeholder="Choisir un cadeau"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('listItem')"
                  class="invalid-feedback"
                >
                  {{ violations.listItem }}
                </div>
              </template>
            </FormSelect>
          </div>
        </div>
      </div>

      <div
        v-if="selectedItem"
        class="p-4 bg-light-grey"
      >
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Cadeau sélectionné
        </div>
        <div class="font-weight-600 text-success">{{ selectedItem.name }}</div>
        <div class="text-soft font-weight-600">{{ selectedItem.price }} € <small>({{ selectedItem.amountAvailable }} € disponibles)</small></div>
      </div>

      <div
        v-if="amountForItem || amountForPot"
        class="p-4"
      >
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Répartition du montant
        </div>
        <div
          v-if="amountForItem"
          class="font-weight-600"
        >
          Montant cadeau : <span class="text-success">{{ amountForItem }} €</span>
        </div>

        <div
          v-if="amountForPot"
          class="font-weight-600"
        >
          Montant cagnotte : <span class="text-success">{{ amountForPot }} €</span>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer le cadeau
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormInput from '../applicationform/FormInput'
import { minValue, decimal, required } from 'vuelidate/lib/validators'
import { mapFields } from 'vuex-map-fields'
import FormCheckbox from '../applicationform/FormCheckbox'
import permission from '../../utils/permission'
import FormTextarea from '../applicationform/FormTextarea'
import FileList from '../file/List'
import { S3_ENTRYPOINT } from '@/config/entrypoint'
import FormSelect from '@/components/applicationform/FormSelect'
import { mapActions } from 'vuex'

export default {
  components: {
    FormSelect,
    FormTextarea,
    FormCheckbox,
    FormInput,
    FileList
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      loadedListItems: false,
      initialItem: {},
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('weddinglistitem/list', {
      error: 'error',
      listItems: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    item () {
      return this.values || this.initialValues
    },

    violations () {
      return this.errors || {}
    },

    title () {
      return this.item.title
    },

    validation () {
      return this.$v.item
    },

    amountForPot () {
      return this.item.amount - this.amountForItem
    },

    amountForItem () {
      if (!this.item.listItem || !this.selectedItem) {
        return 0
      }

      return this.item.amount > this.selectedItem.amountAvailable ? this.selectedItem.amountAvailable : this.item.amount
    },

    selectedItem () {
      if (this.item.listItem) {
        return this.listItems.filter(e => e['@id'] === this.item.listItem).shift()
      }
    },

    giftItems () {
      let items = [
        {
          'id': null,
          'name': 'Contribution libre'
        }
      ]

      this.listItems.forEach((item) => {
        items.push({
          'id': item['@id'],
          'name': item.name
        })
      })

      return items
    }
  },

  watch: {
    listItems (items) {
      if (items) {
        this.loadedListItems = true
      }
    },

    amountForPot (amount) {
      this.item.amountForPot = amount
    },

    amountForItem (amount) {
      this.item.amountForItem = amount
    }
  },

  mounted () {
    // eslint-disable-next-line no-undef
    this.initialItem = _.cloneDeep(this.item)
    this.getItems({ page: 'websites/' + this.user.selectedWebsite.id + '/wedding/list_items' })
  },

  beforeDestroy () {
    this.resetItems()
  },

  methods: {
    ...mapActions({
      getItems: 'weddinglistitem/list/default',
      resetItems: 'weddinglistitem/list/reset',
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      firstname: {
        required,
      },

      lastname: {
        required,
      },

      amount: {
        required,
        decimal,
        minValue: minValue(0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-remove {
  position: absolute;
  top: rem-calc(10);
  right: rem-calc(25);
  width: rem-calc(32);
  height: rem-calc(32);
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
</style>
