var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-contact-preview"},[_c('div',{class:{ 'container': _vm.hasContainer }},[_c('div',{class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"row align-items-stretch"},_vm._l((3),function(i){return _c('div',{staticClass:"col-12 col-md-4 d-flex align-items-stretch"},[_c('section',{staticClass:"my-3 border rounded-lg overflow-hidden w-100 d-flex flex-column align-items-stretch w-100"},[_c('div',{staticClass:"embed-responsive",staticStyle:{"padding-bottom":"200px","background-position":"center","background-size":"cover","background-image":"url(https://s3.eu-west-3.amazonaws.com/static.groowy.fr/pexels-evie-shaffer-2923672.jpg)"}}),_c('div',{staticClass:"p-3 flex-grow-1 d-flex flex-column justify-content-between"},[_c('div',[_c('h4',{staticClass:"mb-0 text-primary"},[_vm._v("Article n°"+_vm._s(i))]),_vm._m(0,true),_c('div',{staticClass:"mt-3"},[_vm._v("\n                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.rem\n                ")])]),_c('div',{staticClass:"text-center mt-3 pb-1"},[_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":function($event){$event.preventDefault();}}},[_vm._v("Lire cet article")])])])])])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-muted"},[_c('small',[_vm._v("Publié le xx/xx/xxxx")])])}]

export { render, staticRenderFns }