<template>
  <div id="app">
    <div
      v-if="$router.currentRoute.meta.layout === 'empty'"
      class="container"
    >
      <RouterView />
    </div>

    <Layout
      v-if="!$router.currentRoute.meta.layout || $router.currentRoute.meta.layout === 'default' || $router.currentRoute.meta.layout === 'fluid' || $router.currentRoute.meta.layout === 'dynamic'"
      :fluid="$router.currentRoute.meta.layout && ($router.currentRoute.meta.layout === 'fluid' || ($router.currentRoute.meta.layout === 'dynamic' && layout === 'fluid'))"
      :sidebar="!($router.currentRoute.meta.layout && ($router.currentRoute.meta.layout === 'dynamic' && layout === 'fluid' && !sidebar))"
      :editor="editor"
    >
      <RouterView />
    </Layout>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Layout from './components/applicationlayout/Layout'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    Layout
  },

  data () {
    return {
      layout: 'default',
      sidebar: true,
      editor: false,
    }
  },

  computed: {
    ...mapFields('application/login', [
      'isLoading',
      'error',
      'user',
      'violations'
    ]),

    ...mapFields('application/auth', [
      'isLoading',
      'isLogged',
      'hasWebsite',
      'hasWebsiteSelected',
      'user'
    ])
  },

  mounted: function () {
    this.$root.$on('layout', this.changeLayout)
    this.$root.$on('sidebar', this.changeSidebar)
    this.$root.$on('editor', this.changeEditor)

    this.$nextTick(function () {
      window.setInterval(() => {
        // noinspection JSValidateTypes
        this.refreshToken({ toggleLoading: false, forceRefresh: true })
      }, 5 * 60 * 1000) // Every 5 minutes
    })
  },

  methods: {
    ...mapActions({
      refreshToken: 'application/auth/refreshToken'
    }),

    changeLayout (layout) {
      this.layout = layout
    },

    changeSidebar (sidebar) {
      this.sidebar = sidebar
    },

    changeEditor (editor) {
      this.editor = editor
    }
  }
}
</script>

<style lang="scss">
  @import "assets/scss/variables";
  @import "~bootstrap/scss/bootstrap";
  @import "~bootstrap-vue/dist/bootstrap-vue.css";
  @import "assets/scss/main";
</style>
