import { options } from './_default'

export const memberSocials = {
  type: 'member-socials',
  position: null,
  variant: 'default',
  content: {
    size: 22,
    coloredIcons: true,
    vertical: false,
    displayName: false,
    align: 'start'
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
