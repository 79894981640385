import { options } from '../elements/_default'
import { column } from './column'

column.position = 0

let component = {
  type: 'row',
  position: null,
  variant: 'default',
  content: {
    align: 'center',
    verticalAlign: 'start',
    fullWidth: false
  },
  submodules: [
    column
  ],
  dependencies: [],
  section: _.cloneDeep(options)
}

component.section.paddingLeft = '3'
component.section.paddingRight = '3'

export const row = component
