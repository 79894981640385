<template>
  <div class="component-member-list-preview">
    <div
      :class="[
        'mt-' + content.section.marginTop.replace('-', 'n'),
        'mb-' + content.section.marginBottom.replace('-', 'n')
      ]"
    >
      <div
        :class="[
          'ml-' + content.section.marginLeft.replace('-', 'n'),
          'mr-' + content.section.marginRight.replace('-', 'n'),
          'pt-' + content.section.paddingTop,
          'pb-' + content.section.paddingBottom,
          'pl-' + content.section.paddingLeft,
          'pr-' + content.section.paddingRight,
          moduleClass
        ]"
        :style="moduleStyle"
      >
        <div class="container">
          <div class="row align-items-stretch">
            <div
              v-for="index in 4"
              :key="index"
              class="col-12 col-sm-6 col-md-4 col-lg-3 my-3"
            >
              <div
                v-if="content.content.showAvatar"
                class="d-flex justify-content-center p-3"
              >
                <div
                  class="avatar-size position-relative"
                  :style="'width:' + content.content.avatarSize + 'px'"
                >
                  <div class="avatar-container w-100 position-relative">
                    <div class="position-absolute top-0 left-0 embed-responsive embed-responsive-1by1 rounded-circle overflow-hidden shadow-sm">
                      <div
                        class="embed-responsive-item"
                        style="background-position: center; background-size: cover;"
                        :style="avatarStyle"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center pt-2">
                <h6
                  class="font-weight-600"
                  :class="[ 'f-' + content.content.usernameFont ]"
                  :style="usernameStyle"
                >
                  John Doe
                </h6>

                <div
                  v-if="content.content.displayFields"
                  class="pt-1 f-14 text-muted"
                >
                  <div
                    v-for="(field, index) in content.content.displayedFields"
                    :key="index"
                    :style="'color:' + field.color"
                  >
                    {{ field.fieldName }}
                  </div>
                </div>
                <div
                  v-if="content.content.displaySocials"
                  class="d-flex justify-content-center align-items-center flex-wrap mw-100"
                  :class="{ 'colored-icons': content.content.coloredSocials, [ 'f-' + content.content.socialSize ]: true }"
                >
                  <div class="d-inline-flex p-1">
                    <i class="fab fa-facebook-square"></i>
                  </div>
                  <div class="d-inline-flex p-1">
                    <i class="fab fa-instagram-square"></i>
                  </div>
                  <div class="d-inline-flex p-1">
                    <i class="fab fa-twitter-square"></i>
                  </div>
                  <div class="d-inline-flex p-1">
                    <i class="fab fa-linkedin"></i>
                  </div>
                  <div class="d-inline-flex p-1">
                    <i class="fab fa-youtube-square"></i>
                  </div>
                  <div class="d-inline-flex p-1">
                    <i class="fab fa-twitch"></i>
                  </div>
                  <div class="d-inline-flex p-1">
                    <i class="fas fa-link"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ENTRYPOINT } from '@/config/entrypoint'

export default {
  props: {
    content: {
      type: Object,
      required: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      APP_API_ENTRYPOINT: ENTRYPOINT,
    }
  },

  computed: {
    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    avatarStyle () {
      let style = ''

      style += 'background-image: url(' + this.APP_API_ENTRYPOINT + '/assets/img/avatar.png);'

      if (this.content.content.avatarBackgroundColor) {
        style += 'background-color: ' + this.content.section.avatarBackgroundColor + ';'
      }

      return style
    },

    usernameStyle () {
      let style = ''

      if (this.content.content.usernameColor) {
        style += 'color: ' + this.content.content.usernameColor + ';'
      }

      if (this.content.content.usernameSize) {
        style += 'font-size: ' + this.content.content.usernameSize + 'px;'
      }

      if (this.content.content.usernameFont) {
        style += 'color: ' + this.content.content.usernameColor + ';'
      }

      return style
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px;'
      }

      return style
    }
  }
}
</script>

<style lang="scss" scoped>
  .avatar-size {
    max-width: 100%;

    & .avatar-container {
      padding-top: 100%;
    }
  }

  .colored-icons {
    .fa-facebook-square {
      color: #3b5998;
    }

    .fa-instagram-square {
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .fa-twitter-square {
      color: #00acee;
    }

    .fa-linkedin {
      color: #0e76a8;
    }

    .fa-youtube-square {
      color: #FF0000;
    }

    .fa-twitch {
      color: #6441a5;
    }
  }
</style>
