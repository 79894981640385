import List from '../views/page/List'
import Create from '../views/page/Create'
import Update from '../views/page/Update'

export default [
  {
    path: '/pages',
    name: 'page-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'page.list',
      title: 'Liste des pages'
    }
  },
  {
    path: '/pages/create',
    name: 'page-create',
    component: Create,
    meta: {
      layout: 'dynamic',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'page.create',
      title: 'Ajouter une page'
    }
  },
  {
    path: '/pages/:id',
    name: 'page-update',
    component: Update,
    meta: {
      layout: 'dynamic',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'page.update',
      title: 'Editer une page'
    }
  }
]
