import { options } from './_default'

export const image = {
  type: 'image',
  position: null,
  variant: 'default',
  content: {
    url: '',
    rounded: 'default',
    size: 'default',
    align: 'center',
    maxSize: '500',
    showName: false,
    name: '',
    showLink: false,
    link: ''
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
