<template>
  <div class="page-file-list">
    <!--suppress JSUnresolvedVariable -->
    <div v-if="(isLoading && (!items || items.length === 0)) || (isWebsiteInformationLoading && !websiteInformation)">
      <div
        class="bg-grey rounded-lg my-3"
        style="height: 110px"
      />
      <div
        class="bg-grey rounded-lg my-3"
        style="height: 400px"
      />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="items && !isWebsiteInformationLoading && websiteInformation"
      :class="{ 'is-loading': isLoading }"
    >
      <div class="my-3">
        <FileCreate
          :handle-create="addFile"
          :mime-type="mimeType"
        />
      </div>

      <div class="bg-white rounded-lg shadow-sm overflow-hidden my-3">
        <!--suppress JSUnresolvedVariable -->
        <div
          v-if="websiteInformation"
          class="bg-light p-3"
        >
          <span class="font-weight-600">{{ storage }}</span> ({{ storagePercent }} %)  utilisés
        </div>
        <div class="row m-3">
          <div
            v-for="item in items"
            :key="item.id"
            class="col-12 col-sm-6 col-lg-3 my-3"
          >
            <!--suppress JSUnresolvedVariable -->
            <div
              v-b-tooltip.hover
              :title="item.originalName"
              class="embed-responsive embed-responsive-1by1 rounded"
            >
              <div
                class="embed-responsive-item bg-light-grey rounded file-item border"
                :style="backgroundImage(item)"
                :class="{ 'is-loading' : deleting === item.id }"
              >
                <button
                  class="btn btn-sm btn-danger shadow-sm position-absolute top-0 right-0 mt-2 mr-2 rounded-circle"
                  @click.prevent="removeFile(item)"
                >
                  <i class="fa fa-trash" />
                </button>
                <!--suppress JSCheckFunctionSignatures -->
                <a
                  :href="fileLink(item)"
                  target="_blank"
                  class="d-flex justify-content-center align-items-center h-100 w-100"
                  @click.prevent="handleSelect(item)"
                >
                  <i
                    v-if="item.type === 'video'"
                    class="fas fa-video text-primary f-64"
                  />
                  <i
                    v-if="item.type === 'audio'"
                    class="fas fa-headphones text-primary f-64"
                  />
                  <i
                    v-if="item.type === 'file'"
                    class="fas fa-file text-primary f-64"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Pagination
        v-if="!Array.isArray(view)"
        v-model="page"
        :view="view"
      />

      <div class="d-flex justify-content-center mt-4 mb-3">
        <button
          class="btn btn-primary px-3 rounded-pill"
          @click.prevent="handleClose"
        >
          Fermer la liste des fichiers
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import FileCreate from '../../components/file/Create'
import Pagination from '../../components/applicationlayout/Pagination'
import { S3_ENTRYPOINT } from '../../config/entrypoint'

export default {
  components: {
    FileCreate,
    Pagination
  },

  props: {
    handleClose: {
      type: Function,
      required: true
    },

    handleSelect: {
      type: Function,
      required: true
    },

    type: {
      type: String,
      default: null
    },

    mimeType: {
      type: String,
      default: '*/*'
    }
  },

  data () {
    return {
      page: 1,
      deleting: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('file/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('file/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('websiteinformation/show', {
      websiteInformationError: 'error',
      isWebsiteInformationLoading: 'isLoading',
      websiteInformation: 'retrieved'
    }),

    storage () {
      let storage = '0 Ko'

      if (!this.websiteInformation) {
        return storage
      }

      let ko = this.websiteInformation.storage / 1024
      let mo = ko / 1024
      let go = mo / 1024

      if (go < 1000) {
        storage = Math.round(go * 100) / 100 + ' Go'
      }

      if (mo < 1000) {
        storage = Math.round(mo * 100) / 100 + ' Mo'
      }

      if (ko < 1000) {
        storage = Math.round(ko * 100) / 100 + ' Ko'
      }

      return storage
    },

    storagePercent () {
      if (!this.websiteInformation) {
        return 0
      }

      let allowed = 0.1

      // noinspection JSUnresolvedVariable
      if (this.user.selectedWebsite.addons.includes('premium')) {
        allowed = 1
      }

      // noinspection JSUnresolvedVariable
      if (this.user.selectedWebsite.addons.includes('pro')) {
        allowed = 100
      }

      let storage = ((this.websiteInformation.storage / 1024) / 1024) / 1024

      return Math.round(storage / allowed * 1000) / 10
    }
  },

  watch: {
    page (page) {
      let query = {
        fileGroup: 'file'
      }

      if (this.type) {
        query.type = this.type
      }

      query.page = page

      // noinspection JSUnresolvedVariable
      this.getFiles({ page: 'websites/' + this.user.selectedWebsite.id + '/files', query })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      this.deleting = null
      let self = this

      this.items.forEach((file, index) => {
        if (file.id === deleted.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
          self.websiteInformation.storage -= parseInt(file.size)
        }
      })
    }
  },

  mounted () {
    let query = {
      fileGroup: 'file'
    }

    if (this.type) {
      query.type = this.type
    }

    // noinspection JSUnresolvedVariable
    this.getFiles({ page: 'websites/' + this.user.selectedWebsite.id + '/files', query })
    // noinspection JSUnresolvedVariable
    this.getWebsiteInformation('websites/' + this.user.selectedWebsite.id + '/information')
  },

  beforeDestroy () {
    this.resetFiles()
    this.resetDelete()
  },

  methods: {
    ...mapActions({
      getFiles: 'file/list/default',
      deleteFile: 'file/del/del',
      resetFiles: 'file/list/reset',
      resetDelete: 'file/del/reset',
      getWebsiteInformation: 'websiteinformation/show/retrieve'
    }),

    addFile (file) {
      this.items.unshift(file)
      this.view.totalItems++
      this.websiteInformation.storage += parseInt(file.size)
    },

    removeFile (file) {
      this.deleting = file.id
      this.deleteFile(file)
    },

    backgroundImage (file) {
      if (file.type !== 'image') {
        return ''
      }

      return 'background-image: url(' + this.fileLink(file) + ')'
    },

    fileLink (file) {
      // noinspection JSUnresolvedVariable
      return S3_ENTRYPOINT + file.contentUrl
    }
  }
}
</script>

<style lang="scss" scoped>
  .embed-responsive:hover {
    box-shadow: 0 0 0 0.2rem rgba(9, 113, 254, 0.25);
  }

  .file-item {
    background-position: center;
    background-size: cover;

    &:hover {
      border-color: #88baff !important;
    }
  }
</style>
