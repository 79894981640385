import List from '@/views/weddingpayment/List'
import Update from '@/views/weddingpayment/Update'
import Create from '@/views/weddingpayment/Create'

export default [
  {
    path: '/wedding/payments',
    name: 'weddingpayment-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'wedding.setting',
      title: 'Liste des paiements'
    }
  },
  {
    path: '/wedding/payments/create',
    name: 'weddingpayment-create',
    component: Create,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'wedding.setting',
      title: 'Ajouter un paiement'
    }
  },
  {
    path: '/wedding/payments/:id',
    name: 'weddingpayment-update',
    component: Update,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'wedding.setting',
      title: 'Editer un paiement'
    }
  }
]
