var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-gallery-preview"},[_c('div',{class:{ 'container': !_vm.content.content.fullWidth && _vm.hasContainer }},[_c('div',{class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n')
      ]},[_c('div',{class:[
          'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
          'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
          'pt-' + _vm.content.section.paddingTop,
          'pb-' + _vm.content.section.paddingBottom,
          'pl-' + _vm.content.section.paddingLeft,
          'pr-' + _vm.content.section.paddingRight,
          _vm.moduleClass
        ],style:(_vm.moduleStyle)},[(!_vm.content.content.gallery)?_c('div',{staticClass:"w-100 mw-100 px-4 py-3 f-14 font-weight-600 bg-danger-light rounded"},[_c('i',{staticClass:"fas fa-exclamation-circle mr-2"}),_vm._v("Vous devez sélectionner une galerie\n        ")]):_vm._e(),(_vm.content.content.gallery && _vm.galleryLoading)?_c('div',{staticClass:"w-100 mw-100 px-4 py-3 f-14 font-weight-600 bg-light rounded"},[_vm._v("\n          Chargement de la galerie\n        ")]):_vm._e(),(_vm.content.content.gallery && _vm.galleryError)?_c('div',{staticClass:"w-100 mw-100 px-4 py-3 f-14 font-weight-600 bg-danger-light rounded"},[_vm._v("\n          Une erreur est survenue pendant le chargement de la galerie\n        ")]):_vm._e(),(_vm.content.content.gallery && _vm.gallery && !_vm.hasImages)?_c('div',{staticClass:"w-100 mw-100 px-4 py-3 f-14 font-weight-600 bg-danger-light rounded"},[_vm._v("\n          Cette galerie ne contient pas d'images\n        ")]):_vm._e(),(_vm.content.content.gallery && _vm.gallery && _vm.hasImages)?_c('div',{staticClass:"w-100 mw-100"},_vm._l((_vm.gallery.groups),function(group){return _c('GalleryGroupPreview',{attrs:{"group":group}})}),1):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }