export default function (data) {
  let regex = /\?.*page=(?<id>\d*)/gm

  let view = { pagination: false }

  if (data['hydra:view']) {
    view = data['hydra:view']
    view.page = 1
    view.maxPage = 1

    let regexResult = regex.exec(data['hydra:view']['@id'])
    if (regexResult && regexResult.groups && regexResult.groups.id) {
      view.page = parseInt(regexResult.groups.id)
      view.pagination = true
    }

    if (data['hydra:view']['hydra:last']) {
      let maxRegexResult = regex.exec(data['hydra:view']['hydra:last'])
      maxRegexResult = regex.exec(data['hydra:view']['hydra:last'])

      if (maxRegexResult && maxRegexResult.groups && maxRegexResult.groups.id) {
        view.maxPage = parseInt(maxRegexResult.groups.id)
      }
    }
  }

  view['totalItems'] = data['hydra:totalItems'] ? data['hydra:totalItems'] : 0

  return view
}
