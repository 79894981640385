<template>
  <div class="module-image">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'video'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
        Choix de la video
      </div>
      <div class="px-4 pt-4">
        <FormInput
          v-model="content.content.url"
          type="url"
          label="Adresse de la vidéo"
          class="mb-4"
        />
      </div>
    </div>

    <div v-if="tab === 'customization'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
        Options d'apparence
      </div>
      <div class="px-4 pt-4">
        <div class="form-group mb-4">
          <div class="form-control-label">
            Taille de la vidéo
          </div>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="full"
          >
            Afficher la vidéo sur toute la largeur de la page
          </b-form-radio>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="container"
          >
            Afficher la vidéo sur toute la largeur du contenu de la page
          </b-form-radio>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="max"
          >
            Afficher la vidéo selon une largeur maximale
          </b-form-radio>
          <FormInput
            v-if="content.content.size === 'max'"
            v-model="content.content.maxSize"
            class="mb-0 mt-1"
            placeholder="Largeur maximale en pixels"
            type="number"
          >
            <template v-slot:label />
          </FormInput>
        </div>

        <div class="form-group mb-4">
          <div class="form-control-label">
            Alignement de la vidéo
          </div>
          <b-form-radio
            v-model="content.content.align"
            name="some-radios"
            value="start"
          >
            Aligner à gauche
          </b-form-radio>
          <b-form-radio
            v-model="content.content.align"
            name="some-radios"
            value="center"
          >
            Aligner au centre
          </b-form-radio>
          <b-form-radio
            v-model="content.content.align"
            name="some-radios"
            value="end"
          >
            Aligner à droite
          </b-form-radio>
        </div>
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import FileList from '../../file/List'
import FormInput from '../../applicationform/FormInput'
import SectionAppearance from './../SectionAppearance'
import ProgressStep from '../../applicationcomponent/ProgressStep'
import ImagePreview from './../preview/ImagePreview'
import Tabs from '../../applicationcomponent/Tabs'

export default {
  components: {
    Tabs,
    ImagePreview,
    ProgressStep,
    FileList,
    FormInput,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  computed: {
    url () {
      return this.content.content.url
    }
  },

  watch: {
    url (url) {
      if (url) {
        const regex = /(watch\?v=|watch\/)/gm;
        const regex2 = /youtu\.be\//gm;
        const regex3 = /(\?.*|&.*)/gm;

        let newUrl = url
        newUrl = newUrl.replace(regex, 'embed/')
        newUrl = newUrl.replace(regex2, 'youtube.com/embed/')
        newUrl = newUrl.replace(regex3, '')

        if (this.content.content.url !== newUrl) {
          this.content.content.url = newUrl
        }
      }
    }
  },

  data () {
    return {
      tab: 'video',
      tabs: [
        {
          name: 'video',
          title: 'Choix de la vidéo'
        },
        {
          name: 'customization',
          title: 'Options de la vidéo'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
      },
      selection: false
    }
  }
}
</script>
