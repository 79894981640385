import * as types from './mutation_types'

export const updateModule = ({ commit }, module) => {
  commit(types.SET_UPDATING_MODULE, module)
}

export const updateBuilderType = ({ commit }, type) => {
  commit(types.SET_BUILDER_TYPE, type)
}

export const updatePageType = ({ commit }, type) => {
  commit(types.SET_PAGE_TYPE, type)
}

export const reset = ({ commit }) => {
  commit(types.RESET)
}
