<template>
  <div class="file-form">
    <FormDropzone
      :handle-create="handleCreate"
      :mime-type="mimeType"
    />
  </div>
</template>

<script>
import FormDropzone from '../applicationform/FormDropzone'

export default {
  components: {
    FormDropzone
  },

  props: {
    handleCreate: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    mimeType: {
      type: String,
      default: '*/*'
    }
  },

  computed: {
    item () {
      return this.values
    }
  }
}
</script>
