import { shape as shapeModule } from '@/config/pagebuilder/components/shape'
import { title as titleModule } from '@/config/pagebuilder/elements/title'
import { text as textModule } from '@/config/pagebuilder/elements/text'
import { articles as articlesModule } from '@/config/pagebuilder/components/articles'

let shape = _.cloneDeep(shapeModule)
shape.position = 0
shape.section.backgroundImage = 'https://scaleweb-local.s3.eu-west-3.amazonaws.com/website/1/files/5f7f16cd79d4f393146829.png'
shape.section.backgroundImageCover = false
shape.content['shape'] = 'custom'
shape.content['height'] = '250'
shape.content['unit'] = 'px'
shape.content = JSON.stringify(shape.content)
shape = { ...shape, ...shape.section }

let title = _.cloneDeep(titleModule)
title.position = 0
title['content'].text = 'Bienvenue sur ton site !'
title['content'].fontFamily = 'josefin'
title['content'].textShadow = 'true'
title['content'].textShadowColor = '#007BFF7A'
title['content'].alignment = 'center'
title.content = JSON.stringify(title.content)
title = { ...title, ...title.section }

shape.submodules = [
  title
]

let text = _.cloneDeep(textModule)
text.position = 1
text['content'].text = '<p>Ceci est un exemple de page d\'accueil. Pour simplifier ta prise en main du site nous avons cr&eacute;&eacute; automatiquement quelques pages et articles d\'exemples pour te pr&eacute;senter nos fonctionnalit&eacute;s. Contrairement &agrave; un article une page est "fixe" et peut-&ecirc;tre affich&eacute;e dans le menu de ton site que tu peux voir en haut de la page.</p><p>&nbsp;</p><p>Pour commencer nous avons g&eacute;n&eacute;r&eacute; la page d\'accueil, o&ugrave; nous sommes actuellement, ainsi que la page de contact. La page d\'accueil est la page principale de ton site, elle doit contenir toutes les informations importantes, elle peut par exemple afficher la liste des derniers articles publi&eacute;s. La page de contact contient un formulaire de contact que tes visiteurs peuvent utiliser pour t\'envoyer un mail depuis le site.</p><p>&nbsp;</p><p>La plupart de nos utilisateurs cr&eacute;ent une page "A propos" qui les introduit aux visiteurs du site. C\'est certainement un bon point de d&eacute;part te pr&eacute;senter ou pr&eacute;senter ton entreprise &agrave; tes futurs visiteurs. Tu peux donc te connecter sur ton <a href="//manager.groowy.fr" target="_blank" rel="noopener">tableau de bord</a> pour cr&eacute;er tes nouvelles pages.</p>'
text.section.marginBottom = '5'
text.section.marginTop = '5'
text.content = JSON.stringify(text.content)
text = { ...text, ...text.section }

let secondText = _.cloneDeep(textModule)
secondText.position = 2
secondText['content'].text = '<p>Tu as un doute ou tu as besoin d\'aide pour l\'utilisation de ton site ? N\'h&eacute;site pas &agrave; nous contacter &agrave; l\'adresse <a href="mailto:contact@groowy.fr">contact@groowy.fr</a>, nous serons heureux de t\'accompagner pour cr&eacute;er le site parfait !</p>'
secondText.section.marginBottom = '5'
secondText.section.paddingTop = '4'
secondText.section.paddingBottom = '4'
secondText.section.paddingLeft = '5'
secondText.section.paddingRight = '5'
secondText.section.backgroundColor = '#cee3ff'
secondText.section.borderRadius = '10'
secondText.content = JSON.stringify(secondText.content)
secondText = { ...secondText, ...secondText.section }

let articles = _.cloneDeep(articlesModule)
articles.position = 3
articles.section.marginBottom = '5'
articles.content = JSON.stringify(articles.content)
articles = { ...articles, ...articles.section }

export const pageHomeDefault = [
  shape,
  text,
  secondText,
  articles
]
