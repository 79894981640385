<template>
  <div class="component-row-preview">
    <BModal
      :id="'edit-modal-' + id"
      size="xl"
      body-class="px-0 pb-0"
      hide-header
      ok-only
      ok-title="Revenir à l'édition"
      ok-variant="primary rounded-pill px-3"
      footer-class="justify-content-center border-top"
    >
      <RowModule
        :content.sync="content"
      />
    </BModal>

    <div :class="{ 'container' : !content.content.fullWidth }">
      <div
        :class="[
          'mt-' + content.section.marginTop.replace('-', 'n'),
          'mb-' + content.section.marginBottom.replace('-', 'n'),
          'ml-' + content.section.marginLeft.replace('-', 'n'),
          'mr-' + content.section.marginRight.replace('-', 'n')
        ]"
      >
        <div
          v-if="!editing"
          class="d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center bg-light-grey py-2 px-3 z-index-8"
        >
          <div class="f-14 font-weight-600 ml-3 mb-2 ml-sm-0 mb-sm-0 mr-3">
            Zone de colonnes
            <i
              v-b-tooltip.hover
              class="far fa-question-circle text-success cursor-pointer ml-2"
              title="Ce bandeau n'apparaitra pas sur votre page."
            />
          </div>
        </div>

        <div
          class="d-inline-block w-100"
          :class="[
            'pt-' + content.section.paddingTop,
            'pb-' + content.section.paddingBottom,
            'pl-' + content.section.paddingLeft,
            'pr-' + content.section.paddingRight,
          ]"
          :style="moduleStyle"
        >
          <div
            class="row"
            :class="moduleClass"
          >
            <ColumnPreview
              v-for="(column, index) in content.submodules"
              :key="index"
              :class="{ 'opacity-50': (hover !== null && hover !== index && !editing) || (updatingModule && hover !== index && !column.submodules.includes(updatingModule) && updatingModule !== content) }"
              :content="column"
              :editing="editing"
              @mouseover.native="hover = index"
              @mouseleave.native="hover = null"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColumnPreview from './ColumnPreview'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'RowPreview',

  components: {
    ColumnPreview,
    RowModule: () => import('../modules/RowModule')
  },

  props: {
    content: {
      type: Object,
      required: true
    },

    editing: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      hover: null
    }
  },

  computed: {
    ...mapFields('pagebuilder/update', {
      updatingModule: 'updatingModule'
    }),

    id () {
      // noinspection JSUnresolvedVariable
      return 'rowpreview-' + this._uid
    },

    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      classes.push('justify-content-' + this.content.content.align)
      classes.push('align-items-' + this.content.content.verticalAlign)

      return classes
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px; overflow-hidden;'
      }

      return style
    }
  },

  methods: {
    editModule () {
      this.$bvModal.show('edit-modal-' + this.id)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
