<template>
  <div
    v-if="updatingModule"
    class="col-4 p-0 bg-white editor-sidebar d-none d-xl-flex align-items-stretch border-left border-right"
  >
    <ModuleForm :sticky="true" />
  </div>
</template>

<script>
import ModuleForm from './ModuleForm'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    ModuleForm
  },

  computed: {
    ...mapFields('pagebuilder/update', {
      updatingModule: 'updatingModule'
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
