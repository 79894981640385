import { options } from './_default'

export const articleComments = {
  type: 'article-comments',
  position: null,
  variant: 'default',
  content: {
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
