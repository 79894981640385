<template>
  <div class="page-website-create">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des paramètres
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste des paramètres
        </h2>
        <span
          v-if="!loadedSetting"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <div v-if="!loadedSetting">
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <div v-if="loadedSetting">
      <div class="bg-white rounded-lg shadow-sm overflow-hidden">
        <div class="row d-flex align-items-stretch border-bottom">
          <div class="col-12 col-xl-8">
            <div class="p-4">
              <div class="mb-4 text-primary font-weight-700 text-uppercase f-14">
                Informations
              </div>
              <div class="row mt-2">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Nom du site
                </div>
                <div class="col-12 col-lg-6">
                  {{ user.selectedWebsite.name }}
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Adresse gratuite
                </div>
                <div class="col-12 col-lg-6">
                  {{ user.selectedWebsite.url }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex align-items-stretch">
            <div class="d-flex justify-content-center align-items-center bg-light-grey w-100 p-3">
              <router-link
                :to="{ name: 'website-settings-informations' }"
                class="btn btn-outline-primary btn-sm px-3 rounded-pill"
              >
                Modifier les informations
              </router-link>
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-stretch border-bottom">
          <div class="col-12 col-xl-8">
            <div class="p-4">
              <div class="mb-4 text-primary font-weight-700 text-uppercase f-14">
                Domaine principal
              </div>
              <div class="row mt-n3">
                <div class="col-12 col-lg-9 f-14 text-success font-weight-500">
                  <div v-if="!user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.domain">
                    Vous devez souscrire à une offre PREMIUM ou PRO pour configurer le domaine principal de votre site.
                  </div>
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium') && user.selectedWebsite.domain">
                    Vous devez souscrire à une offre PREMIUM ou PRO pour continuer à utiliser le domaine principal de votre site.
                  </div>
                </div>
              </div>
              <div
                class="row mt-2 align-items-center"
                :class="{ 'opacity-40': !user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')}"
              >
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Domaine principal
                </div>
                <div class="col-12 col-lg-6">
                  {{ user.selectedWebsite.domain ? user.selectedWebsite.domain : 'Non configuré' }}
                </div>
                <div class="col-12 col-lg-9 f-14 text-success font-weight-500">
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="(user.selectedWebsite.addons.includes('pro') || user.selectedWebsite.addons.includes('premium')) && !user.selectedWebsite.domain">
                    <i class="fad fa-question-circle" /> Vous pouvez configurer un domaine principal pour utiliser votre propre nom de domaine pour votre site.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex align-items-stretch">
            <div class="d-flex justify-content-center align-items-center bg-light-grey w-100 p-3">
              <router-link
                v-if="!user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')"
                :to="{ name: 'website-subscription' }"
                class="btn btn-outline-success btn-sm px-3 rounded-pill"
              >
                Souscrire une offre
              </router-link>
              <router-link
                v-if="user.selectedWebsite.addons.includes('pro') || user.selectedWebsite.addons.includes('premium')"
                :to="{ name: 'website-settings-domain' }"
                class="btn btn-outline-primary btn-sm px-3 rounded-pill"
              >
                Configurer le domaine
              </router-link>
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-stretch border-bottom">
          <div class="col-12 col-xl-8">
            <div class="p-4">
              <div class="mb-4 text-primary font-weight-700 text-uppercase f-14">
                Référencement
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600 pb-2">
                  Description du site
                </div>
                <div
                  v-if="!websiteSetting.description"
                  class="col-12 col-lg-6 text-muted"
                >
                  Description manquante
                </div>
                <div
                  v-if="websiteSetting.description"
                  class="col-12"
                >
                  {{ websiteSetting.description }}
                </div>
              </div>
              <div class="mb-4 mt-5 text-primary font-weight-700 text-uppercase f-14">
                Formulaire de contact
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Adresse email de contact
                </div>
                <div class="col-12 col-lg-6">
                  {{ websiteSetting.contactEmail }}
                </div>
              </div>

              <div class="mb-4 mt-5 text-primary font-weight-700 text-uppercase f-14">
                Commentaires
              </div>
              <div class="row mt-n3">
                <div
                  v-if="!user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')"
                  class="col-12 col-lg-9 f-14 text-success font-weight-500"
                >
                  Vous devez souscrire à une offre PREMIUM ou PRO pour bénéficier des fonctionalités liés aux commentaires.
                </div>
              </div>
              <div
                class="row mt-2 align-items-center"
                :class="{ 'opacity-40': !user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')}"
              >
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Afficher les commentaires des articles
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ websiteSetting.displayComments ? 'text-success' : 'text-danger' ]"
                >
                  {{ websiteSetting.displayComments ? 'Oui' : 'Non' }}
                </div>
              </div>
              <div
                class="row mt-3 align-items-center"
                :class="{ 'opacity-40': !user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')}"
              >
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Autoriser les visiteurs à écrire des commentaires sans se connecter
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ websiteSetting.allowAnonymousComment ? 'text-success' : 'text-danger' ]"
                >
                  {{ websiteSetting.allowAnonymousComment ? 'Oui' : 'Non' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex align-items-stretch">
            <div class="d-flex flex-column justify-content-center align-items-center bg-light-grey w-100 p-3">
              <router-link
                v-if="!user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')"
                :to="{ name: 'website-subscription' }"
                class="btn btn-outline-success btn-sm px-3 mb-3 rounded-pill"
              >
                Souscrire une offre
              </router-link>

              <router-link
                :to="{ name: 'website-settings-settings' }"
                class="btn btn-outline-primary btn-sm px-3 rounded-pill"
              >
                Configurer les paramètres
              </router-link>
            </div>
          </div>
        </div>
        <div class="row d-flex align-items-stretch border-bottom">
          <div class="col-12 col-xl-8">
            <div class="p-4">
              <div class="mb-4 text-primary font-weight-700 text-uppercase f-14">
                Membres
              </div>
              <div class="row mt-n3">
                <div
                  v-if="!user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')"
                  class="col-12 col-lg-9 f-14 text-success font-weight-500"
                >
                  Vous devez souscrire à une offre PREMIUM ou PRO pour bénéficier des fonctionalités liés aux membres.
                </div>
              </div>
              <div
                class="row mt-2 align-items-center"
                :class="{ 'opacity-40': !user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')}"
              >
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Les membres peuvent se connecter
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ websiteSetting.allowMember ? 'text-success' : 'text-danger' ]"
                >
                  {{ websiteSetting.allowMember ? 'Oui' : 'Non' }}
                </div>
              </div>
              <div
                class="row mt-3 align-items-center"
                :class="{ 'opacity-40': !user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')}"
              >
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Les visiteurs peuvent s'inscrire
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ websiteSetting.allowRegister ? 'text-success' : 'text-danger' ]"
                >
                  {{ websiteSetting.allowRegister ? 'Oui' : 'Non' }}
                </div>
              </div>
              <div
                class="row mt-3 align-items-center"
                :class="{ 'opacity-40': !user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')}"
              >
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Les membres doivent être validés par un administrateur pour pouvoir se connecter
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ websiteSetting.memberMustBeValidated ? 'text-success' : 'text-danger' ]"
                >
                  {{ websiteSetting.memberMustBeValidated ? 'Oui' : 'Non' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex align-items-stretch">
            <div class="d-flex flex-column justify-content-center align-items-center bg-light-grey w-100 p-3">
              <router-link
                v-if="!user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium')"
                :to="{ name: 'website-subscription' }"
                class="btn btn-outline-success btn-sm px-3 mb-3 rounded-pill"
              >
                Souscrire une offre
              </router-link>

              <router-link
                :to="{ name: 'website-settings-members' }"
                class="btn btn-outline-primary btn-sm px-3 rounded-pill"
              >
                Configurer les membres
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-if="checkPermission('website.delete')"
          class="row d-flex align-items-stretch"
        >
          <div class="col-12 col-xl-8">
            <div class="p-4">
              <div class="text-danger font-weight-700 text-uppercase f-14">
                Suppression du site
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex align-items-stretch">
            <div class="d-flex justify-content-center align-items-center bg-light-grey w-100 p-3">
              <button
                v-if="deleteIsLoading"
                class="btn btn-outline-danger btn-sm px-3 rounded-pill"
              >
                Suppression en cours
              </button>

              <button
                v-if="!deleteIsLoading"
                class="btn btn-outline-danger btn-sm px-3 rounded-pill"
                id="website-delete"
              >
                Supprimer le site
              </button>

              <BPopover
                target="website-delete"
                triggers="focus"
                placement="auto"
                ref="website-delete-popover"
              >
                <div class="text-danger font-weight-600 p-2">
                  Êtes vous certain de vouloir supprimer ce site ? Cette action est définitive.
                </div>
                <div class="text-center pt-2 pb-3">
                  <button
                    class="btn btn-outline-danger btn-sm px-3 rounded-pill"
                    @click.prevent="deleteWebsite"
                  >
                    Confirmer la suppression
                  </button>

                </div>
              </BPopover>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import permission from '@/utils/permission'

export default {
  data () {
    return {
      loadedSetting: false
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('websitesetting/show', {
      websiteSetting: 'retrieved',
      websiteSettingIsLoading: 'isLoading'
    }),

    ...mapFields('website/del', {
      deleted: 'deleted',
      deleteIsLoading: 'loading'
    }),
  },

  watch: {
    deleted (deleted) {
      if (deleted) {
        this.refreshToken({
          toggleLoading: true,
          forceRefresh: true
        })

        this.$router.push({ name: 'dashboard-home' })
      }
    },

    websiteSetting (setting) {
      if (setting) {
        this.loadedSetting = true
      }
    }
  },

  beforeDestroy () {
    this.resetSetting()
  },

  mounted () {
    this.getWebsiteSetting('websites/' + this.user.selectedWebsite.id + '/setting')
  },

  methods: {
    ...mapActions({
      resetSetting: 'websitesetting/show/reset',
      getWebsiteSetting: 'websitesetting/show/retrieve',
      delete: 'website/del/del',
      refreshToken: 'application/auth/refreshToken'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    deleteWebsite () {
      this.delete('websites/' + this.user.selectedWebsite.id)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
