<template>
  <div class="component-article-thumbnail-preview">
    <div
      :class="[
        { 'container': content.content.size !== 'full' && hasContainer },
        'mt-' + content.section.marginTop.replace('-', 'n'),
        'mb-' + content.section.marginBottom.replace('-', 'n')
      ]"
    >
      <div
        :class="[
          'ml-' + content.section.marginLeft.replace('-', 'n'),
          'mr-' + content.section.marginRight.replace('-', 'n'),
          'pt-' + content.section.paddingTop,
          'pb-' + content.section.paddingBottom,
          'pl-' + content.section.paddingLeft,
          'pr-' + content.section.paddingRight,
          moduleClass
        ]"
        :style="moduleStyle"
      >
        <div
          class="d-flex flex-column"
          :class="{
            'align-items-start': !content.content.align || content.content.align === 'start',
            'align-items-center': content.content.align === 'center',
            'align-items-end': content.content.align === 'end',
          }"
        >
          <img
            v-if="!content.content.ratio"
            class="mw-100"
            :class="{
              'rounded-lg': content.content.rounded === 'rounded',
              'rounded-circle': content.content.rounded === 'rounded-circle'
            }"
            :style="imageStyle"
            src="https://scaleweb-local.s3.eu-west-3.amazonaws.com/website/1/files/5f32ad8adf163530333131.png"
          >
          <div
            v-if="content.content.ratio"
            :class="[ 'embed-responsive embed-responsive-' + content.content.ratio, {
              'rounded-lg': content.content.rounded === 'rounded',
              'rounded-circle': content.content.rounded === 'rounded-circle'
            }]"
            :style="'background-position: center; background-repeat: no-repeat; background-size: cover; background-image: url(https://scaleweb-local.s3.eu-west-3.amazonaws.com/website/1/files/5f32ad8adf163530333131.png);' + imageStyle + (content.content.ratio === 'height' ? 'padding-top:' + content.content.height + 'px;' : '')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px;'
      }

      return style
    },

    imageStyle () {
      return 'width: ' + ((this.content.content.size === 'max' && this.content.content.maxSize) ? this.content.content.maxSize + 'px;' : '100%;')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
