<template>
  <div class="pagination d-flex justify-content-center">
    <nav
      v-if="maxPage > 1"
      class="shadow-sm font-weight-600"
    >
      <ul class="pagination mb-0">
        <li
          class="page-item"
          :class="{ 'disabled': page === 1 }"
        >
          <a
            class="page-link"
            href="#"
            @click.prevent="updateValue(1)"
          >
            <i class="far fa-chevron-double-left f-14" />
          </a>
        </li>

        <li
          v-if="page > 1"
          class="page-item"
        >
          <a
            class="page-link"
            href="#"
            @click.prevent="updateValue(page - 1)"
          >
            {{ page - 1 }}
          </a>
        </li>

        <li class="page-item disabled">
          <a
            class="page-link"
            href="#"
            @click.prevent=""
          >
            {{ page }}
          </a>
        </li>

        <li
          v-if="page !== maxPage"
          class="page-item"
        >
          <a
            class="page-link"
            href="#"
            @click.prevent="updateValue(page + 1)"
          >
            {{ page + 1 }}
          </a>
        </li>

        <li
          class="page-item"
          :class="{ 'disabled': page === maxPage }"
        >
          <a
            class="page-link"
            href="#"
            @click.prevent="updateValue(maxPage)"
          >
            <i class="far fa-chevron-double-right f-14" />
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },

    view: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      page: this.value
    }
  },

  computed: {
    maxPage () {
      return this.view.maxPage ? this.view.maxPage : 1
    }
  },

  watch: {
    value (value) {
      if (value !== this.page) {
        this.page = value
      }
    }
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
