<template>
  <div class="page-weddinglistitem-create">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <router-link
          :to="{ name: 'weddinglistitem-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste de mariage
        </router-link>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Créer un cadeau
        </h2>
        <span
          v-if="!loadedWedding"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>

        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="loadedWedding"
      :class="{ 'is-loading' : isLoading }"
    >
      <WeddingListItemForm
        :handle-submit="onSendForm"
        :values="item"
        :errors="violations"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import WeddingListItemForm from '../../components/weddinglistitem/Form'

export default {
  components: {
    WeddingListItemForm
  },

  data () {
    return {
      loadedWedding: false,
      item: {
        name: '',
        description: '',
        image: '',
        price: 0,
        quantity: 1,
        canBeShared: true,
        active: true
      }
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('wedding/show', {
      wedding: 'retrieved'
    }),

    ...mapFields('weddinglistitem/create', {
      error: 'error',
      isLoading: 'isLoading',
      created: 'created',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return
      }

      this.$router.push({ name: 'weddinglistitem-list' })
    },

    wedding (retrieved) {
      if (retrieved) {
        this.loadedWedding = true
      }
    }
  },

  mounted () {
    this.retrieveWedding('websites/' + this.user.selectedWebsite.id + '/wedding')
  },

  beforeDestroy () {
    this.createReset()
  },

  methods: {
    ...mapActions({
      retrieveWedding: 'wedding/show/retrieve'
    }),

    ...mapActions('weddinglistitem/create', {
      createItem: 'create',
      createReset: 'reset'
    }),

    onSendForm () {
      let payload = {
        name: this.item.name,
        description: this.item.description,
        image: this.item.image,
        price: this.item.price.toString(),
        canBeShared: this.item.canBeShared,
        active: this.item.active,
        wedding: '/api/weddings/' + this.wedding.id,
      }

      this.createItem(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
