import Create from '../views/website/Create'
import Settings from '../views/website/Settings'
import Informations from '../views/website/settings/Informations'
import Domain from '../views/website/settings/Domain'
import WebsiteSettings from '../views/website/settings/Settings'
import Members from '../views/website/settings/Members'

export default [
  {
    path: '/websites/create',
    name: 'website-create',
    component: Create,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: false,
      title: 'Création d\'un site'
    }
  },
  {
    path: '/website/settings',
    name: 'website-settings',
    component: Settings,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'website.settings',
      title: 'Paramètres'
    }
  },
  {
    path: '/website/settings/informations',
    name: 'website-settings-informations',
    component: Informations,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'website.settings',
      title: 'Informations'
    }
  },
  {
    path: '/website/settings/domain',
    name: 'website-settings-domain',
    component: Domain,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'website.settings',
      title: 'Domaine principal'
    }
  },
  {
    path: '/website/settings/settings',
    name: 'website-settings-settings',
    component: WebsiteSettings,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'website.settings',
      title: 'Paramètres'
    }
  },
  {
    path: '/website/settings/members',
    name: 'website-settings-members',
    component: Members,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'website.settings',
      title: 'Paramètres des membres'
    }
  }
]
