import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import view from '../../../../utils/view'

const getItems = ({ commit, state }, { page = 'members', query = {} }) => {
  commit(types.SET_LOADING, true)

  if (state.abortController) {
    state.abortController.abort()
  }

  let controller = new AbortController()
  commit(types.SET_ABORT_CONTROLLER, controller)

  fetch(page, { query, signal: controller.signal })
    .then(response => response.json())
    .then((data) => {
      commit(types.SET_LOADING, false)
      commit(types.SET_ITEMS, data['hydra:member'])
      commit(types.SET_VIEW, view(data))
    })
    .catch((e) => {
      if (e instanceof DOMException) {
        return
      }

      commit(types.SET_LOADING, false)
      commit(types.SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.RESET)
}

export default getItems
