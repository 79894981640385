import { render, staticRenderFns } from "./ArticlePublicationPreview.vue?vue&type=template&id=41ad1007&scoped=true&"
import script from "./ArticlePublicationPreview.vue?vue&type=script&lang=js&"
export * from "./ArticlePublicationPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41ad1007",
  null
  
)

export default component.exports