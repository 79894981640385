<template>
  <div class="component-row-module border-bottom border-primary mb-4">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div class="d-inline-block w-100 border-right border-width-5 border-primary">
      <div v-if="tab === 'column'">
        <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
          Taille de la colonne
        </div>
        <div class="px-4 pt-4">
          <div class="f-14 font-weight-600 text-success mb-3">
            Vous pouvez définir des tailles de colonne différentes en fonction de la taille de l'écran. Sur téléphone nous conseillons de choisir des colonnes qui font presque toute la largeur de la page.
          </div>

          <div class="form-control-label pb-2">
            Largeur de la colonne sur ordinateur <small class="text-soft font-weight-700">{{ parseInt(content.content.largeSize / 12 * 100) }}%</small>
          </div>
          <BFormInput
            v-model="content.content.largeSize"
            type="range"
            min="1"
            max="12"
            step="1"
            class="mb-4"
          />

          <div class="form-control-label pb-2">
            Largeur de la colonne sur tablette <small class="text-soft font-weight-700">{{ parseInt(content.content.mediumSize / 12 * 100) }}%</small>
          </div>
          <BFormInput
            v-model="content.content.mediumSize"
            type="range"
            min="1"
            max="12"
            step="1"
            class="mb-4"
          />

          <div class="form-control-label pb-2">
            Largeur de la colonne sur téléphone <small class="text-soft font-weight-700">{{ parseInt(content.content.smallSize / 12 * 100) }}%</small>
          </div>
          <BFormInput
            v-model="content.content.smallSize"
            type="range"
            min="1"
            max="12"
            step="1"
            class="mb-4"
          />
        </div>

        <div v-if="stretched">
          <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
            Contenu de la colonne
          </div>

          <div class="form-group px-4 pt-4 pb-4 mb-0">
            <BFormCheckbox
              v-model="content.content.verticalCenter"
              :value="true"
              :unchecked-value="false"
              switch
            >
              Centrer verticalement le contenu de la colonne
            </BFormCheckbox>
          </div>
        </div>
      </div>

      <div v-if="tab === 'section'">
        <SectionAppearance :content.sync="content" />
      </div>
    </div>
  </div>
</template>

<script>
import SectionAppearance from '../SectionAppearance'
import Tabs from '../../applicationcomponent/Tabs'

export default {
  name: 'ColumnModule',

  components: {
    Tabs,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    },

    stretched: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      tab: 'column',
      tabs: [
        {
          name: 'column',
          title: 'Taille et contenu de la colonne'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ]
    }
  }
}
</script>
