<template>
  <div class="component-contact-preview">
    <div :class="{ 'container': hasContainer }">
      <div
        :class="[
          'mt-' + content.section.marginTop.replace('-', 'n'),
          'mb-' + content.section.marginBottom.replace('-', 'n'),
          'ml-' + content.section.marginLeft.replace('-', 'n'),
          'mr-' + content.section.marginRight.replace('-', 'n'),
          'pt-' + content.section.paddingTop,
          'pb-' + content.section.paddingBottom,
          'pl-' + content.section.paddingLeft,
          'pr-' + content.section.paddingRight,
          moduleClass
        ]"
        :style="moduleStyle"
      >
        <div class="row align-items-stretch">
          <div
            v-for="i in 3"
            class="col-12 col-md-4 d-flex align-items-stretch"
          >
            <section class="my-3 border rounded-lg overflow-hidden w-100 d-flex flex-column align-items-stretch w-100">
              <div
                class="embed-responsive"
                style="padding-bottom: 200px; background-position: center; background-size: cover; background-image: url(https://s3.eu-west-3.amazonaws.com/static.groowy.fr/pexels-evie-shaffer-2923672.jpg)"
              />
              <div class="p-3 flex-grow-1 d-flex flex-column justify-content-between">
                <div>
                  <h4 class="mb-0 text-primary">Article n°{{ i }}</h4>
                  <div class="text-muted">
                    <small>Publié le xx/xx/xxxx</small>
                  </div>
                  <div class="mt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.rem
                  </div>
                </div>

                <div class="text-center mt-3 pb-1">
                  <button
                    class="btn btn-sm btn-primary"
                    @click.prevent=""
                  >Lire cet article</button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px;'
      }

      return style
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
