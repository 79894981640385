<template>
  <div class="page-gallery-create">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <RouterLink
          :to="{ name: 'gallery-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des galeries
        </RouterLink>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Créer une galerie
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div :class="{ 'is-loading' : isLoading }">
      <GalleryForm
        :handle-submit="onSendForm"
        :values="item"
        :errors="violations"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import GalleryForm from '../../components/gallery/Form'

export default {
  components: {
    GalleryForm
  },

  data () {
    return {
      item: {
        name: '',
        date: null,
        groups: [{
          position: 0,
          columnsLg: 3,
          columnsMd: 2,
          columnsSm: 1,
          padding: 30,
          rounded: null,
          images: []
        }]
      }
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('gallery/create', {
      error: 'error',
      isLoading: 'isLoading',
      created: 'created',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return
      }

      this.$router.push({ name: 'gallery-list' })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.item.website = '/api/websites/' + this.user.selectedWebsite.id
  },

  beforeDestroy () {
    this.createReset()
  },

  methods: {
    ...mapActions('gallery/create', {
      createGallery: 'create',
      createReset: 'reset'
    }),

    getImage (item) {
      return {
        url: item.url,
        type: item.type,
        name: item.name,
        date: item.date,
        description: item.description,
        ratio: item.ratio,
        showDetails: item.showDetails,
        position: parseInt(item.position)
      }
    },

    getGroup (item) {
      let images = []
      let self = this

      item.images.forEach((image) => {
        images.push(self.getImage(image))
      })

      return {
        images,
        position: parseInt(item.position),
        columnsLg: parseInt(item.columnsLg),
        columnsMd: parseInt(item.columnsMd),
        columnsSm: parseInt(item.columnsSm),
        padding: parseInt(item.padding),
        rounded: item.rounded
      }
    },

    onSendForm () {
      let groups = []
      let self = this

      this.item.groups.forEach((group) => {
        groups.push(self.getGroup(group))
      })

      let payload = {
        name: this.item.name,
        date: this.item.date,
        groups,
        website: '/api/websites/' + this.user.selectedWebsite.id
      }

      this.createGallery(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
