import { options } from '../elements/_default'

let component = {
  type: 'articles',
  position: null,
  variant: 'default',
  content: {},
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}

export const articles = component
