<template>
  <div class="component-tab">
    <div class="d-flex align-items-stretch flex-column flex-md-row flex-nowrap overflow-x-auto mw-100">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="d-flex flex-column justify-content-center align-items-center border-bottom border-width-3 px-4 py-3"
        :class="{ 'border-primary bg-light-grey': value === tab.name, 'cursor-pointer bg-light-grey-hover border-transparent': value !== tab.name }"
        @click="goToTab(tab.name)"
      >
        <div
          class="f-14 font-weight-600 text-nowrap"
          :class="{ 'text-primary': value === tab.name }"
        >
          {{ tab.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },

    tabs: {
      type: Array,
      required: true
    }
  },

  methods: {
    goToTab (tab) {
      this.$emit('input', tab)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
