<template>
  <div class="w-100 d-flex flex-column align-items-center justify-content-center py-5 my-5">
    <div class="f-22 text-primary f-nunito">
      Erreur 503
    </div>
    <div class="f-30 text-muted f-nunito">
      Vous n'avez pas accès à cette page.
    </div>
    <div class="text-muted font-weight-600">
      Si vous pensez qu'il s'agit d'une erreur vous pouvez contacter le propriétaire de votre site.
    </div>
    <div class="mt-4">
      <RouterLink
        :to="{ name: 'dashboard-home' }"
        class="btn btn-primary px-3 rounded-pill"
      >
        Revenir à l'accueil
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>
