import App from './App.vue'
import VueBootstrap from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import VueGtag from 'vue-gtag'
//import VueFbCustomerChat from 'vue-fb-customer-chat'
import VueCroppa from 'vue-croppa'
import router from './router'
import store from './store'
import Vue from 'vue'
import { onhover } from './directives/onhover'

const moment = require('moment')

require('moment/locale/fr')
require('@ivanv/vue-collapse-transition')

Vue.config.productionTip = false
Vue.router = router

Vue.use(VueBootstrap)
Vue.use(Vuelidate)
Vue.use(VueMoment, { moment })
Vue.use(VueCroppa)
Vue.use(VueGtag, {
    config: { id: 'G-Q7780E93TC' }
  },
  router
)
/**
Vue.use(VueFbCustomerChat, {
  page_id: 109130044319674,
  theme_color: '#0971fe',
  locale: 'fr_FR',
  logged_in_greeting: "Bonjour ! Peut-être avez-vous besoin d'aide pour la création de votre site ? ",
  logged_out_greeting: "Bonjour ! Peut-être avez-vous besoin d'aide pour la création de votre site ? "
})
 **/

Vue.directive('onhover', onhover)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
