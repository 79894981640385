<template>
  <div
    class="form-website-setting-member-field position-relative"
    :class="{ 'border-top': index > 0 }"
  >
    <div
      class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
      :class="{ 'pl-1': editing }"
    />
    <div class="bg-white">
      <div
        class="d-flex justify-content-between bg-light-grey-hover align-items-center position-relative opacity-100-hover cursor-pointer transition-400"
        :class="{ 'bg-light-grey': editing }"
        @click.prevent="edit"
      >
        <div class="p-4 w-100">
          <div
            v-if="item.name"
            class="font-weight-600"
          >
            {{ item.name }}
          </div>
          <div
            v-if="!item.name"
            class="font-weight-600"
          >
            Champ personnalisé
          </div>
          <div
            v-if="!isValid('memberFields[' + index + '].name') || !isPreValid('name')"
            class="font-weight-600 f-14 text-danger"
          >
            Ce champ contient des erreurs
          </div>
          <div class="f-14 text-muted">Champ de type <span class="text-lowercase font-weight-600">{{ type }}</span></div>
        </div>

        <div class="align-self-stretch px-4 d-flex align-items-center">
          <i
            class="text-soft far fa-angle-right f-18 transition-300"
            :class="{ 'fa-rotate-90': editing }"
          />
        </div>
      </div>

      <BCollapse :id="'collapse-member-field-' + uuid">
        <div class="p-4 bg-white">
          <div class="row">
            <div class="col-12 col-md-6">
              <FormInput
                v-model="item.name"
                :is-valid="isValid('memberFields[' + index + '].name')"
                :is-pre-valid="isPreValid('name')"
                label="Nom du champ"
                type="text"
                placeholder="Profil facebook"
                @input="updateValidation('name')"
              >
                <template v-slot:validation>
                  <div
                    v-if="!isValid('memberFields[' + index + '].name')"
                    class="invalid-feedback"
                  >
                    {{ violations['memberFields[' + index + '].name'] }}
                  </div>
                  <div
                    v-if="!isPreValid('name')"
                    class="invalid-feedback"
                  >
                    <!--suppress JSUnresolvedVariable -->
                    <div v-if="!$v.item.name.required">
                      Le nom du champ doit être indiqué
                    </div>
                    <!--suppress JSUnresolvedVariable -->
                    <div v-if="!$v.item.name.maxLength">
                      Le nom du champ doit contenir au maximum {{ $v.item.name.$params.maxLength.min }} caractères
                    </div>
                  </div>
                </template>
              </FormInput>
            </div>

            <div class="col-12 col-md-6">
              <FormSelect
                v-model="item.type"
                :is-valid="isValid('type')"
                :list="fieldTypes"
                label="Type de champ"
                placeholder="Choisir un type de champ"
              >
                <template v-slot:validation>
                  <div
                    v-if="!isValid('type')"
                    class="invalid-feedback"
                  >
                    {{ violations.type }}
                  </div>
                </template>
              </FormSelect>
            </div>
          </div>

          <FormTextarea
            v-model="item.description"
            :is-valid="isValid('description')"
            label="Description du champ"
            placeholder="Description du champ"
          />

          <div class="row pb-3">
            <div class="col-12 col-md-6">
              <div class="form-control-label pb-2">
                Activation du champ
              </div>
              <FormCheckbox
                v-model="item.active"
                :is-valid="isValid('active')"
                :is-switch="true"
                class="mb-1"
                label="Ce champ peut être utilisé"
              >
                <template v-slot:validation>
                  <div
                    v-if="!isValid('active')"
                    class="invalid-feedback"
                  >
                    {{ violations.active }}
                  </div>
                </template>
              </FormCheckbox>
              <FormCheckbox
                v-if="!!groups.length"
                v-model="activeGroups"
                :is-switch="true"
                class="mb-1"
                label="Ce champ est activé pour certains groupes"
              />
            </div>

            <div class="col-12 col-md-6">
              <div
                v-if="activeGroups && !!groups.length"
                class="p-4 bg-light-grey rounded-lg"
              >
                <BFormCheckboxGroup
                  v-model="item.activeForGroups"
                  :options="groupOptions"
                  stacked
                />
              </div>
            </div>
          </div>

          <div class="row pb-3">
            <div class="col-12 col-md-6">
              <div class="form-control-label pb-2">
                Champ requis
              </div>
              <FormCheckbox
                v-model="item.required"
                :is-valid="isValid('required')"
                :is-switch="true"
                class="mb-1"
                label="Ce champ est obligatoire"
              >
                <template v-slot:validation>
                  <div
                    v-if="!isValid('required')"
                    class="invalid-feedback"
                  >
                    {{ violations.required }}
                  </div>
                </template>
              </FormCheckbox>
              <FormCheckbox
                v-if="!!groups.length"
                v-model="requiredGroups"
                :is-switch="true"
                class="mb-1"
                label="Ce champ est obligatoire pour certains groupes"
              />
            </div>

            <div class="col-12 col-md-6">
              <div
                v-if="requiredGroups && !!groups.length"
                class="p-4 bg-light-grey rounded-lg"
              >
                <BFormCheckboxGroup
                  v-model="item.requiredForGroups"
                  :options="groupOptions"
                  stacked
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-control-label pb-2">
                Affichage du champ
              </div>
              <FormCheckbox
                v-model="item.displayed"
                :is-valid="isValid('displayed')"
                :is-switch="true"
                class="mb-1"
                label="Ce champ est affiché sur le profil"
              >
                <template v-slot:validation>
                  <div
                    v-if="!isValid('displayed')"
                    class="invalid-feedback"
                  >
                    {{ violations.displayed }}
                  </div>
                </template>
              </FormCheckbox>
              <FormCheckbox
                v-if="!!groups.length"
                v-model="displayedGroups"
                :is-switch="true"
                class="mb-1"
                label="Ce champ est affiché sur le profil de certains groupes"
              />
            </div>

            <div class="col-12 col-md-6">
              <div
                v-if="displayedGroups && !!groups.length"
                class="p-4 bg-light-grey rounded-lg"
              >
                <BFormCheckboxGroup
                  v-model="item.displayedForGroups"
                  :options="groupOptions"
                  stacked
                />
              </div>
            </div>
          </div>
        </div>

        <div class="p-3 bg-white border-top d-flex justify-content-center">
          <button
            v-if="index"
            class="btn btn-sm btn-outline-dark rounded-pill px-3 mx-3"
            @click.prevent="move(index, -1)"
          ><i class="fas fa-chevron-up"></i></button>
          <button
            v-if="index !== fields.length - 1"
            class="btn btn-sm btn-outline-dark rounded-pill px-3 mx-3"
            @click.prevent="move(index, 1)"
          ><i class="fas fa-chevron-down"></i></button>
          <button
            class="btn btn-sm btn-outline-danger rounded-pill px-3 mx-3"
            @click.prevent="handleRemove(item)"
          >Supprimer ce champ</button>
        </div>
      </BCollapse>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import { required, email, maxLength } from 'vuelidate/lib/validators'
import FormCheckbox from '../applicationform/FormCheckbox'
import FormInput from '../applicationform/FormInput'
import FormTextarea from '../applicationform/FormTextarea'
import FormSelect from '@/components/applicationform/FormSelect'

export default {
  components: {
    FormSelect,
    FormTextarea,
    FormCheckbox,
    FormInput
  },

  props: {
    values: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: true
    },

    fields: {
      type: Array,
      required: true
    },

    groups: {
      type: Array,
      required: true
    },

    new: {
      type: Boolean,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    handleRemove: {
      type: Function,
      required: true,
    },

    handleMove: {
      type: Function,
      required: true,
    }
  },

  data () {
    return {
      editing: false,
      requiredGroups: !!this.values.requiredForGroups.length,
      displayedGroups: !!this.values.displayedForGroups.length,
      activeGroups: !!this.values.activeForGroups.length,
      fieldTypes: [
        {
          id: 'text',
          name: 'Texte'
        },
        {
          id: 'url',
          name: 'Lien'
        },
        {
          id: 'email',
          name: 'Adresse email'
        },
        {
          id: 'textarea',
          name: 'Texte à plusieurs lignes'
        },
        {
          id: 'checkbox',
          name: 'Case à cocher'
        },/**
        {
          id: 'radio',
          name: 'Choix unique'
        },
        {
          id: 'select',
          name: 'Choix unique (menu déroulant)'
        }**/
      ]
    }
  },

  computed: {
    uuid () {
      return this._uid
    },

    type () {
      let type = ''

      this.fieldTypes.forEach((typeItem) => {
        if (typeItem.id === this.item.type) {
          type = typeItem.name
        }
      })

      return type
    },

    required () {
      return this.item.required
    },

    displayed () {
      return this.item.displayed
    },

    active () {
      return this.item.active
    },

    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    },

    groupOptions () {
      let groupsList = []

      this.groups.forEach((group) => {
        groupsList.push(
          {
            text: group.name,
            value: group['@id']
          }
        )
      })

      return groupsList
    }
  },

  watch: {
    editing (editing) {
      if (!editing) {
        if (this.$v.item.$invalid) {
          this.$v.item.$touch()
        }
      }
    },

    required (required) {
      if (required) {
        this.item.requiredForGroups = []
        this.requiredGroups = false
      }
    },

    requiredGroups (required) {
      if (required) {
        this.item.required = false
      } else {
        this.item.requiredForGroups = []
      }
    },

    displayed (displayed) {
      if (displayed) {
        this.item.displayedForGroups = []
        this.displayedGroups = false
      }
    },

    displayedGroups (displayed) {
      if (displayed) {
        this.item.displayed = false
      } else {
        this.item.displayedForGroups = []
      }
    },

    active (active) {
      if (active) {
        this.item.activeForGroups = []
        this.activeGroups = false
      }
    },

    activeGroups (active) {
      if (active) {
        this.item.active = false
      } else {
        this.item.activeForGroups = []
      }
    }
  },

  mounted () {
    if (this.new) {
      this.edit()
    }
  },

  methods: {
    move (index, value) {
      this.edit()
      this.handleMove(index, value)
    },

    edit () {
      this.editing = !this.editing
      this.$root.$emit('bv::toggle::collapse', 'collapse-member-field-' + this.uuid)
    },

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      name: {
        required,
        maxLength: maxLength(255)
      }
    }
  }
}
</script>
