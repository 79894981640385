<template>
  <div class="component-module-list">
    <div class="text-primary font-weight-700 f-14 text-uppercase px-3 pt-3">
      Organisation
    </div>
    <div class="row mx-0 align-items-stretch">
      <ModuleListItem
        v-if="!isColumn"
        icon="fad fa-columns"
        name="Colonnes"
        type="row"
        :col-class="colClass"
        :handle-select="addComponent"
      />
      <ModuleListItem
        icon="fad fa-shapes"
        name="Forme de contenu"
        type="shape"
        :col-class="colClass"
        :handle-select="addComponent"
      />
    </div>

    <div
      v-if="builderType === 'page' && ['home', 'contact', 'article', 'member'].includes(pageType)"
      class="text-primary font-weight-700 f-14 text-uppercase px-3 pt-3"
    >
      Éléments spécifiques à cette page
    </div>
    <div class="row mx-0 align-items-stretch">
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'article'"
        icon="fas fa-image"
        name="Miniature de l'article"
        type="article-thumnail"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'article'"
        icon="fas fa-heading"
        name="Titre de l'article"
        type="article-title"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'article'"
        icon="fad fa-calendar-day"
        name="Date et auteur de l'article"
        type="article-publication"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'article'"
        icon="fad fa-typewriter"
        name="Contenu de l'article"
        type="article-content"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'article'"
        icon="fad fa-comments-alt"
        name="Commentaires de l'article"
        type="article-comments"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'home'"
        icon="fas fa-newspaper"
        name="Liste des articles"
        type="articles"
        :col-class="colClass"
        :handle-select="addComponent"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'contact'"
        icon="fad fa-mail-bulk"
        name="Formulaire de contact"
        type="contact"
        :col-class="colClass"
        :handle-select="addComponent"
      />

      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'member'"
        icon="fad fa-user-circle"
        name="Avatar du membre"
        type="member-avatar"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'member'"
        icon="fad fa-user-tag"
        name="Nom du membre"
        type="member-name"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'member'"
        icon="fad fa-comment-alt-smile"
        name="Présentation du membre"
        type="member-presentation"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'member'"
        icon="fad fa-hashtag"
        name="Réseaux du membre"
        type="member-socials"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'member'"
        icon="fad fa-users-class"
        name="Groupes du membre"
        type="member-groups"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="builderType === 'page' && pageType === 'member'"
        icon="fad fa-ballot-check"
        name="Champs personnalisés"
        type="member-fields"
        :col-class="colClass"
        :handle-select="addElement"
      />
    </div>

    <div v-if="user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro')">
      <div class="text-primary font-weight-700 f-14 text-uppercase px-3 pt-3">
        Membres
      </div>
      <div class="row mx-0 align-items-stretch">
        <ModuleListItem
          icon="fad fa-users"
          name="Liste de membres"
          type="member-list"
          :col-class="colClass"
          :handle-select="addElement"
        />
      </div>
    </div>

    <div class="text-primary font-weight-700 f-14 text-uppercase px-3 pt-3">
      Éléments
    </div>
    <div class="row mx-0 align-items-stretch">
      <ModuleListItem
        icon="fas fa-heading"
        name="Titre"
        type="title"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        icon="fad fa-font-case"
        name="Texte"
        type="text"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        icon="fad fa-rectangle-wide"
        name="Bouton"
        type="button"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        icon="fad fa-image"
        name="Image"
        type="image"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        icon="fad fa-video"
        name="Vidéo"
        type="video"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="false"
        :col-class="colClass"
        icon="fad fa-quote-right"
        name="Citation"
      />
      <ModuleListItem
        v-if="false"
        :col-class="colClass"
        icon="fad fa-hourglass-start"
        name="Compte à rebours"
      />
      <ModuleListItem
        icon="fas fa-horizontal-rule"
        name="Séparateur"
        type="separator"
        :col-class="colClass"
        :handle-select="addElement"
      />
    </div>

    <div class="text-primary font-weight-700 f-14 text-uppercase px-3 pt-3">
      Composants
    </div>
    <div class="row mx-0 align-items-stretch">
      <ModuleListItem
        icon="fad fa-photo-video"
        name="Carrousel d'images"
        type="carousel"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        icon="fad fa-images"
        name="Galerie"
        type="gallery"
        :col-class="colClass"
        :handle-select="addElement"
      />
      <ModuleListItem
        v-if="false"
        :col-class="colClass"
        icon="fad fa-star-half-alt"
        name="Témoignages"
      />
      <ModuleListItem
        v-if="false"
        :col-class="colClass"
        icon="fad fa-question-circle"
        name="Foire aux questions"
      />
    </div>
  </div>
</template>

<script>
import ModuleListItem from './ModuleListItem'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    ModuleListItem
  },

  props: {
    colClass: {
      type: String,
      default: 'col-6 col-sm-4 col-lg-3'
    },

    isColumn: {
      type: Boolean,
      default: false
    },

    addElement: {
      type: Function,
      required: true
    },

    addComponent: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('pagebuilder/update', {
      builderType: 'builderType',
      pageType: 'pageType'
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
