<template>
  <div class="page-login row d-flex justify-content-center align-items-center">
    <div class="col col-md-6 col-lg-5 col-xl-4 mb-5 my-lg-5">
      <div class="d-flex justify-content-center mb-4 mt-5">
        <span class="px-4 py-2 bg-white rounded-pill shadow-sm f-nunito font-weight-700 text-primary f-24">
          Grow<span class="font-weight-500 text-soft">wy.</span>
        </span>
      </div>
      <!--suppress JSUnresolvedVariable -->
      <div
        class="bg-white shadow rounded-lg overflow-hidden pt-3"
        :class="{ 'is-loading': loading }"
        @keypress.enter="submit"
      >
        <div class="login-content p-3">
          <h4 class="text-center text-primary mb-4 f-20 font-weight-600 f-nunito">
            Inscription
          </h4>

          <FormInput
            v-model="item.username"
            :is-valid="isValid('username')"
            :is-pre-valid="isPreValid('username')"
            label="Nom d'utilisateur"
            type="text"
            @input="updateValidation('username')"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('username')"
                class="invalid-feedback"
              >
                {{ violations.username }}
              </div>
              <div
                v-if="!isPreValid('username')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.username.required">
                  Le nom d'utilisateur doit être indiqué
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.username.usernameRegex">
                  Le nom d'utilisateur ne peut contenir des lettres, des chiffres, des espaces ou les caractères _ et -
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.username.minLength">
                  Le nom d'utilisateur doit contenir au moins {{ $v.item.username.$params.minLength.min }} caractères
                </div>
              </div>
            </template>
          </FormInput>

          <FormInput
            v-model="item.email"
            :is-valid="isValid('email')"
            :is-pre-valid="isPreValid('email')"
            label="Adresse email"
            type="email"
            @input="updateValidation('email')"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('email')"
                class="invalid-feedback"
              >
                {{ violations.email }}
              </div>
              <div
                v-if="!isPreValid('email')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.email.required">
                  L'adresse email doit être indiquée
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.email.email">
                  Cette adresse email n'est pas valide
                </div>
              </div>
            </template>
          </FormInput>

          <FormInput
            v-model="item.password"
            :is-valid="isValid('password')"
            :is-pre-valid="isPreValid('password')"
            label="Mot de passe"
            type="password"
            @input="updateValidation('password')"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('password')"
                class="invalid-feedback"
              >
                {{ violations.password }}
              </div>
              <div
                v-if="!isPreValid('password')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.password.required">
                  Le mot de passe doit être indiqué
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.password.minLength">
                  Le mot de passe doit contenir au moins {{ $v.item.password.$params.minLength.min }} caractères
                </div>
              </div>
            </template>
          </FormInput>

          <FormInput
            v-model="item.confirmPassword"
            :is-pre-valid="isPreValid('confirmPassword')"
            label="Confirmation du mot de passe"
            type="password"
            @input="updateValidation('confirmPassword')"
          >
            <template v-slot:validation>
              <div
                v-if="!isPreValid('confirmPassword')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.confirmPassword.required">
                  Le mot de passe doit être indiqué
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.confirmPassword.sameAsPassword">
                  Les mots de passe doivent être identiques
                </div>
              </div>
            </template>
          </FormInput>

          <div class="d-flex justify-content-center">
            <button
              class="btn btn-primary px-3 mt-3 mb-4 rounded-pill"
              @click="submit"
            >
              Créer mon compte
            </button>
          </div>
        </div>

        <div class="bg-light-grey cursor-pointer f-14 font-weight-600">
          <router-link
            :to="{ name: 'security-lost-password' }"
            class="d-block w-100 p-3 text-center text-decoration-none text-soft"
          >
            Mot de passe oublié
          </router-link>
        </div>

        <div class="bg-light cursor-pointer f-14 font-weight-600">
          <router-link
            :to="{ name: 'security-login' }"
            class="d-block w-100 p-3 text-center text-decoration-none text-soft"
          >
            Je possède déjà un compte
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import FormInput from '../../components/applicationform/FormInput'
import { email, required, sameAs, minLength, helpers } from 'vuelidate/lib/validators'

const usernameRegex = helpers.regex('slug', /^([a-zA-Z0-9_-]|\s)*$/)

export default {
  components: {
    FormInput
  },

  data () {
    return {
      loading: false,
      item: {
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
      }
    }
  },

  computed: {
    ...mapFields('user/create', [
      'isLoading',
      'error',
      'created',
      'violations'
    ]),

    ...mapFields('application/login', [
      'user'
    ])
  },

  watch: {
    user (user) {
      if (user && user.token) {
        this.$router.push({ name: 'dashboard-home' })
      }
    },

    violations (violations) {
      if (violations) {
        this.loading = false
      }
    },

    created (created) {
      if (created) {
        let payload = {
          email: this.item.email,
          password: this.item.password
        }

        this.login({ payload })
      }
    }
  },

  methods: {
    ...mapActions({
      register: 'user/create/create',
      login: 'application/login/login'
    }),

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      let payload = {
        username: this.item.username,
        email: this.item.email,
        plainPassword: this.item.password
      }

      this.loading = true
      this.register(payload)
    }
  },

  validations: {
    item: {
      username: {
        required,
        usernameRegex,
        minLength: minLength(6)
      },

      email: {
        required,
        email
      },

      password: {
        required,
        minLength: minLength(6)
      },

      confirmPassword: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
