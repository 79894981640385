<template>
  <div class="form-website-design-setting-header">
    <BModal
      id="select-image"
      size="xl"
      content-class="bg-light-grey"
      hide-header
      hide-footer
    >
      <FileList
        :handle-close="closeSelection"
        :handle-select="selectImage"
        type="image"
        mime-type="image/*"
      />
    </BModal>

    <div class="bg-white rounded-lg shadow-sm my-3">
      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          En-tête du site
        </div>

        <div class="pb-4">
          <div class="form-control-label pb-2">
            Image de fond de l'entête
          </div>

          <div class="row align-items-stretch">
            <div class="col-12 col-md-6 col-xl-4 d-flex align-items-center justify-content-center justify-content-md-start">
              <div
                v-if="!item.headerBackgroundImage"
                class="text-muted"
              >
                Aucune image sélectionnée
              </div>
              <!--suppress HtmlUnknownTarget -->
              <img
                v-if="item.headerBackgroundImage"
                :src="item.headerBackgroundImage"
                alt="Image de fond de l'entête du site"
                class="img-thumbnail mw-100"
              >
            </div>

            <div class="col d-flex flex-column align-items-center align-items-md-end mt-3 mt-md-0 justify-content-center">
              <button
                class="btn btn-outline-success btn-sm rounded-pill px-3"
                @click.prevent="openSelection('headerBackgroundImage')"
              >
                Choisir une image
              </button>
              <button
                v-if="item.headerBackgroundImage"
                class="btn btn-outline-danger btn-sm rounded-pill mt-3 px-3"
                @click.prevent="item.headerBackgroundImage = null"
              >
                Supprimer l'image
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-xl-6">
            <!--suppress JSUnresolvedVariable -->
            <FormColorInput
              v-model="item.headerBackgroundColor"
              :is-valid="isValid('headerBackgroundColor')"
              :is-pre-valid="isPreValid('headerBackgroundColor')"
              class="mb-4"
              placeholder="#ffffff"
              label="Couleur de fond de l'entête"
              @input="updateValidation('headerBackgroundColor')"
            >
              <template v-slot:afterInput>
                <small class="form-text text-muted">La couleur doit être au format hexadécimal : <b>#fda32e</b></small>
              </template>
              <template v-slot:validation>
                <div
                  v-if="!isValid('headerBackgroundColor')"
                  class="invalid-feedback"
                >
                  {{ violations.headerBackgroundColor }}
                </div>
                <div
                  v-if="!isPreValid('headerBackgroundColor')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.headerBackgroundColor.color">
                    Cette couleur n'est pas valide
                  </div>
                </div>
              </template>
            </FormColorInput>
          </div>
        </div>

        <FormCheckbox
          v-model="item.headerBackgroundFullWidth"
          label="L'entête occupe toute la largeur de la page"
          class="mb-2"
          :is-switch="true"
        />

        <FormCheckbox
          v-if="item.headerBackgroundFullWidth"
          v-model="item.headerFullWidth"
          label="Le contenu de l'entête occupe toute la largeur de la page"
          class="mb-2"
          :is-switch="true"
        />

        <FormCheckbox
          v-model="item.headerBoxShadow"
          label="Afficher une ombre autour de l'entête"
          class="mb-0"
          :is-switch="true"
        />
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click.prevent="submit"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FileList from '../file/List'
import FormColorInput from '../applicationform/FormColorInput'
import { S3_ENTRYPOINT } from '../../config/entrypoint'
import { helpers } from 'vuelidate/lib/validators'
import FormCheckbox from '../applicationform/FormCheckbox'

const color = helpers.regex('slug', /^(#[a-fA-F0-9]{6}|#[a-fA-F0-9]{8})$/)

export default {
  components: {
    FormCheckbox,
    FileList,
    FormColorInput
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    }
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    closeSelection () {
      this.$bvModal.hide('select-image')
      this.selectingType = null
    },

    openSelection (type) {
      this.$bvModal.show('select-image')
      this.selectingType = type
    },

    selectImage (image) {
      this.item[this.selectingType] = S3_ENTRYPOINT + image.contentUrl
      this.closeSelection()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      headerBackgroundImage: {},
      headerBackgroundColor: {
        color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
