<template>
  <div class="form-websiteuser">
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <div class="bg-light p-3">
        <div class="font-weight-600 f-18">
          {{ item.user.username }}
        </div>
        <div class="text-muted font-weight-600 f-14">
          {{ item.user.email }}
        </div>
      </div>

      <div class="bg-light-grey p-3">
        <FormPermissions
          v-model="item.permissions"
          :is-valid="isValid('permissions')"
          :is-pre-valid="isPreValid('permissions')"
          label="Permissions de l'utilisateur"
          @input="updateValidation('permissions')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('permissions')"
              class="invalid-feedback"
            >
              {{ violations.permissions }}
            </div>
            <div
              v-if="!isPreValid('permissions')"
              class="invalid-feedback"
            />
          </template>
        </FormPermissions>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormPermissions from '../applicationform/FormPermissions'

export default {
  components: {
    FormPermissions
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    }
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      permissions: {
      }
    }
  }
}
</script>
