<template>
  <div class="module-carousel">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'customization'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
        Apparence du séparateur
      </div>

      <div class="px-4 pt-4">
        <FormCheckbox
          v-model="content.content.fullWidth"
          label="Afficher le séparateur sur toute la largeur de la page"
          :is-switch="true"
          class="mb-2"
        />

        <div class="form-control-label d-flex flex-wrap align-items-end">
          <span class="mr-2"><i class="fad fa-clock" /></span>
          <span>Hauteur</span>
          <span class="text-success f-14 font-weight-600 ml-auto">{{ content.content.height }}px</span>
        </div>
        <BFormInput
          v-model="content.content.height"
          type="range"
          min="1"
          max="100"
          step="1"
        />

        <FormColorInput
          v-model="content.content.color"
          label="Couleur du séparateur"
          class="mb-4"
        />
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import ProgressStep from '../../applicationcomponent/ProgressStep'
import FileList from '../../file/List'
import FormInput from '../../applicationform/FormInput'
import FormSelect from '../../applicationform/FormSelect'
import SectionAppearance from '../SectionAppearance'
import FormCheckbox from '../../applicationform/FormCheckbox'
import Tabs from '../../applicationcomponent/Tabs'
import FormColorInput from '@/components/applicationform/FormColorInput'

export default {
  components: {
    FormColorInput,
    Tabs,
    FormCheckbox,
    FormInput,
    FormSelect,
    ProgressStep,
    FileList,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      editing: null,
      tab: 'customization',
      tabs: [
        {
          name: 'customization',
          title: 'Apparence du séparateur'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
