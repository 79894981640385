<template>
  <div
    class="p-1 p-sm-2 p-lg-3"
    :class="colClass"
  >
    <!--suppress JSCheckFunctionSignatures -->
    <div
      class="module embed-responsive embed-responsive-1by1 border rounded bg-light-grey-hover cursor-pointer text-soft text-primary-hover"
      @click.prevent="handleSelect(type)"
    >
      <div class="embed-responsive-item d-flex justify-content-center align-items-center">
        <div class="p-3 d-flex flex-column justify-content-center align-items-center">
          <div>
            <i
              class="f-42"
              :class="[ icon ]"
            />
          </div>
          <div class="module-name text-center f-14 font-weight-600 mt-3 py-1 px-3 rounded-pill bg-light-grey">
            {{ name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colClass: {
      type: String,
      default: 'col-6 col-sm-4 col-lg-3'
    },

    icon: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: true
    },

    type: {
      type: String,
      default: ''
    },

    handleSelect: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .module:hover  {
    & .module-name {
      background-color: #fff !important;
    }
  }
</style>
