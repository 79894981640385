<template>
  <div class="module-text">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'text'">
      <Editor
        v-model="content.content.text"
        :init="init"
      />
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import SectionAppearance from '../SectionAppearance'
import Editor from '@tinymce/tinymce-vue'
import Tabs from '../../applicationcomponent/Tabs'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    Tabs,
    SectionAppearance,
    Editor
  },

  props: {
    value: {
      type: String,
      required: true
    },

    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 'text',
      tabs: [
        {
          name: 'text',
          title: 'Édition du texte'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ]
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    init () {
      return {
        height: 500,
        menubar: false,
        branding: false,
        statusbar: false,
        body_class: 'editor',
        plugins: [
          'advlist autolink lists link charmap',
          'searchreplace',
          'insertdatetime table paste'
        ],
        content_css: 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css,//' + this.user.selectedWebsite.url + '/style.css',
        toolbar_mode: 'sliding',
        toolbar1: 'undo redo | fontselect | fontsizeselect | table | bullist numlist | outdent indent',
        toolbar2: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | link | removeformat'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .menubar {
    &-button {
      display: flex;
      height: 36px;
      width: 36px;
      padding-top: 4px;
      justify-content: center;
      align-items: center;
      border: none;
      margin: rem-calc(4);
      border-radius: rem-calc(4);
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

      &.is-active {
        box-shadow: none;
      }

      &:hover {
        background-color: $light;
      }
    }
  }
</style>
