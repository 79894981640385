<template>
  <div class="position-relative gallery-image-item">
    <BModal
      :id="'modify-image-' + index"
      size="lg"
      content-class="bg-light-grey p-0 overflow-hidden"
      body-class="p-0"
      hide-header
      hide-footer
    >
      <div class="p-3 text-primary font-weight-700 text-uppercase f-14 bg-white">
        Aperçu
      </div>

      <div class="border-top border-bottom bg-white">
        <div class="row justify-content-center">
          <div class="col-12 col-md-7 p-3">
            <!--suppress HtmlRequiredAltAttribute -->
            <img
              v-if="!item.ratio"
              :src="item.url"
              class="w-100"
            >

            <div
              v-if="item.ratio"
              class="embed-responsive"
              :class="'embed-responsive-' + item.ratio"
            >
              <div
                class="embed-responsive-item"
                :style="'background-image: url(' + item.url + '); background-position: center; background-size: cover;'"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="p-3">
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
          Détails de l'image
        </div>

        <FormSelect
          v-model="item.ratio"
          :list="ratios"
          label="Format de l'image"
          placeholder="Taille de l'arrondi"
        />

        <FormCheckbox
          v-model="item.showDetails"
          label="Afficher des informations à propos de l'image dans le carousel de la galerie"
          class="mb-2"
          :is-switch="true"
        />

        <div v-if="item.showDetails">
          <div class="row">
            <div class="col-12 col-lg-6">
              <FormInput
                v-model="item.name"
                label="Nom de l'image"
                type="text"
              />
            </div>

            <div class="col-12 col-lg-6">
              <FormInput
                v-model="item.date"
                label="Date"
                placeholder="Voyage 2020"
                type="text"
              />
            </div>
          </div>

          <FormTextarea
            v-model="item.description"
            label="Description"
            rowspan="3"
          />
        </div>
      </div>

      <div class="p-3 border-top d-flex justify-content-center">
        <button
          class="btn btn-sm btn-outline-primary rounded-pill px-3"
          @click.prevent="$bvModal.hide('modify-image-' + index)"
        >
          Revenir à la galerie
        </button>
      </div>
    </BModal>

    <!--suppress HtmlRequiredAltAttribute -->
    <img
      v-if="!item.ratio"
      :src="item.url"
      class="w-100"
    >

    <div
      v-if="item.ratio"
      class="embed-responsive"
      :class="'embed-responsive-' + item.ratio"
    >
      <div
        class="embed-responsive-item"
        :style="'background-image: url(' + item.url + '); background-position: center; background-size: cover;'"
      />
    </div>

    <div
      v-b-tooltip.hover
      title="Retirer l'image"
      class="gallery-image-edit position-absolute top-0 right-0 mt-2 mr-5"
    >
      <div
        @click.prevent="handleRemove(item)"
        class="embed-responsive embed-responsive-1by1 p-3 rounded-pill overflow-hidden"
      >
        <div class="bg-danger text-white cursor-pointer embed-responsive-item d-flex justify-content-center align-items-center">
          <i class="far fa-times f-22 mt-1" />
        </div>
      </div>
    </div>

    <div
      v-b-tooltip.hover
      title="Editer l'image"
      class="gallery-image-edit position-absolute top-0 right-0 mt-2 mr-2"
    >
      <div
        @click.prevent="$bvModal.show('modify-image-' + index)"
        class="embed-responsive embed-responsive-1by1 p-3 rounded-pill overflow-hidden"
      >
        <div class="bg-success text-white cursor-pointer embed-responsive-item d-flex justify-content-center align-items-center">
          <i class="fad fa-pen" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '../applicationform/FormInput'
import FileList from '../file/List'
import FormCheckbox from '@/components/applicationform/FormCheckbox'
import FormSelect from '@/components/applicationform/FormSelect'
import FormTextarea from '@/components/applicationform/FormTextarea'

export default {
  components: {
    FormTextarea,
    FormSelect,
    FormCheckbox,
    FormInput,
    FileList
  },

  props: {
    handleRemove: {
      type: Function,
      required: true
    },

    index: {
      type: Number,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      ratios: [
        {
          id: null,
          name: 'Ratio original'
        },
        {
          id: '1by1',
          name: 'Carré'
        },
        {
          id: '16by9',
          name: 'Rectangle'
        },
        {
          id: '21by9',
          name: 'Rectangle allongé'
        },
        {
          id: '1by2',
          name: 'Rectangle vertical'
        }
      ]
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    }
  },

  methods: {
    closeSelection () {
      this.$bvModal.hide('select-file')
      this.selectType = 'file'
    },

    openSelection (type) {
      this.$bvModal.show('select-file')
      this.selectType = type
    },
  }
}
</script>

<style lang="scss" scoped>
  .gallery-image-item {
    & .gallery-image-edit {
      display: none;
    }

    &:hover .gallery-image-edit {
      display: block;
    }
  }
</style>
