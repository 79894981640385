import { articles } from './components/articles'
import { column } from './components/column'
import { contact } from './components/contact'
import { row } from './components/row'
import { shape } from './components/shape'

export const components = {
  articles,
  column,
  contact,
  row,
  shape
}
