<template>
  <div class="component-gallery-preview">
    <div :class="{ 'container': !content.content.fullWidth && hasContainer }">
      <div
        :class="[
          'mt-' + content.section.marginTop.replace('-', 'n'),
          'mb-' + content.section.marginBottom.replace('-', 'n')
        ]"
      >
        <div
          :class="[
            'ml-' + content.section.marginLeft.replace('-', 'n'),
            'mr-' + content.section.marginRight.replace('-', 'n'),
            'pt-' + content.section.paddingTop,
            'pb-' + content.section.paddingBottom,
            'pl-' + content.section.paddingLeft,
            'pr-' + content.section.paddingRight,
            moduleClass
          ]"
          :style="moduleStyle"
        >
          <div
            v-if="!content.content.gallery"
            class="w-100 mw-100 px-4 py-3 f-14 font-weight-600 bg-danger-light rounded"
          >
            <i class="fas fa-exclamation-circle mr-2" />Vous devez sélectionner une galerie
          </div>
          <div
            v-if="content.content.gallery && galleryLoading"
            class="w-100 mw-100 px-4 py-3 f-14 font-weight-600 bg-light rounded"
          >
            Chargement de la galerie
          </div>
          <div
            v-if="content.content.gallery && galleryError"
            class="w-100 mw-100 px-4 py-3 f-14 font-weight-600 bg-danger-light rounded"
          >
            Une erreur est survenue pendant le chargement de la galerie
          </div>
          <div
            v-if="content.content.gallery && gallery && !hasImages"
            class="w-100 mw-100 px-4 py-3 f-14 font-weight-600 bg-danger-light rounded"
          >
            Cette galerie ne contient pas d'images
          </div>
          <div
            v-if="content.content.gallery && gallery && hasImages"
            class="w-100 mw-100"
          >
            <GalleryGroupPreview
              v-for="group in gallery.groups"
              :group="group"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetch from '@/utils/fetch'
import GalleryGroupPreview from '@/components/pagebuilder/preview/GalleryGroupPreview'

export default {
  components: { GalleryGroupPreview },
  props: {
    content: {
      type: Object,
      required: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      gallery: null,
      galleryError: false,
      galleryLoading: false
    }
  },

  computed: {
    galleryId () {
      return this.content.content.gallery
    },

    hasImages () {
      let images = false

      if (this.gallery) {
        this.gallery.groups.forEach((group) => {
            if (group.images.length > 0) {
              images = true
            }
        })
      }

      return images
    },

    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px;'
      }

      return style
    }
  },

  mounted () {
    if (this.galleryId) {
      this.gallery = null
      this.galleryError = false
      this.galleryLoading = true

      fetch(this.galleryId.replace('/api/', ''))
        .then(response => response.json())
        .then((data) => {
          this.gallery = data
          this.galleryLoading = false
        })
        .catch((e) => {
          this.galleryError = true
          this.galleryLoading = false
        })
    }
  },

  watch: {
    galleryId (galleryId) {
      if (galleryId) {
        this.gallery = null
        this.galleryError = false
        this.galleryLoading = true

        fetch(galleryId.replace('/api/', ''))
          .then(response => response.json())
          .then((data) => {
            this.gallery = data
            this.galleryLoading = false
          })
          .catch((e) => {
            this.galleryError = true
            this.galleryLoading = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
