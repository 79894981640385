<template>
  <div class="component-text-preview">
    <div :class="{ 'container': hasContainer }">
      <div
        class="page-editor-preview"
        :class="[
          'mt-' + content.section.marginTop.replace('-', 'n'),
          'mb-' + content.section.marginBottom.replace('-', 'n'),
          'ml-' + content.section.marginLeft.replace('-', 'n'),
          'mr-' + content.section.marginRight.replace('-', 'n'),
          'pt-' + content.section.paddingTop,
          'pb-' + content.section.paddingBottom,
          'pl-' + content.section.paddingLeft,
          'pr-' + content.section.paddingRight,
          moduleClass
        ]"
        :style="moduleStyle"
      >
        <a
          :href="content.content.link"
          :class="[buttonClass]"
          :style="buttonStyle"
          @click.prevent
        >
          {{ content.content.text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    buttonClass () {
      return [
        'f-' + this.content.content.fontFamily,
        'btn',
        'btn-' + (this.content.content.outline ? 'outline-' : '') + this.content.content.variant,
        this.content.content.inline ? '' : 'btn-block',
        this.content.content.size !== 'normal' ? 'btn-' + this.content.content.size : '',
        this.content.content.round
      ]
    },

    buttonStyle () {
      let style = ''

      if (this.content.content.boxShadow) {
        style += 'box-shadow: ' + this.content.content.boxShadowHorizontal + 'px ' + this.content.content.boxShadowVertical + 'px ' + this.content.content.boxShadowBlur + 'px ' + this.content.content.boxShadowColor
      }

      return style
    },

    moduleClass () {
      let classes = [
        'text-' + this.content.content.alignment
      ]

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px;'
      }

      return style
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
