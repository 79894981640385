<template>
  <div class="form-group position-relative">
    <div
      :id="'tooltip - ' + id"
      class="position-relative d-inline-block"
    >
      <slot name="beforeInput" />

      <b-tooltip
        v-if="addon && !hasAddon"
        :disabled="addon === ''"
        :target="'tooltip - ' + id"
        placement="top"
        triggers="hover"
      >
        Cette fonctionnalité n'est accessible qu'avec l'option {{ addon === 'Premium' ? 'Premium ou Pro' : addon }}
      </b-tooltip>

      <BFormCheckbox
        :id="id"
        v-model="content"
        :switch="isSwitch"
        :disabled="isDisabled"
        :state="isInvalid"
        :class="{ 'is-invalid': !isValid || !isPreValid, 'disabled': isDisabled }"
      >
        <slot name="label">
          {{ label }}
          <span
            v-if="addon && !hasAddon"
            class="ml-2 px-2 rounded-pill f-14 font-weight-600 text-dark cursor-pointer"
            :class="{ 'bg-warning': isDisabled, 'bg-success-light': !isDisabled }"
            @click="$router.push({ name: 'website-subscription' })"
          >{{ addon }}</span>
        </slot>
      </BFormCheckbox>

      <slot name="afterInput" />
      <slot name="validation" />
      <slot name="afterValidation" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },

    isSwitch: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      required: true
    },

    isValid: {
      type: Boolean,
      default: true
    },

    isPreValid: {
      type: Boolean,
      default: true
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    hasAddon: {
      type: Boolean,
      default: true
    },

    addon: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      content: this.value
    }
  },

  computed: {
    id () {
      // noinspection JSUnresolvedVariable
      return 'input-' + this._uid
    },

    isInvalid () {
      if (!this.isValid || !this.isPreValid) {
        return false
      }

      return null
    }
  },

  watch: {
    value (value) {
      if (value !== this.content) {
        this.content = value
      }
    },

    content (content) {
      if (content !== this.value) {
        this.$emit('input', content)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
