var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-article-comments-preview"},[_c('div',{class:[
      'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
      'mb-' + _vm.content.section.marginBottom.replace('-', 'n')
    ]},[_c('div',{class:[
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h2',[_vm._v("Commentaires "),_c('small',{staticClass:"text-muted"},[_vm._v("1 commentaire")])]),_c('div',{staticClass:"my-3 border rounded p-3"},[_c('h5',[_vm._v("Visiteur "),_c('small',{staticClass:"text-muted"},[_vm._v("xx/xx/xxxx")])]),_c('p',{staticClass:"mb-0"},[_vm._v("\n            Lorem ipsum dolor sit amet.\n          ")])])])}]

export { render, staticRenderFns }