<template>
  <div class="page-design-setting">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <span v-if="!editingSection">Gestion des thèmes</span>
        <span
          v-if="editingSection"
          class="cursor-pointer"
          @click.prevent="editingSection = null"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la gestion des paramètres
        </span>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Personnalisation du thème
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="(isLoading && !loadedWebsiteDesignSetting)">
      <div
        class="bg-grey rounded-lg my-3"
        style="height: 110px"
      />
      <div
        class="bg-grey rounded-lg my-3"
        style="height: 400px"
      />
    </div>

    <div
      v-if="retrieved && loadedWebsiteDesignSetting && !editingSection"
      :class="{ 'is-loading': isLoading }"
    >
      <div class="bg-white rounded-lg shadow-sm overflow-hidden">
        <div class="row align-items-stretch border-bottom">
          <div class="col-12 col-xl-8">
            <div class="p-4">
              <div class="mb-4 text-primary font-weight-700 text-uppercase f-14">
                Logo et favicon
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Logo du site
                  <i
                    id="tooltip-setting-logo"
                    class="fad fa-question-circle text-primary ml-2 cursor-pointer"
                  />
                  <BTooltip
                    target="tooltip-setting-logo"
                    triggers="hover"
                  >
                    Le nom de votre site sera affiché à la place du logo si vous ne choissisez pas de logo
                  </BTooltip>
                </div>
                <div class="col-12 col-lg-6">
                  <span v-if="!retrieved.logo">Aucun logo sélectionné</span>
                  <!--suppress HtmlUnknownTarget -->
                  <img
                    v-if="retrieved.logo"
                    :src="retrieved.logo"
                    alt="Logo du site"
                    class="img-thumbnail bg-white mw-100"
                  >
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Favicon du site
                  <i
                    id="tooltip-setting-favicon"
                    class="fad fa-question-circle text-primary ml-2 cursor-pointer"
                  />
                  <BTooltip
                    target="tooltip-setting-favicon"
                    triggers="hover"
                  >
                    Le favicon est la petite image affichée dans l'onglet du navigateur
                  </BTooltip>
                </div>
                <div class="col-12 col-lg-6">
                  <span v-if="!retrieved.favicon">Aucun favicon sélectionné</span>
                  <!--suppress HtmlUnknownTarget -->
                  <img
                    v-if="retrieved.favicon"
                    :src="retrieved.favicon"
                    alt="Favicon du site"
                    class="img-thumbnail bg-white mw-100"
                  >
                </div>
              </div>

              <div class="mb-4 mt-5 text-primary font-weight-700 text-uppercase f-14">
                Couleurs du site
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Couleur principale
                </div>
                <div class="col-12 col-lg-6">
                  <div class="d-flex align-items-stretch">
                    <div
                      class="color-preview rounded-left"
                      :style="primaryColor"
                    />
                    <div class="border-top border-right border-bottom rounded-right d-flex align-items-center px-3 f-14 font-weight-600">
                      {{ retrieved.primaryColor ? retrieved.primaryColor : '#007bff' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Couleur secondaire
                </div>
                <div class="col-12 col-lg-6">
                  <div class="d-flex align-items-stretch">
                    <div
                      class="color-preview rounded-left"
                      :style="secondaryColor"
                    />
                    <div class="border-top border-right border-bottom rounded-right d-flex align-items-center px-3 f-14 font-weight-600">
                      {{ retrieved.secondaryColor ? retrieved.secondaryColor : '#6c757d' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-4 mt-5 text-primary font-weight-700 text-uppercase f-14">
                Couleur et image du fond
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Couleur de fond
                </div>
                <div class="col-12 col-lg-6">
                  <div class="d-flex align-items-stretch">
                    <div
                      class="color-preview rounded-left"
                      :style="bodyBackgroundColor"
                    />
                    <div class="border-top border-right border-bottom rounded-right d-flex align-items-center px-3 f-14 font-weight-600">
                      {{ retrieved.bodyBackgroundColor ? retrieved.bodyBackgroundColor : '#ffffff' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Image de fond
                  <i
                    id="tooltip-setting-background-image"
                    class="fad fa-question-circle text-primary ml-2 cursor-pointer"
                  />
                  <BTooltip
                    target="tooltip-setting-background-image"
                    triggers="hover"
                  >
                    Cette image est affiché sur votre site, comme un fond d'écran
                  </BTooltip>
                </div>
                <div class="col-12 col-lg-6">
                  <span v-if="!retrieved.bodyBackgroundImage">Aucune image sélectionnée</span>
                  <!--suppress HtmlUnknownTarget -->
                  <img
                    v-if="retrieved.bodyBackgroundImage"
                    :src="retrieved.bodyBackgroundImage"
                    alt="Image de fond du site"
                    class="img-thumbnail bg-white mw-100"
                  >
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Comportement de l'image de fond
                </div>
                <div class="col-12 col-lg-6">
                  <span v-if="retrieved.bodyBackgroundImageCover">L'image de fond s'adapte pour couvrir toute la surface du site</span>
                  <span v-if="!retrieved.bodyBackgroundImageCover">L'image de fond est affichée dans sa taille d'origine</span>
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  L'image de fond est fixe
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ retrieved.bodyBackgroundImageFixed ? 'text-success' : 'text-danger' ]"
                >
                  {{ retrieved.bodyBackgroundImageFixed ? 'Oui' : 'Non' }}
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  L'image de fond est répétée pour couvrir toute la surface de la page
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ retrieved.bodyBackgroundImageRepeat ? 'text-success' : 'text-danger' ]"
                >
                  {{ retrieved.bodyBackgroundImageRepeat ? 'Oui' : 'Non' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex align-items-stretch">
            <div class="d-flex justify-content-center align-items-center bg-light-grey w-100 p-3">
              <button
                class="btn btn-outline-primary btn-sm px-3 rounded-pill"
                @click.prevent="editingSection = 'main'"
              >
                Modifier ces paramètres
              </button>
            </div>
          </div>
        </div>

        <div class="row align-items-stretch border-bottom">
          <div class="col-12 col-xl-8">
            <div class="p-4">
              <div class="mb-4 text-primary font-weight-700 text-uppercase f-14">
                Entête du site
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Couleur de fond d'entête
                </div>
                <div class="col-12 col-lg-6">
                  <div class="d-flex align-items-stretch">
                    <div
                      class="color-preview rounded-left"
                      :style="headerBackgroundColor"
                    />
                    <div class="border-top border-right border-bottom rounded-right d-flex align-items-center px-3 f-14 font-weight-600">
                      {{ retrieved.headerBackgroundColor ? retrieved.headerBackgroundColor : '#ffffff' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Image de fond d'entête
                </div>
                <div class="col-12 col-lg-6">
                  <span v-if="!retrieved.headerBackgroundImage">Aucune image sélectionnée</span>
                  <!--suppress HtmlUnknownTarget -->
                  <img
                    v-if="retrieved.headerBackgroundImage"
                    :src="retrieved.headerBackgroundImage"
                    alt="Image de fond d'entête"
                    class="img-thumbnail bg-white mw-100"
                  >
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  L'entête occupe toute la largeur de la page
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ retrieved.headerBackgroundFullWidth ? 'text-success' : 'text-danger' ]"
                >
                  {{ retrieved.headerBackgroundFullWidth ? 'Oui' : 'Non' }}
                </div>
              </div>
              <div
                v-if="retrieved.headerBackgroundFullWidth"
                class="row mt-3 align-items-center"
              >
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Le contenu de l'entête occupe toute la largeur de la page
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ retrieved.headerFullWidth ? 'text-success' : 'text-danger' ]"
                >
                  {{ retrieved.headerFullWidth ? 'Oui' : 'Non' }}
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Afficher une ombre sous l'entête
                </div>
                <div
                  class="col-12 col-lg-6"
                  :class="[ retrieved.headerBoxShadow ? 'text-success' : 'text-danger' ]"
                >
                  {{ retrieved.headerBoxShadow ? 'Oui' : 'Non' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex align-items-stretch">
            <div class="d-flex justify-content-center align-items-center bg-light-grey w-100 p-3">
              <button
                class="btn btn-outline-primary btn-sm px-3 rounded-pill"
                @click.prevent="editingSection = 'header'"
              >
                Modifier l'entête
              </button>
            </div>
          </div>
        </div>

        <div class="row align-items-stretch border-bottom">
          <div class="col-12 col-xl-8">
            <div class="p-4">
              <div class="mb-4 text-primary font-weight-700 text-uppercase f-14">
                Pied de page du site
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Couleur de fond du pied de page
                </div>
                <div class="col-12 col-lg-6">
                  <div class="d-flex align-items-stretch">
                    <div
                      class="color-preview rounded-left"
                      :style="footerBackgroundColor"
                    />
                    <div class="border-top border-right border-bottom rounded-right d-flex align-items-center px-3 f-14 font-weight-600">
                      {{ retrieved.footerBackgroundColor ? retrieved.footerBackgroundColor : '#ffffff' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-12 col-lg-6 text-muted font-weight-600">
                  Image de fond du pied de page
                </div>
                <div class="col-12 col-lg-6">
                  <span v-if="!retrieved.footerBackgroundImage">Aucune image sélectionnée</span>
                  <!--suppress HtmlUnknownTarget -->
                  <img
                    v-if="retrieved.footerBackgroundImage"
                    :src="retrieved.footerBackgroundImage"
                    alt="Image de fond du pied de page"
                    class="img-thumbnail bg-white mw-100"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 d-flex align-items-stretch">
            <div class="d-flex justify-content-center align-items-center bg-light-grey w-100 p-3">
              <button
                class="btn btn-outline-primary btn-sm px-3 rounded-pill"
                @click.prevent="editingSection = 'footer'"
              >
                Modifier le pied de page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="retrieved && loadedWebsiteDesignSetting && editingSection === 'main'"
      :class="{ 'is-loading': isLoading }"
    >
      <MainForm
        :handle-submit="updateMain"
        :values="retrieved"
        :errors="violations"
        :initial-values="retrieved"
      />
    </div>

    <div
      v-if="retrieved && loadedWebsiteDesignSetting && editingSection === 'header'"
      :class="{ 'is-loading': isLoading }"
    >
      <HeaderForm
        :handle-submit="updateHeader"
        :values="retrieved"
        :errors="violations"
        :initial-values="retrieved"
      />
    </div>

    <div
      v-if="retrieved && loadedWebsiteDesignSetting && editingSection === 'footer'"
      :class="{ 'is-loading': isLoading }"
    >
      <FooterForm
        :handle-submit="updateFooter"
        :values="retrieved"
        :errors="violations"
        :initial-values="retrieved"
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import MainForm from '../../components/websitedesignsetting/MainForm'
import HeaderForm from '../../components/websitedesignsetting/HeaderForm'
import FooterForm from '../../components/websitedesignsetting/FooterForm'

export default {
  components: {
    FooterForm,
    HeaderForm,
    MainForm
  },

  data () {
    return {
      loadedWebsiteDesignSetting: false,
      editingSection: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('websitedesignsetting/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    }),

    primaryColor () {
      if (this.retrieved && this.retrieved.primaryColor) {
        return 'background-color: ' + this.retrieved.primaryColor
      }

      return 'background-color: #007bff'
    },

    secondaryColor () {
      if (this.retrieved && this.retrieved.secondaryColor) {
        return 'background-color: ' + this.retrieved.secondaryColor
      }

      return 'background-color: #6c757d'
    },

    bodyBackgroundColor () {
      if (this.retrieved && this.retrieved.bodyBackgroundColor) {
        return 'background-color: ' + this.retrieved.bodyBackgroundColor
      }

      return 'background-color: #ffffff'
    },

    headerBackgroundColor () {
      if (this.retrieved && this.retrieved.headerBackgroundColor) {
        return 'background-color: ' + this.retrieved.headerBackgroundColor
      }

      return 'background-color: #ffffff'
    },

    footerBackgroundColor () {
      if (this.retrieved && this.retrieved.footerBackgroundColor) {
        return 'background-color: ' + this.retrieved.footerBackgroundColor
      }

      return 'background-color: #ffffff'
    }
  },

  watch: {
    retrieved (retrieved) {
      if (retrieved) {
        this.loadedWebsiteDesignSetting = true
      }
    }
  },

  created () {
    // noinspection JSUnresolvedVariable
    this.retrieve('websites/' + this.user.selectedWebsite.id + '/design_setting')
  },

  methods: {
    ...mapActions({
      retrieve: 'websitedesignsetting/update/retrieve',
      reset: 'websitedesignsetting/update/reset',
      update: 'websitedesignsetting/update/update'
    }),

    updateMain () {
      let payload = {
        logo: this.retrieved.logo,
        favicon: this.retrieved.favicon,
        primaryColor: this.retrieved.primaryColor,
        secondaryColor: this.retrieved.secondaryColor,
        bodyBackgroundColor: this.retrieved.bodyBackgroundColor,
        bodyBackgroundImage: this.retrieved.bodyBackgroundImage,
        bodyBackgroundImageCover: this.retrieved.bodyBackgroundImageCover,
        bodyBackgroundImageRepeat: this.retrieved.bodyBackgroundImageRepeat,
        bodyBackgroundImageFixed: this.retrieved.bodyBackgroundImageFixed
      }

      this.update({
        id: 'website_design_settings/' + this.retrieved.id,
        payload
      })
    },

    updateHeader () {
      let payload = {
        headerBackgroundColor: this.retrieved.headerBackgroundColor,
        headerBackgroundImage: this.retrieved.headerBackgroundImage,
        headerBoxShadow: this.retrieved.headerBoxShadow,
        headerBackgroundFullWidth: this.retrieved.headerBackgroundFullWidth,
        headerFullWidth: this.retrieved.headerFullWidth,
        headerVariant: this.retrieved.headerVariant
      }

      this.update({
        id: 'website_design_settings/' + this.retrieved.id + '/header',
        payload
      })
    },

    serializeModule (module) {
      let self = this
      // eslint-disable-next-line no-undef
      let item = _.cloneDeep(module)
      item = { ...item, ...item.section }

      item.submodules = []
      item.content = JSON.stringify(item.content)

      delete item.id
      delete item['@id']
      delete item['@type']
      delete item.section

      if (module.submodules && module.submodules.length > 0) {
        module.submodules.forEach((child) => {
          item.submodules.push(self.serializeModule(child))
        })
      }

      return item
    },

    updateFooter () {
      let self = this
      let modules = []

      this.retrieved.footerModules.forEach((module) => {
        modules.push(self.serializeModule(module))
      })

      let payload = {
        footerModules: modules,
        footerBackgroundColor: this.retrieved.footerBackgroundColor,
        footerBackgroundImage: this.retrieved.footerBackgroundImage
      }

      this.update({
        id: 'website_design_settings/' + this.retrieved.id + '/footer',
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .color-preview {
    height: rem-calc(32);
    width: rem-calc(48);
    border: 1px inset #00000024;
  }
</style>
