import { articleThumbnail } from '@/config/pagebuilder/elements/article_thumbnail'
import { articleTitle } from '@/config/pagebuilder/elements/article_title'
import { articlePublication } from '@/config/pagebuilder/elements/article_publication'
import { articleContent } from '@/config/pagebuilder/elements/article_content'
import { articleComments } from '@/config/pagebuilder/elements/article_comments'

let thumbnail = _.cloneDeep(articleThumbnail)
thumbnail.position = 0
thumbnail.section.backgroundImage = 'https://scaleweb-local.s3.eu-west-3.amazonaws.com/website/1/files/5f7f16cd79d4f393146829.png'
thumbnail.section.backgroundImageCover = false
thumbnail.content = JSON.stringify(thumbnail.content)
thumbnail = { ...thumbnail, ...thumbnail.section }

let title = _.cloneDeep(articleTitle)
title.position = 1
title.content['alignment'] = 'center'
title.content['fontFamily'] = 'abril'
title.section.paddingTop = '5'
title.content = JSON.stringify(title.content)
title = { ...title, ...title.section }

let publication = _.cloneDeep(articlePublication)
publication.position = 2
publication.section.paddingTop = '2'
publication.content = JSON.stringify(publication.content)
publication = { ...publication, ...publication.section }


let content = _.cloneDeep(articleContent)
content.position = 3
content.section.paddingTop = '5'
content.content = JSON.stringify(content.content)
content = { ...content, ...content.section }


let comments = _.cloneDeep(articleComments)
comments.position = 4
comments.section.marginTop = '5'
comments.section.marginBottom = '5'
comments.content = JSON.stringify(comments.content)
comments = { ...comments, ...comments.section }

export const pageArticleDefault = [
  thumbnail,
  title,
  publication,
  content,
  comments
]
