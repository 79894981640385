<template>
  <div class="module-carousel">
    <BModal
      id="select-image"
      size="xl"
      content-class="bg-light-grey"
      hide-header
      hide-footer
      @hidden="editing = null"
    >
      <!--suppress JSUnresolvedVariable -->
      <FileList
        :handle-close="closeSelection"
        :handle-select="selectImage"
        mime-type="image/*"
      />
    </BModal>

    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'slides'">
      <div
        v-if="content.content.slides.length === 0"
        class="d-flex flex-column align-items-center f-14 font-weight-600 text-soft py-4"
      >
        <div>
          Vous n'avez pas encore choisi d'image
        </div>
        <button
          class="btn btn-outline-success btn-sm rounded-pill px-3 my-3"
          @click.prevent="$bvModal.show('select-image')"
        >
          Ajouter une image
        </button>
      </div>

      <div v-if="content.content.slides.length > 0">
        <div class="text-center">
          <button
            class="btn btn-outline-success btn-sm rounded-pill px-3 my-3"
            @click.prevent="$bvModal.show('select-image')"
          >
            Ajouter une image
          </button>
        </div>

        <div
          v-for="(slide, index) in content.content.slides"
          :key="index"
        >
          <div
            v-b-toggle="'collapse-carousel-slide-' + index"
            class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          >
            Image #{{ index + 1 }}
            <i
              class="fas fa-chevron-right text-soft"
              :class="{ 'fa-rotate-90': collapse.fontFamily }"
            />
          </div>
          <BCollapse
            :id="'collapse-carousel-slide-' + index"
            accordion="carousel-slides"
          >
            <div class="px-4 pt-4">
              <div class="form-control-label pb-2">
                Image sélectionnée <span class="text-success">#{{ index + 1 }}</span>
              </div>
              <div class="row mb-4">
                <div class="col-5">
                  <img
                    class="mw-100 w-100 img-thumbnail bg-white"
                    :src="slide.image"
                  >
                </div>
                <div class="col-7 d-flex flex-column align-items-start">
                  <button
                    class="btn btn-outline-success btn-sm rounded-pill px-3 mb-3"
                    @click.prevent="editImage(slide)"
                  >
                    Choisir une autre image
                  </button>
                  <button
                    class="btn btn-outline-danger btn-sm rounded-pill px-3"
                    @click.prevent="removeSlide(slide)"
                  >
                    Supprimer
                  </button>
                </div>
              </div>

              <FormInput
                v-model="slide.caption"
                type="text"
                label="Titre de l'image"
                placeholder="Ce titre sera affiché sur le carrousel"
                class="mb-4"
              />

              <FormInput
                v-model="slide.text"
                type="text"
                label="Texte de l'image"
                placeholder="Ce texte sera affiché sur le carrousel"
                class="mb-4"
              />
            </div>
          </BCollapse>
        </div>
      </div>
    </div>

    <div v-if="tab === 'customization'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
        Paramètres du carrousel
      </div>

      <div class="px-4 pt-4">
        <FormCheckbox
          v-model="content.content.fullWidth"
          label="Afficher le carrousel sur toute la largeur de la page"
          :is-switch="true"
          class="mb-2"
        />

        <FormCheckbox
          v-model="content.content.showControls"
          label="Afficher les boutons pour faire défiler les images"
          :is-switch="true"
          class="mb-2"
        />

        <FormCheckbox
          v-model="content.content.fade"
          label="Utiliser l'animation de fondu"
          :is-switch="true"
          class="mb-4"
        />

        <div class="form-control-label d-flex flex-wrap align-items-end">
          <span class="mr-2"><i class="fad fa-clock" /></span>
          <span>Délai</span>
          <span class="text-success f-14 font-weight-600 ml-auto">{{ content.content.delay / 1000 }} seconde{{ content.content.delay > 1000 ? 's' : '' }}</span>
        </div>
        <BFormInput
          v-model="content.content.delay"
          type="range"
          min="1000"
          max="10000"
          step="1000"
        />
      </div>

      <div class="bg-light-grey mt-4 px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
        Paramètres des images
      </div>

      <div class="px-4 pt-4">
        <FormSelect
          v-model="content.content.ratio"
          :list="ratios"
          class="mb-4"
        >
          <template slot="label">
            <div class="form-control-label pb-1 d-flex flex-wrap align-items-center">
              <span class="inline-icon mr-2"><i class="fad fa-adjust" /></span>
              <span>Format des images</span>
            </div>
          </template>
        </FormSelect>

        <div v-if="content.content.ratio === 'height'">
          <div class="form-control-label d-flex flex-wrap align-items-end">
            <span class="mr-2"><i class="fad fa-arrow-alt-v" /></span>
            <span>Hauteur personnalisée</span>
            <span class="text-success f-14 font-weight-600 ml-auto">{{ content.content.height }}px</span>
          </div>
          <BFormInput
            v-model="content.content.height"
            type="range"
            min="20"
            max="1000"
            step="1"
          />
        </div>

        <div v-if="content.content.ratio">
          <FormCheckbox
            v-model="content.content.cover"
            label="Adapter les images à la taille du carrousel"
            :is-switch="true"
          />
        </div>
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import ProgressStep from '../../applicationcomponent/ProgressStep'
import FileList from '../../file/List'
import { S3_ENTRYPOINT } from '../../../config/entrypoint'
import FormInput from '../../applicationform/FormInput'
import FormSelect from '../../applicationform/FormSelect'
import SectionAppearance from '../SectionAppearance'
import FormCheckbox from '../../applicationform/FormCheckbox'
import Tabs from '../../applicationcomponent/Tabs'

export default {
  components: {
    Tabs,
    FormCheckbox,
    FormInput,
    FormSelect,
    ProgressStep,
    FileList,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      editing: null,
      tab: 'slides',
      tabs: [
        {
          name: 'slides',
          title: 'Choix des images'
        },
        {
          name: 'customization',
          title: 'Options du carrousel'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
      },
      step: 1,
      steps: [
        {
          title: 'Étape 1',
          name: 'Choix des images'
        },
        {
          title: 'Étape 2',
          name: 'Apparence du carrousel'
        },
        {
          title: 'Étape 3',
          name: 'Personnalisation de la section'
        }
      ],
      ratios: [
        {
          id: null,
          name: 'Par défaut'
        },
        {
          id: '1by1',
          name: 'Carré'
        },
        {
          id: '16by9',
          name: 'Rectangle'
        },
        {
          id: '21by9',
          name: 'Rectangle allongé'
        },
        {
          id: 'height',
          name: 'Hauteur personnalisée'
        }
      ]
    }
  },

  methods: {
    addSlide (image) {
      let slide = {
        image: S3_ENTRYPOINT + image.contentUrl,
        caption: null,
        text: null
      }

      this.content.content.slides.push(slide)
    },

    closeSelection () {
      this.$bvModal.hide('select-image')
    },

    selectImage (image) {
      if (this.editing) {
        this.editing.image = S3_ENTRYPOINT + image.contentUrl
        this.editing = null

        this.closeSelection()

        return
      }

      this.closeSelection()
      this.addSlide(image)
    },

    editImage (slide) {
      this.editing = slide
      this.$bvModal.show('select-image')
    },

    removeSlide (slide) {
      this.content.content.slides.splice(this.content.content.slides.indexOf(slide), 1)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
