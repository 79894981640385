<template>
  <div class="component-comment-list">
    <!--suppress JSUnresolvedVariable -->
    <div v-if="!loadedComments">
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="items && loadedComments"
      :class="{ 'is-loading': isLoading }"
    >
      <div class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div
          class="p-4 f-20 f-nunito font-weight-600 d-flex justify-content-between align-items-center"
          :class="{ 'border-bottom': items.length > 0 }"
        >
          <div>
            {{ view.totalItems > 0 ? view.totalItems : 'Aucun' }} {{ view.totalItems > 1 ? 'commentaires' : 'commentaire' }}
          </div>
        </div>

        <div
          v-if="!view.totalItems"
          class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
        >
          Aucun élément à afficher
        </div>

        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="position-relative"
          :class="{ 'is-loading': deleting === item.id, 'border-bottom': index !== items.length - 1 }"
        >
          <div
            class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
            :class="{ 'pl-1': selected === item.id }"
          />
          <div
            class="d-flex justify-content-between bg-light-grey-hover align-items-center position-relative opacity-100-hover cursor-pointer transition-400"
            :class="{ 'opacity-30': selected && selected !== item.id, 'bg-light-grey': selected === item.id }"
            @click="toggleSelected(item.id)"
          >
            <div class="p-4 w-100">
              <div class="row align-items-center">
                <div class="col-12 d-flex flex-column align-items-center align-items-sm-start">
                  <div class="text-primary font-weight-600">
                    {{ item.member.username }} <span class="f-14 text-soft">{{ item.member.email }}</span>
                  </div>
                  <!--suppress JSUnresolvedFunction -->
                  <div class="f-14 font-weight-600 mb-1">
                    Posté le <span class="text-capitalize">{{ item.createdAt | moment( 'dddd Do MMMM YYYY') }}</span>
                  </div>
                  <div class="text-soft">
                    {{ item.content }}
                  </div>
                </div>
              </div>
            </div>
            <div class="align-self-stretch px-4 d-flex align-items-center">
              <i
                class="text-soft far fa-angle-right f-18 transition-300"
                :class="{ 'fa-rotate-90': selected === item.id }"
              />
            </div>
          </div>
          <BCollapse
            :id="'collapse-comment-' + item.id"
            accordion="comment-list"
          >
            <div
              v-if="checkPermission('comment.delete')"
              class="p-3 d-flex flex-column flex-sm-row align-items-center justify-content-center border-bottom"
            >
              <button
                v-if="checkPermission('comment.delete')"
                class="btn btn-outline-danger btn-sm rounded-pill px-3 my-2 my-sm-0"
                @click.prevent="removeComment(item)"
              >
                Supprimer le commentaire
              </button>
            </div>
            <div class="px-4 pt-4 pb-2">
              <div class="row">
                <div class="col-12 col-md-6 pb-3">
                  <div class="font-weight-600 text-soft">
                    Créé le
                  </div>
                  <!--suppress JSUnresolvedFunction -->
                  <div class="text-capitalize">
                    {{ item.createdAt | moment( 'dddd Do MMMM YYYY') }}
                  </div>
                </div>
              </div>
            </div>
          </BCollapse>
        </div>
      </div>

      <Pagination
        v-if="!Array.isArray(view)"
        v-model="page"
        :view="view"
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import permission from '../../utils/permission'
import Pagination from '../../components/applicationlayout/Pagination'

export default {
  components: {
    Pagination
  },

  props: {
    article: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loadedComments: false,
      page: 1,
      deleting: null,
      selected: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('comment/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('comment/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    })
  },

  watch: {
    selected (selected, previousSelected) {
      if (selected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-comment-' + selected)
      }

      if (previousSelected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-comment-' + previousSelected)
      }
    },

    items (items) {
      if (items) {
        this.loadedComments = true

        this.selected = null
      }
    },

    page (page) {
      // noinspection JSUnresolvedVariable
      this.getComments({ page: 'articles/' + decodeURIComponent(this.$route.params.id) + '/comments', query: { page } })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      let self = this

      this.selected = null
      this.deleting = null
      this.items.forEach((comment, index) => {
        if (comment.id === deleted.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
        }
      })
    }
  },

  mounted () {
    this.getComments({ page: 'articles/' + decodeURIComponent(this.$route.params.id) + '/comments' })
  },

  beforeDestroy () {
    this.resetComments()
    this.resetDelete()
  },

  methods: {
    ...mapActions({
      getComments: 'comment/list/default',
      deleteComment: 'comment/del/del',
      resetComments: 'comment/list/reset',
      resetDelete: 'comment/del/reset'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    toggleSelected (id) {
      if (this.selected === id) {
        this.selected = null

        return
      }

      this.selected = id
    },

    removeComment (comment) {
      this.deleting = comment.id
      this.deleteComment(comment)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
