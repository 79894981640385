<template>
  <div class="component-section-appearance">
    <BModal
      id="select-image"
      size="xl"
      content-class="bg-light-grey"
      hide-header
      hide-footer
    >
      <FileList
        :handle-close="closeFileList"
        :handle-select="selectImage"
        type="image"
        mime-type="image/*"
      />
    </BModal>

    <div
      class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
      @click.prevent="collapse.margin = !collapse.margin"
    >
      Espacements extérieurs
      <i
        class="fas fa-chevron-right text-soft"
        :class="{ 'fa-rotate-90': collapse.margin }"
      />
    </div>
    <BCollapse v-model="collapse.margin">
      <div class="px-4 pt-4">
        <div class="f-14 font-weight-600 text-success mb-2">
          Vous pouvez définir des espaces libres autour de la section pour aérer le contenu.
        </div>
        <div class="bg-success-light rounded overflow-hidden border border-success mb-4">
          <div
            class="bg-white px-3 pt-3 pb-2"
            :class="[
              'mt-' + content.section.marginTop,
              'mb-' + content.section.marginBottom,
              'ml-' + content.section.marginLeft,
              'mr-' + content.section.marginRight,
            ]"
          >
            <div class="form-group mb-2">
              <div class="form-control-label d-flex flex-wrap align-items-end">
                <span class="inline-icon"><i class="fad fa-arrow-from-bottom" /></span>
                <span>Espace en haut</span>
                <span class="text-success f-14 font-weight-600 ml-auto">{{ spaces[content.section.marginTop] }}</span>
              </div>
              <BFormInput
                v-model="content.section.marginTop"
                type="range"
                min="-5"
                max="5"
                step="1"
              />
            </div>
            <div class="form-group mb-2">
              <div class="form-control-label d-flex flex-wrap align-items-center">
                <span class="inline-icon"><i class="fad fa-arrow-from-top" /></span>
                <span>Espace en bas</span>
                <span class="text-success f-14 font-weight-600 ml-auto">{{ spaces[content.section.marginBottom] }}</span>
              </div>
              <BFormInput
                v-model="content.section.marginBottom"
                type="range"
                min="-5"
                max="5"
                step="1"
              />
            </div>
            <div class="form-group mb-2">
              <div class="form-control-label d-flex flex-wrap align-items-center">
                <span class="inline-icon"><i class="fad fa-arrow-from-right" /></span>
                <span>Espace à gauche</span>
                <span class="text-success f-14 font-weight-600 ml-auto">{{ spaces[content.section.marginLeft] }}</span>
              </div>
              <BFormInput
                v-model="content.section.marginLeft"
                type="range"
                min="-5"
                max="5"
                step="1"
              />
            </div>
            <div class="form-group mb-0">
              <div class="form-control-label d-flex flex-wrap align-items-center">
                <span class="inline-icon"><i class="fad fa-arrow-from-left" /></span>
                <span>Espace à droite</span>
                <span class="text-success f-14 font-weight-600 ml-auto">{{ spaces[content.section.marginRight] }}</span>
              </div>
              <BFormInput
                v-model="content.section.marginRight"
                class="mb-0"
                type="range"
                min="-5"
                max="5"
                step="1"
              />
            </div>
          </div>
        </div>
      </div>
    </BCollapse>

    <div
      class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
      @click.prevent="collapse.padding = !collapse.padding"
    >
      Espacements intérieurs
      <i
        class="fas fa-chevron-right text-soft"
        :class="{ 'fa-rotate-90': collapse.padding }"
      />
    </div>
    <BCollapse v-model="collapse.padding">
      <div class="px-4 pt-4">
        <div class="f-14 font-weight-600 text-success mb-2">
          Vous pouvez définir des espaces libres à l'intérieur de la section pour aérer le contenu.
        </div>
        <div class="bg-success-light rounded overflow-hidden border border-success mb-4">
          <div
            class="bg-white px-3 pt-3 pb-2"
            :class="[
              'mt-' + content.section.paddingTop,
              'mb-' + content.section.paddingBottom,
              'ml-' + content.section.paddingLeft,
              'mr-' + content.section.paddingRight,
            ]"
          >
            <div class="form-group mb-2">
              <div class="form-control-label d-flex flex-wrap align-items-end">
                <span class="inline-icon"><i class="fad fa-arrow-from-bottom" /></span>
                <span>Espace en haut</span>
                <span class="text-success f-14 font-weight-600 ml-auto">{{ spaces[content.section.paddingTop] }}</span>
              </div>
              <BFormInput
                v-model="content.section.paddingTop"
                type="range"
                min="0"
                max="5"
                step="1"
              />
            </div>
            <div class="form-group mb-2">
              <div class="form-control-label d-flex flex-wrap align-items-center">
                <span class="inline-icon"><i class="fad fa-arrow-from-top" /></span>
                <span>Espace en bas</span>
                <span class="text-success f-14 font-weight-600 ml-auto">{{ spaces[content.section.paddingBottom] }}</span>
              </div>
              <BFormInput
                v-model="content.section.paddingBottom"
                type="range"
                min="0"
                max="5"
                step="1"
              />
            </div>
            <div class="form-group mb-2">
              <div class="form-control-label d-flex flex-wrap align-items-center">
                <span class="inline-icon"><i class="fad fa-arrow-from-right" /></span>
                <span>Espace à gauche</span>
                <span class="text-success f-14 font-weight-600 ml-auto">{{ spaces[content.section.paddingLeft] }}</span>
              </div>
              <BFormInput
                v-model="content.section.paddingLeft"
                type="range"
                min="0"
                max="5"
                step="1"
              />
            </div>
            <div class="form-group mb-0">
              <div class="form-control-label d-flex flex-wrap align-items-center">
                <span class="inline-icon"><i class="fad fa-arrow-from-left" /></span>
                <span>Espace à droite</span>
                <span class="text-success f-14 font-weight-600 ml-auto">{{ spaces[content.section.paddingRight] }}</span>
              </div>
              <BFormInput
                v-model="content.section.paddingRight"
                class="mb-0"
                type="range"
                min="0"
                max="5"
                step="1"
              />
            </div>
          </div>
        </div>
      </div>
    </BCollapse>

    <div
      class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
      @click.prevent="collapse.background = !collapse.background"
    >
      Arrière plan
      <i
        class="fas fa-chevron-right text-soft"
        :class="{ 'fa-rotate-90': collapse.background }"
      />
    </div>
    <BCollapse v-model="collapse.background">
      <div class="px-4 pt-4">
        <FormColorInput
          v-model="content.section.backgroundColor"
          label="Couleur d'arrière plan"
          class="mb-4"
        />

        <div class="row align-items-stretch mb-4">
          <div class="col-12 col-md-6 col-xl-4 d-flex align-items-center justify-content-center justify-content-md-start">
            <div
              v-if="!content.section.backgroundImage"
              class="text-muted"
            >
              Aucune image sélectionnée
            </div>
            <!--suppress HtmlUnknownTarget -->
            <img
              v-if="content.section.backgroundImage"
              :src="content.section.backgroundImage"
              alt="Logo du site"
              class="img-thumbnail mw-100"
            >
          </div>

          <div class="col d-flex flex-column align-items-center align-items-md-end mt-3 mt-md-0 justify-content-center">
            <button
              class="btn btn-outline-success btn-sm rounded-pill px-3"
              @click.prevent="$bvModal.show('select-image')"
            >
              Choisir une image
            </button>
            <button
              v-if="content.section.backgroundImage"
              class="btn btn-outline-danger btn-sm rounded-pill mt-3 px-3"
              @click.prevent="content.section.backgroundImage = null"
            >
              Supprimer l'image
            </button>
          </div>
        </div>

        <div v-if="content.section.backgroundImage">
          <FormCheckbox
            v-model="content.section.backgroundImageCover"
            label="L'image doit recouvrir toute la surface de la page"
            class="mb-2"
            :is-switch="true"
          />

          <FormCheckbox
            v-model="content.section.backgroundImageRepeat"
            label="L'image doit être répétée si elle ne recouvre pas toute la page"
            class="mb-2"
            :is-switch="true"
          />

          <FormCheckbox
            v-model="content.section.backgroundImageFixed"
            label="L'image doit être fixe quand l'utilisateur fait défiler la page"
            :is-switch="true"
          />

          <div
            class="background-preview rounded-lg border mb-4"
            :style="backgroundPreview"
          />
        </div>
      </div>
    </BCollapse>

    <div
      class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
      @click.prevent="collapse.border = !collapse.border"
    >
      Bordures
      <i
        class="fas fa-chevron-right text-soft"
        :class="{ 'fa-rotate-90': collapse.border }"
      />
    </div>
    <BCollapse v-model="collapse.border">
      <div class="px-4 pt-4">
        <div class="form-group mb-2">
          <div class="form-control-label d-flex flex-wrap align-items-center">
            <span class="inline-icon"><i class="fad fa-grip-lines" /></span>
            <span>Épaisseur de la bordure</span>
            <span class="text-success f-14 font-weight-600 ml-auto">{{ content.section.borderWidth !== '0' ? content.section.borderWidth + 'px' : 'Aucune bordure' }}</span>
          </div>
          <BFormInput
            v-model="content.section.borderWidth"
            type="range"
            min="0"
            max="10"
            step="1"
            class="mb-4"
          />
        </div>

        <FormColorInput
          v-model="content.section.borderColor"
          class="mb-4"
        >
          <template slot="label">
            <div class="form-control-label pb-1 d-flex flex-wrap align-items-center">
              <span class="inline-icon"><i class="fas fa-palette" /></span>
              <span>Couleur de la bordure</span>
            </div>
          </template>
        </FormColorInput>

        <div class="form-group mb-2">
          <div class="form-control-label d-flex flex-wrap align-items-center">
            <span class="inline-icon"><i class="fad fa-border-style" /></span>
            <span>Arrondi des angles</span>
            <span class="text-success f-14 font-weight-600 ml-auto">{{ content.section.borderRadius !== '0' ? content.section.borderRadius + 'px' : 'Aucun arrondi' }}</span>
          </div>
          <BFormInput
            v-model="content.section.borderRadius"
            type="range"
            min="0"
            max="20"
            step="1"
            class="mb-4"
          />
        </div>
      </div>
    </BCollapse>

    <div
      class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
      @click.prevent="collapse.shadow = !collapse.shadow"
    >
      Ombres
      <i
        class="fas fa-chevron-right text-soft"
        :class="{ 'fa-rotate-90': collapse.shadow }"
      />
    </div>
    <BCollapse v-model="collapse.shadow">
      <div class="px-4 pt-4">
        <FormSelect
          v-model="content.section.shadow"
          :list="shadows"
          class="mb-4"
        >
          <template slot="label">
            <div class="form-control-label pb-1 d-flex flex-wrap align-items-center">
              <span class="inline-icon"><i class="fad fa-adjust" /></span>
              <span>Ombre</span>
            </div>
          </template>
        </FormSelect>
      </div>
    </BCollapse>
  </div>
</template>

<script>
import FileList from '../file/List'
import FormSelect from '../applicationform/FormSelect'
import FormColorInput from '../applicationform/FormColorInput'
import FormCheckbox from '../applicationform/FormCheckbox'
import { S3_ENTRYPOINT } from '../../config/entrypoint'

export default {
  components: {
    FileList,
    FormCheckbox,
    FormColorInput,
    FormSelect
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      collapse: {
        margin: false,
        padding: false,
        background: false,
        border: false,
        shadow: false
      },
      borderSizes: [
        {
          id: '0',
          name: '0px'
        },
        {
          id: '1',
          name: '1px'
        },
        {
          id: '2',
          name: '2px'
        },
        {
          id: '3',
          name: '3px'
        },
        {
          id: '5',
          name: '5px'
        },
        {
          id: '8',
          name: '8px'
        },
        {
          id: '10',
          name: '10px'
        }
      ],
      shadows: [
        {
          id: null,
          name: 'Aucune ombre'
        },
        {
          id: 'sm',
          name: 'Petite ombre'
        },
        {
          id: 'normal',
          name: 'Moyenne ombre'
        },
        {
          id: 'lg',
          name: 'Grande ombre'
        }
      ],
      spaces: {
        '-5': 'Très grand espace négatif',
        '-4': 'Grand espace négatif',
        '-3': 'Moyen espace négatif',
        '-2': 'Petit espace négatif',
        '-1': 'Très petit espace négatif',
        '0': 'Aucun espace',
        '1': 'Très petit espace',
        '2': 'Petit espace',
        '3': 'Moyen espace',
        '4': 'Grand espace',
        '5': 'Très grand espace'
      }
    }
  },

  computed: {
    backgroundPreview () {
      let style = 'background-color: ' + (this.content.section.backgroundColor ? this.content.section.backgroundColor : '#fff') + '; '

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); '
      }

      style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

      if (this.content.section.backgroundImageFixed) {
        style += 'background-attachment: fixed; '
      }

      if (!this.content.section.backgroundImageRepeat) {
        style += 'background-repeat: no-repeat; '
      }

      return style
    }
  },

  methods: {
    closeFileList () {
      this.$bvModal.hide('select-image')
    },

    selectImage (image) {
      this.content.section.backgroundImage = S3_ENTRYPOINT + image.contentUrl
      this.closeFileList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .inline-icon {
    width: 25px
  }

  .background-preview {
    background-position: center;
    min-height: rem-calc(350);
  }
</style>
