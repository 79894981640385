<template>
  <div
    class="position-relative"
    :class="{ 'is-loading': deleting, 'border-bottom': index !== groups.length - 1 }"
  >
    <div
      class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
      :class="{ 'pl-1': selected === group.id }"
    />
    <div
      class="d-flex justify-content-between bg-light-grey-hover align-items-center position-relative opacity-100-hover cursor-pointer transition-400"
      :class="{ 'opacity-30': selected && selected !== group.id, 'bg-light-grey': selected === group.id }"
      @click="handleSelect(group.id)"
    >
      <div class="p-4 w-100">
        <div class="row align-items-center">
          <div class="col-12 col-sm-6 d-flex flex-column align-items-center align-items-sm-start">
            <div class="text-primary font-weight-600">
              {{ group.name }}
            </div>
          </div>
          <div class="col-12 col-sm-6 d-flex flex-column align-items-center">
          </div>
        </div>
      </div>
      <div class="align-self-stretch px-4 d-flex align-items-center">
        <i
          class="text-soft far fa-angle-right f-18 transition-300"
          :class="{ 'fa-rotate-90': selected === group.id }"
        />
      </div>
    </div>
    <BCollapse
      :id="'collapse-member-group-' + group.id"
      accordion="group-list"
    >
      <div
        v-if="checkPermission('member_group.delete') && (user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'))"
        class="p-3 d-flex flex-column flex-sm-row align-items-center justify-content-center border-bottom"
      >
        <RouterLink
          v-if="checkPermission('member_group.update')"
          :to="{name: 'membergroup-update', params: { id: group.id }}"
          class="btn btn-outline-primary btn-sm rounded-pill px-3 mx-sm-2 my-2 my-sm-0"
        >
          Éditer le groupe
        </RouterLink>

        <button
          v-if="checkPermission('member_group.delete')"
          class="btn btn-outline-danger btn-sm rounded-pill px-3 my-2 my-sm-0"
          @click.prevent="deleteMember(group)"
        >
          Supprimer le groupe
        </button>
      </div>
    </BCollapse>
  </div>
</template>

<script>
import permission from '@/utils/permission'
import { mapFields } from 'vuex-map-fields'
import fetch from '@/utils/fetch'

export default {
  props: {
    handleSelect: {
      type: Function,
      required: true
    },

    handleDelete: {
      type: Function,
      required: true
    },

    group: {
      type: Object,
      required: true,
    },

    groups: {
      type: Array,
      required: true,
    },

    selected: {
      type: Number
    },

    index: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      deleting: false
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    })
  },

  methods: {
    checkPermission (toCheck) {
      return permission(toCheck)
    },

    deleteMember (group) {
      this.deleting = true

      fetch('member_groups/' + group.id, { method: 'DELETE' })
      .then(() => {
        this.handleDelete(group)
        this.deleting = false
      })
      .catch((e) => {
        this.deleting = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
