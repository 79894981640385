import NotFound from '../views/error/NotFound'
import AccessDenied from '../views/error/AccessDenied'

export default [
  {
    path: '/503',
    name: 'error-503',
    component: AccessDenied,
    meta: {
      layout: 'empty',
      requireAuth: false,
      requireSelectedWebsite: false,
      title: 'Accès non autorisé'
    }
  },
  {
    path: '/404',
    name: 'error-404',
    component: NotFound,
    meta: {
      layout: 'empty',
      requireAuth: false,
      requireSelectedWebsite: false,
      title: 'Erreur 404'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]
