import * as types from './mutation_types'
import fetch from '../../../../utils/fetch'

export const logout = ({ commit }) => {
  commit(types.APPLICATION_AUTH_LOGOUT)
}

// noinspection JSUnusedGlobalSymbols
export const refreshToken = ({ commit }, { toggleLoading = true, forceRefresh = true, callback = () => {} }) => {
  if (localStorage.refresh_token && Date.now() - localStorage.token_update < 30 * 24 * 3600 * 1000) { // Token is valid one month
    if (!forceRefresh && localStorage.user && Date.now() - localStorage.token_update < 3600 * 1000) {
      commit(types.APPLICATION_AUTH_SET_USER, JSON.parse(localStorage.getItem('user')))

      callback()
    } else {
      if (toggleLoading) {
        commit(types.APPLICATION_AUTH_TOGGLE_LOADING)
      }

      let payload = {
        refresh_token: localStorage.refresh_token
      }

      return fetch('token/refresh', { method: 'POST', body: JSON.stringify(payload) })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          commit(types.APPLICATION_AUTH_UPDATE_USER, data)

          if (toggleLoading) {
            commit(types.APPLICATION_AUTH_TOGGLE_LOADING)
          }

          callback()
        })
        .catch(() => {
          if (toggleLoading) {
            commit(types.APPLICATION_AUTH_TOGGLE_LOADING)
          }
          commit(types.APPLICATION_AUTH_LOGOUT)

          callback()
        })
    }
  } else {
    callback()
  }
}
