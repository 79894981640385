<template>
  <div
    class="module-member-list"
    :class="{ 'is-loading': !loaded }"
  >
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'settings'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
        Paramètres de la liste
      </div>
      <div class="px-4 pt-4">
        <div class="form-control-label">
          Filtres initiaux
        </div>
        <FormCheckbox
          v-model="content.content.filtered"
          label="Filter les membres à afficher"
          :is-switch="true"
          class="mb-0 mt-2"
        />

        <div v-if="content.content.filtered">
          <div class="form-control-label mt-3">
            Filtres de groupes
          </div>
          <div v-if="groups && groups.length > 0">
            <FormCheckbox
              v-model="content.content.initialFilters.includeGroupLess"
              label="Afficher les membres sans groupe"
              :is-switch="true"
              class="mb-0 mt-2"
            />

            <FormCheckbox
              v-for="(group, index) in groupFilters"
              :key="index"
              v-model="group.value"
              :label="'Afficher les membres du groupe ' + group.group.name"
              :is-switch="true"
              class="mb-0"
            />
          </div>

          <div v-if="false && availableFilters && availableFilters.length > 0">
            <div class="form-control-label mt-3">
              Filtres sur les champs personnalisés
            </div>

            <div
              v-for="(filter, index) in availableFilters"
              :key="index"
              class="border rounded mt-2 overflow-hidden"
            >
              <div
                class="px-3 py-2"
                :class="{ 'bg-light-grey': filter.initialFilter }"
              >
                <FormCheckbox
                  v-model="filter.initialFilter"
                  :is-switch="true"
                  :label="'label'"
                  class="mb-0"
                >
                  <template v-slot:label>
                    Filtrer sur le champ "<span class="font-weight-600">{{ filter.field.name }}</span>"
                  </template>
                </FormCheckbox>
              </div>

              <div
                v-if="filter.initialFilter"
                class="py-2 px-3 border-top"
              >
                <div v-if="filter.field.type === 'checkbox'">
                  <FormCheckbox
                    v-model="filter.initialFilterValue"
                    :label="'label'"
                    class="mb-0"
                  >
                    <template v-slot:label>
                      <span v-if="!filter.initialFilterValue">Seuls les membres n'ayant pas coché cette case seront affichés</span>
                      <span v-if="filter.initialFilterValue">Seuls les membres ayant coché cette case seront affichés</span>
                    </template>
                  </FormCheckbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="bg-light-grey mt-4 px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
          Apparence des membres
        </div>
        <div class="px-4 pt-4">
          <FormCheckbox
            v-model="content.content.showAvatar"
            label="Afficher les avatars"
            class="mb-4 mt-2"
            :is-switch="true"
          />

          <FormColorInput
            v-if="content.content.showAvatar"
            v-model="content.content.avatarBackgroundColor"
            label="Couleur de fond de l'avatar"
            class="mb-4"
            :allow-transparency="true"
          />

          <div v-if="content.content.showAvatar">
            <div class="form-control-label d-flex flex-wrap align-items-end">
              <span>Taille de l'avatar</span>
              <span class="text-success f-14 font-weight-600 ml-auto">{{ content.content.avatarSize }}px</span>
            </div>
            <BFormInput
              v-model="content.content.avatarSize"
              type="range"
              min="32"
              max="256"
              step="1"
            />
          </div>
        </div>

        <div class="bg-light-grey mt-4 px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
          Nom d'utilisateur
        </div>

        <div class="px-4 pt-4">
          <FormColorInput
            v-model="content.content.usernameColor"
            label="Couleur du nom d'utilisateur"
            class="mb-4"
            :allow-transparency="false"
          />

          <div class="form-control-label mb-2">
            Police d'écriture
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'default' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'default'"
          >
            <div class="f-20 mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'roboto' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'roboto'"
          >
            <div class="f-20 f-roboto mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'roboto-condensed' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'roboto-condensed'"
          >
            <div class="f-20 f-roboto-condensed mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'roboto-slab' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'roboto-slab'"
          >
            <div class="f-20 f-roboto-slab mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'roboto-mono' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'roboto-mono'"
          >
            <div class="f-20 f-roboto-mono mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'abril' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'abril'"
          >
            <div class="f-20 f-abril mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'dancing' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'dancing'"
          >
            <div class="f-20 f-dancing mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'josefin' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'josefin'"
          >
            <div class="f-20 f-josefin mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'oswald' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'oswald'"
          >
            <div class="f-20 f-oswald mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-3 px-3 py-2"
            :class="[ content.content.usernameFont === 'pacifico' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'pacifico'"
          >
            <div class="f-20 f-pacifico mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>
          <div
            class="border rounded mb-4 px-3 py-2"
            :class="[ content.content.usernameFont === 'playfair' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="content.content.usernameFont = 'playfair'"
          >
            <div class="f-20 f-playfair mw-100 overflow-hidden text-nowrap">
              Utilisateur
            </div>
          </div>

          <div class="form-group mb-4">
            <div class="form-control-label d-flex flex-wrap align-items-end">
              <span>Taille du nom d'utilisateur</span>
              <span class="text-success f-14 font-weight-600 ml-auto">{{ content.content.usernameSize }}px</span>
            </div>
            <BFormInput
              v-model="content.content.usernameSize"
              type="range"
              min="12"
              max="48"
              step="1"
            />
          </div>
        </div>

        <div class="bg-light-grey mt-4 px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
          Réseaux sociaux
        </div>
        <div class="px-4 pt-4">
          <FormCheckbox
            v-model="content.content.displaySocials"
            label="Afficher les réseaux sociaux des membres"
            :is-switch="true"
          />

          <div
            v-if="content.content.displaySocials"
            class="form-group mb-4"
          >
            <FormCheckbox
              v-model="content.content.coloredSocials"
              label="Afficher les icones en couleur"
              :is-switch="true"
              class="mb-4"
            />
          </div>

          <div
            v-if="content.content.displaySocials"
            class="form-group mb-4"
          >
            <div class="form-control-label d-flex flex-wrap align-items-end">
              <span>Taille des icones</span>
              <span class="text-success f-14 font-weight-600 ml-auto">{{ content.content.socialSize }}px</span>
            </div>
            <BFormInput
              v-model="content.content.socialSize"
              type="range"
              min="12"
              max="48"
              step="1"
            />
          </div>
        </div>

        <div class="bg-light-grey mt-4 px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
          Champs personnalisés
        </div>
        <div class="px-4 pt-4">
          <FormCheckbox
            v-model="content.content.displayFields"
            label="Afficher des champs personnalisés"
            class="mb-4"
            :is-switch="true"
          />

          <div v-if="content.content.displayFields">
            <div
              v-for="(field, index) in availableFields"
              :key="index"
              class="border rounded mt-2 overflow-hidden"
            >
              <div
                class="px-3 py-2"
                :class="{ 'bg-light-grey': field.enabled }"
              >
                <FormCheckbox
                  v-model="field.enabled"
                  :label="'label'"
                  class="mb-0"
                >
                  <template v-slot:label>
                    Afficher le champ "<span class="font-weight-600">{{ field.field.name }}</span>"
                  </template>
                </FormCheckbox>
              </div>

              <div
                v-if="field.enabled"
                class="py-2 px-3 border-top"
              >
                <FormColorInput
                  v-model="field.color"
                  label="Couleur du champ"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import FormInput from '../../applicationform/FormInput'
import SectionAppearance from './../SectionAppearance'
import Tabs from '../../applicationcomponent/Tabs'
import FormSelect from '@/components/applicationform/FormSelect'
import FormCheckbox from '@/components/applicationform/FormCheckbox'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import FormColorInput from '@/components/applicationform/FormColorInput'

export default {
  components: {
    FormColorInput,
    FormCheckbox,
    FormSelect,
    Tabs,
    FormInput,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: {
        groups: true,
        fields: true,
      },
      groupFilters: [],
      availableFilters: [],
      availableFields: [],
      tab: 'settings',
      tabs: [
        {
          name: 'settings',
          title: 'Paramètres'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
      },
      selection: false
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('websitesetting/show', {
      websiteSetting: 'retrieved',
      websiteSettingIsLoading: 'isLoading'
    }),

    ...mapFields('membergroup/list', {
      error: 'error',
      groups: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    loaded () {
      return !this.loading.groups && !this.loading.fields
    },
  },

  watch: {
    groups (groups) {
      if (groups) {
        if (this.loading.groups) {
          let self = this
          groups.forEach((group) => {
            self.groupFilters.push({
              group,
              value: self.content.content.initialFilters.groups.includes(group.id)
            })
          })

          this.loading.groups = false
        }
      }
    },

    websiteSetting (settings) {
      if (settings) {
        if (this.loading.fields) {
          let self = this
          settings.memberFields.forEach((field) => {
            if (['text', 'textarea', 'email', 'url'].includes(field.type)) {
              let existingValue = self.getDisplayedField(field.id)

              self.availableFields.push({
                enabled: !!existingValue,
                color: existingValue ? existingValue.color : '#6c757d',
                field
              })
            }

            if (['checkbox', 'radio'].includes(field.type)) {
              let existingValue = self.getInitialFilter(field.id)
              let value = null
              value = field.type === 'checkbox' ? true : value

              self.availableFilters.push({
                initialFilter: !!existingValue,
                initialFilterValue: existingValue ? existingValue.value : value,
                allowFilter: false,
                field
              })
            }
          })

          this.loading.fields = false
        }
      }
    },

    groupFilters: {
      deep: true,
      handler (filters) {
        let self = this
        self.content.content.initialFilters.groups = []
        filters.forEach(group => {
          if (group.value) {
            self.content.content.initialFilters.groups.push(group.group.id)
          }
        })
      }
    },

    availableFilters: {
      deep: true,
      handler (filters) {
        let self = this
        self.content.content.initialFilters.filters = []
        filters.forEach(field => {
          if (field.initialFilter) {
            self.content.content.initialFilters.filters.push({
              field: field.field.id,
              value: field.initialFilterValue,
            })
          }
        })
      }
    },

    availableFields: {
      deep: true,
      handler (fields) {
        let self = this
        self.content.content.displayedFields = []
        fields.forEach(field => {
          if (field.enabled) {
            self.content.content.displayedFields.push({
              field: field.field.id,
              fieldName: field.field.name,
              color: field.color,
            })
          }
        })
      }
    }
  },

  mounted () {
    this.getWebsiteSetting('websites/' + this.user.selectedWebsite.id + '/setting')
    this.getGroups({ page: 'websites/' + this.user.selectedWebsite.id + '/member_groups?pagination=false' })
  },

  beforeDestroy () {
    this.resetSetting()
    this.resetGroups()
  },

  methods: {
    ...mapActions({
      resetSetting: 'websitesetting/show/reset',
      getWebsiteSetting: 'websitesetting/show/retrieve',
      getGroups: 'membergroup/list/default',
      resetGroups: 'membergroup/list/reset',
    }),

    getDisplayedField (fieldId) {
      let fieldItem = null
      this.content.content.displayedFields.forEach((field) => {
        if (field.field === fieldId) (
          fieldItem = field
        )
      })

      return fieldItem
    },

    getInitialFilter (fieldId) {
      let filterItem = null
      this.content.content.initialFilters.filters.forEach((filter) => {
        if (filter.field === fieldId) (
          filterItem = filter
        )
      })

      return filterItem
    }
  }
}
</script>
