<template>
  <div class="page-home">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Accueil
      </div>
      <div class="page-header-title mb-5">
        <h2 class="f-nunito">
          Tableau de bord
        </h2>
      </div>
    </div>

    <div
      v-show="!user.websiteUser"
      class="bg-white shadow-sm rounded-lg overflow-hidden mb-4 position-relative"
    >
      <div class="row align-items-center pt-2">
        <div class="col-12 col-md-6">
          <div class="pt-5 pl-5 pr-5 pr-md-0 pr-xl-5 pb-0 pb-md-5">
            <h3 class="text-primary f-nunito mb-3">
              Bienvenue sur Groowy !
            </h3>
            <div class="text-soft pb-4">
              Créez gratuitement un <span class="font-weight-600">site professionnel</span> en quelques clics et profitez de la simplicité d'utilisation de Groowy
            </div>
            <div class="text-center">
              <router-link
                :to="{ name: 'website-create' }"
                class="btn btn-primary rounded-pill px-3"
              >
                Créer mon site
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <img
            class="mr-2 w-100 mt-n3 mt-md-0"
            src="../../assets/img/devices.svg"
            width="26"
            alt=""
          >
        </div>
      </div>
    </div>

    <div
      v-if="user.websiteUser && user.websiteUser.hasTutorial"
      class="bg-white shadow-sm rounded-lg overflow-hidden mb-4 position-relative"
    >
      <div
        v-if="false"
        class="position-absolute top-0 z-index-10 w-100 d-flex pt-3 pr-3 justify-content-end text-soft f-12"
      >
        <div class="d-flex align-items-center cursor-pointer text-primary-hover">
          <i class="fad fa-times-circle mr-1" />Ne plus afficher ce message
        </div>
      </div>
      <div class="row align-items-center pt-2">
        <div class="col-12 col-md-6">
          <div class="pt-5 pl-md-5 px-3 pr-md-0 pr-xl-5 pb-0 pb-md-5">
            <h3 class="text-primary f-nunito mb-3">
              Bienvenue sur votre site !
            </h3>
            <div class="text-soft pb-2">
              Votre site <span class="font-weight-600">{{ user.selectedWebsite.name }}</span> est déjà disponible
              <a
                :href="'//' + user.selectedWebsite.url"
                target="_blank"
              >
                en ligne
              </a> !
            </div>
            <div class="text-soft pb-2">
              Vous pouvez commencer dès maintenant à éditer le contenu du site : créer des articles, ajouter des pages ou configurer votre thème ...
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <img
            class="mr-2 w-100 mt-n3 mt-md-0"
            src="../../assets/img/site_under_construction.svg"
            width="26"
            alt=""
          >
        </div>
      </div>
      <div class="bg-light-grey py-5 px-3 px-md-5 mt-xl-3">
        <h4 class="text-center f-nunito pb-3">
          Foire aux questions
        </h4>
        <div class="row">
          <div class="col-12 col-xl-6 mt-3">
            <div class="font-weight-600 pb-2">
              Comment voir mon site ?
            </div>
            <div class="text-muted f-14">
              Votre site est visible à l'adresse
              <a
                :href="'//' + user.selectedWebsite.url"
                target="_blank"
              >
                {{ user.selectedWebsite.url }}
              </a>. Vous pouvez modifier l'adresse de votre site depuis l'onglet <span class="font-weight-600">Paramètres</span>.
            </div>
          </div>
          <div class="col-12 col-xl-6 pt-3">
            <div class="font-weight-600 pb-2">
              Comment personnaliser l'apparence de mon site ?
            </div>
            <div class="text-muted f-14">
              Vous pouvez personnaliser votre thème dans l'onglet <span class="font-weight-600">Thèmes</span>. Vous pouvez par exemple choisir les couleurs principales de votre thème ou votre logo.
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center pt-5">
            <div class="bg-light py-2 px-3 rounded-pill f-14 font-weight-600 d-flex align-items-center">
              <i class="fad fa-question-circle mr-2" />Vous avez une autre question ? Envoyez-nous un email à l'adresse contact@groowy.fr !
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="user.websites.length"
      class="d-flex justify-content-center my-5"
    >
      <router-link
        :to="{ name: 'website-create' }"
        class="btn btn-primary rounded-pill px-3"
      >
        Créer un autre site gratuitement
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { WEBSITE_BASE_DOMAIN } from '../../config/entrypoint'

export default {
  data () {
    return {
      WEBSITE_BASE_DOMAIN
    }
  },

  computed: {
    ...mapFields('application/auth', [
      'isLoading',
      'isLogged',
      'hasWebsite',
      'hasWebsiteSelected',
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .tutorial-icon {
    width: rem-calc(64);
    height: rem-calc(64);
  }
</style>
