<template>
  <div class="page-shopsetting-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion de la boutique
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Paramètres de la boutique
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="!loadedGateways"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="!loadedGateways">
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="items && loadedGateways"
      :class="{ 'is-loading': isLoading }"
    >
      <div>
        <div class="bg-white rounded-lg shadow-sm overflow-hidden position-relative">
          <div class="p-4 d-flex justify-content-between align-items-center">
            <div>
              <div class="text-primary font-weight-600">
                STRIPE
              </div>
              <div class="text-soft f-14">
                Stripe est notre partenaire pour les paiements par carte bancaire.
              </div>
              <div
                v-if="stripeAccount && stripeAccount.chargesEnabled"
                class="text-success f-14 font-weight-600"
              >
                Votre compte Stripe peut accepter les paiements
              </div>
            </div>
            <div class="">
              <button
                v-if="!stripeGateway && !stripeAccount"
                class="btn btn-primary rounded-pill"
                @click.prevent="addGateway('stripe')"
              >Activer</button>
              <button
                v-if="stripeAccount && !stripeAccount.chargesEnabled"
                class="btn btn-primary rounded-pill"
                @click.prevent="completeGateway(stripeGateway)"
              >Terminer l'activation</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import permission from '../../utils/permission'
import Pagination from '../../components/applicationlayout/Pagination'

export default {
  components: {
    Pagination
  },

  data () {
    return {
      loadedGateways: false,
      loadedStripe: false,
      page: 1,
      deleting: null,
      selected: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('shopgateway/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('shopgateway/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('shopgateway/create', {
      createError: 'error',
      createLoading: 'isLoading',
      created: 'created',
      createViolations: 'violations'
    }),

    ...mapFields('stripeaccount/show', {
      stripeAccountIsLoading: 'isLoading',
      stripeAccountError: 'error',
      stripeAccount: 'retrieved'
    }),

    ...mapFields('shopgateway/show', {
      getError: 'error',
      getLoading: 'isLoading',
      retrieved: 'retrieved'
    }),

    stripeGateway () {
      return this.items.filter(e => e.type === 'stripe').shift()
    }
  },

  watch: {
    stripeGateway (gateway) {
      if (gateway) {
        this.getStripeAccount('stripe_accounts/' + gateway.client)
      }
    },

    stripeAccount (account) {
      if (account) {
        this.loadedStripe = true
      }
    },

    selected (selected, previousSelected) {
      if (selected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-gateway-' + selected)
      }

      if (previousSelected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-gateway-' + previousSelected)
      }
    },

    retrieved (gateway) {
      console.log(gateway)

      if (gateway.connectUrl) {
        window.location.href = gateway.connectUrl
      }
    },

    created (gateway) {
      console.log(gateway)
      this.items.push(gateway)

      if (gateway.connectUrl) {
        window.location.href = gateway.connectUrl
      }
    },

    items (items) {
      if (items) {
        this.loadedGateways = true
        this.selected = null
      }
    },

    page (page) {
      // noinspection JSUnresolvedVariable
      this.getGateways({ page: 'websites/' + this.user.selectedWebsite.id + '/shop_gateways', query: { page } })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      let self = this

      this.selected = null
      this.deleting = null
      this.items.forEach((product, index) => {
        if (product.id === deleted.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
        }
      })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.getGateways({ page: 'websites/' + this.user.selectedWebsite.id + '/shop_gateways' })
  },

  beforeDestroy () {
    this.resetGateways()
    this.resetDelete()
  },

  methods: {
    ...mapActions({
      createGateway: 'shopgateway/create/create',
      getGateway: 'shopgateway/show/retrieve',
      getGateways: 'shopgateway/list/default',
      deleteGateway: 'shopgateway/del/del',
      resetGateways: 'shopgateway/list/reset',
      resetDelete: 'shopgateway/del/reset',
      getStripeAccount: 'stripeaccount/show/retrieve',
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    addGateway (type) {
      let payload = {
        name: type,
        type,
        website: '/api/websites/' + this.user.selectedWebsite.id
      }

      this.createGateway(payload)
    },

    completeGateway (gateway) {
      this.getGateway('shop_gateways/' + gateway.id + '/activation')
    },

    toggleSelected (id) {
      if (this.selected === id) {
        this.selected = null

        return
      }

      this.selected = id
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
