import { options } from './_default'

export const gallery = {
  type: 'gallery',
  position: null,
  variant: 'default',
  content: {
    fullWidth: false,
    gallery: null,
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
