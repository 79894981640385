import { options } from './_default'

export const memberAvatar = {
  type: 'member-avatar',
  position: null,
  variant: 'default',
  content: {
    size: 192,
    align: 'center',
    rounded: 'rounded-circle',
    shadow: 'normal'
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
