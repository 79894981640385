<template>
  <div class="page-shoppayment-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des paiements
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste des paiements
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="!loadedPayments || !loadedData"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="!loadedPayments || !loadedData">
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="items && loadedPayments && data && loadedData"
      :class="{ 'is-loading': isLoading }"
    >
      <div class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div class="row align-items-center py-4">
          <div class="col-12 col-md-6 col-xl-3 d-flex flex-column align-items-center">
            <div class="f-22 text-primary font-weight-600">
              {{ data.alltimeIncome }} €
            </div>
            <div class="text-uppercase font-weight-700 text-muted f-14">
              Depuis toujours
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-3 mt-md-0 d-flex flex-column align-items-center">
            <div class="f-22 text-primary font-weight-600">
              {{ data.monthIncome }} €
            </div>
            <div class="text-uppercase font-weight-700 text-muted f-14">
              30 derniers jours
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-3 mt-xl-0 d-flex flex-column align-items-center">
            <div class="f-22 text-primary font-weight-600">
              {{ data.weekIncome }} €
            </div>
            <div class="text-uppercase font-weight-700 text-muted f-14">
              7 derniers jours
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-3 mt-xl-0 d-flex flex-column align-items-center">
            <div class="f-22 text-primary font-weight-600">
              {{ data.dayIncome }} €
            </div>
            <div class="text-uppercase font-weight-700 text-muted f-14">
              Aujourd'hui
            </div>
          </div>
        </div>
        <div
          v-if="!user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro')"
          class="bg-light-grey p-3 text-center"
        >
          Avec l'offre gratuite votre boutique est limitée à <b>200€ / mois</b>.
          <div
            v-if="checkPermission('website.billing')"
            class="text-center pt-2"
          >
            <RouterLink
              :to="{ name: 'website-subscription' }"
              class="btn btn-sm btn-outline-dark rounded-pill px-2"
            >
              Changer d'offre
            </RouterLink>
          </div>
        </div>
      </div>

      <div class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div
          class="p-4 f-20 f-nunito font-weight-600 d-flex justify-content-between align-items-center"
          :class="{ 'border-bottom': items.length > 0 }"
        >
          <div>
            {{ view.totalItems > 0 ? view.totalItems : 'Aucun' }} {{ view.totalItems > 1 ? 'paiements' : 'paiement' }}
          </div>
        </div>

        <div
          v-if="!view.totalItems"
          class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
        >
          Aucun élément à afficher
        </div>

        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="position-relative"
          :class="{ 'is-loading': deleting === item.id, 'border-bottom': index !== items.length - 1 }"
        >
          <div
            class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
            :class="{ 'pl-1': selected === item.id }"
          />
          <div
            class="d-flex justify-content-between bg-light-grey-hover align-items-center position-relative opacity-100-hover cursor-pointer transition-400"
            :class="{ 'opacity-30': selected && selected !== item.id, 'bg-light-grey': selected === item.id }"
            @click="toggleSelected(item.id)"
          >
            <div class="p-4 w-100">
              <div class="row align-items-center">
                <div class="col-12 col-sm-7 col-lg-8 col-xl-8 d-flex flex-column align-items-center align-items-sm-start">
                  <div class="text-primary font-weight-600">
                    {{ item.firstname + ' ' + item.lastname }}
                  </div>
                  <div class="text-soft f-14 font-weight-600">
                    {{ item.createdAt | moment('DD MMMM YYYY à HH:mm') }}
                  </div>
                </div>

                <div class="col-12 col-sm-5 col-lg-4 col-xl-4 d-flex flex-column flex-md-row align-items-center align-items-sm-end align-items-md-center justify-content-between pt-3 pt-sm-0">
                  <div class="font-weight-600">
                    <span
                      v-if="item.refund"
                      class="bg-info px-2 rounded-pill text-white f-14"
                    >Remboursé</span>
                    <span
                      v-else-if="item.disputed"
                      class="bg-danger px-2 rounded-pill text-white f-14"
                    >Litige</span>
                    <span
                      v-else-if="item.paid"
                      class="bg-success px-2 rounded-pill text-white f-14"
                    >Payé</span>
                    <span
                      v-else
                      class="bg-dark px-2 rounded-pill text-white f-14"
                    >En attente</span>
                  </div>
                  <div class="font-weight-600 f-18">
                    {{ item.amount }} €
                  </div>
                </div>
              </div>
            </div>
            <div class="align-self-stretch px-4 d-flex align-items-center">
              <i
                class="text-soft far fa-angle-right f-18 transition-300"
                :class="{ 'fa-rotate-90': selected === item.id }"
              />
            </div>
          </div>
          <BCollapse
            :id="'collapse-payment-' + item.id"
            accordion="payment-list"
          >
            <div
              v-if="checkPermission('shoppayment.update') || checkPermission('shoppayment.delete')"
              class="p-3 d-flex flex-column flex-sm-row align-items-center justify-content-center"
            >
              <RouterLink
                v-if="checkPermission('shoppayment.view')"
                :to="{name: 'shoppayment-view', params: { id: item.id }}"
                class="btn btn-outline-primary btn-sm rounded-pill px-3 mx-sm-2 my-2 my-sm-0"
              >
                Voir le paiement
              </RouterLink>
            </div>
            <div
              v-if="item.description"
              class="p-4 border-top"
            >
              {{ item.description }}
            </div>
          </BCollapse>
        </div>
      </div>

      <Pagination
        v-if="!Array.isArray(view)"
        v-model="page"
        :view="view"
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import permission from '../../utils/permission'
import Pagination from '../../components/applicationlayout/Pagination'

export default {
  components: {
    Pagination
  },

  data () {
    return {
      loadedPayments: false,
      loadedData: false,
      page: 1,
      deleting: null,
      selected: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('shoppayment/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('shoppayment/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('shopincomedata/show', {
      data: 'retrieved',
      dataIsLoading: 'isLoading'
    })
  },

  watch: {
    selected (selected, previousSelected) {
      if (selected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-payment-' + selected)
      }

      if (previousSelected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-payment-' + previousSelected)
      }
    },

    data (data) {
      if (data) {
        this.loadedData = true
      }
    },

    items (items) {
      if (items) {
        this.loadedPayments = true
        this.selected = null
      }
    },

    page (page) {
      // noinspection JSUnresolvedVariable
      this.getPayments({ page: 'websites/' + this.user.selectedWebsite.id + '/shop_payments', query: { page, pending: false } })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      let self = this

      this.selected = null
      this.deleting = null
      this.items.forEach((payment, index) => {
        if (payment.id === deleted.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
        }
      })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.getPayments({ page: 'websites/' + this.user.selectedWebsite.id + '/shop_payments', query: { pending: false } })
    this.getData('shop_income_datas/' + this.user.selectedWebsite.id)
  },

  beforeDestroy () {
    this.resetPayments()
    this.resetDelete()
    this.resetData()
  },

  methods: {
    ...mapActions({
      getData: 'shopincomedata/show/retrieve',
      resetData: 'shopincomedata/show/reset',
      getPayments: 'shoppayment/list/default',
      deletePayment: 'shoppayment/del/del',
      resetPayments: 'shoppayment/list/reset',
      resetDelete: 'shoppayment/del/reset'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    toggleSelected (id) {
      if (this.selected === id) {
        this.selected = null

        return
      }

      this.selected = id
    },

    removePayment (payment) {
      this.deleting = payment.id
      this.deletePayment(payment)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
