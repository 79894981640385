import { options } from './_default'

export const video = {
  type: 'video',
  position: null,
  variant: 'default',
  content: {
    url: '',
    size: 'max',
    align: 'center',
    maxSize: '500'
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
