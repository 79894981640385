<template>
  <div class="bg-white shadow-sm rounded-lg overflow-hidden my-3">
    <BModal
      :id="'select-file-group-' + uuid"
      size="xl"
      content-class="bg-light-grey"
      hide-header
      hide-footer
    >
      <!--suppress JSUnresolvedVariable -->
      <FileList
        :handle-close="closeSelection"
        :handle-select="selectImage"
        mime-type="image/*"
      />
    </BModal>

    <div class="bg-light-grey p-4">
      <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
        Groupe
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-xl-4">
          <div class="form-control-label pb-2">
            Colonnes sur ordinateur <small class="text-soft font-weight-700">{{ parseInt(item.columnsLg) }} {{ item.columnsLg > 1 ? 'colonnes' : 'colonne' }}</small>
          </div>
          <BFormInput
            v-model="item.columnsLg"
            type="range"
            min="1"
            max="6"
            step="1"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="form-control-label pb-2">
            Colonnes sur tablette <small class="text-soft font-weight-700">{{ parseInt(item.columnsMd) }} {{ item.columnsMd > 1 ? 'colonnes' : 'colonne' }}</small>
          </div>
          <BFormInput
            v-model="item.columnsMd"
            type="range"
            min="1"
            max="6"
            step="1"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="form-control-label pb-2">
            Colonnes sur mobile <small class="text-soft font-weight-700">{{ parseInt(item.columnsSm) }} {{ item.columnsSm > 1 ? 'colonnes' : 'colonne' }}</small>
          </div>
          <BFormInput
            v-model="item.columnsSm"
            type="range"
            min="1"
            max="3"
            step="1"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <div class="form-control-label pb-2">
            Espace entre les images <small class="text-soft font-weight-700">{{ parseInt(item.padding) }} px</small>
          </div>
          <BFormInput
            v-model="item.padding"
            type="range"
            min="0"
            max="50"
            step="1"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-4">
          <FormSelect
            v-model="item.rounded"
            :list="roundedList"
            label="Arrondir les angles"
            placeholder="Taille de l'arrondi"
            class="mb-0"
          />
        </div>
      </div>
    </div>
    <div class="px-4">
      <Draggable
        v-model="item.images"
        class="row py-2"
        :style="rowStyle"
        @change="orderImages"
      >
        <div
          v-for="(image, index) in item.images"
          :style="columnStyle"
          class="cursor-grab gallery-column"
          :class="['column-lg-' + item.columnsLg, 'column-md-' + item.columnsMd, 'column-sm-' + item.columnsSm]"
        >
          <div
            class="overflow-hidden"
            :class="columnClass"
          >
            <GalleryImageForm
              :handle-remove="removeImage"
              :values="image"
              :index="index"
            />
          </div>
        </div>
      </Draggable>

      <div class="d-flex justify-content-center py-4">
        <button
          v-if="movableUp"
          class="btn btn-sm btn-outline-dark rounded-pill px-3 mx-3"
          @click.prevent="handleMove(index, -1)"
        >
          <i class="fas fa-chevron-up fa-fw" /> Monter
        </button>
        <button
          v-if="movableDown"
          class="btn btn-sm btn-outline-dark rounded-pill px-3 mx-3"
          @click.prevent="handleMove(index, 1)"
        >
          <i class="fas fa-chevron-down fa-fw" /> Descendre
        </button>

        <button
          v-if="removable"
          class="btn btn-sm btn-outline-danger rounded-pill px-3 mx-3"
          @click.prevent="handleRemove(item)"
        >
          Supprimer ce groupe
        </button>
        <button
          class="btn btn-sm btn-outline-primary rounded-pill px-3 mx-3"
          @click.prevent="openSelection('image')"
        >
          Ajouter un élément
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '../applicationform/FormInput'
import FileList from '../file/List'
import GalleryImageForm from '../galleryimage/Form'
import FormSelect from '@/components/applicationform/FormSelect'
import Draggable from 'vuedraggable'
import { S3_ENTRYPOINT } from '@/config/entrypoint'

export default {
  components: {
    Draggable,
    FormSelect,
    FormInput,
    FileList,
    GalleryImageForm
  },

  props: {
    handleRemove: {
      type: Function,
      required: true
    },

    handleMove: {
      type: Function,
      required: true
    },

    movableUp: {
      type: Boolean,
      required: true
    },

    movableDown: {
      type: Boolean,
      required: true
    },

    index: {
      type: Number,
      required: true
    },

    removable: {
      type: Boolean,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      selectType: 'file',
      roundedList: [
        {
          id: null,
          name: 'Aucun arrondi'
        },
        {
          id: 'sm',
          name: 'Petit arrondi'
        },
        {
          id: 'md',
          name: 'Moyen arrondi'
        },
        {
          id: 'lg',
          name: 'Grand arrondi'
        }
      ]
    }
  },

  created () {
    if (this.item.columnsSm === true) {
      this.item.columnsSm = 1
    }
  },

  computed: {
    uuid () {
      return this._uid
    },

    item () {
      return this.initialValues || this.values
    },

    rowStyle () {
      return 'margin: 0 -' + this.item.padding / 2 + 'px;'
    },

    columnStyle () {
      return 'padding: ' + this.item.padding / 2 + 'px;'
    },

    columnClass () {
      let classes = [];

      switch (this.item.rounded) {
        case 'sm':
          classes.push('rounded-sm')
          break

        case 'md':
          classes.push('rounded')
          break

        case 'lg':
          classes.push('rounded-lg')
          break
      }

      return classes
    }
  },

  methods: {
    orderImages () {
      this.item.images.forEach((image, index) => {
        image.position = index
      })
    },

    closeSelection () {
      this.$bvModal.hide('select-file-group-' + this.uuid)
      this.selectType = 'file'
    },

    openSelection (type) {
      this.$bvModal.show('select-file-group-' + this.uuid)
      this.selectType = type
    },

    selectImage (image) {
      this.item.images.push({
        url: S3_ENTRYPOINT + image.contentUrl,
        type: 'image',
        position: this.item.images.length,
        name: null,
        date: null,
        description: null,
        showDetails: false,
        rounded: false,
        ratio: null
      })

      this.closeSelection()
      this.orderImages()
    },

    removeImage (image) {
      let self = this

      this.item.images.forEach((item, index) => {
        if (item === image) {
          self.item.images.splice(index, 1)
        }
      })

      this.orderImages()
    }
  }
}
</script>

<style lang="scss" scoped>
  .gallery-column {
    &.column-sm-1 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    &.column-sm-2 {
      max-width: 50%;
      flex: 0 0 50%;
    }

    &.column-sm-3 {
      max-width: calc(100% / 3);
      flex: 0 0 calc(100% / 3);
    }
  }

  @media (min-width: 768px) {
    .gallery-column {
      &.column-md-1 {
        max-width: 100%;
        flex: 0 0 100%;
      }

      &.column-md-2 {
        max-width: 50%;
        flex: 0 0 50%;
      }

      &.column-md-3 {
        max-width: calc(100% / 3);
        flex: 0 0 calc(100% / 3);
      }

      &.column-md-4 {
        max-width: 25%;
        flex: 0 0 25%;
      }

      &.column-md-5 {
        max-width: 20%;
        flex: 0 0 20%;
      }

      &.column-md-6 {
        max-width: calc(100% / 6);
        flex: 0 0 calc(100% / 6);
      }
    }
  }

  @media (min-width: 1200px) {
    .gallery-column {
      &.column-lg-1 {
        max-width: 100%;
        flex: 0 0 100%;
      }

      &.column-lg-2 {
        max-width: 50%;
        flex: 0 0 50%;
      }

      &.column-lg-3 {
        max-width: calc(100% / 3);
        flex: 0 0 calc(100% / 3);
      }

      &.column-lg-4 {
        max-width: 25%;
        flex: 0 0 25%;
      }

      &.column-lg-5 {
        max-width: 20%;
        flex: 0 0 20%;
      }

      &.column-lg-6 {
        max-width: calc(100% / 6);
        flex: 0 0 calc(100% / 6);
      }
    }
  }

  .gallery-preview {
    line-height: 0;
    column-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    & > img {
      width: 100% !important;
      height: auto !important;
      margin-bottom: 30px;
    }
  }
</style>
