<template>
  <div class="page-page-create">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <router-link
          :to="{ name: 'page-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des pages
        </router-link>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Créer une page
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div :class="{ 'is-loading' : isLoading }">
      <PageForm
        :handle-submit="onSendForm"
        :values="item"
        :errors="violations"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import PageForm from '../../components/page/Form'

export default {
  components: {
    PageForm
  },

  data () {
    return {
      item: {
        title: '',
        slug: '',
        description: null,
        private: false,
        privateForGroups: false,
        authorizedGroups: [],
        modules: []
      }
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('page/create', {
      error: 'error',
      isLoading: 'isLoading',
      created: 'created',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return
      }

      this.$router.push({ name: 'page-list' })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.item.website = '/api/websites/' + this.user.selectedWebsite.id
  },

  beforeDestroy () {
    this.createReset()
  },

  methods: {
    ...mapActions('page/create', {
      createPage: 'create',
      createReset: 'reset'
    }),

    serializeModule (module) {
      let self = this
      // eslint-disable-next-line no-undef
      let item = _.cloneDeep(module)
      item = { ...item, ...item.section }

      item.submodules = []
      item.content = JSON.stringify(item.content)

      delete item.id
      delete item['@id']
      delete item['@type']
      delete item.section

      if (module.submodules && module.submodules.length > 0) {
        module.submodules.forEach((child) => {
          item.submodules.push(self.serializeModule(child))
        })
      }

      return item
    },

    onSendForm () {
      let self = this
      let modules = []

      this.item.modules.forEach((module) => {
        modules.push(self.serializeModule(module))
      })

      let payload = {
        title: this.item.title,
        slug: this.item.slug,
        description: this.item.description,
        fixed: false,
        private: this.item.private,
        privateForGroups: this.item.privateForGroups,
        authorizedGroups: this.item.authorizedGroups,
        website: this.item.website,
        modules
      }

      this.createPage(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
