<template>
  <div class="page-weddinglistitem-update">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <RouterLink
          :to="{ name: 'weddinglistitem-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste de mariage
        </RouterLink>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Éditer un cadeau
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading && !retrieved"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading && retrieved"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <BAlert
      :show="confirmationCountdown"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
      @dismissed="confirmationCountdown=0"
      @dismiss-count-down="confirmationCountdownUpdated"
    >
      Les modifications du cadeau ont été enregistrées
    </BAlert>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="isLoading && !retrieved">
      <div
        class="py-5 my-3 rounded-lg bg-grey"
        style="height: 320px"
      />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div :class="{ 'is-loading': isLoading }">
      <WeddingListItemForm
        v-if="retrieved"
        :handle-submit="onSendForm"
        :values="retrieved"
        :errors="violations"
        :initial-values="retrieved"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import WeddingListItemForm from '../../components/weddinglistitem/Form'

export default {
  components: {
    WeddingListItemForm
  },

  data () {
    return {
      confirmationSeconds: 10,
      confirmationCountdown: 0
    }
  },

  computed: {
    ...mapFields('weddinglistitem/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    updated (updated) {
      if (updated) {
        this.confirmationCountdown = this.confirmationSeconds
      }
    },

    error (error) {
      if (error) {
        this.$router.push({ name: 'error-404' })
      }
    },

    retrieved (retrieved) {
      if (!retrieved) {
        this.$router.push({ name: 'notfound' })
      }
    }
  },

  created () {
    this.retrieve('wedding_list_items/' + decodeURIComponent(this.$route.params.id))
  },

  beforeDestroy () {
    this.updateReset()
  },

  methods: {
    ...mapActions({
      retrieve: 'weddinglistitem/update/retrieve',
      updateReset: 'weddinglistitem/update/reset',
      update: 'weddinglistitem/update/update',
      updateRetrieved: 'weddinglistitem/update/updateRetrieved'
    }),

    confirmationCountdownUpdated (value) {
      this.confirmationCountdown = value
    },

    onSendForm () {
      let payload = {
        name: this.retrieved.name,
        description: this.retrieved.description,
        image: this.retrieved.image,
        price: this.retrieved.price.toString(),
        canBeShared: this.retrieved.canBeShared,
        active: this.retrieved.active
      }

      this.update({
        id: 'wedding_list_items/' + this.retrieved.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
