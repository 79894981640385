<template>
  <div class="form-gallery">
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <div class="p-4">
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
          Informations de la galerie
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <FormInput
              v-model="item.name"
              :is-valid="isValid('name')"
              :is-pre-valid="isPreValid('name')"
              label="Nom de la galerie"
              type="text"
              class="mb-0"
              @input="updateValidation('name')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('name')"
                  class="invalid-feedback"
                >
                  {{ violations.name }}
                </div>
                <div
                  v-if="!isPreValid('name')"
                  class="invalid-feedback"
                >
                  <div v-if="!$v.item.name.required">
                    Le nom de la galerie doit être indiqué
                  </div>
                </div>
              </template>
            </FormInput>
          </div>
          <div class="col-12 col-md-6">
            <FormInput
              v-model="item.date"
              :is-valid="isValid('date')"
              label="Date"
              placeholder="Voyage 2020"
              type="text"
              class="mb-0 mt-3 mt-md-0"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('date')"
                  class="invalid-feedback"
                >
                  {{ violations.date }}
                </div>
              </template>
            </FormInput>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-light rounded-lg overflow-hidden px-3 mt-4">
      <GalleryGroupForm
        v-for="(group, index) in item.groups"
        :key="index"
        :values="group"
        :handle-remove="removeGroup"
        :handle-move="moveGroup"
        :movable-up="item.groups.length > 1 && index !== 0"
        :movable-down="item.groups.length > 1 && index !== item.groups.length - 1"
        :removable="item.groups.length > 1"
        :index="index"
      />
      <div class="d-flex pb-3">
        <button
          class="btn btn-sm btn-primary rounded-pill px-3"
          @click.prevent="addGroup"
        >
          Ajouter un groupe d'éléments
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormInput from '../applicationform/FormInput'
import FormPermissions from '../applicationform/FormPermissions'
import GalleryGroupForm from '../gallerygroup/Form'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    FormInput,
    FormPermissions,
    GalleryGroupForm
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    }
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    addGroup () {
      this.item.groups.push({
        position: this.item.groups.length,
        columnsLg: 3,
        columnsMd: 2,
        columnsSm: 1,
        images: []
      })

      this.orderGroups()
    },

    removeGroup (group) {
      let self = this

      this.item.groups.forEach((item, index) => {
        if (item === group) {
          self.item.groups.splice(index, 1)
        }
      })

      this.orderGroups()
    },

    moveGroup (from, value = 0) {
      this.item.groups.splice(from + value, 0, this.item.groups.splice(from, 1)[0])
      this.orderGroups()
    },

    orderGroups () {
      this.item.groups.forEach((group, index) => {
        group.position = index
      })
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      name: {
        required
      }
    }
  }
}
</script>
