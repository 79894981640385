<template>
  <div class="form-group">
    <slot name="label">
      <label
        v-if="label"
        :for="id"
        class="form-control-label"
      >{{ label }}</label>
    </slot>

    <slot name="beforeInput" />

    <!--suppress HtmlFormInputWithoutLabel -->
    <textarea
      :id="id"
      v-model.trim="content"
      :class="['form-control', !isValid || !isPreValid ? 'is-invalid' : '']"
      :placeholder="placeholder"
      :rows="rows"
      @input="updateValue($event.target.value)"
    />

    <slot name="afterInput" />
    <slot name="validation" />
    <slot name="afterValidation" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    isValid: {
      type: Boolean,
      default: true
    },

    isPreValid: {
      type: Boolean,
      default: true
    },

    rows: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      content: this.value
    }
  },

  computed: {
    id () {
      // noinspection JSUnresolvedVariable
      return 'input-' + this._uid
    }
  },

  watch: {
    value (value) {
      if (value !== this.content) {
        this.content = value
      }
    }
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value.trim())
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
