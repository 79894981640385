import { options } from './_default'

export const articleThumbnail = {
  type: 'article-thumbnail',
  position: null,
  variant: 'default',
  content: {
    rounded: 'default',
    size: 'full',
    align: 'center',
    maxSize: '500',
    ratio: 'height',
    height: '250'
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
