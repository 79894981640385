<template>
  <div class="form-website-design-setting">
    <BModal
      id="select-image"
      size="xl"
      content-class="bg-light-grey"
      hide-header
      hide-footer
    >
      <!--suppress JSUnresolvedVariable -->
      <FileList
        v-if="selectingType === 'logo' || selectingType === 'bodyBackgroundImage'"
        :handle-close="closeSelection"
        :handle-select="selectImage"
        type="image"
        mime-type="image/*"
      />
      <!--suppress JSUnresolvedVariable -->
      <FileList
        v-if="selectingType === 'favicon'"
        :handle-close="closeSelection"
        :handle-select="selectImage"
        type="image"
      />
    </BModal>

    <div class="bg-white rounded-lg shadow-sm my-3">
      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Logo du site et favicon
        </div>

        <div class="pb-3">
          <div class="form-control-label pb-2">
            Logo du site
            <i
              id="tooltip-setting-logo"
              class="fad fa-question-circle text-primary ml-2 cursor-pointer"
            />
            <BTooltip
              target="tooltip-setting-logo"
              triggers="hover"
            >
              Le nom de votre site sera affiché à la place du logo si vous ne choissisez pas de logo
            </BTooltip>
          </div>

          <div class="row align-items-stretch">
            <div class="col-12 col-md-6 col-xl-4 d-flex align-items-center justify-content-center justify-content-md-start">
              <div
                v-if="!item.logo"
                class="text-muted"
              >
                Aucun logo sélectionné
              </div>
              <!--suppress HtmlUnknownTarget -->
              <img
                v-if="item.logo"
                :src="item.logo"
                alt="Logo du site"
                class="img-thumbnail mw-100"
              >
            </div>

            <div class="col d-flex flex-column align-items-center align-items-md-end mt-3 mt-md-0 justify-content-center">
              <button
                class="btn btn-outline-success btn-sm rounded-pill px-3"
                @click.prevent="openSelection('logo')"
              >
                Choisir un logo
              </button>
              <button
                v-if="item.logo"
                class="btn btn-outline-danger btn-sm rounded-pill mt-3 px-3"
                @click.prevent="item.logo = null"
              >
                Supprimer le logo
              </button>
            </div>
          </div>
        </div>

        <div class="pb-3">
          <div class="form-control-label pb-2">
            Favicon du site
            <i
              id="tooltip-setting-favicon"
              class="fad fa-question-circle text-primary ml-2 cursor-pointer"
            />
            <BTooltip
              target="tooltip-setting-favicon"
              triggers="hover"
            >
              Le favicon est la petite image affichée dans l'onglet du navigateur
            </BTooltip>
          </div>

          <div class="row align-items-stretch">
            <div class="col-12 col-md-6 col-xl-4 d-flex align-items-center justify-content-center justify-content-md-start">
              <div
                v-if="!item.favicon"
                class="text-muted"
              >
                Aucun favicon sélectionné
              </div>
              <!--suppress HtmlUnknownTarget -->
              <img
                v-if="item.favicon"
                :src="item.favicon"
                alt="Favicon du site"
                class="img-thumbnail mw-100"
              >
            </div>

            <div class="col d-flex flex-column align-items-center align-items-md-end mt-3 mt-md-0 justify-content-center">
              <button
                class="btn btn-outline-success btn-sm rounded-pill px-3"
                @click.prevent="openSelection('favicon')"
              >
                Choisir un favicon
              </button>
              <button
                v-if="item.favicon"
                class="btn btn-outline-danger btn-sm rounded-pill mt-3 px-3"
                @click.prevent="item.favicon = null"
              >
                Supprimer le favicon
              </button>
            </div>
          </div>
        </div>

        <div class="f-14 font-weight-700 text-primary text-uppercase pt-4 pb-3">
          Couleurs du site
        </div>

        <div class="row">
          <div class="col-12 col-xl-6">
            <!--suppress JSUnresolvedVariable -->
            <FormColorInput
              v-model="item.primaryColor"
              :is-valid="isValid('primaryColor')"
              :is-pre-valid="isPreValid('primaryColor')"
              class="mb-0"
              placeholder="#fda32e"
              label="Couleur principale"
              @input="updateValidation('primaryColor')"
            >
              <template v-slot:afterInput>
                <small class="form-text text-muted">La couleur doit être au format hexadécimal : <b>#fda32e</b></small>
              </template>
              <template v-slot:validation>
                <div
                  v-if="!isValid('primaryColor')"
                  class="invalid-feedback"
                >
                  {{ violations.primaryColor }}
                </div>
                <div
                  v-if="!isPreValid('primaryColor')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.primaryColor.color">
                    Cette couleur n'est pas valide
                  </div>
                </div>
              </template>
            </FormColorInput>
          </div>
          <div class="col-12 col-xl-6">
            <!--suppress JSUnresolvedVariable -->
            <FormColorInput
              v-model="item.secondaryColor"
              :is-valid="isValid('secondaryColor')"
              :is-pre-valid="isPreValid('secondaryColor')"
              class="mb-0"
              placeholder="#fda32e"
              label="Couleur secondaire"
              @input="updateValidation('secondaryColor')"
            >
              <template v-slot:afterInput>
                <small class="form-text text-muted">La couleur doit être au format hexadécimal : <b>#fda32e</b></small>
              </template>
              <template v-slot:validation>
                <div
                  v-if="!isValid('secondaryColor')"
                  class="invalid-feedback"
                >
                  {{ violations.primaryColor }}
                </div>
                <div
                  v-if="!isPreValid('secondaryColor')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.secondaryColor.color">
                    Cette couleur n'est pas valide
                  </div>
                </div>
              </template>
            </FormColorInput>
          </div>
        </div>

        <div class="f-14 font-weight-700 text-primary text-uppercase pt-5 pb-3">
          Couleur et fond du site
        </div>

        <div class="pb-3">
          <div class="row">
            <div class="col-12 col-xl-6">
              <FormColorInput
                v-model="item.bodyBackgroundColor"
                :is-valid="isValid('bodyBackgroundColor')"
                :is-pre-valid="isPreValid('bodyBackgroundColor')"
                :allow-transparency="true"
                class="mb-4"
                placeholder="#fda32e"
                label="Couleur de fond"
                @input="updateValidation('bodyBackgroundColor')"
              >
                <template v-slot:afterInput>
                  <small class="form-text text-muted">La couleur doit être au format hexadécimal : <b>#fda32e</b></small>
                </template>
                <template v-slot:validation>
                  <div
                    v-if="!isValid('bodyBackgroundColor')"
                    class="invalid-feedback"
                  >
                    {{ violations.primaryColor }}
                  </div>
                  <div
                    v-if="!isPreValid('bodyBackgroundColor')"
                    class="invalid-feedback"
                  >
                    <!--suppress JSUnresolvedVariable -->
                    <div v-if="!$v.item.bodyBackgroundColor.alphacolor">
                      Cette couleur n'est pas valide
                    </div>
                  </div>
                </template>
              </FormColorInput>
            </div>
          </div>

          <div class="form-control-label pb-2">
            Image de fond
            <i
              id="tooltip-setting-background-image"
              class="fad fa-question-circle text-primary ml-2 cursor-pointer"
            />
            <BTooltip
              target="tooltip-setting-background-image"
              triggers="hover"
            >
              Cette image est affiché sur votre site, comme un fond d'écran
            </BTooltip>
          </div>

          <div class="row align-items-stretch mb-4">
            <div class="col-12 col-md-6 col-xl-4 d-flex align-items-center justify-content-center justify-content-md-start">
              <div
                v-if="!item.bodyBackgroundImage"
                class="text-muted"
              >
                Aucune image sélectionnée
              </div>
              <!--suppress HtmlUnknownTarget -->
              <img
                v-if="item.bodyBackgroundImage"
                :src="item.bodyBackgroundImage"
                alt="Image de fond"
                class="img-thumbnail mw-100"
              >
            </div>

            <div class="col d-flex flex-column align-items-center align-items-md-end mt-3 mt-md-0 justify-content-center">
              <button
                class="btn btn-outline-success btn-sm rounded-pill px-3"
                @click.prevent="openSelection('bodyBackgroundImage')"
              >
                Choisir une image
              </button>
              <button
                v-if="item.bodyBackgroundImage"
                class="btn btn-outline-danger btn-sm rounded-pill mt-3 px-3"
                @click.prevent="item.bodyBackgroundImage = null"
              >
                Supprimer l'image
              </button>
            </div>
          </div>

          <div v-if="item.bodyBackgroundImage">
            <FormCheckbox
              v-model="item.bodyBackgroundImageCover"
              label="L'image doit recouvrir toute la surface de la page"
              class="mb-2"
              :is-switch="true"
            />

            <FormCheckbox
              v-model="item.bodyBackgroundImageRepeat"
              label="L'image doit être répétée si elle ne recouvre pas toute la page"
              class="mb-2"
              :is-switch="true"
            />

            <FormCheckbox
              v-model="item.bodyBackgroundImageFixed"
              label="L'image doit être fixe quand l'utilisateur fait défiler la page"
              :is-switch="true"
            />

            <div
              class="background-preview rounded-lg border"
              :style="backgroundPreview"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click.prevent="submit"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FileList from '../file/List'
import FormColorInput from '../applicationform/FormColorInput'
import { S3_ENTRYPOINT } from '../../config/entrypoint'
import { helpers } from 'vuelidate/lib/validators'
import FormCheckbox from '../applicationform/FormCheckbox'

const color = helpers.regex('slug', /^(#[a-fA-F0-9]{6}|)$/)
const alphacolor = helpers.regex('slug', /^(#[a-fA-F0-9]{6}|#[a-fA-F0-9]{8})$/)

export default {
  components: {
    FormCheckbox,
    FileList,
    FormColorInput
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      selectingType: null
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    },

    backgroundPreview () {
      let style = 'background-color: ' + (this.item.bodyBackgroundColor ? this.item.bodyBackgroundColor : '#fff') + '; '

      if (this.item.bodyBackgroundImage) {
        style += 'background-image: url(' + this.item.bodyBackgroundImage + '); '
      }

      style += 'background-size: ' + (this.item.bodyBackgroundImageCover ? 'cover' : 'contain') + '; '

      if (this.item.bodyBackgroundImageFixed) {
        style += 'background-attachment: fixed; '
      }

      if (!this.item.bodyBackgroundImageRepeat) {
        style += 'background-repeat: no-repeat; '
      }

      return style
    }
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    closeSelection () {
      this.$bvModal.hide('select-image')
      this.selectingType = null
    },

    openSelection (type) {
      this.$bvModal.show('select-image')
      this.selectingType = type
    },

    selectImage (image) {
      // noinspection JSUnresolvedVariable
      this.item[this.selectingType] = S3_ENTRYPOINT + image.contentUrl
      this.closeSelection()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      logo: {},
      favicon: {},
      primaryColor: {
        color
      },
      secondaryColor: {
        color
      },
      bodyBackgroundColor: {
        alphacolor
      },
      bodyBackgroundImage: {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo {
    max-width: 200px;
    max-height: 50px;
  }

  .favicon {
    height: rem-calc(16);
    width: rem-calc(16);
  }

  .background-preview {
    background-position: center;
    min-height: rem-calc(350);
  }
</style>
