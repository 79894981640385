var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-row-preview"},[_c('BModal',{attrs:{"id":'edit-modal-' + _vm.id,"size":"xl","body-class":"px-0 pb-0","hide-header":"","ok-only":"","ok-title":"Revenir à l'édition","ok-variant":"primary rounded-pill px-3","footer-class":"justify-content-center border-top"}},[_c('RowModule',{attrs:{"content":_vm.content},on:{"update:content":function($event){_vm.content=$event}}})],1),_c('div',{class:{ 'container' : !_vm.content.content.fullWidth }},[_c('div',{class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n')
      ]},[(!_vm.editing)?_c('div',{staticClass:"d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center bg-light-grey py-2 px-3 z-index-8"},[_c('div',{staticClass:"f-14 font-weight-600 ml-3 mb-2 ml-sm-0 mb-sm-0 mr-3"},[_vm._v("\n          Zone de colonnes\n          "),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"far fa-question-circle text-success cursor-pointer ml-2",attrs:{"title":"Ce bandeau n'apparaitra pas sur votre page."}})])]):_vm._e(),_c('div',{staticClass:"d-inline-block w-100",class:[
          'pt-' + _vm.content.section.paddingTop,
          'pb-' + _vm.content.section.paddingBottom,
          'pl-' + _vm.content.section.paddingLeft,
          'pr-' + _vm.content.section.paddingRight ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"row",class:_vm.moduleClass},_vm._l((_vm.content.submodules),function(column,index){return _c('ColumnPreview',{key:index,class:{ 'opacity-50': (_vm.hover !== null && _vm.hover !== index && !_vm.editing) || (_vm.updatingModule && _vm.hover !== index && !column.submodules.includes(_vm.updatingModule) && _vm.updatingModule !== _vm.content) },attrs:{"content":column,"editing":_vm.editing},nativeOn:{"mouseover":function($event){_vm.hover = index},"mouseleave":function($event){_vm.hover = null}}})}),1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }