<template>
  <div class="page-gallery-update">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <RouterLink
          :to="{ name: 'gallery-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des galeries
        </RouterLink>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Éditer une galerie
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading && !retrieved"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading && retrieved"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <BAlert
      :show="confirmationCountdown"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
      @dismissed="confirmationCountdown=0"
      @dismiss-count-down="confirmationCountdownUpdated"
    >
      Les modifications de la galerie ont été enregistrées
    </BAlert>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="isLoading && !retrieved">
      <div
        class="py-5 my-3 rounded-lg bg-grey"
        style="height: 320px"
      />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div :class="{ 'is-loading': isLoading }">
      <GalleryForm
        v-if="retrieved"
        :handle-submit="onSendForm"
        :values="retrieved"
        :errors="violations"
        :initial-values="retrieved"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import GalleryForm from '../../components/gallery/Form'

export default {
  components: {
    GalleryForm
  },

  data () {
    return {
      confirmationSeconds: 10,
      confirmationCountdown: 0
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('gallery/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    updated (updated) {
      if (updated) {
        this.confirmationCountdown = this.confirmationSeconds
      }
    },

    error (error) {
      if (error) {
        this.$router.push({ name: 'error-404' })
      }
    },

    retrieved (retrieved) {
      if (!retrieved) {
        this.$router.push({ name: 'notfound' })
      }
    }
  },

  created () {
    this.retrieve('galleries/' + decodeURIComponent(this.$route.params.id))
  },

  beforeDestroy () {
    this.updateReset()
  },

  methods: {
    ...mapActions({
      retrieve: 'gallery/update/retrieve',
      updateReset: 'gallery/update/reset',
      update: 'gallery/update/update',
      updateRetrieved: 'gallery/update/updateRetrieved'
    }),

    confirmationCountdownUpdated (value) {
      this.confirmationCountdown = value
    },

    getImage (item) {
      return {
        url: item.url,
        type: item.type,
        name: item.name,
        date: item.date,
        description: item.description,
        ratio: item.ratio,
        showDetails: item.showDetails,
        position: parseInt(item.position)
      }
    },

    getGroup (item) {
      let images = []
      let self = this

      item.images.forEach((image) => {
        images.push(self.getImage(image))
      })

      return {
        images,
        position: parseInt(item.position),
        columnsLg: parseInt(item.columnsLg),
        columnsMd: parseInt(item.columnsMd),
        columnsSm: parseInt(item.columnsSm),
        padding: parseInt(item.padding),
        rounded: item.rounded
      }
    },

    onSendForm () {
      let groups = []
      let self = this

      this.retrieved.groups.forEach((group) => {
        groups.push(self.getGroup(group))
      })

      let payload = {
        name: this.retrieved.name,
        date: this.retrieved.date,
        groups,
        website: '/api/websites/' + this.user.selectedWebsite.id
      }

      this.update({
        id: 'galleries/' + this.retrieved.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
