<template>
  <div class="file-create">
    <FileForm
      :handle-create="handleCreate"
      :values="item"
      :mime-type="mimeType"
    />
  </div>
</template>

<script>
import FileForm from './Form'

export default {
  components: {
    FileForm
  },

  props: {
    handleCreate: {
      type: Function,
      required: true
    },

    mimeType: {
      type: String,
      default: '*/*'
    }
  },

  data () {
    return {
      item: {}
    }
  }
}
</script>
