<template>
  <div class="page-website-create">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <RouterLink
          :to="{ name: 'website-settings' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la gestion des paramètres
        </RouterLink>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Paramètres des membres
        </h2>
        <span
          v-if="!loadedSetting"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <span
          v-if="loadedSetting && isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <BAlert
      :show="confirmationCountdown"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
      @dismissed="confirmationCountdown=0"
      @dismiss-count-down="confirmationCountdownUpdated"
    >
      Les modifications ont été enregistrées
    </BAlert>

    <div
      v-if="loadedSetting"
      :class="{ 'is-loading' : isLoading }"
    >
      <SettingForm
        :handle-submit="onSendForm"
        :values="retrieved"
        :initial-values="retrieved"
        :errors="violations"
      />
    </div>
  </div>
</template>

<script>
import SettingForm from '../../../components/websitesetting/MemberForm'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    SettingForm
  },

  data () {
    return {
      loadedSetting: false,
      confirmationSeconds: 10,
      confirmationCountdown: 0
    }
  },

  computed: {
    ...mapFields('websitesetting/update', {
      error: 'error',
      isLoading: 'isLoading',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    }),

    ...mapFields('application/auth', {
      isUserLoading: 'isLoading',
      isLogged: 'isLogged',
      hasWebsite: 'hasWebsite',
      hasWebsiteSelected: 'hasWebsiteSelected',
      user: 'user'
    })
  },

  watch: {
    updated (updated) {
      if (!updated) {
        return
      }

      this.confirmationCountdown = this.confirmationSeconds
    },

    retrieved (retrieved) {
      if (retrieved) {
        this.loadedSetting = true
      }
    }
  },

  mounted () {
    this.retrieve('websites/' + this.user.selectedWebsite.id + '/setting')
  },

  methods: {
    ...mapActions({
      update: 'websitesetting/update/update',
      retrieve: 'websitesetting/update/retrieve'
    }),

    confirmationCountdownUpdated (value) {
      this.confirmationCountdown = value
    },

    onSendForm (item) {
      let fields = []

      item.memberFields.forEach((fieldItem) => {
        let activeGroups = []
        let requiredGroups = []
        let displayedGroups = []

        fieldItem.activeForGroups.forEach((group) => {
          activeGroups.push(group)
        })

        fieldItem.requiredForGroups.forEach((group) => {
          requiredGroups.push(group)
        })

        fieldItem.displayedForGroups.forEach((group) => {
          displayedGroups.push(group)
        })

        let field = {
          name: fieldItem.name,
          description: fieldItem.description,
          type: fieldItem.type,
          choices: fieldItem.choices,
          defaultValue: fieldItem.defaultValue,
          required: fieldItem.required,
          requiredForGroups: requiredGroups,
          displayed: fieldItem.displayed,
          displayedForGroups: displayedGroups,
          active: fieldItem.active,
          activeForGroups: activeGroups,
          icon: fieldItem.icon,
          position: fieldItem.position,
        }

        if (fieldItem.id) {
          field.id = fieldItem.id
          field['@id'] = '/api/website_setting_member_fields/' + fieldItem['id']
        }

        fields.push(field)
      })

      let payload = {
        allowMember: item.allowMember,
        allowRegister: item.allowRegister,
        memberFields: fields,
        memberMustBeValidated: item.memberMustBeValidated
      }

      this.update({
        id: 'website_settings/member_settings/' + this.retrieved.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
