import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import view from '../../../../utils/view'

const getItems = ({ commit }, { page = 'articles', query = {} }) => {
  commit(types.TOGGLE_LOADING)

  fetch(page, { query: query })
    .then(response => response.json())
    .then((data) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ITEMS, data['hydra:member'])
      commit(types.SET_VIEW, view(data))
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.RESET)
}

export const getSelectItems = (
  { commit },
  { page = 'website_users', params = { properties: ['@id', 'name'] } } = {}
) => {
  commit(types.TOGGLE_LOADING)

  fetch(page, { params })
    .then(response => response.json())
    .then(data => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_SELECT_ITEMS, data['hydra:member'])
    })
    .catch(e => {
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, e.message)
    })
}

export default getItems
