<template>
  <div class="page-article-update">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <router-link
          :to="{ name: 'article-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des articles
        </router-link>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Dupliquer un article
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading && !retrieved"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isCreateLoading && retrieved"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <b-alert
      :show="confirmationCountdown"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
      @dismissed="confirmationCountdown=0"
      @dismiss-count-down="confirmationCountdownUpdated"
    >
      Les modifications de l'article ont été enregistrées
    </b-alert>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="isLoading && !retrieved">
      <div
        class="py-5 my-3 rounded-lg bg-grey"
        style="height: 320px"
      />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div :class="{ 'is-loading': isLoading || isCreateLoading }">
      <ArticleForm
        v-if="retrieved && contentReady"
        :handle-submit="onSendForm"
        :values="retrieved"
        :errors="violations"
        :initial-values="retrieved"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ArticleForm from '../../components/article/Form'

export default {
  components: {
    ArticleForm
  },

  data () {
    return {
      contentReady: false,
      confirmationSeconds: 10,
      confirmationCountdown: 0
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('article/create', {
      error: 'error',
      isCreateLoading: 'isLoading',
      created: 'created',
      violations: 'violations'
    }),

    ...mapFields('article/update', {
      isLoading: 'isLoading',
      retrieved: 'retrieved'
    })
  },

  watch: {
    updated (updated) {
      if (updated) {
        this.confirmationCountdown = this.confirmationSeconds
      }
    },

    created: function (created) {
      if (!created) {
        return
      }

      this.$router.push({ name: 'article-list' })
    },

    error (error) {
      if (error) {
        this.$router.push({ name: 'error-404' })
      }
    },

    retrieved (retrieved) {
      if (!retrieved) {
        this.$router.push({ name: 'notfound' })
      }

      if (retrieved) {
        if (!retrieved.content) {
          retrieved.content = []
        }

        if (retrieved.content && !Array.isArray(retrieved.content)) {
          retrieved.content = JSON.parse(retrieved.content)
        }

        this.contentReady = true
      }
    }
  },

  created () {
    this.retrieve('articles/' + decodeURIComponent(this.$route.params.id))
  },

  beforeDestroy () {
    this.updateReset()
    this.createReset()
  },

  methods: {
    ...mapActions({
      createArticle: 'article/create/create',
      createReset: 'article/create/reset',
      retrieve: 'article/update/retrieve',
      updateReset: 'article/update/reset'
    }),

    confirmationCountdownUpdated (value) {
      this.confirmationCountdown = value
    },

    serializeModule (module) {
      let self = this
      // eslint-disable-next-line no-undef
      let item = _.cloneDeep(module)
      item = { ...item, ...item.section }

      item.submodules = []
      item.content = JSON.stringify(item.content)

      delete item.id
      delete item['@id']
      delete item['@type']
      delete item.section

      if (module.submodules && module.submodules.length > 1) {
        module.submodules.forEach((child) => {
          module.submodules.push(self.serializeModule(child))
        })
      }

      return item
    },

    onSendForm () {
      let self = this
      let modules = []

      this.retrieved.modules.forEach((module) => {
        modules.push(self.serializeModule(module))
      })

      let payload = {
        title: this.retrieved.title,
        description: this.retrieved.description,
        thumbnail: this.retrieved.thumbnail,
        slug: this.retrieved.slug,
        publication: this.retrieved.publication,
        website: '/api/websites/' + this.user.selectedWebsite.id,
        author: this.retrieved.author ? this.retrieved.author['@id'] : null,
        private: this.retrieved.private,
        commentable: this.retrieved.commentable,
        modules
      }

      this.createArticle(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
