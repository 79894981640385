import Profile from '../views/user/Profile'

export default [
  {
    path: '/account/profile',
    name: 'user-profile',
    component: Profile,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: false,
      title: 'Profil'
    }
  }
]
