<template>
  <div class="component-page-builder-form w-100">
    <div v-show="updatingModule">
      <div class="position-fixed top-0 left-0 w-100 h-100 bg-white z-index-10 d-flex d-xl-none align-items-stretch overflow-y-auto">
        <ModuleForm
          v-if="updatingModule"
        />
      </div>
    </div>

    <BModal
      :id="'module-modal-' + id"
      size="lg"
      body-class="p-3"
      hide-header
      ok-only
      ok-title="Revenir à l'édition"
      ok-variant="primary rounded-pill px-3"
      footer-class="justify-content-center border-top"
    >
      <ModuleList
        :is-column="isColumn"
        :add-element="addElement"
        :add-component="addComponent"
      />
    </BModal>

    <div v-if="modules.length === 0">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div
            v-if="!isColumn"
            class="col-12 col-md-10 col-lg-6 col-xl-5"
          >
            <img
              src="../../assets/img/creative_design.svg"
              class="mw-100"
              alt="Page builder"
            >
          </div>
          <div :class="[ isColumn ? 'col-12' : 'col-12 col-lg-6 col-xl-5' ]">
            <div v-if="!isColumn">
              <div class="text-soft pt-5 pb-3 text-center">
                Cette page est vide
              </div>
              <div class="f-14 text-muted font-weight-600 pb-3">
                <i class="fas fa-question-circle mr-2" />Vous pouvez construire votre page en ajoutant petit à petit vos éléments : élément de texte, d'image, de bouton, ...<br>
              </div>
              <div class="f-14 text-muted font-weight-600 pb-3">
                <i class="fas fa-palette mr-2" />Chaque élément est entièrement personnalisable : couleur, bordure et bien d'autres options peuvent être configurées pour vous permettre de créer la page parfaite.
              </div>
            </div>
            <div
              class="text-center mt-3"
              :class="{ 'mb-3': isColumn }"
            >
              <button
                v-b-modal="'module-modal-' + id"
                class="btn bg-white bg-success-hover btn-outline-success btn-sm rounded-pill px-3"
              >
                Ajouter un élément
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-for="(module, index) in modules"
      :id="'element-' + id + '-' + index"
      :key="index"
      class="module position-relative d-flex"
      :class="{ 'opacity-50': (hover !== null && hover !== index && !editing) || (updatingModule && updatingModule !== module && hover !== index && module.type !== 'row') }"
      @mouseover="hover = index"
      @mouseleave="hover = null"
    >
      <BPopover
        :ref="'element-' + id + '-' + index"
        :target="'element-' + id + '-' + index"
        triggers="hover"
        :placement="['row', 'shape'].includes(module.type) ? 'left' : 'top'"
        :title="moduleTypes[module.type]"
      >
        <div class="d-flex flex-column align-items-stretch text-center">
          <div
            v-if="modules.length > 1 && index !== 0"
            class="cursor-pointer border border-dark bg-dark-hover text-white-hover bg-white rounded-pill f-14 font-weight-600 px-3 py-1 mx-2 my-1"
            @click.prevent="[moveModule(index, -1), closePopover('element-' + id + '-' + index)]"
          >
            <i class="fas fa-chevron-up fa-fw" /> Monter
          </div>
          <div
            v-if="modules.length > 1 && index !== modules.length - 1"
            class="cursor-pointer border border-dark bg-dark-hover text-white-hover bg-white rounded-pill f-14 font-weight-600 px-3 py-1 mx-2 my-1"
            @click.prevent="[moveModule(index, 1), closePopover('element-' + id + '-' + index)]"
          >
            <i class="fas fa-chevron-down fa-fw" /> Descendre
          </div>
          <div
            class="cursor-pointer border border-primary bg-primary-hover text-white-hover bg-white rounded-pill f-14 font-weight-600 px-3 py-1 mx-2 my-1 text-primary"
            @click.prevent="[editModule(module), closePopover('element-' + id + '-' + index)]"
          >
            <i class="fad fa-cogs fa-fw" /> Modifier
          </div>
          <div
            class="cursor-pointer border border-success bg-success-hover text-white-hover bg-white rounded-pill f-14 font-weight-600 px-3 py-1 mx-2 my-1 text-success"
            @click.prevent="[duplicateModule(module, index), closePopover('element-' + id + '-' + index)]"
          >
            <i class="fad fa-copy fa-fw" /> Dupliquer
          </div>
          <div
            class="cursor-pointer border border-danger bg-danger-hover text-white-hover bg-white rounded-pill f-14 font-weight-600 px-3 py-1 mx-2 my-1 text-danger"
            :class="{ 'mr-1': updatingModule === module }"
            @click.prevent="[removeModule(index), closePopover('element-' + id + '-' + index)]"
          >
            <i class="fad fa-trash fa-fw" /> Supprimer
          </div>
        </div>
      </BPopover>

      <div
        :class="{ 'module-hover': (hover === index && !editing) || (updatingModule && updatingModule === module), 'module-column': isColumn }"
        class="z-index-1 position-relative d-inline-block w-100"
      >
        <TextPreview
          v-if="module.type === 'text'"
          :content="module"
          :has-container="!isColumn"
        />
        <ImagePreview
          v-if="module.type === 'image'"
          :content="module"
          :has-container="!isColumn"
        />
        <GalleryPreview
          v-if="module.type === 'gallery'"
          :content="module"
          :has-container="!isColumn"
        />
        <RowPreview
          v-if="module.type === 'row'"
          :content="module"
          :has-container="!isColumn"
        />
        <ColumnPreview
          v-if="module.type === 'column'"
          :content="module"
          :has-container="!isColumn"
        />
        <CarouselPreview
          v-if="module.type === 'carousel'"
          :content="module"
          :has-container="!isColumn"
        />
        <TitlePreview
          v-if="module.type === 'title'"
          :content="module"
          :has-container="!isColumn"
        />
        <ButtonPreview
          v-if="module.type === 'button'"
          :content="module"
          :has-container="!isColumn"
        />
        <ShapePreview
          v-if="module.type === 'shape'"
          :content="module"
          :has-container="!isColumn"
        />
        <VideoPreview
          v-if="module.type === 'video'"
          :content="module"
          :has-container="!isColumn"
        />
        <MemberListPreview
          v-if="module.type === 'member-list'"
          :content="module"
          :has-container="!isColumn"
        />
        <MemberAvatarPreview
          v-if="module.type === 'member-avatar'"
          :content="module"
          :has-container="!isColumn"
        />
        <MemberNamePreview
          v-if="module.type === 'member-name'"
          :content="module"
          :has-container="!isColumn"
        />
        <MemberGroupsPreview
          v-if="module.type === 'member-groups'"
          :content="module"
          :has-container="!isColumn"
        />
        <MemberPresentationPreview
          v-if="module.type === 'member-presentation'"
          :content="module"
          :has-container="!isColumn"
        />
        <MemberSocialsPreview
          v-if="module.type === 'member-socials'"
          :content="module"
          :has-container="!isColumn"
        />
        <MemberFieldsPreview
          v-if="module.type === 'member-fields'"
          :content="module"
          :has-container="!isColumn"
        />
        <ArticlesPreview
          v-if="module.type === 'articles'"
          :content="module"
          :has-container="!isColumn"
        />
        <ArticleThumbnailPreview
          v-if="module.type === 'article-thumbnail'"
          :content="module"
          :has-container="!isColumn"
        />
        <ArticleTitlePreview
          v-if="module.type === 'article-title'"
          :content="module"
          :has-container="!isColumn"
        />
        <ArticlePublicationPreview
          v-if="module.type === 'article-publication'"
          :content="module"
          :has-container="!isColumn"
        />
        <ArticleContentPreview
          v-if="module.type === 'article-content'"
          :content="module"
          :has-container="!isColumn"
        />
        <ArticleCommentsPreview
          v-if="module.type === 'article-comments'"
          :content="module"
          :has-container="!isColumn"
        />
        <ContactPreview
          v-if="module.type === 'contact'"
          :content="module"
          :has-container="!isColumn"
        />
        <SeparatorPreview
          v-if="module.type === 'separator'"
          :content="module"
          :has-container="!isColumn"
        />
      </div>
    </div>

    <div
      v-if="!editing && modules.length > 0"
      :class="{ 'my-3 py-3 border-top': !isColumn }"
    >
      <div class="container">
        <div class="row justify-content-center py-3">
          <div
            class="col-12"
            :class="{ 'col-xl-5': !isColumn }"
          >
            <div
              v-if="!isColumn"
              class="f-14 text-muted font-weight-600"
            >
              <i class="fas fa-question-circle mr-2" />Vous pouvez ajouter autant d'éléments que vous le souhaitez<br>
            </div>
            <div
              class="text-center"
              :class="{ 'mt-3': !isColumn }"
            >
              <button
                v-b-modal="'module-modal-' + id"
                class="btn bg-white bg-success-hover btn-outline-success btn-sm rounded-pill px-3"
              >
                Ajouter un élément
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { elements } from '@/config/pagebuilder/elements'
import { components } from '@/config/pagebuilder/components'
import ArticlesPreview from './preview/ArticlesPreview'
import ButtonPreview from './preview/ButtonPreview'
import CarouselPreview from './preview/CarouselPreview'
import ColumnPreview from './preview/ColumnPreview'
import ContactPreview from './preview/ContactPreview'
import ImagePreview from './preview/ImagePreview'
import ModuleForm from './ModuleForm'
import ModuleList from './elements/ModuleList'
import RowPreview from './preview/RowPreview'
import ShapePreview from './preview/ShapePreview'
import TextPreview from './preview/TextPreview'
import TitlePreview from './preview/TitlePreview'
import ArticleThumbnailPreview from '@/components/pagebuilder/preview/ArticleThumbnailPreview'
import ArticleTitlePreview from '@/components/pagebuilder/preview/ArticleTitlePreview'
import ArticleContentPreview from '@/components/pagebuilder/preview/ArticleContentPreview'
import ArticlePublicationPreview from '@/components/pagebuilder/preview/ArticlePublicationPreview'
import ArticleCommentsPreview from '@/components/pagebuilder/preview/ArticleCommentsPreview'
import SeparatorPreview from '@/components/pagebuilder/preview/SeparatorPreview'
import GalleryPreview from '@/components/pagebuilder/preview/GalleryPreview'
import MemberListPreview from '@/components/pagebuilder/preview/MemberListPreview'
import MemberNamePreview from '@/components/pagebuilder/preview/MemberNamePreview'
import MemberAvatarPreview from '@/components/pagebuilder/preview/MemberAvatarPreview'
import MemberGroupsPreview from '@/components/pagebuilder/preview/MemberGroupsPreview'
import MemberFieldsPreview from '@/components/pagebuilder/preview/MemberFieldsPreview'
import MemberPresentationPreview from '@/components/pagebuilder/preview/MemberPresentationPreview'
import MemberSocialsPreview from '@/components/pagebuilder/preview/MemberSocialsPreview'
import VideoPreview from '@/components/pagebuilder/preview/VideoPreview'

export default {
  name: 'BuilderForm',

  components: {
    VideoPreview,
    MemberSocialsPreview,
    MemberPresentationPreview,
    MemberFieldsPreview,
    MemberGroupsPreview,
    MemberAvatarPreview,
    MemberNamePreview,
    MemberListPreview,
    GalleryPreview,
    SeparatorPreview,
    ArticleCommentsPreview,
    ArticlePublicationPreview,
    ArticleContentPreview,
    ArticleTitlePreview,
    ArticleThumbnailPreview,
    ArticlesPreview,
    ButtonPreview,
    CarouselPreview,
    ColumnPreview,
    ContactPreview,
    ImagePreview,
    ModuleForm,
    ModuleList,
    RowPreview,
    ShapePreview,
    TextPreview,
    TitlePreview
  },

  props: {
    content: {
      type: Array,
      required: true,
      default: () => []
    },

    isColumn: {
      type: Boolean,
      default: false
    },

    editing: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      hover: null,
      modules: this.content,
      elements,
      components,
      moduleTypes: {
        text: 'Texte',
        image: 'Image',
        row: 'Colonnes',
        button: 'Bouton',
        carousel: 'Carrousel',
        title: 'Titre',
        shape: 'Forme de contenu',
        'member-list': 'Liste de membres',
        'member-avatar': 'Avatar du membre',
        'member-name': 'Nom du membre',
        'member-groups': 'Groupes du membre',
        'member-socials': 'Réseaux sociaux du membre',
        'member-presentation': 'Présentation du membre',
        'member-fields': 'Champs personnalisés du membre',
        'article-thumbnail': 'Miniature d\'article',
        'article-title': 'Titre d\'article',
        'article-informations': 'Auteur et date d\'article',
        'article-content': 'Contenu de l\'article',
        'article-last': 'Derniers articles',
        'article-comments': 'Commentaires',
      }
    }
  },

  computed: {
    ...mapFields('pagebuilder/update', {
      updatingModule: 'updatingModule',
      builderType: 'builderType',
      pageType: 'pageType'
    }),

    id () {
      // noinspection JSUnresolvedVariable
      return 'pagebuilder-' + this._uid
    }
  },

  watch: {
    modules: {
      deep: true,
      handler (content) {
        this.$emit('update:content', content)
      }
    },

    content: {
      deep: true,
      handler (content) {
        if (this.modules !== content) {
          this.modules = content
        }
      }
    }
  },

  mounted () {
    this.modules.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));
  },

  beforeDestroy () {
    this.updateModule(null)
  },

  methods: {
    ...mapActions({
      updateModule: 'pagebuilder/update/updateModule',
      updateBuilderType: 'pagebuilder/update/updateBuilderType',
      updatePageType: 'pagebuilder/update/updatePageType',
      updateReset: 'pagebuilder/update/reset'
    }),

    computePosition () {
      this.modules.forEach((module, index) => {
        module.position = index
      })
    },

    moveModule (from, value = 0) {
      this.modules.splice(from + value, 0, this.modules.splice(from, 1)[0])
      this.computePosition()
    },

    addElement (type) {
      this.$bvModal.hide('module-modal-' + this.id)

      // eslint-disable-next-line no-undef
      let module = _.cloneDeep(this.elements[type])

      this.modules.push(module)
      this.editModule(module)
      this.computePosition()
    },

    addComponent (type) {
      this.$bvModal.hide('module-modal-' + this.id)

      // eslint-disable-next-line no-undef
      let module = _.cloneDeep(this.components[type])

      this.modules.push(module)
      this.editModule(module)
      this.computePosition()
    },

    editModule (module) {
      this.updateModule(module)
    },

    duplicateModule (module, index) {
      // eslint-disable-next-line no-undef
      let clone = _.cloneDeep(module)
      delete clone.id

      this.modules.splice(index, 0, clone)
      this.computePosition()
    },

    removeModule (index) {
      this.modules.splice(index, 1)
      this.computePosition()
    },

    closePopover (ref) {
      this.$refs[ref][0].$emit('close')
    }
  }
}
</script>

<style lang="scss">
  @media (min-width: 1200px) {
    .page-builder {
      .container,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl {
        max-width: 1140px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .module-hover {
    box-shadow: 0 0 0 4px #8dbbfe;
    border-radius: 2px;
    z-index: 1;
    min-height: 1px;

    &.module-column {
      box-shadow: 0 0 0 4px #9bd4a7;
    }
  }

  .module-hover::before,
  .module-hover::after {
    //content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-top: 1px solid $primary;
  }

  .module-hover::after {
    top: auto;
    bottom: 0;
  }
</style>
