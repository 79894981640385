import { shape as shapeModule } from '@/config/pagebuilder/components/shape'
import { title as titleModule } from '@/config/pagebuilder/elements/title'
import { row as rowModule } from '@/config/pagebuilder/components/row'
import { column as columnModule } from '@/config/pagebuilder/components/column'
import { memberAvatar as avatarModule } from '@/config/pagebuilder/elements/member_avatar'
import { memberName as nameModule } from '@/config/pagebuilder/elements/member_name'
import { memberGroups as groupsModule } from '@/config/pagebuilder/elements/member_groups'
import { memberSocials as socialsModule } from '@/config/pagebuilder/elements/member_socials'
import { memberPresentation as presentationModule } from '@/config/pagebuilder/elements/member_presentation'
import { memberFields as fieldsModule } from '@/config/pagebuilder/elements/member_fields'
import { separator as separatorModule } from '@/config/pagebuilder/elements/separator'

let shape = _.cloneDeep(shapeModule)
shape.position = 0
shape.section.backgroundImage = 'https://scaleweb-local.s3.eu-west-3.amazonaws.com/website/1/files/5f7f16cd79d4f393146829.png'
shape.section.backgroundImageCover = false
shape.content['shape'] = 'custom'
shape.content['height'] = '250'
shape.content['unit'] = 'px'
shape.content = JSON.stringify(shape.content)
shape = { ...shape, ...shape.section }

let title = _.cloneDeep(titleModule)
title.position = 0
title['content'].text = 'Page de profil'
title['content'].fontFamily = 'josefin'
title['content'].textShadow = 'true'
title['content'].textShadowColor = '#007BFF7A'
title['content'].alignment = 'center'
title.content = JSON.stringify(title.content)
title = { ...title, ...title.section }

shape.submodules = [
  title
]

let firstRow = _.cloneDeep(rowModule)
firstRow.position = 1
firstRow.section.marginTop = '5'
firstRow.section.marginBottom = '4'
firstRow.section.verticalAlign = 'center'
firstRow.content = JSON.stringify(firstRow.content)
firstRow = { ...firstRow, ...firstRow.section }

let avatarColumn = _.cloneDeep(columnModule)
avatarColumn.position = 0
avatarColumn.content['smallSize'] = 12
avatarColumn.content['mediumSize'] = 4
avatarColumn.content['largeSize'] = 3
avatarColumn.content = JSON.stringify(avatarColumn.content)
avatarColumn = { ...avatarColumn, ...avatarColumn.section }

let informationsColumn = _.cloneDeep(columnModule)
informationsColumn.position = 1
informationsColumn.content['smallSize'] = 12
informationsColumn.content['mediumSize'] = 8
informationsColumn.content['largeSize'] = 9
informationsColumn.content = JSON.stringify(informationsColumn.content)
informationsColumn = { ...informationsColumn, ...informationsColumn.section }

let avatar = _.cloneDeep(avatarModule)
avatar.position = 0
avatar.content['align'] = 'left'
avatar.content = JSON.stringify(avatar.content)
avatar = { ...avatar, ...avatar.section }

avatarColumn.submodules = [
  avatar
]

let name = _.cloneDeep(nameModule)
name.position = 0
name.content = JSON.stringify(name.content)
name = { ...name, ...name.section }

let groups = _.cloneDeep(groupsModule)
groups.position = 1
groups.section.marginTop = '1'
groups.content = JSON.stringify(groups.content)
groups = { ...groups, ...groups.section }

let socials = _.cloneDeep(socialsModule)
socials.position = 2
socials.section.marginTop = '3'
socials.content = JSON.stringify(socials.content)
socials = { ...socials, ...socials.section }

informationsColumn.submodules = [
  name,
  groups,
  socials,
]

firstRow.submodules = [
  avatarColumn,
  informationsColumn,
]

let separator = _.cloneDeep(separatorModule)
separator.position = 2
separator.content = JSON.stringify(separator.content)
separator = { ...separator, ...separator.section }

let secondRow = _.cloneDeep(rowModule)
secondRow.position = 3
secondRow.section.marginTop = '5'
secondRow.section.marginBottom = '4'
secondRow.content = JSON.stringify(secondRow.content)
secondRow = { ...secondRow, ...secondRow.section }

let presentationColumn = _.cloneDeep(columnModule)
presentationColumn.position = 0
presentationColumn.content['smallSize'] = 12
presentationColumn.content['mediumSize'] = 8
presentationColumn.content['largeSize'] = 8
presentationColumn.section.paddingRight = '4'
presentationColumn.content = JSON.stringify(presentationColumn.content)
presentationColumn = { ...presentationColumn, ...presentationColumn.section }

let fieldsColumn = _.cloneDeep(columnModule)
fieldsColumn.position = 1
fieldsColumn.content['smallSize'] = 12
fieldsColumn.content['mediumSize'] = 4
fieldsColumn.content['largeSize'] = 4
fieldsColumn.section.paddingLeft = '4'
fieldsColumn.section.paddingRight = '4'
fieldsColumn.content = JSON.stringify(fieldsColumn.content)
fieldsColumn = { ...fieldsColumn, ...fieldsColumn.section }

let presentation = _.cloneDeep(presentationModule)
presentation.position = 0
presentation.content = JSON.stringify(presentation.content)
presentation = { ...presentation, ...presentation.section }

presentationColumn.submodules = [
  presentation,
]

let fields = _.cloneDeep(fieldsModule)
fields.position = 0
fields.content = JSON.stringify(fields.content)
fields = { ...fields, ...fields.section }

fieldsColumn.submodules = [
  fields,
]

secondRow.submodules = [
  presentationColumn,
  fieldsColumn,
]

export const pageProfileDefault = [
  shape,
  firstRow,
  separator,
  secondRow
]
