<template>
  <div class="component-gallery-group-preview">
    <div
      class="row"
      :style="rowStyle"
    >
      <div
        v-for="(image, index) in group.images"
        :key="index"
        :style="columnStyle"
      >
        <div
          class="overflow-hidden"
          :class="columnClass"
        >
          <!--suppress HtmlRequiredAltAttribute -->
          <img
            v-if="!image.ratio"
            :src="image.url"
            class="w-100"
          >

          <div
            v-if="image.ratio"
            class="embed-responsive"
            :class="'embed-responsive-' + image.ratio"
          >
            <div
              class="embed-responsive-item"
              :style="'background-image: url(' + image.url + '); background-position: center; background-size: cover;'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      required: true
    }
  },

  computed: {
    rowStyle () {
      return 'margin: 0 -' + this.group.padding / 2 + 'px;'
    },

    columnStyle () {
      let style = 'max-width:' + 100 / this.group.columnsLg + '%;'
      style += 'flex: 0 0 ' + 100 / this.group.columnsLg + '%;'
      style += 'padding: ' + this.group.padding / 2 + 'px;'

      return style
    },

    columnClass () {
      let classes = [];

      switch (this.group.rounded) {
        case 'sm':
          classes.push('rounded-sm')
          break

        case 'md':
          classes.push('rounded')
          break

        case 'lg':
          classes.push('rounded-lg')
          break
      }

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
