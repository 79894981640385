import List from '../views/file/List'

export default [
  {
    path: '/files',
    name: 'file-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'file.list',
      title: 'Liste des fichiers'
    }
  }
]
