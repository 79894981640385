import List from '../views/membergroup/List'
import Create from '../views/membergroup/Create'
import Update from '../views/membergroup/Update'

export default [
  {
    path: '/member_groups',
    name: 'membergroup-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'member_group.list',
      title: 'Liste des groupes'
    }
  },
  {
    path: '/member_groups/create',
    name: 'membergroup-create',
    component: Create,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'member_group.create',
      title: 'Ajouter un groupe'
    }
  },
  {
    path: '/member_groups/:id',
    name: 'membergroup-update',
    component: Update,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'member_group.update',
      title: 'Éditer un groupe'
    }
  }
]
