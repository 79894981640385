<template>
  <div class="module-socials-module">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'customization'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
        Options d'apparence
      </div>
      <div class="px-4 pt-4">
        <div class="form-group mb-4">
          <FormCheckbox
            v-model="content.content.coloredIcons"
            label="Afficher les icones en couleur"
            :is-switch="true"
            class="mb-4"
          />
        </div>

        <div class="form-group mb-4">
          <FormCheckbox
            v-model="content.content.displayName"
            label="Afficher le nom des réseaux"
            :is-switch="true"
            class="mb-4"
          />
        </div>

        <div class="form-group mb-4">
          <FormCheckbox
            v-model="content.content.vertical"
            label="Afficher les réseaux verticalement"
            :is-switch="true"
            class="mb-4"
          />
        </div>

        <div class="form-group mb-4">
          <div class="form-control-label d-flex flex-wrap align-items-end">
            <span>Taille des icones</span>
            <span class="text-success f-14 font-weight-600 ml-auto">{{ content.content.size }}px</span>
          </div>
          <BFormInput
            v-model="content.content.size"
            type="range"
            min="12"
            max="48"
            step="1"
          />
        </div>

        <div class="form-group mb-4">
          <div class="form-control-label">
            Alignement des réseaux
          </div>
          <BFormRadio
            v-model="content.content.align"
            name="some-radios"
            value="start"
          >
            Aligner à gauche
          </BFormRadio>
          <BFormRadio
            v-model="content.content.align"
            name="some-radios"
            value="center"
          >
            Aligner au centre
          </BFormRadio>
          <BFormRadio
            v-model="content.content.align"
            name="some-radios"
            value="end"
          >
            Aligner à droite
          </BFormRadio>
        </div>
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import FormInput from '../../applicationform/FormInput'
import SectionAppearance from './../SectionAppearance'
import Tabs from '../../applicationcomponent/Tabs'
import FormSelect from '@/components/applicationform/FormSelect'
import FormCheckbox from '@/components/applicationform/FormCheckbox'

export default {
  components: {
    FormCheckbox,
    FormSelect,
    Tabs,
    FormInput,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 'customization',
      tabs: [
        {
          name: 'customization',
          title: 'Options des réseaux'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
      },
      selection: false
    }
  }
}
</script>
