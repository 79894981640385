<template>
  <div class="page-invitation-update">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <router-link
          :to="{ name: 'invitation-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des invitations
        </router-link>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Modifier une invitation
        </h2>
        <span
          v-if="!loadedInvitation"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <span
          v-if="loadedInvitation && isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <div v-if="!loadedInvitation">
      <div
        class="py-5 my-3 rounded-lg bg-grey"
        style="height: 320px"
      />
    </div>

    <div
      v-if="loadedInvitation"
      :class="{ 'is-loading' : isLoading }"
    >
      <InvitationForm
        :handle-submit="onSendForm"
        :values="retrieved"
        :initial-values="retrieved"
        :errors="violations"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import InvitationForm from '../../components/invitation/Form'

export default {
  components: {
    InvitationForm
  },

  data () {
    return {
      loadedInvitation: false
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('invitation/update', {
      error: 'error',
      isLoading: 'isLoading',
      updated: 'updated',
      retrieved: 'retrieved',
      violations: 'violations'
    })
  },

  watch: {
    retrieved (retrieved) {
      if (retrieved) {
        this.loadedInvitation = true
      }
    },

    error (error) {
      if (error) {
        this.$router.push({ name: 'error-404' })
      }
    },

    updated (updated) {
      if (!updated) {
        return
      }

      if (updated.accepted) {
        this.$router.push({ name: 'error-503' })
      }

      this.$router.push({ name: 'invitation-list' })
    }
  },

  mounted () {
    this.retrieve('invitations/' + decodeURIComponent(this.$route.params.id))
  },

  beforeDestroy () {
    this.updateReset()
  },

  methods: {
    ...mapActions({
      retrieve: 'invitation/update/retrieve',
      updateReset: 'invitation/update/reset',
      update: 'invitation/update/update'
    }),

    onSendForm () {
      let payload = {
        permissions: this.retrieved.permissions
      }

      this.update({
        id: 'invitations/' + this.retrieved.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
