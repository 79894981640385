import List from '@/views/shoppayment/List'
import View from '@/views/shoppayment/View'

export default [
  {
    path: '/payments',
    name: 'shoppayment-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'shoppayment.list',
      title: 'Liste des paiements'
    }
  },
  {
    path: '/payments/:id',
    name: 'shoppayment-view',
    component: View,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'shoppayment.view',
      title: 'Voir un paiement'
    }
  }
]
