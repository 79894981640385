import { options } from './_default'

export const articlePublication = {
  type: 'article-publication',
  position: null,
  variant: 'default',
  content: {
    showDate: true,
    showAuthor: true,
    showPublicationWord: true,
    showDateWord: true,
    sameLine: false,
    alignment: 'center',
    textColor: '#6c757d',
    fontFamily: 'roboto',
    fontSize: '15',
    background: false,
    backgroundColor: '#f5208b',
    backgroundHeight: '25',
    backgroundMarginTop: '10',
    backgroundMarginLeft: '10',
    underline: false,
    underlineWidth: '50',
    underlineHeight: '5',
    underlineColor: '#F5A721',
    textShadow: false,
    textShadowHorizontal: '2',
    textShadowVertical: '2',
    textShadowBlur: '0',
    textShadowColor: '#00000052'
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
