<template>
  <div class="page-member-create">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <router-link
          :to="{ name: 'member-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des membres
        </router-link>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Créer un membre
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div :class="{ 'is-loading' : isLoading }">
      <MemberForm
        :handle-created="onCreated"
        :handle-submit="onSendForm"
        :values="item"
        :errors="violations"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import MemberForm from '../../components/member/Form'

export default {
  components: {
    MemberForm
  },

  data () {
    return {
      item: {
        username: '',
        email: null,
        presentation: null,
        socialFacebook: null,
        socialInstagram: null,
        socialTwitter: null,
        socialLinkedIn: null,
        socialYoutube: null,
        socialTwitch: null,
        socialWebsite: null,
        groups: [],
        fields: []
      }
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('member/create', {
      error: 'error',
      isLoading: 'isLoading',
      created: 'created',
      violations: 'violations'
    })
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.item.website = '/api/websites/' + this.user.selectedWebsite.id
  },

  beforeDestroy () {
    this.createReset()
  },

  methods: {
    ...mapActions('member/create', {
      createMember: 'create',
      createReset: 'reset'
    }),

    onCreated () {
      this.$router.push({ name: 'member-list' })
    },

    onSendForm () {
      let payload = {
        username: this.item.username,
        email: this.item.email,
        presentation: this.item.presentation,
        website: this.item.website,
        groups: this.item.groups,
        fields: this.item.fields,
        socialFacebook: this.item.socialFacebook,
        socialInstagram: this.item.socialInstagram,
        socialTwitter: this.item.socialTwitter,
        socialLinkedIn: this.item.socialLinkedIn,
        socialYoutube: this.item.socialYoutube,
        socialTwitch: this.item.socialTwitch,
        socialWebsite: this.item.socialWebsite,
      }

      this.createMember(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
