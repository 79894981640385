import { options } from '../elements/_default'

let component = {
  type: 'contact',
  position: null,
  variant: 'default',
  content: {},
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}

export const contact = component
