<template>
  <div class="module-member-groups">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'customization'">
      <div class="bg-danger-light f-14 font-weight-600 text-center p-4">
        Cette section est nécessaire pour afficher les groupes d'un membre.
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import FormInput from '../../applicationform/FormInput'
import SectionAppearance from './../SectionAppearance'
import Tabs from '../../applicationcomponent/Tabs'
import FormSelect from '@/components/applicationform/FormSelect'

export default {
  components: {
    FormSelect,
    Tabs,
    FormInput,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 'customization',
      tabs: [
        {
          name: 'customization',
          title: 'Groupes du membre'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
      },
      selection: false
    }
  }
}
</script>
