<template>
  <div class="component-module-form w-100">
    <div
      v-if="updatingModule"
      class="mh-100-vh mh-100 overflow-y-auto pb-4"
      :class="{ 'position-sticky top-0': sticky }"
      style="overflow-y: auto"
    >
      <div class="border-bottom d-flex d-xl-none justify-content-center py-3">
        <button
          class="btn btn-sm btn-outline-success px-3 rounded-pill"
          @click="updatingModule = null"
        >
          Revenir à l'éditeur
        </button>
      </div>
      <div class="border-bottom d-none d-xl-flex justify-content-center py-3">
        <button
          class="btn btn-sm btn-outline-success px-3 rounded-pill"
          @click="updatingModule = null"
        >
          Fermer l'édition de l'élément
        </button>
      </div>
      <RowModule
        v-if="updatingModule.type === 'row'"
        :content.sync="updatingModule"
      />
      <TextModule
        v-if="updatingModule.type === 'text'"
        v-model="updatingModule.content.text"
        :content.sync="updatingModule"
      />
      <ImageModule
        v-if="updatingModule.type === 'image'"
        :content.sync="updatingModule"
      />
      <GalleryModule
        v-if="updatingModule.type === 'gallery'"
        :content.sync="updatingModule"
      />
      <CarouselModule
        v-if="updatingModule.type === 'carousel'"
        :content.sync="updatingModule"
      />
      <div v-if="updatingModule.type === 'column'">
        Colonne
      </div>
      <TitleModule
        v-if="updatingModule.type === 'title'"
        :content.sync="updatingModule"
      />
      <ButtonModule
        v-if="updatingModule.type === 'button'"
        :content.sync="updatingModule"
      />
      <ShapeModule
        v-if="updatingModule.type === 'shape'"
        :content.sync="updatingModule"
      />
      <VideoModule
        v-if="updatingModule.type === 'video'"
        :content.sync="updatingModule"
      />
      <MemberListModule
        v-if="updatingModule.type === 'member-list'"
        :content.sync="updatingModule"
      />
      <MemberAvatarModule
        v-if="updatingModule.type === 'member-avatar'"
        :content.sync="updatingModule"
      />
      <MemberNameModule
        v-if="updatingModule.type === 'member-name'"
        :content.sync="updatingModule"
      />
      <MemberGroupsModule
        v-if="updatingModule.type === 'member-groups'"
        :content.sync="updatingModule"
      />
      <MemberPresentationModule
        v-if="updatingModule.type === 'member-presentation'"
        :content.sync="updatingModule"
      />
      <MemberSocialsModule
        v-if="updatingModule.type === 'member-socials'"
        :content.sync="updatingModule"
      />
      <MemberFieldsModule
        v-if="updatingModule.type === 'member-fields'"
        :content.sync="updatingModule"
      />
      <ArticlesModule
        v-if="updatingModule.type === 'articles'"
        :content.sync="updatingModule"
      />
      <ArticleThumbnailModule
        v-if="updatingModule.type === 'article-thumbnail'"
        :content.sync="updatingModule"
      />
      <ArticleTitleModule
        v-if="updatingModule.type === 'article-title'"
        :content.sync="updatingModule"
      />
      <ArticlePublicationModule
        v-if="updatingModule.type === 'article-publication'"
        :content.sync="updatingModule"
      />
      <ArticleContentModule
        v-if="updatingModule.type === 'article-content'"
        :content.sync="updatingModule"
      />
      <ArticleCommentsModule
        v-if="updatingModule.type === 'article-comments'"
        :content.sync="updatingModule"
      />
      <ContactModule
        v-if="updatingModule.type === 'contact'"
        :content.sync="updatingModule"
      />
      <SeparatorModule
        v-if="updatingModule.type === 'separator'"
        :content.sync="updatingModule"
      />
    </div>
  </div>
</template>

<script>
import RowModule from './modules/RowModule'
import TextModule from './modules/TextModule'
import ImageModule from './modules/ImageModule'
import CarouselModule from './modules/CarouselModule'
import TitleModule from './modules/TitleModule'
import ButtonModule from './modules/ButtonModule'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import ShapeModule from './modules/ShapeModule'
import ArticlesModule from './modules/ArticlesModule'
import ContactModule from './modules/ContactModule'
import ArticleThumbnailModule from '@/components/pagebuilder/modules/ArticleThumbnailModule'
import ArticleTitleModule from '@/components/pagebuilder/modules/ArticleTitleModule'
import ArticleContentModule from '@/components/pagebuilder/modules/ArticleContentModule'
import ArticlePublicationModule from '@/components/pagebuilder/modules/ArticlePublicationModule'
import ArticleCommentsModule from '@/components/pagebuilder/modules/ArticleCommentsModule'
import SeparatorModule from '@/components/pagebuilder/modules/SeparatorModule'
import GalleryModule from '@/components/pagebuilder/modules/GalleryModule'
import MemberListModule from '@/components/pagebuilder/modules/MemberListModule'
import MemberNameModule from '@/components/pagebuilder/modules/MemberNameModule'
import MemberAvatarModule from '@/components/pagebuilder/modules/MemberAvatarModule'
import MemberGroupsModule from '@/components/pagebuilder/modules/MemberGroupsModule'
import MemberFieldsModule from '@/components/pagebuilder/modules/MemberFieldsModule'
import MemberPresentationModule from '@/components/pagebuilder/modules/MemberPresentationModule'
import MemberSocialsModule from '@/components/pagebuilder/modules/MemberSocialsModule'
import VideoModule from '@/components/pagebuilder/modules/VideoModule'

export default {
  components: {
    VideoModule,
    MemberSocialsModule,
    MemberPresentationModule,
    MemberFieldsModule,
    MemberGroupsModule,
    MemberAvatarModule,
    MemberNameModule,
    MemberListModule,
    GalleryModule,
    SeparatorModule,
    ArticleCommentsModule,
    ArticlePublicationModule,
    ArticleContentModule,
    ArticleTitleModule,
    ArticleThumbnailModule,
    ContactModule,
    ArticlesModule,
    ShapeModule,
    ButtonModule,
    TitleModule,
    CarouselModule,
    ImageModule,
    TextModule,
    RowModule
  },

  props: {
    sticky: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapFields('pagebuilder/update', {
      updatingModule: 'updatingModule'
    })
  },

  beforeDestroy () {
    this.updateReset()
  },

  methods: {
    ...mapActions({
      updateModule: 'pagebuilder/update/updateModule',
      updateReset: 'pagebuilder/update/reset'
    })
  }
}
</script>

<style lang="scss" scoped>
  .mh-100-vh {
    max-height: 100vh !important;
  }
</style>
