<template>
  <div class="form-design">
    <FormInput
      v-model="item.name"
      :is-valid="isValid('name')"
      :is-pre-valid="isPreValid('name')"
      label="Nom du thème"
      type="text"
      @input="updateValidation('name')"
    >
      <template v-slot:validation>
        <div
          v-if="!isValid('name')"
          class="invalid-feedback"
        >
          {{ violations.name }}
        </div>
        <div
          v-if="!isPreValid('name')"
          class="invalid-feedback"
        >
          <!--suppress JSUnresolvedVariable -->
          <div v-if="!$v.item.name.required">
            Le nom du thème doit être indiqué
          </div>
          <!--suppress JSUnresolvedVariable -->
          <div v-if="!$v.item.name.minLength">
            Le nom du thème doit contenir au moins {{ $v.item.name.$params.minLength.min }} caractères
          </div>
          <!--suppress JSUnresolvedVariable -->
          <div v-if="!$v.item.name.maxLength">
            Le nom du thème doit contenir au maximum {{ $v.item.name.$params.maxLength.max }} caractères
          </div>
        </div>
      </template>
    </FormInput>

    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormInput from '../applicationform/FormInput'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

export default {
  components: {
    FormInput
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    }
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      }
    }
  }
}
</script>
