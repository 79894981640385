<template>
  <div
    class="component-page-builder bg-white shadow-sm my-3 mx-n3"
    :class="{ 'is-loading': isLoading }"
  >
    <div class="bg-light-grey overflow-hidde">
      <div class="p-3 font-weight-600">
        Éditeur de contenu
      </div>
    </div>

    <div
      v-if="showAlert"
      class="d-block d-xl-none bg-danger-light overflow-hidden"
    >
      <div class="p-3 f-14 font-weight-600 text-danger">
        Nous vous conseillons d'utiliser l'éditeur de contenu à partir d'un ordinateur pour bénéficier de toutes ses fonctionnalités et de pouvoir prévisualiser l'apparence du contenu sur un grand écran.
        <div
          class="f-12 text-danger font-weight-500 mt-1 d-flex align-items-center cursor-pointer"
          @click.prevent="showAlert = false"
        >
          <i class="fal fa-times mr-1" />Ne plus afficher ce message
        </div>
      </div>
    </div>

    <div
      v-if="!isLoading"
      class="py-4"
    >
      <BuilderForm :content.sync="content" />
    </div>
  </div>
</template>

<script>
import BuilderForm from './BuilderForm'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    BuilderForm
  },

  props: {
    content: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data () {
    return {
      modules: this.content,
      showAlert: true,
      primaryColor: '#007bff',
      secondaryColor: '#6c757d'
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('websitedesignsetting/show', {
      isLoading: 'isLoading',
      designSetting: 'retrieved'
    })
  },

  watch: {
    designSetting (setting) {
      if (setting) {
        document.documentElement.style.setProperty('--editor-primary', setting.primaryColor ? setting.primaryColor : this.primaryColor)
        document.documentElement.style.setProperty('--editor-primary-transparent', setting.primaryColor ? setting.primaryColor + '88' : this.primaryColor + '88')
        document.documentElement.style.setProperty('--editor-secondary', setting.secondaryColor ? setting.secondaryColor : this.secondaryColor)
        document.documentElement.style.setProperty('--editor-secondary-transparent', setting.secondaryColor ? setting.secondaryColor + '88' : this.secondaryColor + '88')
      }
    },

    modules: {
      deep: true,
      handler (content) {
        this.$emit('update:content', content)
      }
    },

    content: {
      deep: true,
      handler (content) {
        if (this.modules !== content) {
          this.modules = content
        }
      }
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.retrieveDesignSetting('websites/' + this.user.selectedWebsite.id + '/design_setting')
    document.documentElement.style.setProperty('--test', this.test)
  },

  beforeDestroy () {
    this.resetDesignSetting()
  },

  methods: {
    ...mapActions({
      retrieveDesignSetting: 'websitedesignsetting/show/retrieve',
      resetDesignSetting: 'websitedesignsetting/show/reset'
    })
  }
}
</script>

<style lang="scss">
  @media (min-width: 1200px) {
    .component-page-builder {
      .container,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl {
        max-width: 1140px;
      }
    }
  }

  .page-editor-preview {
    & .btn-primary {
      background-color: var(--editor-primary);
      border-color: var(--editor-primary);

      &:hover,
      &:focus,
      &:active {
        background-color: var(--editor-primary) !important;
        border-color: var(--editor-primary) !important;
        box-shadow: 0 0 0 .2rem var(--editor-primary-transparent) !important;
      }
    }

    & .btn-secondary {
      background-color: var(--editor-secondary);
      border-color: var(--editor-secondary);

      &:hover,
      &:focus,
      &:active {
        background-color: var(--editor-secondary) !important;
        border-color: var(--editor-secondary) !important;
        box-shadow: 0 0 0 .2rem var(--editor-secondary-transparent) !important;
      }
    }
  }
</style>
