import List from '../views/article/List'
import Create from '../views/article/Create'
import Duplicate from '../views/article/Duplicate'
import Update from '../views/article/Update'

export default [
  {
    path: '/articles',
    name: 'article-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'article.list',
      title: 'Liste des articles'
    }
  },
  {
    path: '/articles/create',
    name: 'article-create',
    component: Create,
    meta: {
      layout: 'dynamic',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'article.create',
      title: 'Ajouter un article'
    }
  },
  {
    path: '/articles/:id',
    name: 'article-update',
    component: Update,
    meta: {
      layout: 'dynamic',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'article.update',
      title: 'Editer un article'
    }
  },
  {
    path: '/articles/:id/duplicate',
    name: 'article-duplicate',
    component: Duplicate,
    meta: {
      layout: 'dynamic',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'article.update',
      title: 'Dupliquer un article'
    }
  }
]
