<template>
  <div class="module-article-thumbnail">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'customization'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
        Options d'apparence
      </div>
      <div class="px-4 pt-4">
        <div class="form-group mb-4">
          <div class="form-control-label">
            Apparence de l'image
          </div>
          <b-form-radio
            v-model="content.content.rounded"
            name="image-round"
            value="default"
          >
            Afficher l'image sans arrondi
          </b-form-radio>
          <b-form-radio
            v-model="content.content.rounded"
            name="image-round"
            value="rounded"
          >
            Arrondir les coins de l'image
          </b-form-radio>
          <b-form-radio
            v-model="content.content.rounded"
            name="image-round"
            value="rounded-circle"
          >
            Afficher l'image en cercle
          </b-form-radio>
        </div>

        <div class="form-group mb-4">
          <div class="form-control-label">
            Taille de l'image
          </div>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="default"
          >
            Afficher l'image selon sa taille d'origine
          </b-form-radio>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="full"
          >
            Afficher l'image sur toute la largeur de la page
          </b-form-radio>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="container"
          >
            Afficher l'image sur toute la largeur du contenu de la page
          </b-form-radio>
          <b-form-radio
            v-model="content.content.size"
            name="image-size"
            value="max"
          >
            Afficher l'image selon une largeur maximale
          </b-form-radio>
          <FormInput
            v-if="content.content.size === 'max'"
            v-model="content.content.maxSize"
            class="mb-0 mt-1"
            placeholder="Largeur maximale en pixels"
            type="number"
          >
            <template v-slot:label />
          </FormInput>
        </div>

        <div class="form-group mb-4">
          <div class="form-control-label">
            Alignement de l'image
          </div>
          <b-form-radio
            v-model="content.content.align"
            name="some-radios"
            value="start"
          >
            Aligner à gauche
          </b-form-radio>
          <b-form-radio
            v-model="content.content.align"
            name="some-radios"
            value="center"
          >
            Aligner au centre
          </b-form-radio>
          <b-form-radio
            v-model="content.content.align"
            name="some-radios"
            value="end"
          >
            Aligner à droite
          </b-form-radio>
        </div>
      </div>

      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
        Format de la miniature
      </div>
      <div class="px-4 pt-4 pb-4">
        <FormSelect
          v-model="content.content.ratio"
          :list="ratios"
          class="mb-4"
        >
          <template slot="label">
            <div class="form-control-label pb-1 d-flex flex-wrap align-items-center">
              <span class="inline-icon mr-2"><i class="fad fa-adjust" /></span>
              <span>Format de la miniature</span>
            </div>
          </template>
        </FormSelect>

        <div v-if="content.content.ratio === 'height'">
          <div class="form-control-label d-flex flex-wrap align-items-end">
            <span class="mr-2"><i class="fad fa-arrow-alt-v" /></span>
            <span>Hauteur personnalisée</span>
            <span class="text-success f-14 font-weight-600 ml-auto">{{ content.content.height }}px</span>
          </div>
          <BFormInput
            v-model="content.content.height"
            type="range"
            min="20"
            max="1000"
            step="1"
          />
        </div>
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import FormInput from '../../applicationform/FormInput'
import SectionAppearance from './../SectionAppearance'
import Tabs from '../../applicationcomponent/Tabs'
import FormSelect from '@/components/applicationform/FormSelect'

export default {
  components: {
    FormSelect,
    Tabs,
    FormInput,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 'customization',
      tabs: [
        {
          name: 'customization',
          title: 'Options de la miniature'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
      },
      ratios: [
        {
          id: null,
          name: 'Par défaut'
        },
        {
          id: '1by1',
          name: 'Carré'
        },
        {
          id: '16by9',
          name: 'Rectangle'
        },
        {
          id: '21by9',
          name: 'Rectangle allongé'
        },
        {
          id: 'height',
          name: 'Hauteur personnalisée'
        }
      ],
      selection: false
    }
  }
}
</script>
