import Subscription from '../views/billing/Subscription'

export default [
  {
    path: '/subscription',
    name: 'website-subscription',
    component: Subscription,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'website.billing',
      title: 'Abonnements'
    }
  }
]
