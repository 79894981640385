<template>
  <div class="page-file-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des fichiers
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste des fichiers
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="((isLoading || isWebsiteInformationLoading) && (!loadedFiles || !loadedWebsiteInformations))">
      <div
        v-if="checkPermission('file.create')"
        class="bg-grey rounded-lg my-3"
        style="height: 110px"
      />
      <div
        class="bg-grey rounded-lg my-3"
        style="height: 400px"
      />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="items && websiteInformation && loadedFiles && loadedWebsiteInformations"
      :class="{ 'is-loading': isLoading }"
    >
      <div
        v-if="checkPermission('file.create')"
        class="my-3"
      >
        <FileCreate
          :handle-create="addFile"
        />
      </div>

      <div class="bg-white rounded-lg shadow-sm overflow-hidden my-3">
        <!--suppress JSUnresolvedVariable -->
        <div
          v-if="websiteInformation"
          class="bg-light p-3"
        >
          <span class="font-weight-600">{{ storage }}</span> ({{ storagePercent }} %)  utilisés
        </div>

        <div
          v-if="!view.totalItems"
          class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
        >
          Aucun élément à afficher
        </div>

        <div
          v-if="view.totalItems"
          class="row m-3"
        >
          <div
            v-for="item in items"
            :key="item.id"
            class="col-3 my-3"
          >
            <!--suppress JSUnresolvedVariable -->
            <div
              v-b-tooltip.hover
              :title="item.originalName"
              class="embed-responsive embed-responsive-1by1 rounded"
            >
              <div
                class="embed-responsive-item bg-light-grey rounded file-item border"
                :style="backgroundImage(item)"
                :class="{ 'is-loading' : deleting === item.id }"
              >
                <button
                  v-if="checkPermission('file.delete')"
                  class="btn btn-sm btn-danger shadow-sm position-absolute top-0 right-0 mt-2 mr-2 rounded-circle"
                  @click.prevent="removeFile(item)"
                >
                  <i class="fa fa-trash" />
                </button>
                <a
                  :href="fileLink(item)"
                  target="_blank"
                  class="d-flex justify-content-center align-items-center h-100 w-100"
                >
                  <i
                    v-if="item.type === 'video'"
                    class="fas fa-video text-primary f-64"
                  />
                  <i
                    v-if="item.type === 'audio'"
                    class="fas fa-headphones text-primary f-64"
                  />
                  <i
                    v-if="item.type === 'file'"
                    class="fas fa-file text-primary f-64"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Pagination
        v-if="!Array.isArray(view)"
        v-model="page"
        :view="view"
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { S3_ENTRYPOINT } from '../../config/entrypoint'
import FileCreate from '../../components/file/Create'
import Pagination from '../../components/applicationlayout/Pagination'
import permission from '../../utils/permission'

export default {
  components: {
    FileCreate,
    Pagination
  },

  data () {
    return {
      loadedWebsiteInformations: false,
      loadedFiles: false,
      page: 1,
      deleting: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('file/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('file/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('websiteinformation/show', {
      websiteInformationError: 'error',
      isWebsiteInformationLoading: 'isLoading',
      websiteInformation: 'retrieved'
    }),

    storage () {
      let storage = '0 Ko'

      if (!this.websiteInformation) {
        return storage
      }

      let ko = this.websiteInformation.storage / 1024
      let mo = ko / 1024
      let go = mo / 1024

      if (go < 1000) {
        storage = Math.round(go * 100) / 100 + ' Go'
      }

      if (mo < 1000) {
        storage = Math.round(mo * 100) / 100 + ' Mo'
      }

      if (ko < 1000) {
        storage = Math.round(ko * 100) / 100 + ' Ko'
      }

      return storage
    },

    storagePercent () {
      if (!this.websiteInformation) {
        return 0
      }

      let allowed = 0.1

      // noinspection JSUnresolvedVariable
      if (this.user.selectedWebsite.addons.includes('premium')) {
        allowed = 1
      }

      // noinspection JSUnresolvedVariable
      if (this.user.selectedWebsite.addons.includes('pro')) {
        allowed = 100
      }

      let storage = ((this.websiteInformation.storage / 1024) / 1024) / 1024

      return Math.round(storage / allowed * 1000) / 10
    }
  },

  watch: {
    items (items) {
      if (items) {
        this.loadedFiles = true
      }
    },

    websiteInformation (websiteInformation) {
      if (websiteInformation) {
        this.loadedWebsiteInformations = true
      }
    },

    page (page) {
      // noinspection JSUnresolvedVariable
      this.getFiles({ page: 'websites/' + this.user.selectedWebsite.id + '/files', query: { page, fileGroup: 'file' } })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      this.deleting = null
      let self = this

      this.items.forEach((file, index) => {
        if (file.id === deleted.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
          self.websiteInformation.storage -= parseInt(file.size)
        }
      })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.getFiles({ page: 'websites/' + this.user.selectedWebsite.id + '/files', query: { fileGroup: 'file' } })
    // noinspection JSUnresolvedVariable
    this.getWebsiteInformation('websites/' + this.user.selectedWebsite.id + '/information')
  },

  beforeDestroy () {
    this.resetFiles()
    this.resetDelete()
  },

  methods: {
    ...mapActions({
      getFiles: 'file/list/default',
      deleteFile: 'file/del/del',
      resetFiles: 'file/list/reset',
      getWebsiteInformation: 'websiteinformation/show/retrieve',
      resetDelete: 'file/del/reset'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    addFile (file) {
      this.items.unshift(file)
      this.view.totalItems++
      this.websiteInformation.storage += parseInt(file.size)
    },

    removeFile (file) {
      this.deleting = file.id
      this.deleteFile(file)
    },

    backgroundImage (file) {
      if (file.type !== 'image') {
        return ''
      }

      return 'background-image: url(' + this.fileLink(file) + ')'
    },

    fileLink (file) {
      // noinspection JSUnresolvedVariable
      return S3_ENTRYPOINT + file.contentUrl
    }
  }
}
</script>

<style lang="scss" scoped>
  .embed-responsive:hover {
    box-shadow: 0 0 0 0.2rem rgba(9, 113, 254, 0.25);
  }

  .file-item {
    background-position: center;
    background-size: cover;

    &:hover {
      border-color: #88baff !important;
    }
  }
</style>
