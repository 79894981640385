import List from '../views/websiteuser/List'
import Update from '../views/websiteuser/Update'

export default [
  {
    path: '/users',
    name: 'user-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'user.list',
      title: 'Liste des utilisateurs'
    }
  },
  {
    path: '/users/:id',
    name: 'user-update',
    component: Update,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'user.update',
      title: 'Editer un utilisateur'
    }
  }
]
