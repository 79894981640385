var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-image-preview"},[_c('div',{class:{ 'container': _vm.content.content.size !== 'full' && _vm.hasContainer }},[_c('div',{class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"d-flex flex-column",class:{
          'align-items-start': !_vm.content.content.align || _vm.content.content.align === 'start',
          'align-items-center': _vm.content.content.align === 'center',
          'align-items-end': _vm.content.content.align === 'end',
        }},[_c('div',{staticClass:"mw-100",class:{ 'w-100': _vm.content.content.size === 'full' || _vm.content.content.size === 'container' },style:(_vm.videoStyle)},[_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[(_vm.content.content.url)?_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":_vm.content.content.url,"allowfullscreen":""}}):_vm._e(),(!_vm.content.content.url)?_c('div',{staticClass:"embed-responsive-item bg-grey"}):_vm._e()])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }