<template>
  <div class="module-button">
    <div class="button-settings">
      <Tabs
        v-model="tab"
        :tabs="tabs"
        class="border-bottom border-primary"
      />

      <div v-if="tab === 'settings'">
        <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
          Texte du bouton
        </div>
        <div class="px-4 pt-3">
          <FormInput
            v-model="content.content.text"
            type="text"
            label="Texte du bouton"
            class="mb-4"
          />
        </div>

        <div class="bg-light-grey mt-4 px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
          Lien du bouton
        </div>
        <div class="px-4 pt-3">
          <FormInput
            v-model="content.content.link"
            type="text"
            label="Lien du bouton"
            class="mb-4"
          />
        </div>

        <div class="bg-light-grey mt-4 px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
          Propositions de bouton
        </div>
        <div class="px-4 pt-4">
          <div
            v-for="(button, index) in propositions"
            :key="index"
            class="border py-3 mb-3 rounded-lg cursor-pointer bg-light-grey-hover border-primary-hover"
            @click.prevent="selectProposition(button)"
          >
            <ButtonPreview :content="button" />
          </div>
        </div>
      </div>

      <div v-if="tab === 'customization'">
        <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
          Texte du bouton
        </div>
        <div class="px-4 pt-3">
          <FormInput
            v-model="content.content.text"
            type="text"
            label="Texte du bouton"
            class="mb-4"
          />
        </div>

        <div
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          @click.prevent="collapse.fontFamily = !collapse.fontFamily"
        >
          Police d'écriture
          <i
            class="fas fa-chevron-right text-soft"
            :class="{ 'fa-rotate-90': collapse.fontFamily }"
          />
        </div>
        <BCollapse v-model="collapse.fontFamily">
          <div class="px-4 pt-4">
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'roboto' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'roboto'"
            >
              <div class="f-20 f-roboto mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'roboto-condensed' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'roboto-condensed'"
            >
              <div class="f-20 f-roboto-condensed mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'roboto-slab' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'roboto-slab'"
            >
              <div class="f-20 f-roboto-slab mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'roboto-mono' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'roboto-mono'"
            >
              <div class="f-20 f-roboto-mono mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'abril' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'abril'"
            >
              <div class="f-20 f-abril mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'dancing' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'dancing'"
            >
              <div class="f-20 f-dancing mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'josefin' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'josefin'"
            >
              <div class="f-20 f-josefin mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'oswald' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'oswald'"
            >
              <div class="f-20 f-oswald mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'pacifico' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'pacifico'"
            >
              <div class="f-20 f-pacifico mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
            <div
              class="border rounded mb-4 px-3 py-2"
              :class="[ content.content.fontFamily === 'playfair' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'playfair'"
            >
              <div class="f-20 f-playfair mw-100 overflow-hidden text-nowrap">
                {{ content.content.text }}
              </div>
            </div>
          </div>
        </BCollapse>

        <div
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          @click.prevent="collapse.appearance = !collapse.appearance"
        >
          Taille, arrondi et alignement
          <i
            class="fas fa-chevron-right text-soft"
            :class="{ 'fa-rotate-90': collapse.appearance }"
          />
        </div>
        <BCollapse v-model="collapse.appearance">
          <div class="px-4 pt-4">
            <div class="form-group mb-4">
              <div class="form-control-label pb-2">
                Taille du bouton
              </div>
              <b-form-radio
                v-model="content.content.size"
                name="button-size"
                value="sm"
              >
                Petit
              </b-form-radio>
              <b-form-radio
                v-model="content.content.size"
                name="button-size"
                value="normal"
              >
                Moyen
              </b-form-radio>
              <b-form-radio
                v-model="content.content.size"
                name="button-size"
                value="lg"
              >
                Grand
              </b-form-radio>
            </div>

            <div class="form-group mb-4">
              <div class="form-control-label pb-2">
                Arrondi du bouton
              </div>
              <b-form-radio
                v-model="content.content.round"
                name="button-round"
                value="rounded-0"
              >
                Aucun arrondi
              </b-form-radio>
              <b-form-radio
                v-model="content.content.round"
                name="button-round"
                value="rounded"
              >
                Petit arrondi
              </b-form-radio>
              <b-form-radio
                v-model="content.content.round"
                name="button-round"
                value="rounded-lg"
              >
                Grand arrondi
              </b-form-radio>
              <b-form-radio
                v-model="content.content.round"
                name="button-round"
                value="rounded-pill"
              >
                Arrondi en forme de pillule
              </b-form-radio>
            </div>

            <div class="form-control-label pb-2">
              Largeur du bouton
            </div>
            <FormCheckbox
              v-model="content.content.inline"
              label="Le bouton s'adapte à la largeur du texte"
              :is-switch="true"
              class="mb-4"
            />

            <div class="form-control-label pb-2">
              Alignement du bouton
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <div
                  v-b-tooltip="'Aligner à gauche'"
                  class="border rounded px-3 py-1 f-32 text-center"
                  :class="[ content.content.alignment === 'left' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
                  @click.prevent="content.content.alignment = 'left'"
                >
                  <i class="fad fa-align-left" />
                </div>
              </div>
              <div class="col-4">
                <div
                  v-b-tooltip="'Aligner au centre'"
                  class="border rounded px-3 py-1 f-32 text-center"
                  :class="[ content.content.alignment === 'center' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
                  @click.prevent="content.content.alignment = 'center'"
                >
                  <i class="fad fa-align-center" />
                </div>
              </div>
              <div class="col-4">
                <div
                  v-b-tooltip="'Aligner à droite'"
                  class="border rounded px-3 py-1 f-32 text-center"
                  :class="[ content.content.alignment === 'right' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
                  @click.prevent="content.content.alignment = 'right'"
                >
                  <i class="fad fa-align-right" />
                </div>
              </div>
            </div>
          </div>
        </BCollapse>

        <div
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          @click.prevent="collapse.color = !collapse.color"
        >
          Couleur
          <i
            class="fas fa-chevron-right text-soft"
            :class="{ 'fa-rotate-90': collapse.color }"
          />
        </div>
        <BCollapse v-model="collapse.color">
          <div class="px-4 pt-4">
            <div class="form-control-label pb-2">
              Bordure du bouton
            </div>
            <FormCheckbox
              v-model="content.content.outline"
              label="Afficher seulement la bordure du bouton"
              :is-switch="true"
              class="mb-4"
            />

            <div class="form-control-label pb-2">
              Couleur du bouton
            </div>
            <div class="row mb-2">
              <div class="col-3 mb-3">
                <!--suppress CssUnresolvedCustomProperty -->
                <div
                  class="pb-5 rounded cursor-pointer"
                  style="background-color: var(--editor-primary)"
                  @click.prevent="content.content.variant = 'primary'"
                />
              </div>
              <div class="col-3 mb-3">
                <!--suppress CssUnresolvedCustomProperty -->
                <div
                  class="pb-5 rounded cursor-pointer"
                  style="background-color: var(--editor-secondary)"
                  @click.prevent="content.content.variant = 'secondary'"
                />
              </div>
              <div class="col-3 mb-3">
                <div
                  class="pb-5 rounded bg-warning cursor-pointer"
                  @click.prevent="content.content.variant = 'warning'"
                />
              </div>
              <div class="col-3 mb-3">
                <div
                  class="pb-5 rounded bg-danger cursor-pointer"
                  @click.prevent="content.content.variant = 'danger'"
                />
              </div>
              <div class="col-3 mb-3">
                <div
                  class="pb-5 rounded bg-success cursor-pointer"
                  @click.prevent="content.content.variant = 'success'"
                />
              </div>
              <div class="col-3 mb-3">
                <div
                  class="pb-5 rounded bg-info cursor-pointer"
                  @click.prevent="content.content.variant = 'info'"
                />
              </div>
              <div class="col-3 mb-3">
                <div
                  class="pb-5 rounded bg-light cursor-pointer"
                  @click.prevent="content.content.variant = 'light'"
                />
              </div>
              <div class="col-3 mb-3">
                <div
                  class="pb-5 rounded bg-dark cursor-pointer"
                  @click.prevent="content.content.variant = 'dark'"
                />
              </div>
              <div class="col-3 mb-3">
                <div
                  class="pb-5 rounded bg-white cursor-pointer border"
                  @click.prevent="content.content.variant = 'link'"
                />
              </div>
            </div>
          </div>
        </BCollapse>

        <div
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          @click.prevent="collapse.boxShadow = !collapse.boxShadow"
        >
          Ombre du bouton
          <i
            class="fas fa-chevron-right text-soft"
            :class="{ 'fa-rotate-90': collapse.boxShadow }"
          />
        </div>
        <BCollapse v-model="collapse.boxShadow">
          <div class="px-4 pt-4">
            <FormCheckbox
              v-model="content.content.boxShadow"
              label="Afficher une ombre autour du bouton"
              :is-switch="true"
              class="mb-4"
            />

            <div
              v-if="content.content.boxShadow"
              class="border-top pt-4"
            >
              <div class="form-control-label pb-2">
                Décallage horizontal de l'ombre <small class="text-soft font-weight-700">{{ content.content.boxShadowHorizontal }}px</small>
              </div>
              <BFormInput
                v-model="content.content.boxShadowHorizontal"
                type="range"
                min="-50"
                max="50"
                step="1"
                class="mb-4"
              />

              <div class="form-control-label pb-2">
                Décalage vertical de l'ombre <small class="text-soft font-weight-700">{{ content.content.boxShadowVertical }}px</small>
              </div>
              <BFormInput
                v-model="content.content.boxShadowVertical"
                type="range"
                min="-50"
                max="50"
                step="1"
                class="mb-4"
              />

              <div class="form-control-label pb-2">
                Flou de l'ombre <small class="text-soft font-weight-700">{{ content.content.boxShadowBlur }}px</small>
              </div>
              <BFormInput
                v-model="content.content.boxShadowBlur"
                type="range"
                min="0"
                max="30"
                step="1"
                class="mb-4"
              />

              <FormColorInput
                v-model="content.content.boxShadowColor"
                label="Couleur de l'ombre"
                class="mb-4"
                :allow-transparency="true"
              />
            </div>
          </div>
        </BCollapse>
      </div>

      <div v-if="tab === 'section'">
        <SectionAppearance :content.sync="content" />
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '../../applicationform/FormInput'
import SectionAppearance from './../SectionAppearance'
import FormCheckbox from '../../applicationform/FormCheckbox'
import Tabs from '../../applicationcomponent/Tabs'
import { options } from '@/config/pageBuilderSectionOptions'
import ButtonPreview from '../preview/ButtonPreview'
import FormColorInput from '../../applicationform/FormColorInput'

export default {
  components: {
    FormColorInput,
    ButtonPreview,
    Tabs,
    FormCheckbox,
    FormInput,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 'settings',
      tabs: [
        {
          name: 'settings',
          title: 'Paramètres du bouton'
        },
        {
          name: 'customization',
          title: 'Apparence du bouton'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
        fontFamily: false,
        appearance: false,
        color: false,
        boxShadow: false
      },
      propositions: [
        {
          type: 'button',
          content: {
            text: 'Bouton',
            size: 'normal',
            variant: 'primary',
            round: 'rounded',
            outline: false,
            alignment: 'left',
            link: '',
            inline: true,
            fontFamily: 'roboto',
            boxShadow: false,
            boxShadowHorizontal: '2',
            boxShadowVertical: '2',
            boxShadowBlur: '0',
            boxShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'button',
          content: {
            text: 'Bouton',
            size: 'normal',
            variant: 'secondary',
            round: 'rounded-0',
            outline: false,
            alignment: 'left',
            link: '',
            inline: true,
            fontFamily: 'abril',
            boxShadow: false,
            boxShadowHorizontal: '2',
            boxShadowVertical: '2',
            boxShadowBlur: '0',
            boxShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'button',
          content: {
            text: 'Bouton',
            size: 'normal',
            variant: 'info',
            round: 'rounded-0',
            outline: false,
            alignment: 'left',
            link: '',
            inline: true,
            fontFamily: 'roboto-mono',
            boxShadow: true,
            boxShadowHorizontal: '2',
            boxShadowVertical: '2',
            boxShadowBlur: '0',
            boxShadowColor: '#006AD0FF'
          },
          section: options
        },
        {
          type: 'button',
          content: {
            text: 'Bouton',
            size: 'normal',
            variant: 'success',
            round: 'rounded-pill',
            outline: true,
            alignment: 'left',
            link: '',
            inline: true,
            fontFamily: 'roboto-slab',
            boxShadow: false,
            boxShadowHorizontal: '2',
            boxShadowVertical: '2',
            boxShadowBlur: '0',
            boxShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'button',
          content: {
            text: 'Bouton',
            size: 'normal',
            variant: 'danger',
            round: 'rounded-lg',
            outline: true,
            alignment: 'left',
            link: '',
            inline: true,
            fontFamily: 'roboto-slab',
            boxShadow: false,
            boxShadowHorizontal: '2',
            boxShadowVertical: '2',
            boxShadowBlur: '0',
            boxShadowColor: '#00000052'
          },
          section: options
        }
      ]
    }
  },

  methods: {
    selectProposition (proposition) {
      this.content.content.size = proposition.content.size
      this.content.content.variant = proposition.content.variant
      this.content.content.round = proposition.content.round
      this.content.content.outline = proposition.content.outline
      this.content.content.inline = proposition.content.inline
      this.content.content.fontFamily = proposition.content.fontFamily
      this.content.content.boxShadow = proposition.content.boxShadow
      this.content.content.boxShadowHorizontal = proposition.content.boxShadowHorizontal
      this.content.content.boxShadowVertical = proposition.content.boxShadowVertical
      this.content.content.boxShadowBlur = proposition.content.boxShadowBlur
      this.content.content.boxShadowColor = proposition.content.boxShadowColor
    }
  }
}
</script>
