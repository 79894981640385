<template>
  <div class="component-row-module border-bottom border-primary mb-4">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div class="d-inline-block w-100 border-right border-width-5 border-primary">
      <div v-if="tab === 'shape'">
        <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
          Choix de la forme
        </div>
        <div class="px-4 pt-4">
          <FormCheckbox
            v-model="content.content.fullWidth"
            label="Utiliser toute la largeur de la page"
            :is-switch="true"
            class="mb-4"
          />

          <div v-if="content.content.shape === 'custom'">
            <div class="form-control-label">
              Unité de taille
            </div>
            <div class="mb-4">
              <BFormRadio
                v-model="content.content.unit"
                name="shape-unit"
                value="px"
              >
                Taille en pixels
              </BFormRadio>
              <BFormRadio
                v-model="content.content.unit"
                name="shape-unit"
                value="%"
              >
                Taille en pourcentage
              </BFormRadio>
              <BFormRadio
                v-model="content.content.unit"
                name="shape-unit"
                value="vh"
              >
                Taille en pourcentage par rapport à la taille de la fenêtre
              </BFormRadio>
            </div>

            <div class="form-control-label pb-2">
              Hauteur de la forme <small class="text-soft font-weight-700">{{ parseInt(content.content.height) + content.content.unit }}</small>
            </div>
            <BFormInput
              v-if="content.content.unit === 'px'"
              v-model="content.content.height"
              type="range"
              min="1"
              max="1920"
              step="1"
              class="mb-4"
            />
            <BFormInput
              v-if="content.content.unit !== 'px'"
              v-model="content.content.height"
              type="range"
              min="1"
              max="100"
              step="1"
              class="mb-4"
            />
          </div>
        </div>

        <div class="bg-light-grey px-4 mt-5 py-3 text-primary f-14 font-weight-700 text-uppercase">
          Centrage
        </div>
        <div class="px-4 pt-4">
          <FormCheckbox
            v-model="content.content.horizontalCenter"
            label="Centrer le contenu horizontalement"
            :is-switch="true"
          />
          <FormCheckbox
            v-model="content.content.verticalCenter"
            label="Centrer le contenu verticalement"
            :is-switch="true"
          />
        </div>
      </div>

      <div v-if="tab === 'section'">
        <SectionAppearance :content.sync="content" />
      </div>
    </div>
  </div>
</template>

<script>
import SectionAppearance from '../SectionAppearance'
import Tabs from '../../applicationcomponent/Tabs'
import FormCheckbox from '../../applicationform/FormCheckbox'

export default {
  name: 'ContactModule',

  components: {
    FormCheckbox,
    Tabs,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 'variant',
      tabs: [
        {
          name: 'settings',
          title: 'Paramètre du formulaire'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ]
    }
  }
}
</script>
