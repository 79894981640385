import Vue from 'vue'
import Vuex from 'vuex'
import addon from './store/modules/addon'
import application from './store/modules/application'
import article from './store/modules/article'
import comment from './store/modules/comment'
import design from './store/modules/design'
import file from './store/modules/file'
import gallery from './store/modules/gallery'
import gallerygroup from './store/modules/gallerygroup'
import invitation from './store/modules/invitation'
import member from './store/modules/member'
import membergroup from './store/modules/membergroup'
import menu from './store/modules/menu'
import page from './store/modules/page'
import pagebuilder from './store/modules/pagebuilder'
import shopcategory from './store/modules/shopcategory'
import shopgateway from './store/modules/shopgateway'
import shopincomedata from './store/modules/shopincomedata'
import shoppayment from './store/modules/shoppayment'
import shopproduct from './store/modules/shopproduct'
import stripeaccount from './store/modules/stripeaccount'
import stripecheckoutsession from './store/modules/stripecheckoutsession'
import stripecoupon from './store/modules/stripecoupon'
import stripecustomer from './store/modules/stripecustomer'
import stripepaymentmethod from './store/modules/stripepaymentmethod'
import stripeportalsession from './store/modules/stripeportalsession'
import stripesubscription from './store/modules/stripesubscription'
import template from './store/modules/template'
import user from './store/modules/user'
import website from './store/modules/website'
import websitebilling from './store/modules/websitebilling'
import websitedesignsetting from './store/modules/websitedesignsetting'
import websiteinformation from './store/modules/websiteinformation'
import websitesetting from './store/modules/websitesetting'
import websitesubscription from './store/modules/websitesubscription'
import websitesubscriptionaddon from './store/modules/websitesubscriptionaddon'
import websiteuser from './store/modules/websiteuser'
import wedding from './store/modules/wedding'
import weddingincomedata from './store/modules/weddingincomedata'
import weddinglistitem from './store/modules/weddinglistitem'
import weddingpayment from './store/modules/weddingpayment'

Vue.use(Vuex)

// noinspection JSCheckFunctionSignatures
export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    addon,
    application,
    article,
    comment,
    design,
    file,
    gallery,
    gallerygroup,
    invitation,
    member,
    membergroup,
    menu,
    page,
    pagebuilder,
    shopcategory,
    shopgateway,
    shopincomedata,
    shoppayment,
    shopproduct,
    stripeaccount,
    stripecheckoutsession,
    stripecoupon,
    stripecustomer,
    stripepaymentmethod,
    stripeportalsession,
    stripesubscription,
    template,
    user,
    website,
    websitebilling,
    websitedesignsetting,
    websiteinformation,
    websitesetting,
    websitesubscription,
    websitesubscriptionaddon,
    websiteuser,
    wedding,
    weddingincomedata,
    weddinglistitem,
    weddingpayment
  }
})
