var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative overflow-hidden w-100 mh-100",class:[
    'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
    'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
    'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
    'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
    _vm.moduleClass,
    { 'rounded-circle': _vm.content.rounded }
  ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"position-absolute w-100 h-100 overflow-y-auto overflow-y-hidden d-flex",class:[
      'pt-' + _vm.content.section.paddingTop,
      'pb-' + _vm.content.section.paddingBottom,
      'pl-' + _vm.content.section.paddingLeft,
      'pr-' + _vm.content.section.paddingRight,
      { 'justify-content-center': _vm.content.content.horizontalCenter }
    ]},[_c('div',{class:{ 'align-self-center': _vm.content.content.verticalCenter, 'w-100': !_vm.content.content.horizontalCenter }},[_c('BuilderForm',{attrs:{"content":_vm.content.submodules,"is-column":true,"row-style":_vm.rowClass,"editing":_vm.editing},on:{"update:content":function($event){return _vm.$set(_vm.content, "submodules", $event)}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }