<template>
  <div class="module-gallery">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'customization'">
      <div
        :class="{ 'is-loading': !loadedGalleries }"
      >
        <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
          Choix de la galerie
        </div>
        <div
          v-if="loadedGalleries && galleries.length < 1"
          class="px-4 pt-4 text-center"
        >
          <div>
            Vous devez créer une galerie pour l'insérer dans cette page.
          </div>

          <RouterLink
            :to="{ 'name': 'gallery-create' }"
            class="btn btn-sm btn-outline-primary rounded-pill px-3 mt-2"
            target="_blank"
          >
            Créer une galerie
          </RouterLink>
        </div>

        <div
          v-if="loadedGalleries && galleries.length >= 1"
          class="px-4 pt-4"
        >
          <BFormGroup>
            <BFormRadio
              v-model="selectedGallery"
              v-for="gallery in galleries"
              name="gallery-module-select"
              :value="gallery.id"
            >
              {{ gallery.name }}
            </BFormRadio>
          </BFormGroup>
        </div>

        <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase mt-4">
          Choix de la forme
        </div>
        <div class="px-4 pt-4">
          <FormCheckbox
            v-model="content.content.fullWidth"
            label="Utiliser toute la largeur de la page"
            :is-switch="true"
            class="mb-4"
          />
        </div>
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import FormInput from '../../applicationform/FormInput'
import SectionAppearance from './../SectionAppearance'
import Tabs from '../../applicationcomponent/Tabs'
import FormSelect from '@/components/applicationform/FormSelect'
import { mapActions } from 'vuex'
import permission from '@/utils/permission'
import { mapFields } from 'vuex-map-fields'
import FormCheckbox from '@/components/applicationform/FormCheckbox'

export default {
  components: {
    FormCheckbox,
    FormSelect,
    Tabs,
    FormInput,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loadedGalleries: false,
      tab: 'customization',
      tabs: [
        {
          name: 'customization',
          title: 'Choix de la galerie'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
      },
      selection: false,
      selectedGallery: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('gallery/list', {
      error: 'error',
      galleries: 'items',
      isLoading: 'isLoading',
      view: 'view'
    })
  },

  watch: {
    selectedGallery (selected) {
      if (selected) {
        this.content.content.gallery = '/api/galleries/' + selected
        this.content.dependencies = []
        this.content.dependencies.push({
          'gallery': '/api/galleries/' + selected
        })
      }
    },

    galleries (galleries) {
      if (galleries) {
        this.loadedGalleries = true
      }
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.getGalleries({ page: 'websites/' + this.user.selectedWebsite.id + '/galleries?pagination=false' })
  },

  beforeDestroy () {
    this.resetGalleries()
  },

  methods: {
    ...mapActions({
      getGalleries: 'gallery/list/default',
      resetGalleries: 'gallery/list/reset',
    }),

    selectGallery (gallery) {
    }
  }
}
</script>
