<template>
  <div class="page-article-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des utilisateurs
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste des invitations
        </h2>
        <span
          v-if="!loadedInvitations"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <div v-if="!loadedInvitations">
      <div
        v-if="checkPermission('invitation.create')"
        class="d-flex justify-content-center"
      >
        <button class="btn btn-fake px-3 rounded-pill">
          Inviter un utilisateur
        </button>
      </div>
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <div
      v-if="items && loadedInvitations"
      :class="{ 'is-loading': isLoading }"
    >
      <div
        v-if="checkPermission('invitation.create')"
        class="d-flex justify-content-center"
      >
        <router-link
          :to="{ name: 'invitation-create' }"
          class="btn btn-primary px-3 rounded-pill"
        >
          Inviter un utilisateur
        </router-link>
      </div>

      <div class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div
          class="p-4 f-20 f-nunito font-weight-600 d-flex justify-content-between align-items-center"
          :class="{ 'border-bottom': items.length > 0 }"
        >
          <div>
            {{ view.totalItems }} {{ view.totalItems > 1 ? 'invitations' : 'invitation' }}
          </div>
        </div>

        <div
          v-if="!user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro')"
          class="bg-light f-14 font-weight-600 text-center p-4"
        >
          <div>
            Cette fonctionnalité est réservée aux offres PREMIUM et PRO
          </div>
          <div class="mt-3">
            <RouterLink
              :to="{ name: 'website-subscription' }"
              class="btn btn-outline-primary btn-sm px-3 rounded-pill"
            >
              Souscrire une offre
            </RouterLink>
          </div>
        </div>

        <div
          v-if="!view.totalItems"
          class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
        >
          Aucun élément à afficher
        </div>

        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="position-relative"
          :class="{ 'is-loading': deleting === item.id, 'border-bottom': index !== items.length - 1 }"
        >
          <div
            class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
            :class="{ 'pl-1': selected === item.id }"
          />
          <div
            class="d-flex justify-content-between bg-light-grey-hover align-items-center position-relative opacity-100-hover cursor-pointer transition-400"
            :class="{ 'opacity-30': selected && selected !== item.id, 'bg-light-grey': selected === item.id }"
            @click="toggleSelected(item.id)"
          >
            <div class="p-4 w-100">
              <div class="row align-items-center">
                <div class="col-12 col-sm-6 d-flex flex-column align-items-center align-items-sm-start">
                  <div class="text-soft font-weight-600">
                    {{ item.email }}
                  </div>
                </div>
                <div class="col-12 col-sm-6 d-flex flex-column align-items-center align-items-sm-start">
                  <span
                    v-if="item.accepted"
                    class="mt-3 mt-sm-0 px-2 bg-success-light rounded-pill f-14 f-nunito font-weight-600"
                  >
                    Invitation acceptée
                  </span>
                  <span
                    v-if="!item.accepted"
                    class="mt-3 mt-sm-0 px-2 bg-light rounded-pill f-14 f-nunito font-weight-600"
                  >
                    Invitation en attente
                  </span>
                </div>
              </div>
            </div>
            <div class="align-self-stretch px-4 d-flex align-items-center">
              <i
                class="text-soft far fa-angle-right f-18 transition-300"
                :class="{ 'fa-rotate-90': selected === item.id }"
              />
            </div>
          </div>
          <BCollapse
            :id="'collapse-article-' + item.id"
            accordion="article-list"
          >
            <div
              v-if="!item.accepted && (checkPermission('invitation.create') || checkPermission('invitation.delete'))"
              class="p-3 d-flex flex-column flex-sm-row align-items-center justify-content-center border-bottom"
            >
              <RouterLink
                v-if="checkPermission('invitation.create')"
                :to="{name: 'invitation-update', params: { id: item.id }}"
                class="btn btn-outline-primary btn-sm rounded-pill px-3 mx-sm-3 my-2 my-sm-0"
              >
                Modifier l'invitation
              </RouterLink>

              <button
                v-if="checkPermission('invitation.delete')"
                class="btn btn-outline-danger btn-sm px-3 my-2 my-sm-0 rounded-pill"
                @click.prevent="removeInvitation(item)"
              >
                Supprimer l'invitation
              </button>
            </div>
            <div class="px-4 pb-2">
              <PermissionList :user-permissions="item.permissions" />
            </div>
          </BCollapse>
        </div>
      </div>

      <Pagination
        v-if="!Array.isArray(view)"
        v-model="page"
        :view="view"
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import Pagination from '../../components/applicationlayout/Pagination'
import permission from '../../utils/permission'
import PermissionList from '../../components/websiteuser/PermissionList'

export default {
  components: {
    Pagination,
    PermissionList
  },

  data () {
    return {
      loadedInvitations: false,
      page: 1,
      deleting: null,
      selected: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('invitation/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('invitation/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    })
  },

  watch: {
    selected (selected, previousSelected) {
      if (selected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-article-' + selected)
      }

      if (previousSelected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-article-' + previousSelected)
      }
    },

    items (items) {
      if (items) {
        this.loadedInvitations = true
      }
    },

    page (page) {
      // noinspection JSUnresolvedVariable
      this.getInvitations({ page: 'websites/' + this.user.selectedWebsite.id + '/invitations', query: { page } })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      let self = this

      this.deleting = null
      this.items.forEach((invitation, index) => {
        if (invitation.id === deleted.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
        }
      })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.getInvitations({ page: 'websites/' + this.user.selectedWebsite.id + '/invitations' })
  },

  beforeDestroy () {
    this.resetInvitations()
    this.resetDelete()
  },

  methods: {
    ...mapActions({
      getInvitations: 'invitation/list/default',
      deleteInvitation: 'invitation/del/del',
      resetInvitations: 'invitation/list/reset',
      resetDelete: 'invitation/del/reset'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    toggleSelected (id) {
      if (this.selected === id) {
        this.selected = null

        return
      }

      this.selected = id
    },

    removeInvitation (invitation) {
      this.deleting = invitation.id
      this.deleteInvitation(invitation)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
