import * as types from './mutation_types'
import { APP_BASE_DOMAIN } from '@/config/entrypoint'

export default {
  [types.APPLICATION_LOGIN_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.APPLICATION_LOGIN_TOGGLE_LOADING] (state) {
    Object.assign(state, { error: '', isLoading: !state.isLoading })
  },

  [types.APPLICATION_LOGIN_SET_USER] (state, user) {
    Object.assign(state, { user })

    localStorage.setItem('token_update', Date.now().toString())
    localStorage.setItem('refresh_token', user.refresh_token)
    localStorage.setItem('token', user.token)
    localStorage.setItem('user', JSON.stringify(user.data))

    let cookieDate = new Date();
    cookieDate.setMonth(cookieDate.getMonth() + 1)

    document.cookie = "logged=true;domain=." + APP_BASE_DOMAIN + ";expires=" + cookieDate
    document.cookie = "account=true;domain=." + APP_BASE_DOMAIN + ";expires=Fri, 31 Dec 9999 23:59:59 GMT"
  },

  [types.APPLICATION_LOGIN_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },

  [types.APPLICATION_LOGIN_RESET] (state) {
    Object.assign(state, {
      user: null,
      error: '',
      isLoading: false,
      violations: null
    })
  }
}
