import { options } from '../elements/_default'
import { text } from '../elements/text'

text.position = 0

export const column = {
  type: 'column',
  position: null,
  variant: 'default',
  content: {
    smallSize: '12',
    mediumSize: '6',
    largeSize: '6',
    verticalCenter: false
  },
  submodules: [
    text
  ],
  dependencies: [],
  section: _.cloneDeep(options)
}
