var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-text-preview"},[_c('div',{class:{ 'container': _vm.hasContainer }},[_c('div',{class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[(!_vm.content.content.text)?_c('div',{staticClass:"w-100 mw-100 px-4 py-3 f-14 font-weight-600 bg-danger-light rounded"},[_c('i',{staticClass:"fas fa-exclamation-circle mr-2"}),_vm._v("Attention, cette zone de texte est vide !\n      ")]):_vm._e(),(_vm.content.content.text)?_c('div',{staticClass:"d-inline-block w-100 mw-100 page-editor-preview",domProps:{"innerHTML":_vm._s(_vm.content.content.text)}}):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }