var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
    'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
    'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
    'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
    'col-' + _vm.content.content.smallSize,
    'col-md-' + _vm.content.content.mediumSize,
    'col-xl-' + _vm.content.content.largeSize,
    'pt-' + _vm.content.section.paddingTop,
    'pb-' + _vm.content.section.paddingBottom,
    'pl-' + _vm.content.section.paddingLeft,
    'pr-' + _vm.content.section.paddingRight,
    _vm.moduleClass
  ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"d-inline-block w-100 position-relative"},[_c('BuilderForm',{attrs:{"content":_vm.content.submodules,"is-column":true,"row-style":_vm.rowClass,"editing":_vm.editing},on:{"update:content":function($event){return _vm.$set(_vm.content, "submodules", $event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }