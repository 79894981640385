export const permissions = {
  articlePermissions: [
    { text: 'Voir les articles', value: 'article.list' },
    { text: 'Créer un article', value: 'article.create' },
    { text: 'Editer un article', value: 'article.update' },
    { text: 'Supprimer un article', value: 'article.delete' }
  ],
  pagePermissions: [
    { text: 'Voir les pages', value: 'page.list' },
    { text: 'Créer une page', value: 'page.create' },
    { text: 'Editer une page', value: 'page.update' },
    { text: 'Supprimer une page', value: 'page.delete' }
  ],
  memberPermissions: [
    { text: 'Voir les membres', value: 'member.list' },
    { text: 'Ajouter un membre', value: 'member.create' },
    // { text: 'Editer un membre', value: 'member.update' },
    { text: 'Supprimer un membre', value: 'member.delete' },
    { text: 'Valider un membre', value: 'member.validate' },
    { text: 'Voir les groupes de membres', value: 'member_group.list' },
    { text: 'Ajouter un groupe', value: 'member_group.create' },
    // { text: 'Editer un groupe', value: 'member_group.update' },
    { text: 'Supprimer un groupe', value: 'member_group.delete' },
  ],
  menuPermissions: [
    { text: 'Voir les menus', value: 'menu.list' },
    { text: 'Editer les menus', value: 'menu.update' }
  ],
  settingsPermissions: [
    { text: 'Gérer les paramètres du site', value: 'website.settings' },
    { text: 'Gérer la facturation', value: 'website.billing' }
  ],
  filePermissions: [
    { text: 'Voir les fichiers', value: 'file.list' },
    { text: 'Créer un fichier', value: 'file.create' },
    { text: 'Supprimer un fichier', value: 'file.delete' }
  ],
  userPermissions: [
    { text: 'Voir les utilisateurs', value: 'user.list' },
    { text: 'Editer un utilisateur', value: 'user.update' },
    { text: 'Supprimer un utilisateur', value: 'user.delete' },
    { text: 'Créer une invitation', value: 'invitation.create' },
    { text: 'Annuler une invitation', value: 'invitation.delete' }
  ],
  designPermissions: [
    { text: 'Voir les thèmes', value: 'design.list' },
    { text: 'Créer un thème', value: 'design.create' },
    { text: 'Editer un thème', value: 'design.update' },
    { text: 'Supprimer un thème', value: 'design.delete' },
    { text: 'Sélectionner un thème', value: 'design.select' }
  ],
  shopPermissions: [
    { text: 'Voir les produits', value: 'shopproduct.list' },
    { text: 'Créer un produit', value: 'shopproduct.create' },
    { text: 'Editer un produit', value: 'shopproduct.update' },
    { text: 'Supprimer un produit', value: 'shopproduct.delete' },
    { text: 'Voir les paiements', value: 'shoppayment.list' },
    { text: 'Voir un paiement', value: 'shoppayment.view' },
    { text: 'Gérer les paramètres', value: 'shop.setting' },
  ]
}
