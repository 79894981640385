<template>
  <div class="page-login row d-flex justify-content-center align-items-center">
    <div class="col col-md-6 col-lg-5 col-xl-4 mb-5 my-lg-5">
      <div class="d-flex justify-content-center mb-4 mt-5">
        <span class="px-4 py-2 bg-white rounded-pill shadow-sm f-nunito font-weight-700 text-primary f-24">
          Groo<span class="font-weight-500 text-soft">wy.</span>
        </span>
      </div>
      <!--suppress JSUnresolvedVariable -->
      <div
        class="bg-white shadow rounded-lg overflow-hidden pt-3"
        :class="{ 'is-loading': loading }"
        @keypress.enter="submit"
      >
        <div class="login-content p-3">
          <h4 class="text-center text-primary mb-4 f-20 font-weight-600 f-nunito">
            Mot de passe oublié
          </h4>

          <div v-if="hasToken">
            <FormInput
              v-model="item.token"
              :is-valid="isValid('token')"
              :is-pre-valid="isItemPreValid('token')"
              label="Token de réinitialisation"
              type="text"
              @input="updateItemValidation('token')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('token')"
                  class="invalid-feedback"
                >
                  {{ violations.token }}
                </div>
                <div
                  v-if="!isItemPreValid('token')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.email.required">
                    Le token doit être indiqué
                  </div>
                </div>
              </template>
            </FormInput>

            <FormInput
              v-model="item.password"
              :is-valid="isValid('plainPassword')"
              :is-pre-valid="isItemPreValid('password')"
              label="Mot de passe"
              type="password"
              @input="updateItemValidation('password')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('plainPassword')"
                  class="invalid-feedback"
                >
                  {{ violations.plainPassword }}
                </div>
                <div
                  v-if="!isItemPreValid('password')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.password.required">
                    Le mot de passe doit être indiqué
                  </div>
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.password.minLength">
                    Le mot de passe doit contenir au moins {{ $v.item.password.$params.minLength.min }} caractères
                  </div>
                </div>
              </template>
            </FormInput>

            <FormInput
              v-model="item.confirmPassword"
              :is-pre-valid="isItemPreValid('confirmPassword')"
              label="Confirmation du mot de passe"
              type="password"
              @input="updateItemValidation('confirmPassword')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isItemPreValid('confirmPassword')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.confirmPassword.required">
                    Le mot de passe doit être indiqué
                  </div>
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.confirmPassword.sameAsPassword">
                    Les mots de passe doivent être identiques
                  </div>
                </div>
              </template>
            </FormInput>

            <div class="d-flex justify-content-center">
              <button
                class="btn btn-primary px-3 mt-3 mb-4 rounded-pill"
                @click.prevent="resetPassword"
              >
                Enregistrer le nouveau mot de passe
              </button>
            </div>
          </div>

          <div v-if="!hasToken">
            <FormInput
              v-model="email"
              :is-valid="isValid('email')"
              :is-pre-valid="isPreValid('email')"
              label="Adresse email"
              type="email"
              @input="updateValidation('email')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('email')"
                  class="invalid-feedback"
                >
                  {{ violations.email }}
                </div>
                <div
                  v-if="!isPreValid('email')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.email.required">
                    L'adresse email doit être indiquée
                  </div>
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.email.email">
                    Cette adresse email n'est pas valide
                  </div>
                </div>
              </template>
            </FormInput>

            <div class="d-flex justify-content-center">
              <button
                class="btn btn-primary px-3 mt-3 mb-4 rounded-pill"
                @click.prevent="requireToken"
              >
                Envoyer la demande
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="tokenNotification"
          class="bg-success-light f-14 font-weight-600 text-center p-3"
        >
          Un token de réinitalisation de mot de passe vous a été envoyé par email
        </div>
        <div
          v-if="requestTokenError"
          class="bg-danger-light f-14 font-weight-600 text-center p-3"
        >
          Une erreur est survenue. Assurez-vous d'avoir indiqué la bonne adresse email ou réessayez ultérieurement.
        </div>
        <div class="bg-light-grey cursor-pointer f-14 font-weight-600">
          <a
            v-if="!hasToken"
            href="#"
            class="d-block w-100 p-3 text-center text-decoration-none text-soft"
            @click.prevent="hasToken = true"
          >
            J'ai déjà reçu un token de réinitialisation<br> de mot de passe
          </a>
          <a
            v-if="hasToken"
            href="#"
            class="d-block w-100 p-3 text-center text-decoration-none text-soft"
            @click.prevent="hasToken = false"
          >
            Faire une demande de mot de passe oublié
          </a>
        </div>

        <div class="bg-light cursor-pointer f-14 font-weight-600">
          <router-link
            :to="{ name: 'security-login' }"
            class="d-block w-100 p-3 text-center text-decoration-none text-soft"
          >
            Revenir à la page de connexion
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import fetch from '../../utils/fetch'
import FormInput from '../../components/applicationform/FormInput'
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators'

export default {
  components: {
    FormInput
  },

  data () {
    return {
      loading: false,
      hasToken: false,
      tokenNotification: false,
      requestTokenError: false,
      email: '',
      item: {
        token: this.$route.query.token ? this.$route.query.token : '',
        email: '',
        password: '',
        confirmPassword: ''
      }
    }
  },

  computed: {
    ...mapFields('user/update', {
      error: 'error',
      isLoading: 'isLoading',
      updated: 'updated',
      violations: 'violations'
    }),

    ...mapFields('application/login', [
      'user'
    ])
  },

  watch: {
    user (user) {
      if (user && user.token) {
        this.$router.push({ name: 'dashboard-home' })
      }
    },

    violations (violations) {
      if (violations) {
        this.loading = false
      }
    },

    updated (updated) {
      if (updated) {
        let payload = {
          email: this.updated.email,
          password: this.item.password
        }

        this.login({ payload })
      }
    }
  },

  mounted () {
    if (this.$route.query.token) {
      this.hasToken = true
    }
  },

  methods: {
    ...mapActions({
      login: 'application/login/login',
      updateUser: 'user/update/update'
    }),

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v[key].$error || !this.$v[key].$invalid
    },

    isItemPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v[key].$touch()
    },

    updateItemValidation (key) {
      this.$v.item[key].$touch()
    },

    resetPassword () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      let id = this.item.token.split('/')[0]

      let payload = {
        plainResetPasswordToken: this.item.token,
        plainPassword: this.item.password
      }

      this.loading = true
      this.updateUser({
        id: 'lost-password/' + id,
        payload
      })
    },

    requireToken () {
      if (this.$v.email.$invalid) {
        this.$v.email.$touch()

        return
      }

      this.requestTokenError = false

      let payload = new FormData()
      payload.append('lost_password[email]', this.email)

      this.loading = true

      fetch('users/lost-password', { method: 'POST', body: payload })
        .then((response) => {
          this.loading = false
          this.hasToken = true
          this.tokenNotification = true
        })
        .catch((e) => {
          this.loading = false
          this.requestTokenError = true
        })
    }
  },

  validations: {
    email: {
      required,
      email
    },

    item: {
      token: {
        required
      },

      password: {
        required,
        minLength: minLength(6)
      },

      confirmPassword: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
