import List from '../views/member/List'
import Create from '../views/member/Create'
import Update from '../views/member/Update'

export default [
  {
    path: '/members',
    name: 'member-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'member.list',
      title: 'Liste des membres'
    }
  },
  {
    path: '/members/create',
    name: 'member-create',
    component: Create,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'member.create',
      title: 'Ajouter un membre'
    }
  },
  {
    path: '/members/:id',
    name: 'member-update',
    component: Update,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'member.update',
      title: 'Éditer un membre'
    }
  }
]
