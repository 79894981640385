<template>
  <div class="form-website">
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <div class="row align-items-stretch border-bottom">
        <div class="col-12 col-sm-6 py-3 border-right">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="text-soft">
              Choix du nom de domaine
            </div>
            <div
              v-if="!initialValues.domain || initialValues.domain !== item.domain"
              class="d-flex align-items-center text-primary f-14 font-weight-600"
            >
              <i class="fad fa-angle-right mr-2 f-18" />
              En cours
            </div>
            <div
              v-if="initialValues.domain && initialValues.domain === item.domain"
              class="d-flex align-items-center text-success f-14 font-weight-600"
            >
              <i class="fad fa-check mr-2" />
              Effectué
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 py-3">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="text-soft">
              Configuration des DNS
            </div>
            <div
              v-if="!initialValues.domain || initialValues.domain !== item.domain"
              class="d-flex align-items-center text-muted f-14 font-weight-600"
            >
              <i class="fad fa-ellipsis-h mr-2 f-18" />
              Étape suivante
            </div>
            <div
              v-if="initialValues.domain && initialValues.domain === item.domain"
              class="d-flex align-items-center text-warning f-14 font-weight-600"
            >
              <i class="far fa-exclamation-triangle mr-2" />
              Important
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 pt-4 pb-2">
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
          Domaine du site
        </div>

        <div class="f-14 font-weight-600 text-success">
          Vous pouvez configurer un nom de domaine qui vous appartient pour avoir une adresse de site entièrement personnalisée.<br>
          Pour lier votre nom de domaine à votre site il suffit d'indiquer ici votre domaine et de configurer vos enregistrements DNS.
        </div>
      </div>

      <div class="p-4">
        <FormInput
          v-model="item.domain"
          :is-valid="isValid('domain')"
          :is-pre-valid="isPreValid('domain')"
          label="Domaine du site"
          type="text"
          placeholder="monsite.fr"
          class="mb-0"
          @input="updateValidation('domain')"
        >
          <template v-slot:afterInput>
            <small
              v-if="item.domain && isPreValid('domain')"
              class="form-text text-muted"
            >
              Le site sera accessible à l'adresse <span class="font-weight-600 text-primary">{{ item.domain }}</span>
            </small>
          </template>
          <template v-slot:validation>
            <div
              v-if="!isValid('domain')"
              class="invalid-feedback"
            >
              {{ violations.domain }}
            </div>
            <div
              v-if="!isPreValid('domain')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.domain.url">
                Ce nom de domaine n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>

        <div
          v-if="initialValues.domain && initialValues.domain === item.domain"
          class="rounded-lg overflow-hidden mt-5"
        >
          <div class="bg-light p-3 f-14 font-weight-600">
            La zone DNS doit contenir un enregistrement de <span class="text-primary">Type A</span> de <span class="text-primary">{{ item.domain }}</span> qui pointe vers l'IP <span class="text-primary">{{ APP_API }}</span>
          </div>
          <div class="bg-light-grey p-3 f-14 font-weight-600">
            <div class="pb-3">
              Le nom de domaine <span class="text-primary">{{ item.domain }}</span> est lié à ce site.
              Pour qu'il fonctionne il est nécessaire de modifier ses paramètres DNS pour qu'il pointe vers les serveurs de Groowy.
            </div>
            <div>
              Guides de configuration des DNS :
            </div>
            <ul class="mt-2 mb-0">
              <li>
                <a
                  href="https://docs.ovh.com/fr/domains/editer-ma-zone-dns/"
                  target="_blank"
                >OVH</a>
              </li>
              <li>
                <a
                  href="https://support.cloudflare.com/hc/fr-fr/articles/360019093151-Gestion-des-enregistrements-DNS-dans-Cloudflare#h_60566325041543261564371"
                  target="_blank"
                >Cloudflare</a>
              </li>
              <li>
                <a
                  href="https://docs.gandi.net/fr/noms_domaine/faq/type_enregistrements_dns/a_record.html"
                  target="_blank"
                >Gandi</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-primary px-3 rounded-pill"
        @click.prevent="submit()"
      >
        Mettre à jour le site
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import { helpers } from 'vuelidate/lib/validators'
import { APP_API } from '../../config/entrypoint'
import FormInput from '../applicationform/FormInput'

const url = helpers.regex('slug', /^[-a-zA-Z0-9._]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/)

export default {
  components: {
    FormInput
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      APP_API
    }
  },

  computed: {
    item () {
      return this.values || {}
    },

    violations () {
      return this.errors || {}
    }
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      domain: {
        url
      }
    }
  }
}
</script>
