<template>
  <div
    class="border rounded-lg d-flex align-items-center py-2 px-3 my-3 cursor-pointer border-primary-hover"
    :class="{ 'border-primary': isSelected }"
    @click.prevent="handleSelect(item)"
  >
    <i
      class="f-32 mt-1"
      :class="[
        isSelected ? 'text-primary' : 'text-soft',
        item.icon
      ]"
    />
    <div
      class="text-soft font-weight-600 pl-4"
      :class="[isSelected ? 'text-primary' : 'text-soft']"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    selected: {
      type: String,
      required: true
    },

    handleSelect: {
      type: Function,
      required: true
    }
  },

  computed: {
    isSelected () {
      return this.selected === this.item.slug
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
