import { button } from '@/config/pagebuilder/elements/button'
import { carousel } from '@/config/pagebuilder/elements/carousel'
import { gallery } from '@/config/pagebuilder/elements/gallery'
import { image } from '@/config/pagebuilder/elements/image'
import { text } from '@/config/pagebuilder/elements/text'
import { title } from '@/config/pagebuilder/elements/title'
import { separator } from '@/config/pagebuilder/elements/separator'
import { video } from '@/config/pagebuilder/elements/video'
import { articleThumbnail } from '@/config/pagebuilder/elements/article_thumbnail'
import { articleTitle } from '@/config/pagebuilder/elements/article_title'
import { articleContent } from '@/config/pagebuilder/elements/article_content'
import { articlePublication } from '@/config/pagebuilder/elements/article_publication'
import { articleComments } from '@/config/pagebuilder/elements/article_comments'
import { memberList } from '@/config/pagebuilder/elements/member_list'
import { memberAvatar } from '@/config/pagebuilder/elements/member_avatar'
import { memberName } from '@/config/pagebuilder/elements/member_name'
import { memberGroups } from '@/config/pagebuilder/elements/member_groups'
import { memberPresentation } from '@/config/pagebuilder/elements/member_presentation'
import { memberSocials } from '@/config/pagebuilder/elements/member_socials'
import { memberFields } from '@/config/pagebuilder/elements/member_fields'

export const elements = {
  button,
  carousel,
  gallery,
  image,
  text,
  title,
  separator,
  video,
  'member-list': memberList,
  'member-avatar': memberAvatar,
  'member-name': memberName,
  'member-groups': memberGroups,
  'member-presentation': memberPresentation,
  'member-socials': memberSocials,
  'member-fields': memberFields,
  'article-thumnail': articleThumbnail,
  'article-title': articleTitle,
  'article-content': articleContent,
  'article-publication': articlePublication,
  'article-comments': articleComments
}
