import List from '../views/menu/List'

export default [
  {
    path: '/menus',
    name: 'menu-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'menu.list',
      title: 'Liste des menus'
    }
  }
]
