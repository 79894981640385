import { options } from './_default'

export const button = {
  type: 'button',
  position: null,
  variant: 'default',
  content: {
    text: 'Bouton',
    variant: 'primary',
    size: 'normal',
    round: 'rounded',
    outline: false,
    alignment: 'left',
    link: '',
    inline: true,
    fontFamily: 'roboto',
    boxShadow: false,
    boxShadowHorizontal: '2',
    boxShadowVertical: '2',
    boxShadowBlur: '0',
    boxShadowColor: '#00000052'
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
