var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-text-preview"},[_c('div',{class:{ 'container': _vm.hasContainer }},[_c('div',{staticClass:"page-editor-preview",class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('a',{class:[_vm.buttonClass],style:(_vm.buttonStyle),attrs:{"href":_vm.content.content.link},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("\n        "+_vm._s(_vm.content.content.text)+"\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }