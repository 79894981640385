import List from '@/views/shopproduct/List'
import Update from '@/views/shopproduct/Update'
import Create from '@/views/shopproduct/Create'

export default [
  {
    path: '/products',
    name: 'shopproduct-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'shopproduct.list',
      title: 'Liste des produits'
    }
  },
  {
    path: '/products/create',
    name: 'shopproduct-create',
    component: Create,
    meta: {
      layout: 'dynamic',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'shopproduct.create',
      title: 'Ajouter un produit'
    }
  },
  {
    path: '/products/:id',
    name: 'shopproduct-update',
    component: Update,
    meta: {
      layout: 'dynamic',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'shopproduct.update',
      title: 'Editer un produit'
    }
  }
]
