<template>
  <div class="module-article-publication">
    <div class="title-settings">
      <Tabs
        v-model="tab"
        :tabs="tabs"
        class="border-bottom border-primary"
      />

      <div v-if="tab === 'propositions'">
        <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
          Propositions de format
        </div>
        <div class="px-4 pt-4">
          <div
            v-for="(proposition, index) in propositions"
            :key="index"
            class="border py-3 mb-3 rounded-lg cursor-pointer bg-light-grey-hover border-primary-hover"
            @click.prevent="selectProposition(proposition)"
          >
            <ArticlePublicationPreview :content="proposition" />
          </div>
        </div>
      </div>

      <div v-if="tab === 'customization'">
        <div
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          @click.prevent="collapse.fontFamily = !collapse.fontFamily"
        >
          Police d'écriture
          <i
            class="fas fa-chevron-right text-soft"
            :class="{ 'fa-rotate-90': collapse.fontFamily }"
          />
        </div>
        <BCollapse v-model="collapse.fontFamily">
          <div class="px-4 pt-4">
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'roboto' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'roboto'"
            >
              <div class="f-20 f-roboto mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'roboto-condensed' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'roboto-condensed'"
            >
              <div class="f-20 f-roboto-condensed mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'roboto-slab' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'roboto-slab'"
            >
              <div class="f-20 f-roboto-slab mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'roboto-mono' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'roboto-mono'"
            >
              <div class="f-20 f-roboto-mono mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'abril' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'abril'"
            >
              <div class="f-20 f-abril mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'dancing' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'dancing'"
            >
              <div class="f-20 f-dancing mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'josefin' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'josefin'"
            >
              <div class="f-20 f-josefin mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'oswald' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'oswald'"
            >
              <div class="f-20 f-oswald mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
            <div
              class="border rounded mb-3 px-3 py-2"
              :class="[ content.content.fontFamily === 'pacifico' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'pacifico'"
            >
              <div class="f-20 f-pacifico mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
            <div
              class="border rounded mb-4 px-3 py-2"
              :class="[ content.content.fontFamily === 'playfair' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
              @click.prevent="content.content.fontFamily = 'playfair'"
            >
              <div class="f-20 f-playfair mw-100 overflow-hidden text-nowrap">
                Publié le 00/00/00
              </div>
            </div>
          </div>
        </BCollapse>

        <div
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          @click.prevent="collapse.appearance = !collapse.appearance"
        >
          Taille, couleur et alignement
          <i
            class="fas fa-chevron-right text-soft"
            :class="{ 'fa-rotate-90': collapse.appearance }"
          />
        </div>
        <BCollapse v-model="collapse.appearance">
          <div class="px-4 pt-4">
            <div class="form-control-label pb-2">
              Taille du texte <small class="text-soft font-weight-700">{{ content.content.fontSize }}px</small>
            </div>
            <BFormInput
              v-model="content.content.fontSize"
              type="range"
              min="8"
              max="24"
              step="1"
              class="mb-4"
            />

            <div class="form-control-label pb-2">
              Alignement du texte
            </div>
            <div class="row mb-4">
              <div class="col-4">
                <div
                  v-b-tooltip="'Aligner à gauche'"
                  class="border rounded px-3 py-1 f-32 text-center"
                  :class="[ content.content.alignment === 'left' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
                  @click.prevent="content.content.alignment = 'left'"
                >
                  <i class="fad fa-align-left" />
                </div>
              </div>
              <div class="col-4">
                <div
                  v-b-tooltip="'Aligner au centre'"
                  class="border rounded px-3 py-1 f-32 text-center"
                  :class="[ content.content.alignment === 'center' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
                  @click.prevent="content.content.alignment = 'center'"
                >
                  <i class="fad fa-align-center" />
                </div>
              </div>
              <div class="col-4">
                <div
                  v-b-tooltip="'Aligner à droite'"
                  class="border rounded px-3 py-1 f-32 text-center"
                  :class="[ content.content.alignment === 'right' ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
                  @click.prevent="content.content.alignment = 'right'"
                >
                  <i class="fad fa-align-right" />
                </div>
              </div>
            </div>

            <FormColorInput
              v-model="content.content.textColor"
              label="Couleur du texte"
              class="mb-4"
              :allow-transparency="true"
            />
          </div>
        </BCollapse>

        <div
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          @click.prevent="collapse.textShadow = !collapse.textShadow"
        >
          Ombre de texte
          <i
            class="fas fa-chevron-right text-soft"
            :class="{ 'fa-rotate-90': collapse.textShadow }"
          />
        </div>
        <BCollapse v-model="collapse.textShadow">
          <div class="px-4 pt-4">
            <FormCheckbox
              v-model="content.content.textShadow"
              label="Afficher une ombre de texte"
              :is-switch="true"
              class="mb-4"
            />

            <div
              v-if="content.content.textShadow"
              class="border-top pt-4"
            >
              <div class="form-control-label pb-2">
                Décallage horizontal de l'ombre <small class="text-soft font-weight-700">{{ content.content.textShadowHorizontal }}px</small>
              </div>
              <BFormInput
                v-model="content.content.textShadowHorizontal"
                type="range"
                min="-50"
                max="50"
                step="1"
                class="mb-4"
              />

              <div class="form-control-label pb-2">
                Décalage vertical de l'ombre <small class="text-soft font-weight-700">{{ content.content.textShadowVertical }}px</small>
              </div>
              <BFormInput
                v-model="content.content.textShadowVertical"
                type="range"
                min="-50"
                max="50"
                step="1"
                class="mb-4"
              />

              <div class="form-control-label pb-2">
                Flou de l'ombre <small class="text-soft font-weight-700">{{ content.content.textShadowBlur }}px</small>
              </div>
              <BFormInput
                v-model="content.content.textShadowBlur"
                type="range"
                min="0"
                max="30"
                step="1"
                class="mb-4"
              />

              <FormColorInput
                v-model="content.content.textShadowColor"
                label="Couleur de l'ombre"
                class="mb-4"
                :allow-transparency="true"
              />
            </div>
          </div>
        </BCollapse>

        <div
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          @click.prevent="collapse.background = !collapse.background"
        >
          Fond de couleur
          <i
            class="fas fa-chevron-right text-soft"
            :class="{ 'fa-rotate-90': collapse.background }"
          />
        </div>
        <BCollapse v-model="collapse.background">
          <div class="px-4 pt-4">
            <FormCheckbox
              v-model="content.content.background"
              label="Afficher un fond coloré"
              :is-switch="true"
              class="mb-4"
            />

            <div
              v-if="content.content.background"
              class="border-top pt-4"
            >
              <div class="form-control-label pb-2">
                Hauteur du fond <small class="text-soft font-weight-700">{{ content.content.backgroundHeight }}px</small>
              </div>
              <BFormInput
                v-model="content.content.backgroundHeight"
                type="range"
                min="1"
                max="60"
                step="1"
                class="mb-4"
              />

              <div class="form-control-label pb-2">
                Distance à partir du haut <small class="text-soft font-weight-700">{{ content.content.backgroundMarginTop }}px</small>
              </div>
              <BFormInput
                v-model="content.content.backgroundMarginTop"
                type="range"
                min="1"
                max="60"
                step="1"
                class="mb-4"
              />

              <div class="form-control-label pb-2">
                Distance à partir de la gauche <small class="text-soft font-weight-700">{{ content.content.backgroundMarginLeft }}px</small>
              </div>
              <BFormInput
                v-model="content.content.backgroundMarginLeft"
                type="range"
                min="1"
                max="60"
                step="1"
                class="mb-4"
              />

              <FormColorInput
                v-model="content.content.backgroundColor"
                label="Couleur du fond"
                class="mb-4"
                :allow-transparency="true"
              />
            </div>
          </div>
        </BCollapse>

        <div
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
          @click.prevent="collapse.underline = !collapse.underline"
        >
          Soulignage
          <i
            class="fas fa-chevron-right text-soft"
            :class="{ 'fa-rotate-90': collapse.underline }"
          />
        </div>
        <BCollapse v-model="collapse.underline">
          <div class="px-4 pt-4">
            <FormCheckbox
              v-model="content.content.underline"
              label="Afficher une ligne colorée sous le texte"
              :is-switch="true"
              class="mb-4"
            />

            <div
              v-if="content.content.underline"
              class="border-top pt-4"
            >
              <div class="form-control-label pb-2">
                Hauteur de la ligne <small class="text-soft font-weight-700">{{ content.content.underlineHeight }}px</small>
              </div>
              <BFormInput
                v-model="content.content.underlineHeight"
                type="range"
                min="1"
                max="60"
                step="1"
                class="mb-4"
              />

              <div class="form-control-label pb-2">
                Longueur de la ligne <small class="text-soft font-weight-700">{{ content.content.underlineWidth }}px</small>
              </div>
              <BFormInput
                v-model="content.content.underlineWidth"
                type="range"
                min="1"
                max="1920"
                step="1"
                class="mb-4"
              />

              <FormColorInput
                v-model="content.content.underlineColor"
                label="Couleur du fond"
                class="mb-4"
                :allow-transparency="true"
              />
            </div>
          </div>
        </BCollapse>
      </div>

      <div v-if="tab === 'section'">
        <SectionAppearance :content.sync="content" />
      </div>
    </div>
  </div>
</template>

<script>
import { options } from '@/config/pageBuilderSectionOptions'
import FormColorInput from '../../applicationform/FormColorInput'
import FormInput from '../../applicationform/FormInput'
import SectionAppearance from './../SectionAppearance'
import Tabs from '../../applicationcomponent/Tabs'
import FormCheckbox from '../../applicationform/FormCheckbox'
import ArticlePublicationPreview from '@/components/pagebuilder/preview/ArticlePublicationPreview'

export default {
  components: {
    ArticlePublicationPreview,
    FormCheckbox,
    FormColorInput,
    FormInput,
    SectionAppearance,
    Tabs
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 'propositions',
      tabs: [
        {
          name: 'propositions',
          title: 'Choix du format'
        },
        {
          name: 'customization',
          title: 'Options du texte'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      collapse: {
        fontFamily: false,
        appearance: false,
        textShadow: false,
        background: false,
        underline: false
      },
      propositions: [
        {
          type: 'article-publication',
          content: {
            showDate: true,
            showAuthor: true,
            showPublicationWord: true,
            showDateWord: true,
            sameLine: false,
            alignment: 'left',
            textColor: '#000000',
            fontFamily: 'roboto',
            fontSize: '16',
            background: false,
            backgroundColor: '#f5208b',
            backgroundHeight: '25',
            backgroundMarginTop: '22',
            backgroundMarginLeft: '10',
            underline: false,
            underlineWidth: '50',
            underlineHeight: '5',
            underlineColor: '#21D8F5',
            textShadow: false,
            textShadowHorizontal: '2',
            textShadowVertical: '2',
            textShadowBlur: '0',
            textShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'article-publication',
          content: {
            showDate: true,
            showAuthor: true,
            showPublicationWord: true,
            showDateWord: false,
            sameLine: false,
            alignment: 'left',
            textColor: '#000000',
            fontFamily: 'roboto',
            fontSize: '16',
            background: false,
            backgroundColor: '#f5208b',
            backgroundHeight: '25',
            backgroundMarginTop: '22',
            backgroundMarginLeft: '10',
            underline: false,
            underlineWidth: '1920',
            underlineHeight: '2',
            underlineColor: '#40941E',
            textShadow: false,
            textShadowHorizontal: '2',
            textShadowVertical: '2',
            textShadowBlur: '0',
            textShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'article-publication',
          content: {
            showDate: true,
            showAuthor: true,
            showPublicationWord: true,
            showDateWord: true,
            sameLine: true,
            alignment: 'left',
            textColor: '#000000',
            fontFamily: 'roboto',
            fontSize: '16',
            background: false,
            backgroundColor: '#f5208b',
            backgroundHeight: '25',
            backgroundMarginTop: '22',
            backgroundMarginLeft: '10',
            underline: false,
            underlineWidth: '50',
            underlineHeight: '5',
            underlineColor: '#21D8F5',
            textShadow: false,
            textShadowHorizontal: '2',
            textShadowVertical: '2',
            textShadowBlur: '0',
            textShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'article-publication',
          content: {
            showDate: false,
            showAuthor: true,
            showPublicationWord: true,
            showDateWord: false,
            sameLine: false,
            alignment: 'left',
            textColor: '#000000',
            fontFamily: 'roboto',
            fontSize: '16',
            background: false,
            backgroundColor: '#f5208b',
            backgroundHeight: '25',
            backgroundMarginTop: '22',
            backgroundMarginLeft: '10',
            underline: false,
            underlineWidth: '50',
            underlineHeight: '5',
            underlineColor: '#21D8F5',
            textShadow: false,
            textShadowHorizontal: '2',
            textShadowVertical: '2',
            textShadowBlur: '0',
            textShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'article-publication',
          content: {
            showDate: true,
            showAuthor: false,
            showPublicationWord: true,
            showDateWord: true,
            sameLine: true,
            alignment: 'left',
            textColor: '#000000',
            fontFamily: 'roboto',
            fontSize: '16',
            background: false,
            backgroundColor: '#f5208b',
            backgroundHeight: '25',
            backgroundMarginTop: '22',
            backgroundMarginLeft: '10',
            underline: false,
            underlineWidth: '50',
            underlineHeight: '5',
            underlineColor: '#21D8F5',
            textShadow: false,
            textShadowHorizontal: '2',
            textShadowVertical: '2',
            textShadowBlur: '0',
            textShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'article-publication',
          content: {
            showDate: true,
            showAuthor: true,
            showPublicationWord: false,
            showDateWord: false,
            sameLine: false,
            alignment: 'left',
            textColor: '#000000',
            fontFamily: 'roboto',
            fontSize: '16',
            background: false,
            backgroundColor: '#f5208b',
            backgroundHeight: '25',
            backgroundMarginTop: '22',
            backgroundMarginLeft: '10',
            underline: false,
            underlineWidth: '50',
            underlineHeight: '5',
            underlineColor: '#21D8F5',
            textShadow: false,
            textShadowHorizontal: '2',
            textShadowVertical: '2',
            textShadowBlur: '0',
            textShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'article-publication',
          content: {
            showDate: false,
            showAuthor: true,
            showPublicationWord: false,
            showDateWord: false,
            sameLine: false,
            alignment: 'left',
            textColor: '#000000',
            fontFamily: 'roboto',
            fontSize: '16',
            background: false,
            backgroundColor: '#f5208b',
            backgroundHeight: '25',
            backgroundMarginTop: '22',
            backgroundMarginLeft: '10',
            underline: false,
            underlineWidth: '50',
            underlineHeight: '5',
            underlineColor: '#21D8F5',
            textShadow: false,
            textShadowHorizontal: '2',
            textShadowVertical: '2',
            textShadowBlur: '0',
            textShadowColor: '#00000052'
          },
          section: options
        },
        {
          type: 'article-publication',
          content: {
            showDate: true,
            showAuthor: false,
            showPublicationWord: false,
            showDateWord: false,
            sameLine: false,
            alignment: 'left',
            textColor: '#000000',
            fontFamily: 'roboto',
            fontSize: '16',
            background: false,
            backgroundColor: '#f5208b',
            backgroundHeight: '25',
            backgroundMarginTop: '22',
            backgroundMarginLeft: '10',
            underline: false,
            underlineWidth: '50',
            underlineHeight: '5',
            underlineColor: '#21D8F5',
            textShadow: false,
            textShadowHorizontal: '2',
            textShadowVertical: '2',
            textShadowBlur: '0',
            textShadowColor: '#00000052'
          },
          section: options
        }
      ]
    }
  },

  methods: {
    selectProposition (proposition) {
      this.content.content.showDate = proposition.content.showDate
      this.content.content.showAuthor = proposition.content.showAuthor
      this.content.content.showPublicationWord = proposition.content.showPublicationWord
      this.content.content.showDateWord = proposition.content.showDateWord
      this.content.content.sameLine = proposition.content.sameLine
    }
  }
}
</script>
