<template>
  <div class="form-menu">
    <div class="p-3">
      <div
        v-if="!item.id"
        class="mb-3 text-primary font-weight-700 text-uppercase f-14"
      >
        Ajouter un menu
      </div>

      <FormCheckbox
        v-model="existingPage"
        :is-switch="true"
        label="Utiliser une page existante"
        class="mb-0"
      />
      <div
        v-if="!existingPage"
        class="f-14 text-muted"
      >
        Vous pouvez indiquer un lien externe
      </div>
    </div>

    <div class="bg-light-grey p-3">
      <div class="row justify-content-center">
        <div
          v-if="existingPage"
          class="col-4"
        >
          <FormSelect
            v-model="item.page"
            :is-valid="isValid('page')"
            :list="pages"
            label="Page"
            placeholder="Choisir une page"
            @input="selectPage"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('page')"
                class="invalid-feedback"
              >
                {{ violations.page }}
              </div>
            </template>
          </FormSelect>
        </div>

        <div class="col-4">
          <FormInput
            v-model="item.text"
            :is-valid="isValid('text')"
            :is-pre-valid="isPreValid('text')"
            label="Texte du menu"
            placeholder="Mon menu"
            type="text"
            @input="updateValidation('text')"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('text')"
                class="invalid-feedback"
              >
                {{ violations.text }}
              </div>
              <div
                v-if="!isPreValid('text')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.text.required">
                  Le texte du menu doit être indiqué
                </div>
              </div>
            </template>
          </FormInput>
        </div>

        <div class="col-4">
          <FormInput
            v-model="item.link"
            :is-valid="isValid('link')"
            :is-disabled="existingPage"
            label="Lien du menu"
            placeholder="https://monsite.fr/monlien"
            type="text"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('link')"
                class="invalid-feedback"
              >
                {{ violations.link }}
              </div>
            </template>
          </FormInput>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center p-3">
      <button
        class="btn btn-success px-3 rounded-pill"
        @click.prevent="submit()"
      >
        Enregistrer
      </button>

      <button
        class="btn btn-link text-soft ml-3"
        @click.prevent="handleClose()"
      >
        Annuler
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormSelect from '../applicationform/FormSelect'
import { required } from 'vuelidate/lib/validators'
import FormInput from '@/components/applicationform/FormInput'
import FormCheckbox from '@/components/applicationform/FormCheckbox'

export default {
  components: {
    FormCheckbox,
    FormInput,
    FormSelect
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    handleClose: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    pages: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      existingPage: true,
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    }
  },

  watch: {
    existingPage (existing) {
      if (!existing) {
        this.item.page = null
      }
    }
  },

  mounted () {
    if (this.item.id && !this.item.page) {
      this.existingPage = false
    }
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    selectPage (selected) {
      let page = this.pages.filter(e => e.id === selected).shift()

      this.item.text = page.name
      this.item.link = '/' + page.slug
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      if (this.item.page && !this.item.page.includes('/api/pages/')) {
        this.item.page = null
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      text: {
        required
      }
    }
  }
}
</script>
