<template>
  <div
    :class="[
      'mt-' + content.section.marginTop.replace('-', 'n'),
      'mb-' + content.section.marginBottom.replace('-', 'n'),
      'ml-' + content.section.marginLeft.replace('-', 'n'),
      'mr-' + content.section.marginRight.replace('-', 'n'),
      'col-' + content.content.smallSize,
      'col-md-' + content.content.mediumSize,
      'col-xl-' + content.content.largeSize,
      'pt-' + content.section.paddingTop,
      'pb-' + content.section.paddingBottom,
      'pl-' + content.section.paddingLeft,
      'pr-' + content.section.paddingRight,
      moduleClass
    ]"
    :style="moduleStyle"
  >
    <div class="d-inline-block w-100 position-relative">
      <BuilderForm
        :content.sync="content.submodules"
        :is-column="true"
        :row-style="rowClass"
        :editing="editing"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    BuilderForm: () => import('../BuilderForm')
  },

  props: {
    content: {
      type: Object,
      required: true
    },

    editing: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      if (this.content.content.verticalCenter) {
        classes.push('d-flex')
        classes.push('align-items-center')
      }

      return classes
    },

    moduleStyle () {
      let style = 'min-height: 32px;'

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px; overflow-hidden;'
      }

      return style
    },

    rowClass () {
      let classes = []

      classes.push('justify-content-' + this.content.content.align)
      classes.push('align-items-' + this.content.content.verticalAlign)

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
