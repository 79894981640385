<template>
  <div class="layout position-relative">
    <b-sidebar
      id="sidebar"
      backdrop
      shadow="true"
      bg-variant="white"
    >
      <ul class="nav navbar px-5 justify-content-center">
        <!--suppress JSUnresolvedVariable -->
        <BNavItemDropdown
          v-if="user.websites.length > 0"
          no-caret
        >
          <template v-slot:button-content>
            <div
              v-if="hasWebsiteSelected"
              class="bg-light f-nunito px-3 py-2 font-weight-600 rounded-pill"
            >
              {{ user.selectedWebsite.name }}
              <i class="ml-1 f-12 fa fa-chevron-down" />
            </div>
            <div
              v-if="!hasWebsiteSelected"
              class="bg-light f-nunito px-3 py-2 font-weight-600 rounded-pill"
            >
              Sélectionner un site
              <i class="ml-1 f-12 fa fa-chevron-down" />
            </div>
          </template>
          <!--suppress JSUnresolvedVariable -->
          <BDropdownItem
            v-if="hasWebsiteSelected"
            variant="success"
            target="_blank"
            :href="'//' + user.selectedWebsite.url"
          >
            Voir le site
          </BDropdownItem>
          <!--suppress JSUnresolvedVariable -->
          <BDropdownDivider v-if="hasWebsiteSelected" />
          <!--suppress JSUnresolvedVariable -->
          <BDropdownItem
            v-for="website in user.websites"
            :key="website.id"
            href="#"
            variant="soft"
            @click.prevent="selectWebsite(website.website)"
          >
            {{ website.name }}
          </BDropdownItem>
          <BDropdownDivider />
          <BDropdownItem
            variant="primary"
            :to="{ name: 'website-create' }"
          >
            Ajouter un site
          </BDropdownItem>
        </BNavItemDropdown>
      </ul>
      <ul class="nav nav-navbar px-5 justify-content-center">
        <BNavItemDropdown
          right
          no-caret
        >
          <template v-slot:button-content>
            <div class="user-nav text-capitalize f-nunito font-weight-600 bg-light-grey px-3 py-2 rounded-pill">
              {{ user.username }}
              <i class="ml-1 f-12 fa fa-chevron-down" />
            </div>
          </template>
          <BDropdownItem
            :to="{ name: 'user-profile' }"
            variant="soft"
          >
            Mon compte
          </BDropdownItem>
          <BDropdownDivider />
          <BDropdownItem
            href="#"
            variant="danger"
            @click.prevent="logout"
          >
            Déconnexion
          </BDropdownItem>
        </BNavItemDropdown>
      </ul>

      <div class="sidebar px-5 pb-4">
        <Sidebar />
      </div>
    </b-sidebar>

    <nav class="navbar navbar-light bg-white navbar-expand-lg header-navbar shadow w-100 px-0 px-md-3 z-index-10">
      <div class="container">
        <div class="d-flex d-lg-none justify-content-center w-100 position-relative">
          <ul class="nav navbar-nav">
            <router-link
              class="navbar-brand f-nunito font-weight-700 text-primary"
              :to="{ name: 'dashboard-home' }"
            >
              Groo<span class="font-weight-500 text-soft">wy.</span>
            </router-link>
          </ul>

          <div class="position-absolute top-0 left-0 px-3 py-3 mt-n2">
            <i
              v-b-toggle.sidebar
              class="far fa-bars f-24 cursor-pointer text-primary"
            />
          </div>
        </div>
        <div
          id="navbar-collapse"
          class="collapse navbar-collapse py-1"
        >
          <div class="mr-auto">
            <ul class="nav navbar-nav">
              <li
                v-if="!sidebar"
                class="nav-item mr-3"
              >
                <i
                  v-b-toggle.sidebar
                  class="nav-link far fa-bars f-24 cursor-pointer text-primary"
                />
              </li>
              <router-link
                class="navbar-brand f-nunito font-weight-700 text-primary"
                :to="{ name: 'dashboard-home' }"
              >
                Groo<span class="font-weight-500 text-soft">wy.</span>
              </router-link>
            </ul>

            <div class="position-absolute top-0 left-0 px-3 py-3 mt-n2" />
          </div>
          <ul class="nav navbar-nav">
            <!--suppress JSUnresolvedVariable -->
            <BNavItemDropdown
              v-if="user.websites.length > 0"
              no-caret
            >
              <template v-slot:button-content>
                <div
                  v-if="hasWebsiteSelected"
                  class="bg-light f-nunito px-2 font-weight-600 rounded-pill"
                >
                  {{ user.selectedWebsite.name }}
                  <i class="ml-1 f-12 fa fa-chevron-down" />
                </div>
                <div
                  v-if="!hasWebsiteSelected"
                  class="bg-light f-nunito px-2 font-weight-600 rounded-pill"
                >
                  Sélectionner un site
                  <i class="ml-1 f-12 fa fa-chevron-down" />
                </div>
              </template>
              <!--suppress JSUnresolvedVariable -->
              <BDropdownItem
                v-if="hasWebsiteSelected"
                variant="success"
                target="_blank"
                :href="'//' + user.selectedWebsite.url"
              >
                Voir le site
              </BDropdownItem>
              <!--suppress JSUnresolvedVariable -->
              <BDropdownDivider v-if="user.selectedWebsite" />
              <!--suppress JSUnresolvedVariable -->
              <BDropdownItem
                v-for="website in user.websites"
                :key="website.id"
                href="#"
                variant="soft"
                @click.prevent="selectWebsite(website.website)"
              >
                {{ website.name }}
              </BDropdownItem>
              <BDropdownDivider />
              <BDropdownItem
                variant="primary"
                :to="{ name: 'website-create' }"
              >
                Ajouter un site
              </BDropdownItem>
            </BNavItemDropdown>

            <BNavItemDropdown
              right
              no-caret
            >
              <template v-slot:button-content>
                <div class="user-nav ml-3 text-capitalize f-nunito font-weight-600">
                  {{ user.username }}
                  <i class="ml-1 f-12 fa fa-chevron-down" />
                </div>
              </template>
              <BDropdownItem
                :to="{ name: 'user-profile' }"
                variant="soft"
              >
                Mon compte
              </BDropdownItem>
              <BDropdownDivider />
              <BDropdownItem
                href="#"
                variant="danger"
                @click.prevent="logout"
              >
                Déconnexion
              </BDropdownItem>
            </BNavItemDropdown>
          </ul>
        </div>
      </div>
    </nav>

    <div :class="[ fluid ? 'container-fluid' : 'container']">
      <!--suppress JSUnresolvedVariable, ES6ModulesDependencies -->
      <div
        v-if="!isLoading && user"
        class="row justify-content-center"
        :class="{ 'is-loading': websiteIsLoading }"
      >
        <div
          v-if="sidebar"
          class="col col-lg-3 col-xl-2 d-none d-lg-block sidebar"
        >
          <Sidebar />
        </div>
        <EditorSidebar v-if="editor" />
        <div
          class="col-12 main-content position-relative pb-5"
          :class="{ 'col-lg-9 col-xl-10': sidebar, 'col-xl-8': editor }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import Sidebar from './Sidebar'
import EditorSidebar from '../pagebuilder/EditorSidebar'

export default {
  components: {
    EditorSidebar,
    Sidebar
  },

  props: {
    fluid: {
      type: Boolean,
      required: true
    },

    sidebar: {
      type: Boolean,
      required: true
    },

    editor: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      websiteIsLoading: false,
      previousWebsite: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      isLoading: 'isLoading',
      isLogged: 'isLogged',
      hasWebsite: 'hasWebsite',
      hasWebsiteSelected: 'hasWebsiteSelected',
      user: 'user'
    }),

    ...mapFields('user/update', {
      userUpdateError: 'error',
      isUserUpdateLoading: 'isLoading',
      userUpdateRetrieved: 'retrieved',
      userUpdateUpdated: 'updated',
      userUpdateViolations: 'violations'
    }),

    currentRoute () {
      return this.$route.name
    }
  },

  watch: {
    user (user) {
      if (!user) {
        this.$router.push({ name: 'security-login' })
      }

      this.websiteIsLoading = false

      // noinspection JSUnresolvedVariable
      if (this.previousWebsite !== null && this.previousWebsite !== user.selectedWebsite.id) {
        this.previousWebsite = null
        this.$router.push({ name: 'dashboard-home' })
      }
    },

    userUpdateUpdated (updated) {
      // noinspection JSUnresolvedVariable
      if (updated && (!this.hasWebsiteSelected || updated.activeWebsite.id !== this.user.selectedWebsite.id)) {
        this.refreshToken({})
      }
    }
  },

  methods: {
    ...mapActions({
      refreshToken: 'application/auth/refreshToken',
      logout: 'application/auth/logout',
      updateUser: 'user/update/update',
      checkPermission: 'application/auth/checkPermission'
    }),

    selectWebsite (id) {
      this.websiteIsLoading = true

      if (this.hasWebsiteSelected) {
        this.previousWebsite = this.user.selectedWebsite.id
      }

      let payload = {
        activeWebsite: '/api/websites/' + id
      }

      this.updateUser({
        id: 'active-website/' + this.user.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .editor-sidebar {
    min-height: calc(100vh - 64px);
  }
</style>
