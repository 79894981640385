import { options } from './_default'

export const carousel = {
  type: 'carousel',
  position: false,
  variant: 'default',
  content: {
    slides: [],
    fullWidth: false,
    ratio: '16by9',
    height: 100,
    cover: false,
    showControls: true,
    fade: false,
    delay: 5000
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
