<template>
  <!--suppress JSUnresolvedVariable -->
  <div
    class="component-stripe-create-card"
    :class="{ 'is-loading': isLoading }"
  >
    <form
      id="subscription-form"
      @submit.prevent="createPaymentMethod"
    >
      <div class="p-3">
        <div class="form-control-label mb-2">
          Ajouter une carte bancaire
        </div>
        <div
          id="card-element"
          class="MyCardElement border rounded px-3 py-2"
        />

        <!-- We'll put the error messages in this element -->
        <div
          id="card-errors"
          role="alert"
          class="invalid-feedback d-block"
        />

        <div
          :class="{ 'd-block': stripeError }"
          class="invalid-feedback"
        >
          {{ stripeError }}
        </div>
      </div>

      <div class="d-flex justify-content-center mt-4 mb-3">
        <!--suppress JSUnresolvedVariable -->
        <button
          v-if="!isLoading"
          class="btn btn-primary px-3 rounded-pill"
          type="submit"
        >
          Enregistrer la carte bancaire
        </button>
        <!--suppress JSUnresolvedVariable -->
        <button
          v-if="isLoading"
          class="btn btn-primary px-3 rounded-pill"
        >
          Enregistrement de la carte
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { STRIPE_KEY } from '../../config/entrypoint'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

export default {
  props: {
    handleClose: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      stripeError: '',
      stripe: null,
      card: null,
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Nunito", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#dc3545',
          iconColor: '#fa755a'
        }
      }
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('stripepaymentmethod/create', {
      error: 'error',
      isLoading: 'isLoading',
      created: 'created',
      violation: 'violations'
    }),

    ...mapFields('websitebilling/show', {
      websiteBillingIsLoading: 'isLoading',
      websiteBilling: 'retrieved'
    })
  },

  watch: {
    created (created) {
      if (created) {
        // noinspection JSCheckFunctionSignatures
        this.handleClose(created)
      }
    }
  },

  mounted () {
    if (!this.websiteBilling) {
      // noinspection JSUnresolvedVariable
      this.getWebsiteBilling('websites/' + this.user.selectedWebsite.id + '/billing')
    }

    let self = this
    this.$nextTick(() => {
      self.initStripe()
    })
  },

  methods: {
    ...mapActions({
      create: 'stripepaymentmethod/create/create',
      getWebsiteBilling: 'websitebilling/show/retrieve'
    }),

    initStripe () {
      // noinspection JSUnresolvedFunction
      const stripe = window.Stripe(STRIPE_KEY)
      this.stripe = stripe

      let elements = stripe.elements()
      this.card = elements.create('card', { style: this.style })
      this.card.mount('#card-element')

      this.card.on('change', this.showCardError)
    },

    showCardError (event) {
      let displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    },

    createPaymentMethod () {
      this.error = null
      return this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card
      }).then((result) => {
        if (result.error) {
          this.stripeError = result.error.message
        } else {
          // noinspection JSUnresolvedVariable
          let payload = {
            id: result.paymentMethod.id,
            customer: this.websiteBilling.stripeCustomerId
          }

          this.create(payload)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
