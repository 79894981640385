var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-member-avatar-preview"},[_c('div',{class:[
      { 'container': _vm.hasContainer },
      'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
      'mb-' + _vm.content.section.marginBottom.replace('-', 'n')
    ]},[_c('div',{class:[
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"d-flex flex-column",class:{
          'align-items-start': !_vm.content.content.align || _vm.content.content.align === 'start',
          'align-items-center': _vm.content.content.align === 'center',
          'align-items-end': _vm.content.content.align === 'end',
        }},[_c('img',{staticClass:"mw-100",class:{
            'rounded-lg': _vm.content.content.rounded === 'rounded',
            'rounded-circle': _vm.content.content.rounded === 'rounded-circle',
            'shadow-sm': _vm.content.content.shadow === 'sm',
            'shadow': _vm.content.content.shadow === 'normal',
            'shadow-lg': _vm.content.content.shadow === 'lg',
          },attrs:{"height":_vm.content.content.size,"width":_vm.content.content.size,"src":_vm.APP_API_ENTRYPOINT + '/assets/img/avatar.png',"alt":"Member avatar"}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }