<template>
  <div class="page-weddinglistitem-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion de la liste de mariage
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste de mariage
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="!loadedItems || !loadedData"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="!loadedItems || !loadedData">
      <div class="py-5 mb-5 rounded-lg bg-grey" />

      <div
        v-if="checkPermission('wedding.setting')"
        class="d-flex justify-content-center"
      >
        <button class="btn btn-fake px-3 rounded-pill">
          Ajouter un cadeau
        </button>
      </div>
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="items && loadedItems && data && loadedData"
      :class="{ 'is-loading': isLoading }"
    >
      <div class="mb-5 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div class="row align-items-center py-4">
          <div class="col-12 col-md-6 col-xl-4 d-flex flex-column align-items-center">
            <div class="f-22 text-primary font-weight-600">
              {{ data.giftCount }}
            </div>
            <div class="text-uppercase font-weight-700 text-muted f-14">
              Participations
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 mt-3 mt-md-0 d-flex flex-column align-items-center">
            <div class="f-22 text-primary font-weight-600">
              {{ data.giftIncome }} €
            </div>
            <div class="text-uppercase font-weight-700 text-muted f-14">
              Valeur des cadeaux
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-4 mt-3 mt-xl-0 d-flex flex-column align-items-center">
            <div class="f-22 text-primary font-weight-600">
              {{ data.potIncome }} €
            </div>
            <div class="text-uppercase font-weight-700 text-muted f-14">
              Valeur de la cagnotte
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="checkPermission('wedding.setting')"
        class="d-flex justify-content-center"
      >
        <RouterLink
          :to="{ name: 'weddinglistitem-create' }"
          class="btn btn-primary px-3 rounded-pill"
        >
          Ajouter un cadeau
        </RouterLink>
      </div>

      <div class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div
          class="p-4 f-20 f-nunito font-weight-600 d-flex justify-content-between align-items-center"
          :class="{ 'border-bottom': items.length > 0 }"
        >
          <div>
            {{ view.totalItems > 0 ? view.totalItems : 'Aucun' }} {{ view.totalItems > 1 ? 'cadeaux' : 'cadeau' }}
          </div>
        </div>

        <div
          v-if="!view.totalItems"
          class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
        >
          Aucun élément à afficher
        </div>

        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="position-relative"
          :class="{ 'is-loading': deleting === item.id, 'border-bottom': index !== items.length - 1 }"
        >
          <div
            class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
            :class="{ 'pl-1': selected === item.id }"
          />
          <div
            class="d-flex justify-content-between bg-light-grey-hover align-items-center position-relative opacity-100-hover cursor-pointer transition-400"
            :class="{ 'opacity-30': selected && selected !== item.id, 'bg-light-grey': selected === item.id }"
            @click="toggleSelected(item.id)"
          >
            <div class="p-4 w-100">
              <div class="row align-items-center">
                <div class="col-12 col-sm-7 col-lg-8 col-xl-6 d-flex flex-column align-items-center align-items-sm-start">
                  <div class="text-primary font-weight-600">
                    {{ item.name }}
                  </div>
                  <div class="text-soft f-14 font-weight-600 d-flex flex-column flex-sm-row align-items-start justify-content-between w-100">
                    <div>
                      <span class="font-weight-700">{{ item.price ? item.price + ' €' : 'Gratuit' }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-5 col-lg-4 col-xl-6 d-flex justify-content-center justify-content-sm-end">
                  <span
                    v-if="item.active && item.amountFunded > 0 && item.amountAvailable > 0"
                    class="mt-3 mt-sm-0 px-2 bg-success-light rounded-pill f-14 f-nunito font-weight-600"
                  >
                    {{ item.amountFunded }}€ récoltés
                  </span>
                  <span
                    v-if="item.active && item.amountAvailable === 0"
                    class="mt-3 mt-sm-0 px-2 bg-light rounded-pill f-14 f-nunito font-weight-600"
                  >
                    Financé
                  </span>
                  <span
                    v-if="!item.active"
                    class="mt-3 mt-sm-0 px-2 bg-danger-light rounded-pill f-14 f-nunito font-weight-600"
                  >
                    Désactivé
                  </span>
                </div>
              </div>
            </div>
            <div class="align-self-stretch px-4 d-flex align-items-center">
              <i
                class="text-soft far fa-angle-right f-18 transition-300"
                :class="{ 'fa-rotate-90': selected === item.id }"
              />
            </div>
          </div>
          <BCollapse
            :id="'collapse-item-' + item.id"
            accordion="item-list"
          >
            <div
              v-if="checkPermission('wedding.setting')"
              class="p-3 d-flex flex-column flex-sm-row align-items-center justify-content-center"
            >
              <RouterLink
                v-if="checkPermission('wedding.setting')"
                :to="{name: 'weddinglistitem-update', params: { id: item.id }}"
                class="btn btn-outline-primary btn-sm rounded-pill px-3 mx-sm-2 my-2 my-sm-0"
              >
                Éditer le cadeau
              </RouterLink>

              <button
                v-if="checkPermission('wedding.setting')"
                class="btn btn-outline-danger btn-sm rounded-pill px-3 mx-sm-2 my-2 my-sm-0"
                @click.prevent="removeItem(item)"
              >
                Supprimer le cadeau
              </button>
            </div>
            <div
              v-if="item.description"
              class="p-4 border-top"
            >
              {{ item.description }}
            </div>
          </BCollapse>
        </div>
      </div>

      <Pagination
        v-if="!Array.isArray(view)"
        v-model="page"
        :view="view"
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import permission from '../../utils/permission'
import Pagination from '../../components/applicationlayout/Pagination'

export default {
  components: {
    Pagination
  },

  data () {
    return {
      loadedItems: false,
      loadedData: false,
      page: 1,
      deleting: null,
      selected: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('weddinglistitem/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('weddinglistitem/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('weddingincomedata/show', {
      data: 'retrieved',
      dataIsLoading: 'isLoading'
    })
  },

  watch: {
    selected (selected, previousSelected) {
      if (selected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-item-' + selected)
      }

      if (previousSelected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-item-' + previousSelected)
      }
    },

    data (data) {
      if (data) {
        this.loadedData = true
      }
    },

    items (items) {
      if (items) {
        this.loadedItems = true
        this.selected = null
      }
    },

    page (page) {
      // noinspection JSUnresolvedVariable
      this.getItems({ page: 'websites/' + this.user.selectedWebsite.id + '/wedding/list_items', query: { page } })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      let self = this

      this.selected = null
      this.deleting = null
      this.items.forEach((item, index) => {
        if (item.id === deleted.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
        }
      })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.getItems({ page: 'websites/' + this.user.selectedWebsite.id + '/wedding/list_items' })
    this.getData('wedding_income_datas/' + this.user.selectedWebsite.id)
  },

  beforeDestroy () {
    this.resetItems()
    this.resetDelete()
    this.resetData()
  },

  methods: {
    ...mapActions({
      getData: 'weddingincomedata/show/retrieve',
      resetData: 'weddingincomedata/show/reset',
      getItems: 'weddinglistitem/list/default',
      deleteItem: 'weddinglistitem/del/del',
      resetItems: 'weddinglistitem/list/reset',
      resetDelete: 'weddinglistitem/del/reset'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    toggleSelected (id) {
      if (this.selected === id) {
        this.selected = null

        return
      }

      this.selected = id
    },

    removeItem (item) {
      this.deleting = item.id
      this.deleteItem(item)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
