<template>
  <div class="form-group">
    <slot name="label">
      <div class="form-control-label mb-1">
        {{ label }}
      </div>
    </slot>

    <slot name="beforeInput" />

    <div
      class="input-group"
      :class="[!isValid || !isPreValid ? 'is-invalid' : '']"
    >
      <div class="input-group-prepend">
        <span
          class="input-color-value input-group-text bg-white"
          :style="colorStyle"
        />
      </div>

      <!--suppress HtmlFormInputWithoutLabel -->
      <input
        :id="id"
        v-model.trim="content"
        :class="['form-control', !isValid || !isPreValid ? 'is-invalid' : '']"
        :type="type"
        :placeholder="placeholder"
        @input="updateValue($event.target.value)"
      >

      <div class="input-group-append">
        <button
          :id="'popover' + id"
          class="btn btn-outline-success"
        >
          Sélectionner une couleur
        </button>
        <b-popover
          ref="popover"
          :target="'popover' + id"
          triggers="click"
          placement="top"
          custom-class="color-picker"
        >
          <ColorPicker
            v-click-outside="closePopover"
            class="shadow-none"
            :value="color"
            :disable-alpha="!allowTransparency"
            @input="setColor"
          />
        </b-popover>
      </div>
    </div>

    <slot name="afterInput" />
    <slot name="validation" />
    <slot name="afterValidation" />
  </div>
</template>

<script>
import ColorPicker from 'vue-color/src/components/Chrome'
import vClickOutside from 'v-click-outside'

export default {
  components: {
    ColorPicker
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  props: {
    value: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'text'
    },

    label: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    isValid: {
      type: Boolean,
      default: true
    },

    isPreValid: {
      type: Boolean,
      default: true
    },

    allowTransparency: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      content: this.value
    }
  },

  computed: {
    id () {
      // noinspection JSUnresolvedVariable
      return 'input-' + this._uid
    },

    colorStyle () {
      if (this.content) {
        return 'background-color: ' + this.content + ' !important;'
      }

      return ''
    },

    color () {
      return this.content || '#000000'
    }
  },

  watch: {
    value (value) {
      if (value !== this.content) {
        this.content = value
      }
    }
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value.trim())
    },

    closePopover () {
      this.$refs.popover.$emit('close')
    },

    setColor (color) {
      if (this.allowTransparency) {
        this.updateValue(color.hex8)

        return
      }

      this.updateValue(color.hex)
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-color-value {
    padding-left: 36px;
    border: inset 1px rgba(0,0,0,.2) !important;
  }
</style>

<style lang="scss">
  .color-picker > .popover-body {
    padding: 0 !important;
    overflow: hidden;
    border-radius: rem-calc(8);
  }
</style>
