<template>
  <div class="component-row-module border-bottom border-primary mb-4">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div class="d-inline-block w-100 border-right border-width-5 border-primary">
      <div v-if="tab === 'shape'">
        <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
          Choix de la forme
        </div>
        <div class="px-4 pt-4">
          <FormCheckbox
            v-model="content.content.fullWidth"
            label="Utiliser toute la largeur de la page"
            :is-switch="true"
            class="mb-4"
          />

          <div
            v-for="(shape, index) in shapes"
            :key="index"
            class="border rounded mb-3 px-3 py-3 d-flex align-items-center"
            :class="[ (content.content.shape === shape.ratio && content.content.rounded === shape.rounded) ? 'border-primary bg-light' : 'cursor-pointer bg-light-grey-hover' ]"
            @click.prevent="selectShape(shape)"
          >
            <i
              class="fas fa-fw f-32 text-soft mt-1 mr-3"
              :class="shape.icon"
            />
            <div class="f-20 f-roboto mw-100 text-nowrap">
              {{ shape.name }}
            </div>
          </div>

          <div v-if="content.content.shape === 'custom'">
            <div class="form-control-label">
              Unité de taille
            </div>
            <div class="mb-4">
              <BFormRadio
                v-model="content.content.unit"
                name="shape-unit"
                value="px"
              >
                Taille en pixels
              </BFormRadio>
              <BFormRadio
                v-model="content.content.unit"
                name="shape-unit"
                value="%"
              >
                Taille en pourcentage
              </BFormRadio>
              <BFormRadio
                v-model="content.content.unit"
                name="shape-unit"
                value="vh"
              >
                Taille en pourcentage par rapport à la taille de la fenêtre
              </BFormRadio>
            </div>

            <div class="form-control-label pb-2">
              Hauteur de la forme <small class="text-soft font-weight-700">{{ parseInt(content.content.height) + content.content.unit }}</small>
            </div>
            <BFormInput
              v-if="content.content.unit === 'px'"
              v-model="content.content.height"
              type="range"
              min="1"
              max="1920"
              step="1"
              class="mb-4"
            />
            <BFormInput
              v-if="content.content.unit !== 'px'"
              v-model="content.content.height"
              type="range"
              min="1"
              max="100"
              step="1"
              class="mb-4"
            />
          </div>
        </div>

        <div class="bg-light-grey px-4 mt-5 py-3 text-primary f-14 font-weight-700 text-uppercase">
          Centrage
        </div>
        <div class="px-4 pt-4">
          <FormCheckbox
            v-model="content.content.horizontalCenter"
            label="Centrer le contenu horizontalement"
            :is-switch="true"
          />
          <FormCheckbox
            v-model="content.content.verticalCenter"
            label="Centrer le contenu verticalement"
            :is-switch="true"
          />
        </div>
      </div>

      <div v-if="tab === 'section'">
        <SectionAppearance :content.sync="content" />
      </div>
    </div>
  </div>
</template>

<script>
import SectionAppearance from '../SectionAppearance'
import Tabs from '../../applicationcomponent/Tabs'
import FormCheckbox from '../../applicationform/FormCheckbox'

export default {
  name: 'ShapeModule',

  components: {
    FormCheckbox,
    Tabs,
    SectionAppearance
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tab: 'shape',
      tabs: [
        {
          name: 'shape',
          title: 'Paramètre de la forme'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ],
      shapes: [
        {
          name: 'Cercle',
          icon: 'fa-circle',
          ratio: '1by1',
          rounded: true
        },
        {
          name: 'Carré',
          icon: 'fa-square',
          ratio: '1by1',
          rounded: false
        },
        {
          name: 'Rectangle horizontal',
          icon: 'fa-rectangle-landscape',
          ratio: '4by3',
          rounded: false
        },
        {
          name: 'Rectangle vertical',
          icon: 'fa-rectangle-portrait',
          ratio: '3by4',
          rounded: false
        },
        {
          name: 'Grand rectangle horizontal',
          icon: 'fa-rectangle-wide',
          ratio: '16by9',
          rounded: false
        },
        {
          name: 'Grand rectangle vertical',
          icon: 'fa-rectangle-wide fa-rotate-90',
          ratio: '9by16',
          rounded: false
        },
        {
          name: 'Taille personnalisée',
          icon: 'fa-draw-square',
          ratio: 'custom',
          rounded: false
        }
      ]
    }
  },

  methods: {
    selectShape (shape) {
      this.content.content.shape = shape.ratio
      this.content.content.rounded = shape.rounded
    }
  }
}
</script>
