import { options } from './_default'

export const memberList = {
  type: 'member-list',
  position: null,
  variant: 'default',
  content: {
    order: 'alphabetical',
    filtered: false,
    initialFilters: {
      includeGroupLess: true,
      groups: [],
      filters: [],
    },
    allowFilter: false,
    allowedFilters: {
      filterByGroup: false,
    },
    usernameColor: '#000000',
    usernameSize: '16',
    usernameFont: 'default',
    avatarBackgroundColor: '#FFFFFF00',
    displaySocials: true,
    socialSize: 22,
    coloredSocials: true,
    displayFields: false,
    displayedFields: [],
    fullWidth: false,
    showAvatar: true,
    avatarSize: '96',
    avatarStyle: 'rounded',
    memberPerRow: 4,
    maxMemberCount: 20,
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
