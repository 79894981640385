export const onhover = {
  bind (el, binding, vnode) {
    const { value = [] } = binding
    el.addEventListener('mouseenter', () => {
      if (Array.isArray(value)) {
        value.forEach((valueItem) => {
          el.classList.add(valueItem)
        })
      }
    })

    el.addEventListener('mouseleave', () => {
      if (Array.isArray(value)) {
        value.forEach((valueItem) => {
          el.classList.remove(valueItem)
        })
      }
    })
  },

  unbind (el, binding, vnode) {
    el.removeEventListener('mouseenter', () => {})
    el.removeEventListener('mouseleave', () => {})
  }
}
