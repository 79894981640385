import { options } from './_default'

export const articleContent = {
  type: 'article-content',
  position: null,
  variant: 'default',
  content: {
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
