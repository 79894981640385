import { shape as shapeModule } from '@/config/pagebuilder/components/shape'
import { title as titleModule } from '@/config/pagebuilder/elements/title'
import { articles as articlesModule } from '@/config/pagebuilder/components/articles'

let shape = _.cloneDeep(shapeModule)
shape.position = 0
shape.section.backgroundImage = 'https://scaleweb-local.s3.eu-west-3.amazonaws.com/website/1/files/5f7f16cd79d4f393146829.png'
shape.section.backgroundImageCover = false
shape.content['shape'] = 'custom'
shape.content['height'] = '250'
shape.content['unit'] = 'px'
shape.content = JSON.stringify(shape.content)
shape = { ...shape, ...shape.section }

let title = _.cloneDeep(titleModule)
title.position = 0
title['content'].text = 'Boutique'
title['content'].fontFamily = 'josefin'
title['content'].textShadow = 'true'
title['content'].textShadowColor = '#007BFF7A'
title['content'].alignment = 'center'
title.content = JSON.stringify(title.content)
title = { ...title, ...title.section }

shape.submodules = [
  title
]

export const pageShopProductDefault = [
  shape
]
