import List from '../views/gallery/List'
import Create from '../views/gallery/Create'
import Update from '../views/gallery/Update'

export default [
  {
    path: '/galleries',
    name: 'gallery-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'gallery.list',
      title: 'Liste des galeries'
    }
  },
  {
    path: '/galleries/create',
    name: 'gallery-create',
    component: Create,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'gallery.create',
      title: 'Ajouter une galerie'
    }
  },
  {
    path: '/galleries/:id',
    name: 'gallery-update',
    component: Update,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'gallery.update',
      title: 'Editer une galerie'
    }
  }
]
