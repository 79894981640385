<template>
  <div class="form-page">
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <Tabs
        v-model="tab"
        :tabs="tabs"
      />
    </div>

    <div
      v-show="tab === 'informations'"
      class="bg-white shadow-sm rounded-lg my-3 overflow-hidden"
    >
      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Titre et informations
        </div>

        <FormInput
          v-model="item.title"
          :is-valid="isValid('title')"
          :is-pre-valid="isPreValid('title')"
          label="Titre de la page"
          type="text"
          @input="updateValidation('title')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('title')"
              class="invalid-feedback"
            >
              {{ violations.title }}
            </div>
            <div
              v-if="!isPreValid('title')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.title.required">
                Le nom de la page doit être indiqué
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.title.minLength">
                Le nom de la page doit contenir au moins {{ $v.item.title.$params.minLength.min }} caractères
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.title.maxLength">
                Le nom de la page doit contenir au maximum {{ $v.item.title.$params.maxLength.max }} caractères
              </div>
            </div>
          </template>
        </FormInput>

        <FormTextarea
          v-model="item.description"
          :is-valid="isValid('description')"
          :is-pre-valid="isPreValid('description')"
          label="Description de la page"
          placeholder="La description de la page peut contenir jusqu'à 200 caractères"
          class="mb-0"
          @input="updateValidation('description')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('description')"
              class="invalid-feedback"
            >
              {{ violations.description }}
            </div>
            <div
              v-if="!isPreValid('description')"
              class="invalid-feedback"
            >
              <div v-if="!$v.item.description.minLength">
                La description de la page doit contenir au moins {{ $v.item.description.$params.minLength.min }} caractères
              </div>
              <div v-if="!$v.item.description.maxLength">
                La description de la page doit contenir au maximum {{ $v.item.description.$params.maxLength.max }} caractères
              </div>
            </div>
          </template>
        </FormTextarea>
      </div>

      <div
        v-if="item.fixed && !publicPages.includes(initialItem.slug)"
        class="p-4 bg-light-grey"
      >
        <div
          v-if="initialItem.slug"
          class="f-14 font-weight-600 text-center"
        >
          La page est visible à l'adresse :
          <a
            :href="'//' + user.selectedWebsite.url + '/' + initialItem.slug"
            target="_blank"
          >
            {{ user.selectedWebsite.url + '/' + initialItem.slug }}
          </a>
        </div>
      </div>

      <div
        v-if="!item.fixed"
        class="p-4 bg-light-grey"
      >
        <div
          v-if="initialItem.slug"
          class="f-14 font-weight-600 text-center"
        >
          La page est actuellement visible à l'adresse :
          <a
            :href="'//' + user.selectedWebsite.url + '/page/' + initialItem.slug"
            target="_blank"
          >
            {{ user.selectedWebsite.url + '/page/' + initialItem.slug }}
          </a>
        </div>
        <div
          v-if="initialItem.slug !== item.slug"
          class="f-14 font-weight-600 text-center"
        >
          Après enregistrement l'adresse de la page sera : <span class="text-success">{{ user.selectedWebsite.url + '/page/' + item.slug }}</span>
        </div>
        <div
          v-if="!editUrl"
          class="text-center pt-3"
        >
          <button
            class="btn btn-sm btn-outline-success px-3 rounded-pill"
            @click.prevent="editUrl = true"
          >
            Modifier l'adresse de la page
          </button>
        </div>
        <div
          v-if="editUrl"
          class="pt-3"
        >
          <FormInput
            v-model="item.slug"
            :is-valid="isValid('slug')"
            :is-pre-valid="isPreValid('slug')"
            label="Adresse de la page"
            type="text"
            @keypress.enter.native="editUrl = false"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('slug')"
                class="invalid-feedback"
              >
                {{ violations.slug }}
              </div>
              <div
                v-if="!isPreValid('slug')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.slug.required">
                  L'adresse de la page doit être indiquée
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.slug.minLength">
                  L'adresse de la page doit contenir au moins {{ $v.item.slug.$params.minLength.min }} caractères
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.slug.maxLength">
                  L'adresse de la page doit contenir au maximum {{ $v.item.slug.$params.maxLength.max }} caractères
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.slug.slug">
                  L'adresse de la page ne peut contenir des lettres, des chiffres ou les caractères _ et -
                </div>
              </div>
            </template>
          </FormInput>
        </div>
      </div>

      <div
        v-if="!publicPages.includes(item.slug)"
        class="p-4"
      >
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Paramètres de confidentialité
        </div>

        <FormCheckbox
          v-model="item.private"
          :is-switch="true"
          :is-disabled="!user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro')"
          :has-addon="user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro')"
          label="Page visible uniquement pour les membres du site"
          class="mb-2"
          addon="Premium"
        />

        <FormCheckbox
          v-model="item.privateForGroups"
          :is-switch="true"
          :is-disabled="!user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro') || !item.private"
          :has-addon="user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro')"
          label="Article visible uniquement pour certains groupes de membres"
          class="mb-0"
          addon="Premium"
        />

        <div
          v-if="item.privateForGroups && item.private"
          class="bg-light-grey p-4 rounded-lg mt-3"
        >
          <div class="form-control-label mb-2">
            Groupes autorisés à voir cet article
          </div>

          <BFormCheckboxGroup
            v-model="item.authorizedGroups"
            :options="groupOptions"
            stacked
            switches
          />
        </div>
      </div>
    </div>

    <PageBuilderForm
      v-if="unserialized"
      v-show="tab === 'content'"
      :content.sync="item.modules"
    />

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer la page
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormInput from '../applicationform/FormInput'
import FormTextarea from '../applicationform/FormTextarea'
import PageBuilderForm from '../pagebuilder/PageBuilder'
import { minLength, maxLength, required, helpers } from 'vuelidate/lib/validators'
import slugify from 'slugify'
import Tabs from '../applicationcomponent/Tabs'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import FormCheckbox from '@/components/applicationform/FormCheckbox'

const slug = helpers.regex('slug', /^[a-zA-Z0-9_-]*$/)

export default {
  components: {
    FormCheckbox,
    FormInput,
    FormTextarea,
    PageBuilderForm,
    Tabs
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      unserialized: false,
      tab: 'informations',
      tabs: [
        {
          name: 'informations',
          title: 'Titre et paramètres'
        },
        {
          name: 'content',
          title: 'Contenu de la page'
        }
      ],
      editUrl: false,
      initialItem: {},
      publicPages: [
        'home',
        'article'
      ]
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('membergroup/list', {
      error: 'error',
      groups: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    groupOptions () {
      let groupsList = []

      this.groups.forEach((group) => {
        groupsList.push(
          {
            text: group.name,
            value: group['@id']
          }
        )
      })

      return groupsList
    },

    item () {
      return this.initialValues || this.values
    },

    title () {
      return this.item.title
    },

    violations () {
      return this.errors || {}
    }
  },

  watch: {
    tab (tab) {
      if (tab === 'content') {
        this.$root.$emit('layout', 'fluid')
        this.$root.$emit('sidebar', false)
        this.$root.$emit('editor', true)

        return
      }

      this.$root.$emit('layout', 'default')
      this.$root.$emit('sidebar', true)
      this.$root.$emit('editor', false)
    },

    title (title, previousTitle) {
      if (this.item.fixed) {
        return
      }

      let slug = null

      if (previousTitle) {
        slug = slugify(previousTitle, { remove: /[^a-zA-Z0-9\s_-]/g, lower: true })
      }

      if (!slug || slug === this.item.slug) {
        this.item.slug = slugify(title, { remove: /[^a-zA-Z0-9\s_-]/g, lower: true })
        this.updateValidation('slug')
      }
    }
  },

  mounted () {
    let self = this

    this.$root.$emit('layout', 'default')
    this.$root.$emit('sidebar', true)
    this.$root.$emit('editor', false)

    this.getGroups({ page: 'websites/' + this.user.selectedWebsite.id + '/member_groups?pagination=false' })

    this.item.modules.forEach((module) => {
      self.unserializeModule(module)
    })

    if (this.item.publication) {
      this.item.publication = new Date(this.item.publication)
    }

    if (!this.item.slug) {
      this.editUrl = true
    }

    // eslint-disable-next-line no-undef
    this.initialItem = _.cloneDeep(this.item)

    this.updateBuilderType('page')

    if (this.item.fixed) {
      this.updatePageType(this.item.slug)
    }

    this.unserialized = true
  },

  beforeDestroy () {
    this.$root.$emit('layout', 'default')
    this.$root.$emit('sidebar', true)
    this.$root.$emit('editor', false)
    this.updateReset()
    this.resetGroups()
  },

  methods: {
    ...mapActions({
      updateModule: 'pagebuilder/update/updateModule',
      updateBuilderType: 'pagebuilder/update/updateBuilderType',
      updatePageType: 'pagebuilder/update/updatePageType',
      updateReset: 'pagebuilder/update/reset',
      getGroups: 'membergroup/list/default',
      resetGroups: 'membergroup/list/reset',
    }),

    unserializeModule (module) {
      let self = this

      module.content = JSON.parse(module.content)

      if (module.submodules && module.submodules.length > 0) {
        module.submodules.forEach((child) => {
          self.unserializeModule(child)
        })
      }
    },

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations () {
    if (this.item.fixed) {
      return {
        item: {
          title: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(50)
          },

          description: {
            minLength: minLength(3),
            maxLength: maxLength(200)
          }
        }
      }
    } else {
      return {
        item: {
          title: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(50)
          },

          description: {
            minLength: minLength(3),
            maxLength: maxLength(200)
          },

          slug: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(50),
            slug
          }
        }
      }
    }
  }
}
</script>
