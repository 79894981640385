<template>
  <div class="component-member-socials-preview">
    <div
      :class="[
        'mt-' + content.section.marginTop.replace('-', 'n'),
        'mb-' + content.section.marginBottom.replace('-', 'n')
      ]"
    >
      <div
        :class="[
          'ml-' + content.section.marginLeft.replace('-', 'n'),
          'mr-' + content.section.marginRight.replace('-', 'n'),
          'pt-' + content.section.paddingTop,
          'pb-' + content.section.paddingBottom,
          'pl-' + content.section.paddingLeft,
          'pr-' + content.section.paddingRight,
          moduleClass
        ]"
        :style="moduleStyle"
      >
        <div :class="{ 'container': hasContainer }">
          <div
            class="d-flex"
            :class="{
              'flex-column': content.content.vertical,
              'justify-content-center': content.content.align === 'center',
              'justify-content-end': content.content.align === 'end',
              'align-items-center': content.content.align === 'center' && content.content.vertical,
              'align-items-end': content.content.align === 'end' && content.content.vertical,
              'colored-icons': content.content.coloredIcons,
            }"
          >
            <div class="mr-3 d-flex align-items-center">
              <i
                class="fab fa-facebook-square"
                :class="'f-' + content.content.size"
              />
              <span
                v-if="content.content.displayName"
                class="pl-2 font-weight-600"
              >Facebook</span>
            </div>

            <div class="mr-3 d-flex align-items-center">
              <i
                class="fab fa-instagram-square"
                :class="'f-' + content.content.size"
              />
              <span
                v-if="content.content.displayName"
                class="pl-2 font-weight-600"
              >Instagram</span>
            </div>

            <div class="mr-3 d-flex align-items-center">
              <i
                class="fab fa-twitter-square"
                :class="'f-' + content.content.size"
              />
              <span
                v-if="content.content.displayName"
                class="pl-2 font-weight-600"
              >Twitter</span>
            </div>

            <div class="mr-3 d-flex align-items-center">
              <i
                class="fab fa-linkedin"
                :class="'f-' + content.content.size"
              />
              <span
                v-if="content.content.displayName"
                class="pl-2 font-weight-600"
              >LinkedIn</span>
            </div>

            <div class="mr-3 d-flex align-items-center">
              <i
                class="fab fa-youtube-square"
                :class="'f-' + content.content.size"
              />
              <span
                v-if="content.content.displayName"
                class="pl-2 font-weight-600"
              >Youtube</span>
            </div>

            <div class="mr-3 d-flex align-items-center">
              <i
                class="fab fa-twitch"
                :class="'f-' + content.content.size"
              />
              <span
                v-if="content.content.displayName"
                class="pl-2 font-weight-600"
              >Twitch</span>
            </div>

            <div class="mr-3 d-flex align-items-center">
              <i
                class="fas fa-link"
                :class="'f-' + content.content.size"
              />
              <span
                v-if="content.content.displayName"
                class="pl-2 font-weight-600"
              >Site internet</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px;'
      }

      return style
    }
  }
}
</script>

<style lang="scss" scoped>
.colored-icons {
  .fa-facebook-square {
    color: #3b5998;
  }

  .fa-instagram-square {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fa-twitter-square {
    color: #00acee;
  }

  .fa-linkedin {
    color: #0e76a8;
  }

  .fa-youtube-square {
    color: #FF0000;
  }

  .fa-twitch {
    color: #6441a5;
  }
}
</style>
