<template>
  <div
    class="form-member"
    :class="{ 'is-loading': !loadedSettings || avatarUploading }"
  >
    <BModal
      id="select-avatar"
      content-class="overflow-hidden"
      body-class="p-0"
      hide-header
      hide-footer
    >
      <div :class="{ 'is-loading': avatarUploading }">
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14 border-bottom p-4 text-center">
          Choix de l'avatar
        </div>

        <div class="p-4 mb-3">
          <div class="embed-responsive embed-responsive-1by1 avatar-preview rounded-circle shadow border overflow-hidden">
            <div
              class="embed-responsive-item rounded-circle"
              :style="'background-image: url(' + avatar + ')'"
            >
              <Croppa
                v-model="croppa"
                :width="150"
                :height="150"
                placeholder="''"
                placeholder-color="transparent"
                :placeholder-font-size="16"
                canvas-color="transparent"
                :show-remove-button="false"
                remove-button-color="red"
                :remove-button-size="20"
                :show-loading="true"
                :loading-size="50"
                :loading-color="'#606060'"
                :prevent-white-space="true"
              >
              </Croppa>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <button
            class="btn btn-sm btn-outline-danger rounded-pill mx-3"
            :disabled="((!croppaHasImage && !item.avatar) || !item.avatar) && !avatarBlob"
            @click.prevent="removeAvatar"
          >
            Supprimer l'avatar
          </button>

          <button
            class="btn btn-sm btn-outline-success rounded-pill mx-3"
            @click.prevent="croppa.chooseFile()"
          >
            Choisir une image
          </button>
        </div>

        <div class="p-4 text-center border-top mt-4">
          <button
            class="btn btn-sm btn-primary rounded-pill"
            :disabled="!croppaHasImage"
            @click.prevent="updateAvatar"
          >
            Sélectionner cet avatar
          </button>
        </div>
      </div>
    </BModal>

    <div class="bg-white shadow-sm rounded-lg my-3 overflow-hidden">
      <div
        v-if="!initialEmail"
        class="bg-light-grey p-4 font-weight-600 f-14"
      >
       Si une adresse email est définie un email sera envoyé au membre après l'enregistrement afin qu'il puisse définir son mot de passe et se connecter.
      </div>

      <div
        v-if="websiteSetting && !websiteSetting.allowMember"
        class="bg-danger-light p-4 font-weight-600 f-14 text-center"
      >
        Les membres ne peuvent pas se connecter sur le site.

        <div class="pt-2">
          <RouterLink
            :to="{ name: 'website-settings-settings' }"
            class="btn btn-outline-danger btn-sm px-3 rounded-pill"
          >
            Modifier les paramètres
          </RouterLink>
        </div>
      </div>

      <div class="p-4">
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
          Informations de l'utilisateur
        </div>

        <div class="row">
          <div class="col-3 text-center">
            <div class="form-control-label text-center mb-2">
              Avatar
            </div>
            <div
              class="embed-responsive embed-responsive-1by1 avatar-preview cursor-pointer rounded-circle shadow border overflow-hidden"
              @click.prevent="chooseAvatar"
            >
              <div
                class="embed-responsive-item rounded-circle"
                :style="'background-image: url(' + avatar + ')'"
              />
            </div>
          </div>

          <div class="col-9">
            <FormInput
              v-model="item.username"
              :is-valid="isValid('username')"
              :is-pre-valid="isPreValid('username')"
              label="Nom d'utilisateur"
              type="text"
              @input="updateValidation('username')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('username')"
                  class="invalid-feedback"
                >
                  {{ violations.username }}
                </div>
                <div
                  v-if="!isPreValid('username')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.username.required">
                    Le nom du membre doit être indiqué
                  </div>
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.username.minLength">
                    Le nom du membre doit contenir au moins {{ $v.item.username.$params.minLength.min }} caractères
                  </div>
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.username.maxLength">
                    Le nom du membre doit contenir au maximum {{ $v.item.username.$params.maxLength.max }} caractères
                  </div>
                </div>
              </template>
            </FormInput>

            <FormInput
              v-model="item.email"
              :is-valid="isValid('email')"
              :is-pre-valid="isPreValid('email')"
              :is-disabled="!!item.id && !!initialEmail"
              label="Adresse email"
              type="email"
              class="mb-0"
              @input="updateValidation('email')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('email')"
                  class="invalid-feedback"
                >
                  {{ violations.email }}
                </div>
                <div
                  v-if="!isPreValid('email')"
                  class="invalid-feedback"
                >
                  <div v-if="!$v.item.email.email">
                    Cette adresse n'est pas valide
                  </div>
                </div>
              </template>
            </FormInput>
          </div>
        </div>

        <div class="mt-4">
          <FormTextarea
            v-model="item.presentation"
            :is-valid="isValid('presentation')"
            :rows="3"
            label="Présentation"
          >
            <template v-slot:beforeInput>
              <div class="text-muted f-14 mt-n2 mb-1">
                Vous pouvez utiliser du markdown : <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">guide markdown</a>
              </div>
            </template>
            <template v-slot:validation>
              <div
                v-if="!isValid('presentation')"
                class="invalid-feedback"
              >
                {{ violations.presentation }}
              </div>
            </template>
          </FormTextarea>
        </div>

        <div
          v-if="groupOptions.length > 0"
          class="mt-5 mb-3 text-primary font-weight-700 text-uppercase f-14"
        >
          Groupes de l'utilisateur
        </div>

        <BFormCheckboxGroup
          v-model="item.groups"
          :options="groupOptions"
          stacked
          switches
        />

        <div class="mt-5 mb-3 text-primary font-weight-700 text-uppercase f-14">
          Réseaux sociaux
        </div>

        <FormInput
          v-model="item.socialFacebook"
          :is-valid="isValid('socialFacebook')"
          :is-pre-valid="isPreValid('socialFacebook')"
          label="Compte Facebook"
          type="text"
          @input="updateValidation('socialFacebook')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('socialFacebook')"
              class="invalid-feedback"
            >
              {{ violations.socialFacebook }}
            </div>
            <div
              v-if="!isPreValid('socialFacebook')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.socialFacebook.url">
                Cette adresse n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>

        <FormInput
          v-model="item.socialInstagram"
          :is-valid="isValid('socialInstagram')"
          :is-pre-valid="isPreValid('socialInstagram')"
          label="Compte Instagram"
          type="text"
          @input="updateValidation('socialInstagram')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('socialInstagram')"
              class="invalid-feedback"
            >
              {{ violations.socialInstagram }}
            </div>
            <div
              v-if="!isPreValid('socialInstagram')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.socialInstagram.url">
                Cette adresse n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>

        <FormInput
          v-model="item.socialTwitter"
          :is-valid="isValid('socialTwitter')"
          :is-pre-valid="isPreValid('socialTwitter')"
          label="Compte Twitter"
          type="text"
          @input="updateValidation('socialTwitter')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('socialTwitter')"
              class="invalid-feedback"
            >
              {{ violations.socialTwitter }}
            </div>
            <div
              v-if="!isPreValid('socialTwitter')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.socialTwitter.url">
                Cette adresse n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>

        <FormInput
          v-model="item.socialLinkedIn"
          :is-valid="isValid('socialLinkedIn')"
          :is-pre-valid="isPreValid('socialLinkedIn')"
          label="Compte LinkedIn"
          type="text"
          @input="updateValidation('socialLinkedIn')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('socialLinkedIn')"
              class="invalid-feedback"
            >
              {{ violations.socialLinkedIn }}
            </div>
            <div
              v-if="!isPreValid('socialLinkedIn')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.socialLinkedIn.url">
                Cette adresse n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>

        <FormInput
          v-model="item.socialYoutube"
          :is-valid="isValid('socialYoutube')"
          :is-pre-valid="isPreValid('socialYoutube')"
          label="Compte Youtube"
          type="text"
          @input="updateValidation('socialYoutube')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('socialYoutube')"
              class="invalid-feedback"
            >
              {{ violations.socialYoutube }}
            </div>
            <div
              v-if="!isPreValid('socialYoutube')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.socialYoutube.url">
                Cette adresse n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>

        <FormInput
          v-model="item.socialTwitch"
          :is-valid="isValid('socialTwitch')"
          :is-pre-valid="isPreValid('socialTwitch')"
          label="Compte Twitch"
          type="text"
          @input="updateValidation('socialTwitch')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('socialTwitch')"
              class="invalid-feedback"
            >
              {{ violations.socialLinkedIn }}
            </div>
            <div
              v-if="!isPreValid('socialTwitch')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.socialTwitch.url">
                Cette adresse n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>

        <FormInput
          v-model="item.socialWebsite"
          :is-valid="isValid('socialWebsite')"
          :is-pre-valid="isPreValid('socialWebsite')"
          label="Site internet"
          type="text"
          @input="updateValidation('socialWebsite')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('socialWebsite')"
              class="invalid-feedback"
            >
              {{ violations.socialWebsite }}
            </div>
            <div
              v-if="!isPreValid('socialWebsite')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.socialWebsite.url">
                Cette adresse n'est pas valide
              </div>
            </div>
          </template>
        </FormInput>
      </div>

      <div
        v-if="fields.length > 0"
        class="p-4 bg-light-grey"
      >
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
          Champs personnalisés
        </div>

        <FieldItem
          v-for="(field, index) in fields"
          :key="index"
          :field="field"
        />
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import Croppa from 'vue-croppa'
import FormInput from '../applicationform/FormInput'
import { maxLength, minLength, required, email, url } from 'vuelidate/lib/validators'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { ENTRYPOINT, S3_ENTRYPOINT } from '@/config/entrypoint'
import FieldItem from '@/components/member/FieldItem'
import FormTextarea from '@/components/applicationform/FormTextarea'

export default {
  components: {
    FormTextarea,
    FieldItem,
    Croppa: Croppa.component,
    FormInput
  },

  props: {
    handleCreated: {
      type: Function,
      default: () => {}
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      initialEmail: this.values.email,
      APP_API_ENTRYPOINT: ENTRYPOINT,
      S3_ENTRYPOINT,
      loadedSettings: false,
      croppa: null,
      avatarUploading: false,
      avatarUploadProgress: 0,
      avatarUploadErrors: null,
      avatarBlob: null,
      avatarBlobUrl: null,
      avatarUploadIsLoading: false,
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('websitesetting/show', {
      websiteSetting: 'retrieved',
      websiteSettingIsLoading: 'isLoading'
    }),

    ...mapFields('membergroup/list', {
      error: 'error',
      groups: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('member/create', {
      createError: 'error',
      createIsLoading: 'isLoading',
      created: 'created',
      createViolations: 'violations'
    }),

    ...mapFields('member/update', {
      updateIsLoading: 'isLoading',
      updateError: 'error',
      updated: 'updated',
      updateViolations: 'violations'
    }),

    fields () {
      let fields = []
      let self = this

      if (this.websiteSetting) {
        this.websiteSetting.memberFields.forEach((fieldItem) => {
          if (fieldItem.active || fieldItem.activeForGroups.filter(group => self.item.groups.includes(group)).length > 0) {
            let existingField = self.getMemberField(fieldItem)
            if (!existingField) {
              existingField = {
                checked: null,
                field: fieldItem['@id'],
                value: null
              }

              self.item.fields.push(existingField)
            }

            fields.push({
              field: fieldItem,
              value: existingField
            })
          }
        })
      }

      return fields
    },

    groupOptions () {
      let groupsList = []

      this.groups.forEach((group) => {
        groupsList.push(
          {
            text: group.name,
            value: group['@id']
          }
        )
      })

      return groupsList
    },

    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    },

    avatar () {
      if (!this.item.id && this.avatarBlobUrl) {
        return this.avatarBlobUrl
      }

      return this.item.avatar ? S3_ENTRYPOINT + this.item.avatar : this.APP_API_ENTRYPOINT + '/assets/img/avatar.png'
    },

    croppaHasImage () {
      return this.croppa && this.croppa.hasImage()
    }
  },

  watch: {
    created (created) {
      if (created) {
        if (!this.avatarBlob) {
          this.handleCreated()

          return
        }

        this.avatarUploading = true
        this.item.id = created.id
        this.uploadAvatar(this.avatarBlob)
      }
    },

    updated (updated) {
      if (updated) {
        this.$bvModal.hide('select-avatar')
        this.item.avatar = updated.avatar
        this.initialEmail = updated.email
      }
    },

    websiteSetting (settings) {
      if (settings) {
        this.loadedSettings = true
      }
    }
  },

  mounted () {
    this.getWebsiteSetting('websites/' + this.user.selectedWebsite.id + '/setting')
    this.getGroups({ page: 'websites/' + this.user.selectedWebsite.id + '/member_groups?pagination=false' })
  },

  beforeDestroy () {
    this.resetSetting()
    this.resetGroups()
  },

  methods: {
    ...mapActions({
      resetSetting: 'websitesetting/show/reset',
      getWebsiteSetting: 'websitesetting/show/retrieve',
      getGroups: 'membergroup/list/default',
      resetGroups: 'membergroup/list/reset',
      update: 'member/update/update'
    }),

    getMemberField(field) {
      let fieldItem = null

      this.item.fields.forEach((item) => {
        if (item.field === field['@id']) {
          fieldItem = item
        }
      })

      return fieldItem
    },

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    chooseAvatar () {
      this.$bvModal.show('select-avatar')
      this.$nextTick(() => {
        this.croppa.chooseFile()
      })
    },

    removeAvatar () {
      this.croppa.remove()
      this.avatarBlob = null
      this.avatarBlobUrl = null

      this.update({
        id: 'members/delete-avatar/' + this.item.id,
        payload: {}
      })
    },

    updateAvatar () {
      this.avatarUploading = true
      this.avatarUploadProgress = 0
      this.avatarUploadErrors = null

      let self = this
      this.croppa.generateBlob((blob) => {
        if (!self.item.id) {
          self.avatarBlob = blob
          self.avatarBlobUrl = URL.createObjectURL(blob)
          self.avatarUploading = false
          self.$bvModal.hide('select-avatar')

          return
        }

        self.uploadAvatar(blob)
      }, 'image/*', 1)
    },

    uploadAvatar (blob) {
      let self = this
      let fd = new FormData()
      fd.append('file', blob)
      fd.append('memberId', self.item.id)

      let request = new XMLHttpRequest()
      // noinspection JSCheckFunctionSignatures
      request.open('POST', new URL('api/files/avatar', ENTRYPOINT), true)
      request.setRequestHeader('Authorization', 'Bearer ' + localStorage.token)

      request.upload.onprogress = function (e) {
        if (e.lengthComputable) {
          self.avatarUploadProgress = parseInt(((e.loaded / e.total) * 100).toString())
        }
      }

      request.onerror = function () {
        self.avatarUploadErrors = ['Une erreur est intervenue, merci de réessayer plus tard']

        this.avatarUploading = false
      }

      request.onload = function () {
        if (this.status === 201) {
          self.handleAvatar(JSON.parse(this.response))
        }

        if (this.status === 400) {
          let errors = []

          if (!this.response.violations) {
            self.avatarUploadErrors = ['Une erreur est intervenue, merci de réessayer plus tard']

            return
          }

          this.response.violations.forEach((violation) => {
            errors.push(violation.message)
          })

          self.avatarUploadErrors = errors
        }

        this.avatarUploading = false
      }

      request.send(fd)
    },

    handleAvatar(response) {
      this.item.avatar = response.contentUrl
      this.avatarUploading = false
      this.$bvModal.hide('select-avatar')

      if (this.avatarBlob) {
        this.handleCreated()
      }
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      if (!this.item.email) {
        this.item.email = null
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      username: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },

      email: {
        email
      },

      socialFacebook: {
        url
      },

      socialInstagram: {
        url
      },

      socialTwitter: {
        url
      },

      socialLinkedIn: {
        url
      },

      socialYoutube: {
        url
      },

      socialTwitch: {
        url
      },

      socialWebsite: {
        url
      }
    }
  }
}
</script>

<style lang="scss">
  .croppa-container {
    position: relative;
    cursor: pointer;

    & .icon-remove {
      top: auto !important;
      bottom: 15px !important;
      right: calc(50% - 10px) !important;
      position: absolute;
      border-radius: 50%;
      background-color: #FFF;
    }
  }

  .avatar-preview {
    max-width: 150px;
    margin: auto;

    & .embed-responsive-item {
      z-index: 2;
      background-position: center;
      background-size: cover;
    }
  }
</style>
