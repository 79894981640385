import Home from '../views/dashboard/Home'

export default [
  {
    path: '/',
    name: 'dashboard-home',
    component: Home,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: false,
      title: 'Tableau de bord'
    }
  }
]
