<template>
  <div
    v-click-outside="closeAutocomplete"
    class="component-form-autocomplete position-relative"
    @focusin="focus = true"
    @keydown.up.prevent="decrementFocused"
    @keydown.esc.prevent="editing = false"
    @keydown.down.prevent="incrementFocused"
    @keydown.enter.prevent="selectFocused"
  >
    <FormInput
      v-model="content"
      :type="type"
      :label="label"
      :placeholder="placeholder"
      :is-valid="isValid"
      :is-pre-valid="isPreValid"
      :is-disabled="isDisabled"
      :show-input="editing"
      :has-addon="hasAddon"
      :addon="addon"
    >
      <template slot="beforeInput">
        <slot name="beforeInput">
          <div
            v-if="!(!value && editing)"
            class="mb-3 d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center"
          >
            <div
              v-if="value"
              class="d-flex d-md-inline-flex flex-column flex-md-row align-items-center px-3 py-2 border rounded mb-3 mb-md-0"
            >
              <div class="d-inline-flex flex-column mt-2 mt-md-0 text-center text-md-left">
                <div class="font-weight-600 f-14">
                  {{ value.username }}
                </div>
                <div class="f-12 font-weight-600 text-muted">
                  {{ value.email }}
                </div>
              </div>
              <div class="mt-3 mt-md-0 mb-2 mb-md-0 ml-0 ml-md-4">
                <button
                  class="btn btn-outline-danger btn-sm rounded-pill px-3"
                  :class="{ 'disabled': isDisabled }"
                  :disabled="isDisabled"
                  @click.prevent="selectItem(null)"
                >
                  Supprimer l'auteur
                </button>
              </div>
            </div>

            <button
              v-if="!editing"
              class="btn btn-outline-success btn-sm rounded-pill px-3 align-self-center"
              :class="{ 'disabled': isDisabled }"
              :disabled="isDisabled"
              @click.prevent="openEditing"
            >
              Choisir un auteur
            </button>
          </div>
        </slot>
      </template>

      <template slot="afterInput">
        <slot name="afterInput" />
      </template>

      <template slot="validation">
        <slot name="validation" />
      </template>

      <template slot="afterValidation">
        <slot name="afterValidation" />
      </template>
    </FormInput>

    <div
      v-if="focus && editing"
      class="d-inline-flex flex-column position-absolute top-100 mt-n2 left-0 bg-white border rounded-lg shadow z-index-10 py-2"
    >
      <div
        v-if="isLoading"
        class="p-5 text-muted f-14 font-weight-600"
      >
        Chargement
      </div>
      <div v-if="!isLoading">
        <a
          v-for="(item, index) in items"
          :key="item.id"
          href="#"
          class="d-block px-3 py-1 bg-light-grey-hover cursor-pointer f-16 font-weight-600 d-flex text-decoration-none text-reset align-items-center"
          :class="{ 'bg-light-grey': focused === index }"
          @click.prevent="selectItem(item)"
        >
          <i class="fas fa-angle-right mr-2 f-13 text-soft" /> {{ item.username }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from './FormInput'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import vClickOutside from 'v-click-outside'

export default {
  components: {
    FormInput
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  props: {
    value: {
      type: Object,
      default: () => {}
    },

    type: {
      type: String,
      default: 'text'
    },

    label: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    isValid: {
      type: Boolean,
      default: true
    },

    isPreValid: {
      type: Boolean,
      default: true
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    hasAddon: {
      type: Boolean,
      default: true
    },

    addon: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      content: null,
      focus: false,
      focused: null,
      controller: null,
      selecting: false,
      editing: false
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('member/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view',
      abortController: 'abortController'
    }),

    id () {
      // noinspection JSUnresolvedVariable
      return 'input-' + this._uid
    }
  },

  watch: {
    items () {
      this.focused = 0
    },

    editing (editing) {
      if (editing && !this.content) {
        this.focus = true
        this.getMembers({ page: 'websites/' + this.user.selectedWebsite.id + '/members' })
      }
    },

    content (content, previousContent) {
      if (content.length < 3 && (!previousContent || previousContent.length >= 3)) {
        this.focus = true
        this.getMembers({ page: 'websites/' + this.user.selectedWebsite.id + '/members' })
      }

      if (content.length >= 3 && !this.selecting) {
        this.focus = true
        this.getMembers({ page: 'websites/' + this.user.selectedWebsite.id + '/members', query: { username: content } })
      }

      this.selecting = false
    }
  },

  beforeDestroy () {
    this.resetMembers()
  },

  methods: {
    ...mapActions({
      getMembers: 'member/list/default',
      resetMembers: 'member/list/reset'
    }),

    decrementFocused () {
      if (this.focused === 0) {
        return
      }

      this.focused--
    },

    incrementFocused () {
      if (this.focused + 1 === this.items.length) {
        return
      }

      this.focused++
    },

    updateValue (value) {
      this.$emit('input', value.trim())
    },

    openEditing () {
      this.editing = true
      this.focus = false
    },

    closeAutocomplete () {
      this.focus = false
    },

    selectFocused () {
      if (this.items[this.focused]) {
        this.selectItem(this.items[this.focused])
      }
    },

    selectItem (item) {
      this.selecting = true

      if (item) {
        this.content = item.username
      }

      this.$emit('input', item)

      this.focus = false
      this.editing = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
