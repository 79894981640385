<template>
  <div class="component-article-title-preview">
    <div :class="{ 'container': hasContainer }">
      <div
        :class="[
          'page-editor-preview',
          'mt-' + content.section.marginTop.replace('-', 'n'),
          'mb-' + content.section.marginBottom.replace('-', 'n'),
          'ml-' + content.section.marginLeft.replace('-', 'n'),
          'mr-' + content.section.marginRight.replace('-', 'n'),
          'pt-' + content.section.paddingTop,
          'pb-' + content.section.paddingBottom,
          'pl-' + content.section.paddingLeft,
          'pr-' + content.section.paddingRight,
          moduleClass
        ]"
        :style="moduleStyle"
      >
        <h1
          v-if="content.content.fontSize === '1'"
          class="position-relative mb-0"
          :class="titleClass"
          :style="titleStyle"
        >
          <span class="position-relative">
            <span class="z-index-2 position-relative">
              Titre de l'article
            </span>
            <span
              v-if="content.content.background"
              class="z-index-1 position-absolute w-100"
              :style="backgroundStyle"
            />
          </span>
        </h1>

        <h2
          v-if="content.content.fontSize === '2'"
          class="position-relative mb-0"
          :class="titleClass"
          :style="titleStyle"
        >
          <span class="position-relative">
            <span class="z-index-2 position-relative">
              Titre de l'article
            </span>
            <span
              v-if="content.content.background"
              class="z-index-1 position-absolute w-100"
              :style="backgroundStyle"
            />
          </span>
        </h2>

        <h3
          v-if="content.content.fontSize === '3'"
          class="position-relative mb-0"
          :class="titleClass"
          :style="titleStyle"
        >
          <span class="position-relative">
            <span class="z-index-2 position-relative">
              Titre de l'article
            </span>
            <span
              v-if="content.content.background"
              class="z-index-1 position-absolute w-100"
              :style="backgroundStyle"
            />
          </span>
        </h3>

        <h4
          v-if="content.content.fontSize === '4'"
          class="position-relative mb-0"
          :class="titleClass"
          :style="titleStyle"
        >
          <span class="position-relative">
            <span class="z-index-2 position-relative">
              Titre de l'article
            </span>
            <span
              v-if="content.content.background"
              class="z-index-1 position-absolute w-100"
              :style="backgroundStyle"
            />
          </span>
        </h4>

        <h5
          v-if="content.content.fontSize === '5'"
          class="position-relative mb-0"
          :class="titleClass"
          :style="titleStyle"
        >
          <span class="position-relative">
            <span class="z-index-2 position-relative">
              Titre de l'article
            </span>
            <span
              v-if="content.content.background"
              class="z-index-1 position-absolute w-100"
              :style="backgroundStyle"
            />
          </span>
        </h5>

        <h6
          v-if="content.content.fontSize === '6'"
          class="position-relative mb-0"
          :class="titleClass"
          :style="titleStyle"
        >
          <span class="position-relative">
            <span class="z-index-2 position-relative">
              Titre de l'article
            </span>
            <span
              v-if="content.content.background"
              class="z-index-1 position-absolute w-100"
              :style="backgroundStyle"
            />
          </span>
        </h6>

        <div
          v-if="content.content.underline"
          class="d-flex"
          :class="{ 'justify-content-start': content.content.alignment === 'left', 'justify-content-center': content.content.alignment === 'center', 'justify-content-end': content.content.alignment === 'right' }"
        >
          <div
            class="rounded-pill mw-100"
            :style="underlineStyle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    backgroundStyle () {
      let style = ''

      if (this.content.content.background) {
        style += 'background-color: ' + this.content.content.backgroundColor + '; '
        style += 'padding-bottom: ' + this.content.content.backgroundHeight + 'px; '
        style += 'top: ' + this.content.content.backgroundMarginTop + 'px; '
        style += 'left: ' + this.content.content.backgroundMarginLeft + 'px; '
      }

      return style
    },

    underlineStyle () {
      let style = ''

      if (this.content.content.underline) {
        style += 'background-color: ' + this.content.content.underlineColor + '; '
        style += 'height: ' + this.content.content.underlineHeight + 'px; '
        style += 'width: ' + this.content.content.underlineWidth + 'px; '
      }

      return style
    },

    titleClass () {
      return [
        'text-' + this.content.content.alignment,
        'f-' + this.content.content.fontFamily
      ]
    },

    titleStyle () {
      let style = ''

      if (this.content.content.textColor) {
        style += 'color: ' + this.content.content.textColor + ';'
      }

      if (this.content.content.textShadow) {
        style += 'text-shadow: ' + this.content.content.textShadowHorizontal + 'px ' + this.content.content.textShadowVertical + 'px ' + this.content.content.textShadowBlur + 'px ' + this.content.content.textShadowColor
      }

      return style
    },

    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px;'
      }

      return style
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
