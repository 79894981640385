<template>
  <div class="page-wedding-view">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion du mariage
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Mariage
        </h2>
        <span
          v-if="!loadedWedding"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <span
          v-if="isFormLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <div
      v-if="loadedWedding"
      :class="{ 'is-loading' : isFormLoading }"
    >
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      loadedWedding: false,
      isFormLoading: false,
    }
  },

  computed: {
    ...mapFields('wedding/update', {
      error: 'error',
      isLoading: 'isLoading',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    }),

    ...mapFields('application/auth', {
      isUserLoading: 'isLoading',
      isLogged: 'isLogged',
      hasWebsite: 'hasWebsite',
      hasWebsiteSelected: 'hasWebsiteSelected',
      user: 'user'
    })
  },

  watch: {
    error (error) {
      if (error) {
        this.isFormLoading = false
      }
    },

    updated () {
      this.isFormLoading = false
    },

    retrieved (retrieved) {
      if (retrieved) {
        this.loadedWedding = true
      }
    }
  },

  mounted () {
    this.retrieve('websites/' + this.user.selectedWebsite.id + '/wedding')
  },

  methods: {
    ...mapActions({
      update: 'wedding/update/update',
      retrieve: 'wedding/update/retrieve'
    }),

    onSendForm (item) {
      this.isFormLoading = true

      let payload = {
        name: item.name,
      }

      this.update({
        id: 'wedding/' + this.retrieved.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
