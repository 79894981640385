import auth from './auth'
import login from './login'

export default {
  namespaced: true,
  modules: {
    auth,
    login
  }
}
