var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-member-list-preview"},[_c('div',{class:[
      'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
      'mb-' + _vm.content.section.marginBottom.replace('-', 'n')
    ]},[_c('div',{class:[
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-stretch"},_vm._l((4),function(index){
      var _obj;
return _c('div',{key:index,staticClass:"col-12 col-sm-6 col-md-4 col-lg-3 my-3"},[(_vm.content.content.showAvatar)?_c('div',{staticClass:"d-flex justify-content-center p-3"},[_c('div',{staticClass:"avatar-size position-relative",style:('width:' + _vm.content.content.avatarSize + 'px')},[_c('div',{staticClass:"avatar-container w-100 position-relative"},[_c('div',{staticClass:"position-absolute top-0 left-0 embed-responsive embed-responsive-1by1 rounded-circle overflow-hidden shadow-sm"},[_c('div',{staticClass:"embed-responsive-item",staticStyle:{"background-position":"center","background-size":"cover"},style:(_vm.avatarStyle)})])])])]):_vm._e(),_c('div',{staticClass:"text-center pt-2"},[_c('h6',{staticClass:"font-weight-600",class:[ 'f-' + _vm.content.content.usernameFont ],style:(_vm.usernameStyle)},[_vm._v("\n                John Doe\n              ")]),(_vm.content.content.displayFields)?_c('div',{staticClass:"pt-1 f-14 text-muted"},_vm._l((_vm.content.content.displayedFields),function(field,index){return _c('div',{key:index,style:('color:' + field.color)},[_vm._v("\n                  "+_vm._s(field.fieldName)+"\n                ")])}),0):_vm._e(),(_vm.content.content.displaySocials)?_c('div',{staticClass:"d-flex justify-content-center align-items-center flex-wrap mw-100",class:( _obj = { 'colored-icons': _vm.content.content.coloredSocials }, _obj[ 'f-' + _vm.content.content.socialSize ] = true, _obj )},[_vm._m(0,true),_vm._m(1,true),_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true)]):_vm._e()])])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-flex p-1"},[_c('i',{staticClass:"fab fa-facebook-square"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-flex p-1"},[_c('i',{staticClass:"fab fa-instagram-square"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-flex p-1"},[_c('i',{staticClass:"fab fa-twitter-square"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-flex p-1"},[_c('i',{staticClass:"fab fa-linkedin"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-flex p-1"},[_c('i',{staticClass:"fab fa-youtube-square"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-flex p-1"},[_c('i',{staticClass:"fab fa-twitch"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-flex p-1"},[_c('i',{staticClass:"fas fa-link"})])}]

export { render, staticRenderFns }