var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-contact-preview"},[_c('div',{class:{ 'container': _vm.hasContainer }},[_c('div',{class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();}}},[_vm._v("\n          Envoyer le message\n        ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contact_email"}},[_vm._v("Adresse email")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","id":"contact_email","name":"contact[email]","disabled":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contact_name"}},[_vm._v("Nom")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"contact_name","name":"contact[name]","disabled":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contact_message"}},[_vm._v("Message")]),_c('textarea',{staticClass:"form-control",attrs:{"id":"contact_message","name":"contact[message]","disabled":""}})])}]

export { render, staticRenderFns }