<template>
  <div class="form-shopproduct">
    <BModal
      id="select-image"
      size="xl"
      content-class="bg-light-grey"
      hide-header
      hide-footer
    >
      <!--suppress JSUnresolvedVariable -->
      <FileList
        :handle-close="closeSelection"
        :handle-select="addImage"
        mime-type="image/*"
      />
    </BModal>

    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <Tabs
        v-model="tab"
        :tabs="tabs"
      />
    </div>

    <div
      v-show="tab === 'informations'"
      class="bg-white shadow-sm rounded-lg my-3"
    >
      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Nom et informations
        </div>

        <FormInput
          v-model="item.name"
          :is-valid="isValid('name')"
          :is-pre-valid="isPreValid('name')"
          label="Nom du produit"
          type="text"
          class="mb-4"
          @input="updateValidation('name')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('name')"
              class="invalid-feedback"
            >
              {{ violations.name }}
            </div>
            <div
              v-if="!isPreValid('name')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.required">
                Le nom du produit doit être indiqué
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.minLength">
                Le nom du produit doit contenir au moins {{ $v.item.name.$params.minLength.min }} caractères
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.maxLength">
                Le nom du produit doit contenir au maximum {{ $v.item.name.$params.maxLength.max }} caractères
              </div>
            </div>
          </template>
        </FormInput>

        <FormTextarea
          v-model="item.description"
          :is-valid="isValid('description')"
          :is-pre-valid="isPreValid('description')"
          label="Description du produit"
          placeholder="La description du produit peut contenir jusqu'à 200 caractères."
          class="mb-4"
          @input="updateValidation('description')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('description')"
              class="invalid-feedback"
            >
              {{ violations.description }}
            </div>
            <div
              v-if="!isPreValid('description')"
              class="invalid-feedback"
            >
              <div v-if="!$v.item.description.maxLength">
                La description du produit doit contenir au maximum {{ $v.item.description.$params.maxLength.max }} caractères
              </div>
            </div>
          </template>
        </FormTextarea>

        <div class="form-control-label pb-2">
          Images du produit
        </div>

        <div class="pb-2">
          <button
            class="btn btn-outline-success btn-sm rounded-pill px-3"
            @click.prevent="addPriceImage(null)"
          >
            Ajouter une image
          </button>

          <div class="row">
            <div
              v-for="image in item.images"
              class="col-12 col-md-6 col-xl-4 position-relative mt-3"
            >
              <div
                class="image-remove rounded-circle border border-danger bg-danger-light bg-danger-hover text-danger text-white-hover"
                @click.prevent="removeImage(image, item)"
              >
                <i class="far fa-trash" />
              </div>

              <img
                v-if="image"
                :src="image"
                alt="Product image"
                class="img-thumbnail mw-100"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 bg-light-grey">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Prix, quantités et variantes
        </div>

        <div class="row">
          <div class="col">
            <FormCheckbox
              v-model="multiplePrices"
              :is-switch="true"
              label="Indiquer des variantes de ce produit"
              class="mb-0"
            />
          </div>

          <div class="col">
            <FormCheckbox
              v-model="editQuantity"
              :is-switch="true"
              label="Indiquer une quantité limitée"
              class="mb-0"
            />
          </div>
        </div>
      </div>

      <div
        v-for="(price, index) in item.prices"
        class="bg-light-grey border-top p-4"
      >
        <div class="row">
          <div
            v-if="multiplePrices"
            class="col-12"
          >
            <div class="d-flex justify-content-between">
              <div class="f-14 font-weight-700 text-success text-underline pb-3">
                Variante n°{{ index + 1 }}
              </div>

              <i
                class="far fa-times-circle cursor-pointer text-danger f-18 opacity-70 opacity-100-hover"
                @click.prevent="removePrice(index)"
              />
            </div>

            <FormInput
              v-model="price.label"
              label="Nom de la variante"
              type="text"
              class="mb-4"
            />
          </div>
          <div class="col">
            <FormInput
              v-model="price.price"
              :is-valid="isValid('price')"
              :is-pre-valid="isPricePreValid(index, 'price')"
              :append="'€'"
              label="Prix du produit"
              type="number"
              class="mb-4"
              @input="updatePriceValidation(index, 'price')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('price')"
                  class="invalid-feedback"
                >
                  {{ violations.price }}
                </div>
                <div
                  v-if="!isPricePreValid(index, 'price')"
                  class="invalid-feedback"
                >
                  <div v-if="!$v.item.prices.$each[index].price.required">
                    Le prix du produit doit être indiqué
                  </div>
                  <div v-if="!$v.item.prices.$each[index].price.decimal">
                    Le prix du produit doit être un nombre
                  </div>
                  <div v-if="!$v.item.prices.$each[index].price.minValue">
                    Le prix du produit doit être supérieur à 0€
                  </div>
                </div>
              </template>
            </FormInput>
          </div>

          <div class="col">
            <FormInput
              v-if="editQuantity"
              v-model="price.quantity"
              :is-valid="isValid('quantity')"
              :is-pre-valid="isPricePreValid(index, 'quantity')"
              label="Quantité disponible"
              type="number"
              class="mb-4"
              @input="updatePriceValidation(index, 'quantity')"
            >
              <template v-slot:validation>
                <div
                  v-if="!isValid('quantity')"
                  class="invalid-feedback"
                >
                  {{ violations.title }}
                </div>
                <div
                  v-if="!isPricePreValid(index, 'quantity')"
                  class="invalid-feedback"
                >
                  <div v-if="!$v.item.prices.$each[index].quantity.integer">
                    La quantité du produit doit être un nombre entier
                  </div>
                  <div v-if="!$v.item.prices.$each[index].quantity.minValue">
                    La quantité doit être supérieure ou égale à 0€
                  </div>
                </div>
              </template>
            </FormInput>
          </div>

          <div
            v-if="multiplePrices"
            class="col-12"
          >
            <div class="form-control-label pb-2">
              Images de la variante
            </div>

            <div class="pb-2">
              <button
                class="btn btn-outline-success btn-sm rounded-pill px-3"
                @click.prevent="addPriceImage(price)"
              >
                Ajouter une image
              </button>

              <div class="row">
                <div
                  v-for="image in price.images"
                  class="col-12 col-md-6 col-xl-4 position-relative mt-3"
                >
                  <div
                    class="image-remove rounded-circle border border-danger bg-danger-light bg-danger-hover text-danger text-white-hover"
                    @click.prevent="removeImage(image, price)"
                  >
                    <i class="far fa-trash" />
                  </div>

                  <img
                    v-if="image"
                    :src="image"
                    alt="Product image"
                    class="img-thumbnail mw-100"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="multiplePrices"
        class="px-4 py-3 bg-light-grey border-top text-center"
      >
        <button
          class="btn btn-sm btn-outline-success rounded-pill"
          @click.prevent="addPrice"
        >
          Ajouter une variante
        </button>
      </div>

      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Paramètres
        </div>

        <FormCheckbox
          v-if="false"
          v-model="item.requireShipping"
          :is-switch="true"
          label="Appliquer les frais de livraison pour ce produit"
          class="mb-2"
        />

        <FormCheckbox
          v-model="item.showIfEmpty"
          :is-switch="true"
          label="Afficher ce produit sur la boutique même en cas de rupture de stock"
          class="mb-2"
        />

        <FormCheckbox
          v-model="item.active"
          :is-switch="true"
          label="Activer ce produit"
          class="mb-2"
        />
      </div>
    </div>

    <PageBuilderForm
      v-if="unserialized"
      v-show="tab === 'content'"
      :content.sync="item.modules"
    />

    <div class="d-flex justify-content-center mt-4">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click="submit()"
      >
        Enregistrer le produit
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormDatePicker from '../applicationform/FormDatePicker'
import FormInput from '../applicationform/FormInput'
import PageBuilderForm from '../pagebuilder/PageBuilder'
import { minLength, maxLength, minValue, integer, decimal, required } from 'vuelidate/lib/validators'
import Tabs from '../applicationcomponent/Tabs'
import { mapFields } from 'vuex-map-fields'
import FormCheckbox from '../applicationform/FormCheckbox'
import FormMemberAutocomplete from '../applicationform/FormMemberAutocomplete'
import permission from '../../utils/permission'
import FormTextarea from '../applicationform/FormTextarea'
import FileList from '../file/List'
import { S3_ENTRYPOINT } from '@/config/entrypoint'
import { mapActions } from 'vuex'

export default {
  components: {
    FormTextarea,
    FormMemberAutocomplete,
    FormCheckbox,
    Tabs,
    FormDatePicker,
    FormInput,
    PageBuilderForm,
    FileList
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      unserialized: false,
      tab: 'informations',
      editQuantity: false,
      multiplePrices: false,
      editingPrice: null,
      initialItem: {},
      tabs: [
        {
          name: 'informations',
          title: 'Titre et paramètres'
        },
        {
          name: 'content',
          title: 'Contenu du produit'
        }
      ]
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    item () {
      return this.values || this.initialValues
    },

    violations () {
      return this.errors || {}
    },

    title () {
      return this.item.title
    },

    validation () {
      return this.$v.item
    }
  },

  watch: {
    tab (tab) {
      if (tab === 'content') {
        this.$root.$emit('layout', 'fluid')
        this.$root.$emit('sidebar', false)
        this.$root.$emit('editor', true)

        return
      }

      this.$root.$emit('layout', 'default')
      this.$root.$emit('sidebar', true)
      this.$root.$emit('editor', false)
    }
  },

  mounted () {
    let self = this
    this.$root.$emit('layout', 'default')
    this.$root.$emit('sidebar', true)
    this.$root.$emit('editor', false)

    this.item.modules.forEach((module) => {
      self.unserializeModule(module)
    })

    if (this.item.prices[0] && (this.item.prices[0].quantity || this.item.prices[0].quantity === 0)) {
      this.editQuantity = true
    }

    if (this.item.prices.length > 1) {
      this.multiplePrices = true
    }

    // eslint-disable-next-line no-undef
    this.initialItem = _.cloneDeep(this.item)

    this.updateBuilderType('shopproduct')

    this.unserialized = true
  },

  beforeDestroy () {
    this.$root.$emit('layout', 'default')
    this.$root.$emit('sidebar', true)
    this.$root.$emit('editor', false)
    this.updateReset()
  },

  methods: {
    ...mapActions({
      updateModule: 'pagebuilder/update/updateModule',
      updateBuilderType: 'pagebuilder/update/updateBuilderType',
      updatePageType: 'pagebuilder/update/updatePageType',
      updateReset: 'pagebuilder/update/reset'
    }),

    unserializeModule (module) {
      let self = this

      module.content = JSON.parse(module.content)

      if (module.submodules && module.submodules.length > 0) {
        module.submodules.forEach((child) => {
          self.unserializeModule(child)
        })
      }
    },

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    closeSelection () {
      this.$bvModal.hide('select-image')
    },

    addImage (image) {
      if (!this.editingPrice) {
        this.item.images.push(S3_ENTRYPOINT + image.contentUrl)
      } else {
        this.editingPrice.images.push(S3_ENTRYPOINT + image.contentUrl)
      }

      this.editingPrice = null
      this.closeSelection()
    },

    removeImage (image, item) {
      let index = item.images.indexOf(image)
      item.images.splice(index, 1)
    },

    addPrice () {
      this.item.prices.push({
        label: null,
        price: 0,
        quantity: null,
        images: []
      })
    },

    removePrice (index) {
      this.item.prices.splice(index, 1)
    },

    addPriceImage (price) {
      this.editingPrice = price
      this.$bvModal.show('select-image')
    },

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    isPricePreValid (index, key) {
      return !this.$v.item.prices.$each[index][key].$error || !this.$v.item.prices.$each[index][key].$invalid
    },

    updatePriceValidation (index, key) {
      this.$v.item.prices.$each[index][key].$touch()
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      if (!this.editPublication) {
        this.item.publication = null
      }

      if (!this.editImage) {
        this.item.image = null
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },

      description: {
        maxLength: maxLength(200)
      },

      prices: {
        $each: {
          price: {
            required,
            decimal,
            minValue: minValue(0)
          },

          quantity: {
            minValue: minValue(0),
            integer
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .image-remove {
    position: absolute;
    top: rem-calc(10);
    right: rem-calc(25);
    width: rem-calc(32);
    height: rem-calc(32);
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }
</style>
