<template>
  <div class="text-soft">
    <div
      v-onhover="['text-primary']"
      class="position-relative"
      @dragover.prevent
      @drop="onDrop"
    >
      <input
        class="app-dropzone-input position-absolute"
        type="file"
        name="file"
        multiple
        :accept="mimeType"
        :disabled="disabled"
        @click="onClick"
        @change="onChange"
      >
      <slot>
        <div class="d-flex flex-column align-items-center bg-white rounded-lg shadow-sm p-4">
          <i class="fas fa-cloud-upload f-30" />
          <div class="f-nunito font-weight-600 mt-2">
            Cliquer ou déposer un fichier
          </div>
        </div>
      </slot>
    </div>
    <div
      class="app-dropzone-preview"
    >
      <slot
        name="preview"
        :files="files"
      >
        <div
          v-if="files.length > 0"
          class="bg-white rounded-lg shadow-sm p-3 my-3"
        >
          <div
            v-for="(file, fileIndex) in files"
            :key="fileIndex"
            class="d-flex p-3 bg-light-grey rounded-lg align-items-center"
            :class="{ 'mb-3' : fileIndex !== files.length - 1 }"
          >
            <div class="file-progress">
              <span
                v-if="(!file.errors || file.errors.length === 0) && !file.complete"
                class="f-nunito f-14 font-weight-600 text-primary"
              >
                {{ file.progress }}%
              </span>
              <span
                v-if="file.errors.length > 0"
                class="text-danger"
              >
                <i class="fas fa-times-circle" />
              </span>
              <span
                v-if="file.complete"
                class="text-success"
              >
                <i class="fas fa-check-circle" />
              </span>
            </div>
            <div class="file-content flex-grow-1">
              <div class="file-name">
                {{ file.name }}
              </div>
              <div v-if="file.errors.length > 0">
                <div
                  v-for="(error, index) in file.errors"
                  :key="index"
                  class="text-danger f-12"
                >
                  {{ error }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { ENTRYPOINT } from '../../config/entrypoint'

export default {
  props: {
    mimeType: {
      type: String,
      default: '*/*'
    },

    path: {
      type: String,
      default: ''
    },

    preview: {
      type: Boolean,
      default: false
    },

    handleCreate: {
      type: Function,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      files: [],
      requests: []
    }
  },

  methods: {
    onDrop (event) {
      event.stopPropagation()
      event.preventDefault()

      if (this.disabled) {
        return
      }

      let files = event.dataTransfer.files
      let self = this

      Array.from(files).forEach((file) => {
        self.handleFile(file)
      })
    },

    onClick (event) {
    },

    onChange (event) {
      if (this.disabled) {
        event.stopPropagation()
        event.preventDefault()
      }

      let files = event.target.files
      let self = this

      Array.from(files).forEach((file) => {
        self.handleFile(file)
      })
    },

    handleFile (file) {
      let fileObject = {
        name: file.name,
        content: '',
        progress: 0,
        errors: [],
        complete: false
      }

      if (!this.checkMimeType(file)) {
        fileObject.errors.push('Ce format de fichier n\'est pas accepté')
        this.files.push(fileObject)

        return
      }

      if (!this.checkSize(file)) {
        fileObject.errors.push('Ce fichier est trop volumineux')
        this.files.push(fileObject)

        return
      }

      this.files.push(fileObject)

      this.upload(file)
    },

    upload (file) {
      let self = this
      let fd = new FormData()
      fd.append('file', file)

      let index = this.requests.length
      this.requests[index] = new XMLHttpRequest()
      // noinspection JSCheckFunctionSignatures
      this.requests[index].open('POST', new URL('api/files', ENTRYPOINT), true)
      this.requests[index].setRequestHeader('Authorization', 'Bearer ' + localStorage.token)

      this.requests[index].upload.onprogress = function (e) {
        if (e.lengthComputable) {
          let fileItem = self.files.find(f => f.name === file.name)
          fileItem.progress = parseInt(((e.loaded / e.total) * 100).toString())
        }
      }

      this.requests[index].onerror = function () {
        let fileItem = self.files.find(f => f.name === file.name)
        fileItem.errors = ['Une erreur est intervenue, merci de réessayer plus tard']
      }

      this.requests[index].onload = function () {
        if (this.status === 201) {
          let fileItem = self.files.find(f => f.name === file.name)
          fileItem.complete = true

          self.handleCreate(JSON.parse(this.response))
        }

        if (this.status === 400) {
          let fileItem = self.files.find(f => f.name === file.name)
          let errors = []

          if (!this.response.violations) {
            fileItem.errors = ['Une erreur est intervenue, merci de réessayer plus tard']

            return
          }

          this.response.violations.forEach((violation) => {
            errors.push(violation.message)
          })

          fileItem.errors = errors
        }
      }

      this.requests[index].send(fd)
    },

    checkSize (file) {
      let size = (file.size / 1024) / 1024

      return !(Math.round(size * 100) > 2499) // Maximum 24.99 MB
    },

    checkMimeType (file) {
      let types = this.mimeType.split(',')
      let result = false

      types.forEach((type) => {
        if (type === '*/*') {
          result = true

          return
        }

        if (file.type.match(type)) {
          result = true
        }
      })

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-dropzone-input {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }

  .file-progress {
    width: 55px;
  }
</style>
