<template>
  <div class="component-progress-step">
    <div
      class="row mx-0 align-items-stretch"
      :class="[ whiteBackground ? 'bg-white': 'bg-light-grey' ]"
    >
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="d-flex flex-column justify-content-center align-items-center cursor-pointer bg-light-hover"
        :class="{ 'py-2 py-lg-4': !small, 'py-1 py-lg-2': small, 'bg-light': value === index + 1, [columnClass]: columnClass }"
        @click="goToStep(index + 1)"
      >
        <div class="text-primary f-14 font-weight-700 text-uppercase">
          {{ step.title }}
        </div>
        <div class="text-soft text-center font-weight-600">
          {{ step.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },

    steps: {
      type: Array,
      required: true
    },

    columnClass: {
      type: String,
      default: 'col-12'
    },

    small: {
      type: Boolean,
      default: false
    },

    whiteBackground: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    goToStep (step) {
      this.$emit('input', step)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
