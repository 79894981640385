<template>
  <div class="page-website-create">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <router-link
          :to="{ name: 'website-settings' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la gestion des paramètres
        </router-link>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Domaine principal
        </h2>
        <span
          v-if="!loadedWebsite"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <span
          v-if="isFormLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <div
      v-if="loadedWebsite"
      :class="{ 'is-loading' : isFormLoading }"
    >
      <WebsiteForm
        :handle-submit="onSendForm"
        :values="retrieved"
        :initial-values="initialItem"
        :errors="violations"
      />
    </div>
  </div>
</template>

<script>
import WebsiteForm from '../../../components/website/DomainForm'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    WebsiteForm
  },

  data () {
    return {
      loadedWebsite: false,
      isFormLoading: false,
      initialItem: {}
    }
  },

  computed: {
    ...mapFields('website/update', {
      error: 'error',
      isLoading: 'isLoading',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    }),

    ...mapFields('application/auth', {
      isUserLoading: 'isLoading',
      isLogged: 'isLogged',
      hasWebsite: 'hasWebsite',
      hasWebsiteSelected: 'hasWebsiteSelected',
      user: 'user'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    user (user) {
      if (!user) {
        return
      }

      this.initialItem = _.cloneDeep(this.updated)
      this.isFormLoading = false
    },

    updated (updated) {
      if (!updated) {
        return
      }

      this.refreshToken({
        toggleLoading: false,
        forceRefresh: true
      })
    },

    retrieved (retrieved) {
      if (retrieved) {
        this.loadedWebsite = true
        this.initialItem = _.cloneDeep(retrieved)
      }
    }
  },

  mounted () {
    this.retrieve('websites/' + this.user.selectedWebsite.id)
  },

  methods: {
    ...mapActions({
      update: 'website/update/update',
      retrieve: 'website/update/retrieve',
      refreshToken: 'application/auth/refreshToken'
    }),

    onSendForm (item) {
      this.isFormLoading = true

      let payload = {
        domain: item.domain
      }

      this.update({
        id: 'websites/domain/' + this.retrieved.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
