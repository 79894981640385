<template>
  <div class="page-design-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des thèmes
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste des thèmes
        </h2>
        <span
          v-if="!loadedDesigns && !loadedFixedDesigns"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="updateWebsiteLoading && website"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Changement de thème
        </span>
      </div>
    </div>

    <BModal
      id="design-create"
      size="md"
      hide-header
      hide-footer
    >
      <!--suppress JSUnresolvedVariable -->
      <DesignForm
        :handle-submit="addDesign"
        :values="designCreate"
        :errors="createViolations"
      />
    </BModal>

    <div v-if="!loadedFixedDesigns || !loadedDesigns">
      <div
        v-if="checkPermission('design.create')"
        class="d-flex justify-content-center"
      >
        <button class="btn btn-fake px-3 rounded-pill">
          Ajouter un thème
        </button>
      </div>
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="loadedDesigns && loadedFixedDesigns"
      :class="{ 'is-loading': updateWebsiteLoading && website }"
    >
      <div
        v-if="checkPermission('design.create') && (user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'))"
        class="d-flex justify-content-center"
      >
        <button
          v-b-modal.design-create
          class="btn btn-primary px-3 rounded-pill"
        >
          Ajouter un thème
        </button>
      </div>

      <b-tooltip
        v-if="!user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro')"
        :target="id"
        placement="top"
        triggers="hover"
      >
        Cette fonctionnalité n'est accessible qu'avec l'option Premium ou Pro
      </b-tooltip>

      <div
        :id="id"
        class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative"
      >
        <div
          class="p-4 f-20 f-nunito font-weight-600 d-flex justify-content-between align-items-center"
          :class="{ 'border-bottom': items.length > 0 }"
        >
          <div>
            {{ view.totalItems > 0 ? view.totalItems : 'Aucun' }} {{ view.totalItems > 1 ? 'thèmes personnalisés' : 'thème personnalisé' }}

            <span
              v-if="!user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro')"
              class="ml-2 px-2 rounded-pill f-14 font-weight-600 text-dark cursor-pointer bg-warning"
              @click="$router.push({ name: 'website-subscription' })"
            >
              Premium
            </span>
          </div>
        </div>

        <div
          v-if="!user.selectedWebsite.addons.includes('Premium') && !user.selectedWebsite.addons.includes('pro')"
          class="bg-light-grey p-4 f-14 font-weight-600 text-soft"
        >
          Les offres Premium et Pro vous permettent de créer un thème HTML / CSS entièrement personnalisé grâce à notre éditeur HTML.
        </div>

        <div
          v-if="!view.totalItems"
          class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
          :class="{ 'is-loading': !user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro') }"
        >
          Aucun élément à afficher
        </div>

        <div :class="{ 'is-loading': !user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro') }">
          <div
            v-for="(item, index) in items"
            :key="item.id"
            class="position-relative"
            :class="{ 'is-loading': deleting === item.id, 'border-bottom': index !== items.length - 1 }"
          >
            <div
              class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
              :class="{ 'pl-1': selected === item.id }"
            />
            <div
              class="d-flex justify-content-between bg-light-grey-hover align-items-center position-relative opacity-100-hover transition-400"
              :class="{ 'cursor-pointer': user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'), 'opacity-30': selected && selected !== item.id, 'bg-light-grey': selected === item.id }"
              @click="toggleSelected(item.id, false)"
            >
              <div class="flex-grow-1 p-4">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="text-primary font-weight-600">
                      {{ item.name }}
                    </div>
                  </div>
                  <div class="col">
                    <span
                      v-if="website && website.currentDesign && website.currentDesign.id === item.id && (user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'))"
                      class="ml-3 mt-n0 px-2 bg-success-light rounded-pill f-14 f-nunito font-weight-600"
                    >
                      Sélectionné
                    </span>
                  </div>
                </div>
              </div>
              <div class="align-self-stretch px-4 d-flex align-items-center">
                <i
                  v-if="user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro')"
                  class="text-soft far fa-angle-right f-18 transition-300"
                  :class="{ 'fa-rotate-90': selected === item.id }"
                />
              </div>
            </div>
            <BCollapse
              :id="'collapse-design-' + item.id"
              accordion="design-list"
            >
              <div
                v-if="checkPermission('design.select') || checkPermission('design.update') || checkPermission('design.delete')"
                class="p-3 d-flex flex-column flex-md-row align-items-center justify-content-center"
              >
                <button
                  v-if="checkPermission('design.select') && website && (!website.currentDesign || website.currentDesign.id !== item.id)"
                  class="btn btn-outline-success btn-sm px-3 mx-md-2 my-2 my-md-0 rounded-pill"
                  @click.prevent="selectDesign(item)"
                >
                  Sélectionner le thème
                </button>

                <RouterLink
                  v-if="checkPermission('design.update')"
                  :to="{name: 'design-update', params: { id: item.id }}"
                  class="btn btn-outline-primary btn-sm rounded-pill px-3 mx-md-2 my-2 my-md-0"
                >
                  Éditer le thème
                </RouterLink>

                <button
                  v-if="checkPermission('design.delete') && website && (!website.currentDesign || website.currentDesign.id !== item.id)"
                  class="btn btn-outline-danger btn-sm px-3 mx-md-2 my-2 my-md-0 rounded-pill"
                  @click.prevent="removeDesign(item)"
                >
                  Supprimer le thème
                </button>
              </div>
            </BCollapse>
          </div>
        </div>
      </div>

      <div class="my-5 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div class="p-4 f-20 f-nunito font-weight-600 d-flex align-items-center">
          <div class="d-flex align-items-center">
            Thème gratuit
          </div>
          <span
            v-if="baseDesignSelected"
            class="ml-3 mt-n0 px-2 bg-success-light rounded-pill f-14 f-nunito font-weight-600"
          >
            Sélectionné
          </span>
          <button
            v-if="checkPermission('design.select') && website && !baseDesignSelected"
            class="btn btn-outline-success btn-sm px-3 ml-3 rounded-pill"
            @click.prevent="selectDesign(baseDesign)"
          >
            Sélectionner le thème gratuit
          </button>
        </div>

        <div class="p-4 border-top f-14 f-nunito font-weight-600">
          <div>
            Ce thème est utilisable <span class="text-success">gratuitement</span>. Les couleurs, le fond du site, l'entête et le bas de page peuvent être personnalisés. Associé à notre éditeur de page ce thème vous permettra d'avoir un design profesionnel.
          </div>

          <div
            v-if="baseDesignSelected"
            class="pt-4 d-flex justify-content-center"
          >
            <RouterLink
              :to="{ name: 'design-setting' }"
              class="btn btn-success rounded-pill"
            >
              Personnaliser le thème
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import DesignForm from '../../components/design/Form'
import permission from '../../utils/permission'

export default {
  components: {
    DesignForm
  },

  data () {
    return {
      loadedDesigns: false,
      loadedFixedDesigns: false,
      deleting: null,
      selected: null,
      designCreate: {}
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('design/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('design/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('design/listfixed', {
      errorFixed: 'error',
      itemsFixed: 'items',
      isFixedLoading: 'isLoading',
      viewFixed: 'view'
    }),

    ...mapFields('design/create', {
      createError: 'error',
      createLoading: 'isLoading',
      created: 'created',
      createViolations: 'violations'
    }),

    ...mapFields('website/update', {
      updateWebsiteError: 'error',
      updateWebsiteLoading: 'isLoading',
      updatedWebsite: 'updated',
      retrievedWebsite: 'retrieved',
      createViolations: 'violations'
    }),

    id () {
      // noinspection JSUnresolvedVariable
      return 'design-list-' + this._uid
    },

    website () {
      return this.updatedWebsite || this.retrievedWebsite
    },

    baseDesign () {
      return this.itemsFixed.find(design => design.name === 'Base')
    },

    baseDesignSelected () {
      if (!this.website || !this.itemsFixed) {
        return
      }

      if (!this.website.currentDesign || this.baseDesign.id === this.website.currentDesign.id) {
        return true
      }

      return !this.user.selectedWebsite.addons.includes('premium') && !this.user.selectedWebsite.addons.includes('pro')
    }
  },

  watch: {
    selected (selected, previousSelected) {
      if (selected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-design-' + selected)
      }

      if (previousSelected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-design-' + previousSelected)
      }
    },

    itemsFixed (items) {
      if (items) {
        this.loadedFixedDesigns = true
      }
    },

    items (items) {
      if (items) {
        this.loadedDesigns = true
      }
    },

    created: function (created) {
      if (!created) {
        return
      }

      this.$bvModal.hide('design-create')
      this.$router.push({ name: 'design-update', params: { id: created.id } })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      let self = this
      this.deleting = null
      this.selected = null

      this.items.forEach((page, index) => {
        if (page.id === deleted.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
        }
      })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.getDesigns({ page: 'websites/' + this.user.selectedWebsite.id + '/designs', query: { fixed: false } })
    // noinspection JSUnresolvedVariable
    this.getFixedDesigns({ page: 'designs', query: { fixed: true } })
    // noinspection JSUnresolvedVariable
    this.getWebsite('websites/' + this.user.selectedWebsite.id)
  },

  beforeDestroy () {
    this.resetDesigns()
    this.resetFixedDesigns()
    this.resetWebsite()
  },

  methods: {
    ...mapActions({
      getDesigns: 'design/list/default',
      resetDesigns: 'design/list/reset',
      getFixedDesigns: 'design/listfixed/default',
      resetFixedDesigns: 'design/listfixed/reset',
      deleteDesign: 'design/del/del',
      createDesign: 'design/create/create',
      getWebsite: 'website/update/retrieve',
      updateWebsite: 'website/update/update',
      resetWebsite: 'website/update/reset'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    toggleSelected (id, fixed = true) {
      if (!fixed && !this.user.selectedWebsite.addons.includes('premium') && !this.user.selectedWebsite.addons.includes('pro')) {
        return
      }

      if (this.selected === id) {
        this.selected = null

        return
      }

      this.selected = id
    },

    addDesign (design) {
      // noinspection JSUnresolvedVariable
      let payload = {
        name: design.name,
        website: '/api/websites/' + this.user.selectedWebsite.id
      }

      this.createDesign(payload)
    },

    removeDesign (design) {
      this.deleting = design.id
      this.deleteDesign(design)
    },

    selectDesign (design) {
      let payload = {
        currentDesign: design['@id']
      }

      this.updateWebsite({
        id: 'websites/select-design/' + this.user.selectedWebsite.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
