import * as types from './mutation_types'
import { APP_BASE_DOMAIN } from '@/config/entrypoint'

export default {
  [types.APPLICATION_AUTH_LOGOUT] (state) {
    Object.assign(state, {
      isLoading: false,
      isLogged: false,
      hasWebsite: false,
      hasWebsiteSelected: false,
      user: null
    })

    localStorage.removeItem('token_update')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('token')
    localStorage.removeItem('user')

    document.cookie = "logged=;domain=" + APP_BASE_DOMAIN + ";expires=Thu, 01 Jan 1970 00:00:01 GMT"
  },

  [types.APPLICATION_AUTH_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.APPLICATION_AUTH_SET_HAS_WEBSITE] (state, hasWebsite) {
    Object.assign(state, { hasWebsite })
  },

  [types.APPLICATION_AUTH_SET_HAS_WEBSITE_SELECTED] (state, hasWebsiteSelected) {
    Object.assign(state, { hasWebsiteSelected })
  },

  [types.APPLICATION_AUTH_SET_USER] (state, user) {
    // noinspection JSUnresolvedVariable
    Object.assign(state, {
      isLogged: true,
      hasWebsite: user.hasWebsite,
      hasWebsiteSelected: !!user.selectedWebsite,
      user
    })
  },

  [types.APPLICATION_AUTH_UPDATE_USER] (state, user) {
    // noinspection JSUnresolvedVariable
    Object.assign(state, {
      isLogged: true,
      hasWebsite: user.data.hasWebsite,
      hasWebsiteSelected: !!user.data.selectedWebsite,
      user: user.data
    })

    localStorage.setItem('token_update', Date.now().toString())
    localStorage.setItem('refresh_token', user.refresh_token)
    localStorage.setItem('token', user.token)
    localStorage.setItem('user', JSON.stringify(user.data))

    let cookieDate = new Date();
    cookieDate.setMonth(cookieDate.getMonth() + 1)

    document.cookie = "logged=true;domain=." + APP_BASE_DOMAIN + ";expires=" + cookieDate
    document.cookie = "account=true;domain=." + APP_BASE_DOMAIN + ";expires=Fri, 31 Dec 9999 23:59:59 GMT"
  }
}
