<template>
  <div class="form-website">
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <div class="pt-4 px-4">
        <div class="text-primary font-weight-700 text-uppercase f-14">
          Création du site
        </div>
        <div class="f-14 font-weight-600 text-success">
          Pour créer votre site il suffit d'indiquer son nom.
        </div>
      </div>

      <div class="px-4 pt-5 pb-2 f-14 font-weight-600 text-soft">
        <div class="">
          En validant ce formulaire le site sera créé immédiatement et vous pourrez commencer à l'utiliser.<br>
          Après la création un guide vous sera proposé pour prendre en main facilement votre nouveau site.
        </div>
      </div>

      <div class="p-4">
        <FormInput
          v-model="item.name"
          :is-valid="isValid('name')"
          :is-pre-valid="isPreValid('name')"
          label="Nom du site"
          placeholder="Mon nom de site"
          type="text"
          @input="updateValidation('name')"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('name')"
              class="invalid-feedback"
            >
              {{ violations.name }}
            </div>
            <div
              v-if="!isPreValid('name')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.required">
                Le nom du site doit être indiqué
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.minLength">
                Le nom du site doit contenir au moins {{ $v.item.name.$params.minLength.min }} caractères
              </div>
            </div>
          </template>
        </FormInput>
      </div>

      <div class="p-4 bg-light-grey">
        <div
          v-if="!item.subdomain"
          class="f-14 text-center text-success"
        >
          Indiquez un nom de site pour générer automatiquement une adresse
        </div>
        <div
          v-if="initialItem.subdomain !== item.subdomain"
          class="f-14 font-weight-600 text-center"
        >
          Après enregistrement l'adresse du site sera : <span class="text-success">{{ item.subdomain }}.{{ WEBSITE_BASE_DOMAIN }}</span>
        </div>
        <div
          v-if="!editSubdomain"
          class="text-center pt-3"
        >
          <button
            class="btn btn-sm btn-outline-success px-3 rounded-pill"
            @click.prevent="editSubdomain = true"
          >
            Personnaliser l'adresse du site
          </button>

          <div
            v-if="!isValid('subdomain')"
            class="invalid-feedback d-block"
          >
            {{ violations.subdomain }}
          </div>
          <div
            v-if="!isPreValid('subdomain')"
            class="invalid-feedback d-block"
          >
            <!--suppress JSUnresolvedVariable -->
            <div v-if="!$v.item.subdomain.required">
              L'adresse du site doit être indiquée
            </div>
            <!--suppress JSUnresolvedVariable -->
            <div v-if="!$v.item.subdomain.minLength">
              L'adresse du site doit contenir au moins {{ $v.item.subdomain.$params.minLength.min }} caractères
            </div>
            <!--suppress JSUnresolvedVariable -->
            <div v-if="!$v.item.subdomain.subdomain">
              Cette adresse est invalide. L'adresse ne peut contenir que des lettres minuscules, des chiffres ou un tiret
            </div>
          </div>
        </div>
        <div
          v-if="editSubdomain"
          class="pt-3"
        >
          <FormInput
            v-model="item.subdomain"
            :is-valid="isValid('subdomain')"
            :is-pre-valid="isPreValid('subdomain')"
            :append="'.' + WEBSITE_BASE_DOMAIN"
            label="Adresse du site"
            type="text"
            placeholder="monsite"
            @input="updateValidation('subdomain')"
            @keypress.enter.native="editSubdomain = false"
          >
            <template v-slot:validation>
              <div
                v-if="!isValid('subdomain')"
                class="invalid-feedback"
              >
                {{ violations.subdomain }}
              </div>
              <div
                v-if="!isPreValid('subdomain')"
                class="invalid-feedback"
              >
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.subdomain.required">
                  L'adresse du site doit être indiquée
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.subdomain.minLength">
                  L'adresse du site doit contenir au moins {{ $v.item.subdomain.$params.minLength.min }} caractères
                </div>
                <!--suppress JSUnresolvedVariable -->
                <div v-if="!$v.item.subdomain.subdomain">
                  Cette adresse est invalide. L'adresse ne peut contenir que des lettres minuscules, des chiffres ou un tiret
                </div>
              </div>
            </template>
          </FormInput>
        </div>
      </div>

      <div class="p-4">
        <div class="text-primary font-weight-700 text-uppercase f-14">
          De quoi va parler votre site ?
        </div>
        <div class="text-success f-14 font-weight-600">Sélectionnez le sujet de votre site.</div>
        <div
          v-if="!isValid('topic')"
          class="text-danger f-14 font-weight-600"
        >
          {{ violations.topic }}
        </div>
        <div
          v-if="!isPreValid('topic')"
          class="text-danger f-14 font-weight-600"
        >
          <!--suppress JSUnresolvedVariable -->
          <div v-if="!$v.item.topic.required">
            Vous devez sélectionner un sujet de site.
          </div>
        </div>

        <div class="row mt-3">
          <div
            class="col-12 col-sm-6 col-xl-3"
            v-for="topic in topics"
          >
            <WebsiteInterest
              :item="topic"
              :selected="selected"
              :handle-select="selectTopic"
            />
          </div>
        </div>

        <FormInput
          v-model="customTopic"
          label="Si aucune catégorie ne correspond à votre site"
          placeholder="Décrivez le sujet de votre site"
          type="text"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('name')"
              class="invalid-feedback"
            >
              {{ violations.name }}
            </div>
            <div
              v-if="!isPreValid('name')"
              class="invalid-feedback"
            >
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.required">
                Le nom du site doit être indiqué
              </div>
              <!--suppress JSUnresolvedVariable -->
              <div v-if="!$v.item.name.minLength">
                Le nom du site doit contenir au moins {{ $v.item.name.$params.minLength.min }} caractères
              </div>
            </div>
          </template>
        </FormInput>
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-primary px-3 rounded-pill"
        @click.prevent="submit()"
      >
        Créer mon site
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import { required, minLength, helpers } from 'vuelidate/lib/validators'
import { WEBSITE_BASE_DOMAIN } from '../../config/entrypoint'
import FormInput from '../applicationform/FormInput'
import slugify from 'slugify'
import WebsiteInterest from '@/components/website/WebsiteInterest'

const subdomain = helpers.regex('slug', /^[a-z0-9-]*$/)

export default {
  components: {
    WebsiteInterest,
    FormInput
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      WEBSITE_BASE_DOMAIN,
      editSubdomain: false,
      initialItem: {},
      selected: '',
      customTopic: '',
      topics: [
        {
          icon: 'fad fa-comments-alt',
          name: 'Blog / Actualités',
          slug: 'blog'
        },
        {
          icon: 'fad fa-utensils-alt',
          name: 'Restaurant',
          slug: 'restaurant'
        },
        {
          icon: 'fad fa-store',
          name: 'Commerce',
          slug: 'shop'
        },
        {
          icon: 'fad fa-palette',
          name: 'Artisan',
          slug: 'artisan'
        },
        {
          icon: 'fad fa-user-music',
          name: 'Artiste',
          slug: 'artist'
        },
        {
          icon: 'fad fa-user-tie',
          name: 'CV en ligne',
          slug: 'cv'
        },
        {
          icon: 'fad fa-camera-retro',
          name: 'Photographie',
          slug: 'photobook'
        },
        {
          icon: 'fad fa-rings-wedding',
          name: 'Mariage',
          slug: 'wedding'
        },
        {
          icon: 'fad fa-house',
          name: 'Logement',
          slug: 'housing'
        },
        {
          icon: 'fad fa-building',
          name: 'Entreprise',
          slug: 'corporate'
        },
        {
          icon: 'fad fa-handshake-alt',
          name: 'Association',
          slug: 'association'
        }
      ]
    }
  },

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    },

    name () {
      return this.item.name
    },

    subdomain () {
      return this.item.subdomain ? this.item.subdomain : 'monsite'
    }
  },

  watch: {
    name (name, previousName) {
      let slug = null

      if (previousName) {
        slug = slugify(previousName, { remove: /[^a-zA-Z0-9\s_-]/g, lower: true })
      }

      if (!slug || slug === this.item.subdomain) {
        this.item.subdomain = slugify(name, { remove: /[^a-zA-Z0-9\s_-]/g, lower: true })
        this.updateValidation('subdomain')
      }
    },

    customTopic (topic) {
      if (topic) {
        this.item.topic = topic
        this.selected = ''
      }
    }
  },

  mounted () {
    this.initialItem = _.cloneDeep(this.item)
  },

  methods: {
    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    selectTopic (topic) {
      this.selected = topic.slug
      this.item.topic = topic.slug
      this.customTopic = ''
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      name: {
        required,
        minLength: minLength(5)
      },

      subdomain: {
        required,
        minLength: minLength(5),
        subdomain
      },

      topic: {
        required
      }
    }
  }
}
</script>
