export const fixedPages = [
  {
    title: 'Page d\'accueil',
    slug: 'home',
    linkable: true,
    disable: false,
    editable: true,
    description: 'Page d\'accueil. C\'est la page la plus importante de votre site'
  },
  {
    title: 'Page d\'article',
    slug: 'article',
    linkable: false,
    disable: false,
    editable: true,
    description: 'Page utilisée pour afficher le contenu d\'un article'
  },
  {
    title: 'Liste des articles',
    slug: 'articles',
    linkable: true,
    disable: false,
    editable: true,
    description: 'Page de liste des articles, aussi appelée page d\'archives'
  },
  {
    title: 'Page de membre',
    slug: 'member',
    linkable: false,
    disable: true,
    editable: true,
    description: 'Page de profil des membres'
  },
  {
    title: 'Page de contact',
    slug: 'contact',
    linkable: true,
    disable: true,
    editable: true,
    description: 'Formulaire de contact, grâce à cette page les visiteurs peuvent vous contacter. Vous pouvez définir l\'adresse email de contact dans les paramètres du site',
    disabled: 'Cette page doit être activée pour que le formulaire de contact soit disponible'
  },
  {
    title: 'Boutique',
    slug: 'shop',
    linkable: true,
    disable: true,
    editable: true,
    description: 'Cette page liste les produits vendus sur le site',
    disabled: 'Cette page doit être activée pour que la boutique puisse fonctionner',
    soon: 'L\'apparence de la liste des produits pourra bientôt être personnalisée'
  },
  {
    title: 'Boutique - Page de produit',
    slug: 'shop_product',
    linkable: false,
    disable: false,
    editable: true,
    description: 'Page de présentation d\'un produit de la boutique',
    soon: 'L\'apparence du produit pourra bientôt être personnalisée'
  },
  {
    title: 'CGU',
    slug: 'cgu',
    linkable: true,
    disable: true,
    editable: true,
    description: 'Conditions générales d\'utilisation du site'
  },
  {
    title: 'Connexion',
    slug: 'login',
    linkable: true,
    disable: false,
    editable: false,
    requiredAddon: 'premium',
    description: 'Page de connexion'
  },
  {
    title: 'Inscription',
    slug: 'register',
    linkable: true,
    disable: false,
    editable: false,
    requiredAddon: 'premium',
    description: 'Page d\'inscription'
  }
]
