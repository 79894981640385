<template>
  <div class="page-member-group-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des groupes de membres
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste des groupes de membres
        </h2>
        <span
          v-if="!loadedGroups"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <div v-if="!loadedGroups">
      <div
        v-if="checkPermission('member_group.create') && (user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'))"
        class="d-flex justify-content-center"
      >
        <button class="btn btn-fake px-3 rounded-pill">
          Ajouter un groupe
        </button>
      </div>
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="items && loadedGroups"
      :class="{ 'is-loading': isLoading }"
    >
      <div
        v-if="checkPermission('member_group.create') && (user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'))"
        class="d-flex justify-content-center"
      >
        <RouterLink
          :to="{ name: 'membergroup-create' }"
          class="btn btn-primary px-3 rounded-pill"
        >
          Ajouter un groupe
        </RouterLink>
      </div>

      <div class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div
          class="p-4 f-20 f-nunito font-weight-600 d-flex justify-content-between align-items-center"
          :class="{ 'border-bottom': items.length > 0 }"
        >
          <div>
            {{ view.totalItems }} {{ view.totalItems > 1 ? 'groupes' : 'groupe' }}
          </div>
        </div>

        <div
          v-if="!user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro')"
          class="bg-light f-14 font-weight-600 text-center p-4"
        >
          <div>
            Cette fonctionnalité est réservée aux offres PREMIUM et PRO
          </div>
          <div class="mt-3">
            <RouterLink
              :to="{ name: 'website-subscription' }"
              class="btn btn-outline-primary btn-sm px-3 rounded-pill"
            >
              Souscrire une offre
            </RouterLink>
          </div>
        </div>

        <div
          v-if="!view.totalItems"
          class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
        >
          Aucun élément à afficher
        </div>

        <GroupItem
          v-for="(item, index) in items"
          :key="item.id"
          :index="index"
          :handle-select="toggleSelected"
          :handle-delete="removeGroup"
          :group="item"
          :groups="items"
          :selected="selected"
        />
      </div>

      <Pagination
        v-if="!Array.isArray(view)"
        v-model="page"
        :view="view"
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import GroupItem from '@/components/membergroup/ListItem'
import permission from '../../utils/permission'
import Pagination from '../../components/applicationlayout/Pagination'

export default {
  components: {
    Pagination,
    GroupItem
  },

  data () {
    return {
      loadedGroups: false,
      loadedSettings: false,
      page: 1,
      deleting: null,
      selected: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('membergroup/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    })
  },

  watch: {
    selected (selected, previousSelected) {
      if (selected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-member-group-' + selected)
      }

      if (previousSelected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-member-group-' + previousSelected)
      }
    },

    items (items) {
      if (items) {
        this.loadedGroups = true
        this.selected = null
      }
    },

    page (page) {
      // noinspection JSUnresolvedVariable
      this.getGroups({ page: 'websites/' + this.user.selectedWebsite.id + '/member_groups', query: { page } })
    }
  },

  mounted () {
    this.getGroups({ page: 'websites/' + this.user.selectedWebsite.id + '/member_groups' })
  },

  beforeDestroy () {
    this.resetGroups()
  },

  methods: {
    ...mapActions({
      getGroups: 'membergroup/list/default',
      resetGroups: 'membergroup/list/reset'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    toggleSelected (id) {
      if (this.selected === id) {
        this.selected = null

        return
      }

      this.selected = id
    },

    removeGroup (group) {
      let self = this

      this.items.forEach((groupItem, index) => {
        if (groupItem.id === group.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
