<template>
  <div class="page-page-update">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <router-link
          :to="{ name: 'design-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des thèmes
        </router-link>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Éditer un thème
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isUpdateLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isCreateLoading || isLoading || isFixedLoading || isDesignFixedLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="isLoading || isFixedLoading || isDesignFixedLoading">
      <div class="row">
        <div class="col-3">
          <div
            class="bg-grey rounded-lg"
            style="height: 300px"
          />
        </div>
        <div class="col-9">
          <div
            class="bg-grey rounded-lg"
            style="height: 300px"
          />
        </div>
      </div>
    </div>

    <div
      class="d-xl-none alert alert-danger"
      role="status"
    >
      Les thèmes ne peuvent être édités que via un ordinateur.
    </div>

    <BModal
      id="ask-save"
      size="md"
      hide-header
      hide-footer
    >
      <h5 class="f-nunito font-weight-600 text-primary text-center mb-4 mt-3">
        Attention !
      </h5>
      <div v-if="template">
        Vous n'avez pas enregistré les modifications du fichier <span class="text-primary font-weight-600">{{ template.name.replace('website/', '').replace('.twig', '') }}</span>.
        Voulez-vous enregistrer les modifications avant de changer de template ?
      </div>
      <div class="d-flex justify-content-center mt-5">
        <button
          class="btn btn-primary px-3 rounded-pill"
          @click="submit(template)"
        >
          Enregistrer et continuer
        </button>
      </div>
      <div class="d-flex justify-content-center my-3">
        <button
          class="btn btn-sm btn-link text-soft px-3 font-weight-600 rounded-pill"
          @click="selectTemplate(nextTemplate, true)"
        >
          Ne pas enregistrer
        </button>
      </div>
    </BModal>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="templates"
      class="d-none d-xl-block"
      :class="{ 'is-loading' : isCreateLoading || isUpdateLoading }"
    >
      <div class="row">
        <div class="col-3">
          <div class="bg-white rounded-lg shadow-sm overflow-hidden">
            <div
              v-for="(templateItem, index) in templates"
              :key="templateItem.id"
              v-onhover="['bg-light-grey-hover']"
              class="d-flex p-3 cursor-pointer"
              :class="{ 'border-bottom': index !== templates.length - 1, 'bg-light-grey': template && templateItem.id === template.id }"
              @click.prevent="selectTemplate(templateItem)"
            >
              <div
                class="template-type text-uppercase f-nunito f-14 font-weight-700"
                :class="{ 'text-success': templateItem.type === 'html', 'text-warning': templateItem.type === 'css' }"
              >
                {{ templateItem.type }}
              </div>
              {{ templateItem.name.replace('website/', '').replace('.twig', '') }}
            </div>
          </div>
        </div>
        <div class="col-9">
          <div
            v-if="currentTemplate"
          >
            <div class="rounded-lg position-relative overflow-hidden">
              <div class="position-absolute top-0 right-0 z-index-10 text-right">
                <div
                  v-if="currentTemplate.source !== currentTemplate.beforeModification"
                  class="mt-2 mr-2"
                  @click="submit(currentTemplate)"
                >
                  <span class="bg-light rounded-pill px-3 py-1 f-14 f-nunito font-weight-600 text-soft cursor-pointer">
                    Enregistrer les modifications
                  </span>
                </div>
                <div
                  v-if="currentTemplate.source !== currentTemplate.original"
                  class="mt-2 mr-2"
                  @click="showOriginal = !showOriginal"
                >
                  <span
                    v-if="showOriginal"
                    class="bg-white rounded-pill px-3 py-1 f-14 f-nunito font-weight-600 text-soft cursor-pointer"
                  >
                    Revenir au thème personnalisé
                  </span>
                  <span
                    v-if="!showOriginal"
                    class="bg-white rounded-pill px-3 py-1 f-14 f-nunito font-weight-600 text-soft cursor-pointer"
                  >
                    Afficher le thème original
                  </span>
                </div>
              </div>

              <div v-if="showOriginal">
                <div class="position-absolute h-100 w-100 z-index-9" />
              </div>

              <div v-if="showOriginal">
                <Editor
                  v-model="currentTemplate.original"
                  lang="twig"
                  theme="monokai"
                  height="700"
                  @init="editorInit"
                />
              </div>

              <div v-if="!showOriginal">
                <Editor
                  v-model="currentTemplate.source"
                  lang="twig"
                  theme="monokai"
                  height="700"
                  @init="editorInit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import Editor from 'vue2-ace-editor'

export default {
  components: {
    Editor
  },

  data () {
    return {
      contentReady: false,
      showOriginal: false,
      template: null,
      nextTemplate: null
    }
  },

  computed: {
    ...mapFields('template/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('template/listfixed', {
      errorFixed: 'error',
      itemsFixed: 'items',
      isFixedLoading: 'isLoading',
      viewFixed: 'view'
    }),

    ...mapFields('design/listfixed', {
      errorDesignFixed: 'error',
      itemsDesignFixed: 'items',
      isDesignFixedLoading: 'isLoading',
      viewDesignFixed: 'view'
    }),

    ...mapFields('template/create', {
      createError: 'error',
      isCreateLoading: 'isLoading',
      created: 'created',
      createViolations: 'violations'
    }),

    ...mapFields('template/update', {
      updateError: 'error',
      isUpdateLoading: 'isLoading',
      updated: 'updated',
      updateViolations: 'violations'
    }),

    currentTemplate () {
      if (!this.template) {
        return
      }

      return this.templates.find(template => template.id === this.template.id)
    },

    templates () {
      let templates = []
      let self = this

      this.itemsFixed.forEach((templateFixed) => {
        let currentItem = self.items.find(item => item.name === templateFixed.name)

        templates.push({
          ...templateFixed,
          ...currentItem,
          beforeModification: currentItem ? currentItem.source : templateFixed.source,
          originalId: templateFixed.id,
          original: templateFixed.source,
          type: templateFixed.name.split('.').slice(-2)[0]
        })
      })

      return templates
    }
  },

  watch: {
    created (created) {
      if (!created) {
        return
      }

      this.items.push(created)
      let createdTemplate = this.templates.find(templateItem => templateItem.id === created.id)

      if (createdTemplate) {
        this.selectTemplate(createdTemplate)
      }
    },

    updated (updated) {
      if (!updated) {
        return
      }

      this.items.forEach((template) => {
        if (template.id === updated.id) {
          template.source = updated.source
        }
      })

      this.template.original = updated.source

      if (this.nextTemplate) {
        this.selectTemplate(this.nextTemplate, true)
      }
    },

    itemsDesignFixed (designs) {
      if (designs) {
        let design = designs.find(design => design.name === 'Base')

        if (!design) {
          return
        }

        this.getFixedTemplates({ page: 'designs/' + design.id + '/templates' })
      }
    }
  },

  mounted () {
    this.getTemplates({ page: 'designs/' + this.$route.params.id + '/templates' })
    this.getFixedDesigns({ page: 'designs', query: { fixed: true, name: 'Base' } })
  },

  beforeDestroy () {
    this.resetTemplates()
    this.resetFixedTemplates()
    this.resetFixedDesigns()
  },

  methods: {
    ...mapActions({
      getTemplates: 'template/list/default',
      resetTemplates: 'template/list/reset',
      getFixedTemplates: 'template/listfixed/default',
      resetFixedTemplates: 'template/listfixed/reset',
      getFixedDesigns: 'design/listfixed/default',
      resetFixedDesigns: 'design/listfixed/reset',
      createTemplate: 'template/create/create',
      updateTemplate: 'template/update/update'
    }),

    editorInit (editor) {
      require('brace/ext/language_tools')
      require('brace/mode/html')
      require('brace/mode/twig')
      require('brace/mode/javascript')
      require('brace/mode/css')
      require('brace/theme/chrome')
      require('brace/theme/monokai')
      require('brace/snippets/javascript')

      editor.setOptions({
        fontFamily: 'Roboto Mono',
        fontSize: '13.5px'
      })
      editor.setShowPrintMargin(false)

      editor.on('change', (arg, activeEditor) => {
        const aceEditor = activeEditor
        // noinspection JSUnresolvedVariable
        let newHeight = aceEditor.getSession().getScreenLength() *
          (aceEditor.renderer.lineHeight + aceEditor.renderer.scrollBar.getWidth())
        newHeight = newHeight > 500 ? newHeight : 500
        aceEditor.container.style.height = `${newHeight}px`
        aceEditor.resize()
      })
    },

    selectTemplate (template, confirmed = false) {
      if (!confirmed && this.currentTemplate && this.currentTemplate.source !== this.currentTemplate.beforeModification) {
        this.nextTemplate = template
        this.$bvModal.show('ask-save')

        return
      }

      if (this.currentTemplate && this.currentTemplate.source !== this.currentTemplate.beforeModification) {
        this.currentTemplate.source = this.currentTemplate.beforeModification
      }

      if (template.id === template.originalId) {
        let payload = {
          name: template.name,
          source: template.source,
          design: '/api/designs/' + this.$route.params.id
        }

        this.createTemplate(payload)
      }

      this.$bvModal.hide('ask-save')
      this.showOriginal = false
      this.template = template
      this.nextTemplate = null
    },

    submit (template) {
      this.$bvModal.hide('ask-save')

      let payload = {
        source: template.source
      }

      this.updateTemplate({
        id: 'templates/' + template.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .template-type {
    width: 50px;
    padding-top: rem-calc(3);
  }
</style>
