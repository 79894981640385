<template>
  <div
      class="position-relative"
      :class="{ 'is-loading': deleting || validating, 'border-bottom': index !== members.length - 1 }"
  >
    <div
        class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
        :class="{ 'pl-1': selected === member.id }"
    />
    <div
        class="d-flex justify-content-between bg-light-grey-hover align-items-center position-relative opacity-100-hover cursor-pointer transition-400"
        :class="{ 'opacity-30': selected && selected !== member.id, 'bg-light-grey': selected === member.id }"
        @click="handleSelect(member.id)"
    >
      <div class="p-4 w-100">
        <div class="row align-items-center">
          <div class="col-12 col-sm-7 d-flex align-items-center justify-content-center justify-content-md-start">
            <img
              :src="avatar"
              class="mr-3 rounded-circle shadow-sm bg-light-grey d-none d-md-block"
              height="64"
              width="64"
              alt="Member avatar"
            />

            <div class="d-flex flex-column align-items-center align-items-sm-start">
              <div class="text-primary font-weight-600">
                {{ member.username }}
              </div>
              <div
                v-if="!member.email"
                class="text-danger"
              >
                <i class="fad fa-exclamation-circle" /> Adresse email non définie
              </div>
              <div
                v-if="member.email"
                class="text-soft"
              >
                <i class="fad fa-envelope mr-1" /> {{ member.email }}
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-5 d-flex flex-column align-items-center">
            <span
              v-if="!member.email"
              class="mt-3 mt-sm-0 px-2 bg-danger-light rounded-pill f-14 f-nunito font-weight-600"
            >
              Aucun utilisateur
            </span>
            <span
                v-if="member.enabled && member.email"
                class="mt-3 mt-sm-0 px-2 bg-success-light rounded-pill f-14 f-nunito font-weight-600"
            >
              Validé
            </span>
            <span
                v-if="!member.enabled && member.email"
                class="mt-3 mt-sm-0 px-2 bg-warning rounded-pill f-14 f-nunito font-weight-600"
            >
              En attente de validation
            </span>

            <div
                v-if="!member.enabled && member.email && checkPermission('member.validate')"
                class="mt-2"
            >
              <button
                  class="btn btn-outline-warning btn-sm px-3 rounded-pill"
                  @click.prevent="validateMember(member)"
              >
                Valider ce membre
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="align-self-stretch px-4 d-flex align-items-center">
        <i
            class="text-soft far fa-angle-right f-18 transition-300"
            :class="{ 'fa-rotate-90': selected === member.id }"
        />
      </div>
    </div>
    <BCollapse
        :id="'collapse-member-' + member.id"
        accordion="member-list"
    >
      <div
          v-if="checkPermission('member.delete') && (user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'))"
          class="p-3 d-flex flex-column flex-sm-row align-items-center justify-content-center border-bottom"
      >
        <RouterLink
          v-if="checkPermission('member.update')"
          :to="{name: 'member-update', params: { id: member.id }}"
          class="btn btn-outline-primary btn-sm rounded-pill px-3 mx-sm-2 my-2 my-sm-0"
        >
          Éditer le membre
        </RouterLink>

        <button
            v-if="checkPermission('member.delete')"
            class="btn btn-outline-danger btn-sm rounded-pill px-3 my-2 my-sm-0"
            @click.prevent="deleteMember(member)"
        >
          Supprimer le membre
        </button>
      </div>
      <div class="px-4 pt-4 pb-2">
        <div class="row">
          <div class="col-12 col-md-6 pb-3">
            <div class="font-weight-600 text-soft">
              Membre depuis le
            </div>
            <!--suppress JSUnresolvedFunction -->
            <div class="text-capitalize">
              {{ member.createdAt | moment( 'dddd Do MMMM YYYY') }}
            </div>
          </div>
        </div>
      </div>
    </BCollapse>
  </div>
</template>

<script>
import permission from '@/utils/permission'
import { mapFields } from 'vuex-map-fields'
import fetch from '@/utils/fetch'
import { ENTRYPOINT, S3_ENTRYPOINT } from '@/config/entrypoint'

export default {
  props: {
    handleSelect: {
      type: Function,
      required: true
    },

    handleDelete: {
      type: Function,
      required: true
    },

    member: {
      type: Object,
      required: true,
    },

    members: {
      type: Array,
      required: true,
    },

    selected: {
      type: Number
    },

    index: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      deleting: false,
      validating: false,
      APP_API_ENTRYPOINT: ENTRYPOINT,
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    avatar () {
      return this.member.avatar ? S3_ENTRYPOINT + this.member.avatar : this.APP_API_ENTRYPOINT + '/assets/img/avatar.png'
    },
  },

  methods: {
    checkPermission (toCheck) {
      return permission(toCheck)
    },

    validateMember (member) {
      this.validating = true

      let payload = {
        enabled: true
      }

      fetch('members/validate/' + member.id, {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': 'application/ld+json' }),
        body: JSON.stringify(payload)
      })
      .then(response => response.json())
      .then((data) => {
        this.member.enabled = data.enabled
        this.validating = false
      })
      .catch((e) => {
        this.validating = false
      })
    },

    deleteMember (member) {
      this.deleting = true

      fetch('members/' + member.id, { method: 'DELETE' })
      .then(() => {
        this.handleDelete(member)
        this.deleting = false
      })
      .catch((e) => {
        this.deleting = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
