<template>
  <div class="component-row-module">
    <Tabs
      v-model="tab"
      :tabs="tabs"
      class="border-bottom border-primary"
    />

    <div v-if="tab === 'columns'">
      <div class="d-flex justify-content-center py-4">
        <button
          class="btn btn-outline-success btn-sm px-3 rounded-pill"
          @click.prevent="addColumn"
        >
          Ajouter une colonne
        </button>
      </div>

      <div
        v-for="(column, index) in content.submodules"
        :key="index"
      >
        <div
          v-b-toggle="'collapse-row-column-' + index"
          class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top d-flex justify-content-between cursor-pointer align-items-center"
        >
          Colonne #{{ index + 1 }}
          <i
            class="fas fa-chevron-right text-soft"
          />
        </div>
        <BCollapse
          :id="'collapse-row-column-' + index"
          accordion="row-columns"
        >
          <div class="d-flex justify-content-center py-3 border-bottom">
            <button
              class="btn btn-sm btn-outline-success rounded-pill px-3 mx-2"
              @click.prevent="duplicateColumn(column, index)"
            >
              Dupliquer cette colonne
            </button>
            <button
              v-if="content.submodules.length > 1"
              class="btn btn-sm btn-outline-danger rounded-pill px-3 mx-2"
              @click.prevent="removeColumn(index)"
            >
              Supprimer cette colonne
            </button>
          </div>

          <ColumnModule
            :content.sync="column"
            :stretched="content.content.verticalAlign === 'stretch'"
          />
        </BCollapse>
      </div>
    </div>

    <div v-if="tab === 'alignment'">
      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
        Largeur de la zone de colonnes
      </div>
      <div class="px-4">
        <FormCheckbox
          v-model="content.content.fullWidth"
          :is-switch="true"
          label="Utiliser toute la largeur de la page"
          class="mb-4 mt-4"
        />
      </div>

      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase">
        Alignement horizontal
      </div>
      <div class="px-4">
        <div class="form-group mb-4 pt-4">
          <b-form-radio
            v-model="content.content.align"
            name="row-align"
            value="start"
          >
            Aligner les colonnes à gauche
          </b-form-radio>
          <b-form-radio
            v-model="content.content.align"
            name="row-align"
            value="center"
          >
            Aligner les colonnes au centre
          </b-form-radio>
          <b-form-radio
            v-model="content.content.align"
            name="row-align"
            value="end"
          >
            Aligner les colonnes à droite
          </b-form-radio>
        </div>
      </div>

      <div class="bg-light-grey px-4 py-3 text-primary f-14 font-weight-700 text-uppercase border-top">
        Alignement vertical
      </div>
      <div class="px-4 pt-4">
        <div
          v-if="content.submodules.length < 2"
          class="f-14 font-weight-600 text-success mt-n3 mb-3"
        >
          Ces options ne sont disponibles qu'à partir de 2 colonnes.
        </div>
        <div
          class="form-group mb-4"
          :class="{ 'position-relative is-loading': content.submodules.length < 2 }"
        >
          <b-form-radio
            v-model="content.content.verticalAlign"
            name="row-verticalAlign"
            value="start"
          >
            Aligner les colonnes en haut
          </b-form-radio>
          <b-form-radio
            v-model="content.content.verticalAlign"
            name="row-verticalAlign"
            value="center"
          >
            Aligner les colonnes au centre
          </b-form-radio>
          <b-form-radio
            v-model="content.content.verticalAlign"
            name="row-verticalAlign"
            value="end"
          >
            Aligner les colonnes en bas
          </b-form-radio>
          <b-form-radio
            v-model="content.content.verticalAlign"
            name="row-verticalAlign"
            value="stretch"
          >
            Les colonnes côte à côte doivent avoir la même hauteur
          </b-form-radio>
        </div>
      </div>
    </div>

    <div v-if="tab === 'section'">
      <SectionAppearance :content.sync="content" />
    </div>
  </div>
</template>

<script>
import { components } from '../../../config/pagebuilder/components'
import SectionAppearance from '../SectionAppearance'
import ColumnModule from './ColumnModule'
import Tabs from '../../applicationcomponent/Tabs'
import FormCheckbox from '../../applicationform/FormCheckbox'

export default {
  name: 'RowModule',

  components: {
    FormCheckbox,
    Tabs,
    SectionAppearance,
    ColumnModule
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      components,
      tab: 'columns',
      tabs: [
        {
          name: 'columns',
          title: 'Choix des colonnes'
        },
        {
          name: 'alignment',
          title: 'Alignement des colonnes'
        },
        {
          name: 'section',
          title: 'Personnalisation de la section'
        }
      ]
    }
  },

  methods: {
    computePosition () {
      this.content.submodules.forEach((module, index) => {
        module.position = index
      })
    },

    addColumn () {
      let column = _.cloneDeep(this.components.column)

      this.content.submodules.push(column)
      this.computePosition()
    },

    duplicateColumn (column, index) {
      let clone = _.cloneDeep(column)
      delete clone.id

      this.content.submodules.splice(index, 0, clone)
      this.computePosition()
    },

    removeColumn (index) {
      this.content.submodules.splice(index, 1)
      this.computePosition()
    }
  }
}
</script>
