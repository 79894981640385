import List from '@/views/shopsetting/List'

export default [
  {
    path: '/shop/settings',
    name: 'shopsetting-list',
    component: List,
    meta: {
      layout: 'default',
      requireAuth: true,
      requireSelectedWebsite: true,
      requirePermission: 'shop.setting',
      title: 'Paramètres de la boutique'
    }
  }
]
