import { options } from './_default'

export const memberGroups = {
  type: 'member-groups',
  position: null,
  variant: 'default',
  content: {
  },
  submodules: [],
  dependencies: [],
  section: _.cloneDeep(options)
}
