<template>
  <div class="form-website-design-setting-footery">
    <BModal
      id="select-image"
      size="xl"
      content-class="bg-light-grey"
      hide-header
      hide-footer
    >
      <FileList
        :handle-close="closeSelection"
        :handle-select="selectImage"
        type="image"
        mime-type="image/*"
      />
    </BModal>

    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <Tabs
        v-model="tab"
        :tabs="tabs"
      />
    </div>

    <div
      v-if="tab === 'settings'"
      class="bg-white rounded-lg shadow-sm my-3"
    >
      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Pied de page du site
        </div>

        <div class="pb-4">
          <div class="form-control-label pb-2">
            Image de fond du pied de page
          </div>

          <div class="row align-items-stretch">
            <div class="col-12 col-md-6 col-xl-4 d-flex align-items-center justify-content-center justify-content-md-start">
              <div
                v-if="!item.footerBackgroundImage"
                class="text-muted"
              >
                Aucune image sélectionnée
              </div>
              <!--suppress HtmlUnknownTarget -->
              <img
                v-if="item.footerBackgroundImage"
                :src="item.footerBackgroundImage"
                alt="Image de fond du pied de page du site"
                class="img-thumbnail mw-100"
              >
            </div>

            <div class="col d-flex flex-column align-items-center align-items-md-end mt-3 mt-md-0 justify-content-center">
              <button
                class="btn btn-outline-success btn-sm rounded-pill px-3"
                @click.prevent="openSelection('footerBackgroundImage')"
              >
                Choisir une image
              </button>
              <button
                v-if="item.footerBackgroundImage"
                class="btn btn-outline-danger btn-sm rounded-pill mt-3 px-3"
                @click.prevent="item.footerBackgroundImage = null"
              >
                Supprimer l'image
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-xl-6">
            <!--suppress JSUnresolvedVariable -->
            <FormColorInput
              v-model="item.footerBackgroundColor"
              :is-valid="isValid('footerBackgroundColor')"
              :is-pre-valid="isPreValid('footerBackgroundColor')"
              class="mb-0"
              placeholder="#ffffff"
              label="Couleur de fond du pied de page"
              @input="updateValidation('footerBackgroundColor')"
            >
              <template v-slot:afterInput>
                <small class="form-text text-muted">La couleur doit être au format hexadécimal : <b>#fda32e</b></small>
              </template>
              <template v-slot:validation>
                <div
                  v-if="!isValid('footerBackgroundColor')"
                  class="invalid-feedback"
                >
                  {{ violations.footerBackgroundColor }}
                </div>
                <div
                  v-if="!isPreValid('footerBackgroundColor')"
                  class="invalid-feedback"
                >
                  <!--suppress JSUnresolvedVariable -->
                  <div v-if="!$v.item.footerBackgroundColor.color">
                    Cette couleur n'est pas valide
                  </div>
                </div>
              </template>
            </FormColorInput>
          </div>
        </div>
      </div>
    </div>

    <PageBuilder
      v-if="unserialized"
      v-show="tab === 'content'"
      :content.sync="item.footerModules"
    />

    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-primary rounded-pill px-3"
        @click.prevent="submit"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FileList from '../file/List'
import FormColorInput from '../applicationform/FormColorInput'
import { S3_ENTRYPOINT } from '@/config/entrypoint'
import { helpers } from 'vuelidate/lib/validators'
import Tabs from '../applicationcomponent/Tabs'
import PageBuilder from '../pagebuilder/PageBuilder'

const color = helpers.regex('slug', /^(#[a-fA-F0-9]{6}|#[a-fA-F0-9]{8})$/)

export default {
  components: {
    PageBuilder,
    Tabs,
    FileList,
    FormColorInput
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      unserialized: false,
      tab: 'settings',
      tabs: [
        {
          name: 'settings',
          title: 'Paramètres du pied de page'
        },
        {
          name: 'content',
          title: 'Contenu du pied de page'
        }
      ]
    }
  },

  computed: {
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    }
  },

  watch: {
    tab (tab) {
      if (tab === 'content') {
        this.$root.$emit('layout', 'fluid')
        this.$root.$emit('sidebar', false)
        this.$root.$emit('editor', true)

        return
      }

      this.$root.$emit('layout', 'default')
      this.$root.$emit('sidebar', true)
      this.$root.$emit('editor', false)
    }
  },

  mounted () {
    let self = this

    this.$root.$emit('layout', 'default')
    this.$root.$emit('sidebar', true)
    this.$root.$emit('editor', false)

    this.item.footerModules.forEach((module) => {
      self.unserializeModule(module)
    })

    this.unserialized = true
  },

  beforeDestroy () {
    this.$root.$emit('layout', 'default')
    this.$root.$emit('sidebar', true)
    this.$root.$emit('editor', false)
  },

  methods: {
    unserializeModule (module) {
      let self = this

      module.content = JSON.parse(module.content)

      if (module.submodules && module.submodules.length > 0) {
        module.submodules.forEach((child) => {
          self.unserializeModule(child)
        })
      }
    },

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    isPreValid (key) {
      return !this.$v.item[key].$error || !this.$v.item[key].$invalid
    },

    updateValidation (key) {
      this.$v.item[key].$touch()
    },

    closeSelection () {
      this.$bvModal.hide('select-image')
      this.selectingType = null
    },

    openSelection (type) {
      this.$bvModal.show('select-image')
      this.selectingType = type
    },

    selectImage (image) {
      this.item[this.selectingType] = S3_ENTRYPOINT + image.contentUrl
      this.closeSelection()
    },

    submit () {
      if (this.$v.item.$invalid) {
        this.$v.item.$touch()

        return
      }

      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  },

  validations: {
    item: {
      footerBackgroundImage: {},
      footerBackgroundColor: {
        color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
