import store from '../store'

export default function (permission) {
  let user = store.getters['application/auth/getField']('user')

  // noinspection JSUnresolvedVariable
  if (!store.getters['application/auth/getField']('isLogged') || !user.websiteUser) {
    return false
  }

  return user.websiteUser.permissions.includes('*.*') || user.websiteUser.permissions.includes(permission)
}
