<template>
  <div class="permissions-list">
    <div class="row">
      <PermissionListItem
        label="Articles"
        icon="fa-file-alt"
        :user-permissions="userPermissions"
        :permissions="permissions.articlePermissions"
      />

      <PermissionListItem
        label="Pages"
        icon="fa-ballot"
        :user-permissions="userPermissions"
        :permissions="permissions.pagePermissions"
      />

      <PermissionListItem
        label="Membres"
        icon="fa-user"
        :user-permissions="userPermissions"
        :permissions="permissions.memberPermissions"
      />

      <PermissionListItem
        label="Utilisateurs"
        icon="fa-users-cog"
        :user-permissions="userPermissions"
        :permissions="permissions.userPermissions"
      />

      <PermissionListItem
        label="Thèmes"
        icon="fa-paint-roller"
        :user-permissions="userPermissions"
        :permissions="permissions.designPermissions"
      />

      <PermissionListItem
        label="Fichiers"
        icon="fa-image"
        :user-permissions="userPermissions"
        :permissions="permissions.filePermissions"
      />

      <PermissionListItem
        label="Boutique"
        icon="fa-shopping-cart"
        :user-permissions="userPermissions"
        :permissions="permissions.shopPermissions"
      />

      <PermissionListItem
        label="Menus"
        icon="fa-list"
        :user-permissions="userPermissions"
        :permissions="permissions.menuPermissions"
      />

      <PermissionListItem
        label="Paramètres"
        icon="fa-cog"
        :user-permissions="userPermissions"
        :permissions="permissions.settingsPermissions"
      />
    </div>
  </div>
</template>

<script>
import { permissions } from '../../config/permissions'
import PermissionListItem from './PermissionListItem'

export default {
  components: { PermissionListItem },
  props: {
    userPermissions: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      permissions
    }
  },

  methods: {
    hasPermission (permission) {
      if (this.userPermissions.includes('*.*')) {
        return true
      }

      return this.userPermissions.includes(permission)
    }
  }
}
</script>

<style lang="scss" scoped>
  .permission-title {
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-transition: color .3s, background-color .3s;
    transition: color .3s, background-color .3s;
    color: $primary;
    height: 3rem;

    &-icon {
      width: rem-calc(36);
    }

    &-text {
      letter-spacing: 0.00937rem;
    }
  }

  .permission-icon {
    width: rem-calc(26)
  }
</style>
