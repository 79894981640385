<template>
  <div
    class="position-relative overflow-hidden w-100 mh-100"
    :class="[
      'mt-' + content.section.marginTop.replace('-', 'n'),
      'mb-' + content.section.marginBottom.replace('-', 'n'),
      'ml-' + content.section.marginLeft.replace('-', 'n'),
      'mr-' + content.section.marginRight.replace('-', 'n'),
      moduleClass,
      { 'rounded-circle': content.rounded }
    ]"
    :style="moduleStyle"
  >
    <div
      class="position-absolute w-100 h-100 overflow-y-auto overflow-y-hidden d-flex"
      :class="[
        'pt-' + content.section.paddingTop,
        'pb-' + content.section.paddingBottom,
        'pl-' + content.section.paddingLeft,
        'pr-' + content.section.paddingRight,
        { 'justify-content-center': content.content.horizontalCenter }
      ]"
    >
      <div :class="{ 'align-self-center': content.content.verticalCenter, 'w-100': !content.content.horizontalCenter }">
        <BuilderForm
          :content.sync="content.submodules"
          :is-column="true"
          :row-style="rowClass"
          :editing="editing"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    BuilderForm: () => import('../BuilderForm')
  },

  props: {
    content: {
      type: Object,
      required: true
    },

    editing: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    moduleStyle () {
      let style = 'min-height: 32px;'

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px; overflow-hidden;'
      }

      switch (this.content.content.shape) {
        case '1by1':
          style += 'padding-bottom: 100%;'
          break

        case '4by3':
          style += 'padding-bottom: 75%;'
          break

        case '3by4':
          style += 'padding-bottom: 133.33%;'
          break

        case '16by9':
          style += 'padding-bottom: 56.25%;'
          break

        case '9by16':
          style += 'padding-bottom: 177.78%;'
          break

        case 'custom':
          style += 'padding-bottom: ' + this.content.content.height + this.content.content.unit + ';'
          break
      }

      return style
    },

    rowClass () {
      let classes = []

      classes.push('justify-content-' + this.content.content.align)
      classes.push('align-items-' + this.content.content.verticalAlign)

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
