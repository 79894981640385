<template>
  <div class="form-group">
    <slot name="label">
      <div
        v-if="label"
        class="form-control-label"
      >
        {{ label }}
      </div>
    </slot>

    <slot name="beforeInput" />

    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-sm btn-primary px-3 rounded-pill mr-3"
        @click.prevent="checkAll"
      >
        Tout cocher
      </button>

      <button
        class="btn btn-sm btn-primary px-3 rounded-pill"
        @click.prevent="uncheckAll"
      >
        Tout décocher
      </button>
    </div>

    <div class="row px-3">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="mt-4">
          <div class="permission-title">
            <span class="permission-title-icon f-22"><i class="far fa-file-alt" /></span>
            <span class="permission-title-text f-nunito f-15 font-weight-700">Articles</span>
          </div>

          <BFormCheckboxGroup
            v-model="content"
            :options="articlePermissions"
            stacked
            switches
            @input="updateValue"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <div class="mt-4">
          <div class="permission-title">
            <span class="permission-title-icon f-22"><i class="far fa-ballot" /></span>
            <span class="permission-title-text f-nunito f-15 font-weight-700">Pages</span>
          </div>

          <BFormCheckboxGroup
            v-model="content"
            :options="pagePermissions"
            stacked
            switches
            @input="updateValue"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <div class="mt-4">
          <div class="permission-title">
            <span class="permission-title-icon f-22"><i class="far fa-user" /></span>
            <span class="permission-title-text f-nunito f-15 font-weight-700">Membres</span>
          </div>

          <BFormCheckboxGroup
            v-model="content"
            :options="memberPermissions"
            stacked
            switches
            @input="updateValue"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <div class="mt-4">
          <div class="permission-title">
            <span class="permission-title-icon f-22"><i class="far fa-users-cog" /></span>
            <span class="permission-title-text f-nunito f-15 font-weight-700">Utilisateurs</span>
          </div>

          <BFormCheckboxGroup
            v-model="content"
            :options="userPermissions"
            stacked
            switches
            @input="updateValue"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <div class="mt-4">
          <div class="permission-title">
            <span class="permission-title-icon f-22"><i class="far fa-paint-roller" /></span>
            <span class="permission-title-text f-nunito f-15 font-weight-700">Thèmes</span>
          </div>

          <BFormCheckboxGroup
            v-model="content"
            :options="designPermissions"
            stacked
            switches
            @input="updateValue"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <div class="mt-4">
          <div class="permission-title">
            <span class="permission-title-icon f-22"><i class="far fa-image" /></span>
            <span class="permission-title-text f-nunito f-15 font-weight-700">Fichiers</span>
          </div>

          <BFormCheckboxGroup
            v-model="content"
            :options="filePermissions"
            stacked
            switches
            @input="updateValue"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <div class="mt-4">
          <div class="permission-title">
            <span class="permission-title-icon f-22"><i class="far fa-shopping-cart" /></span>
            <span class="permission-title-text f-nunito f-15 font-weight-700">Boutique</span>
          </div>

          <BFormCheckboxGroup
            v-model="content"
            :options="shopPermissions"
            stacked
            switches
            @input="updateValue"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <div class="mt-4">
          <div class="permission-title">
            <span class="permission-title-icon f-22"><i class="far fa-list" /></span>
            <span class="permission-title-text f-nunito f-15 font-weight-700">Menus</span>
          </div>

          <BFormCheckboxGroup
            v-model="content"
            :options="menuPermissions"
            stacked
            switches
            @input="updateValue"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <div class="mt-4">
          <div class="permission-title">
            <span class="permission-title-icon f-22"><i class="far fa-cog" /></span>
            <span class="permission-title-text f-nunito f-15 font-weight-700">Paramètres</span>
          </div>

          <BFormCheckboxGroup
            v-model="content"
            :options="settingsPermissions"
            stacked
            switches
            @input="updateValue"
          />
        </div>
      </div>
    </div>

    <slot name="afterInput" />
    <slot name="validation" />
    <slot name="afterValidation" />
  </div>
</template>

<script>
import { permissions } from '../../config/permissions'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: ''
    },

    isValid: {
      type: Boolean,
      default: true
    },

    isPreValid: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      content: this.value,
      articlePermissions: permissions.articlePermissions,
      pagePermissions: permissions.pagePermissions,
      memberPermissions: permissions.memberPermissions,
      menuPermissions: permissions.menuPermissions,
      settingsPermissions: permissions.settingsPermissions,
      filePermissions: permissions.filePermissions,
      userPermissions: permissions.userPermissions,
      designPermissions: permissions.designPermissions,
      shopPermissions: permissions.shopPermissions
    }
  },

  computed: {
    id () {
      // noinspection JSUnresolvedVariable
      return 'input-' + this._uid
    }
  },

  watch: {
    value (value) {
      if (value !== this.content) {
        this.content = value
      }

      if (value.includes('*.*')) {
        this.checkAll()
      }
    }
  },

  methods: {
    updateValue () {
      this.$emit('input', this.content)
    },

    checkAll () {
      this.content = []
      this.addAll(this.articlePermissions)
      this.addAll(this.pagePermissions)
      this.addAll(this.menuPermissions)
      this.addAll(this.filePermissions)
      this.addAll(this.userPermissions)
      this.addAll(this.designPermissions)
      this.addAll(this.settingsPermissions)
      this.addAll(this.memberPermissions)
      this.addAll(this.shopPermissions)
    },

    uncheckAll () {
      this.content = []
    },

    addAll (permissions) {
      let self = this
      permissions.forEach((permission) => {
        self.content.push(permission.value)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .permission-title {
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-transition: color .3s, background-color .3s;
    transition: color .3s, background-color .3s;
    color: #6e82a5;
    height: 3rem;

    &-icon {
      width: rem-calc(36);
    }

    &-text {
      letter-spacing: 0.00937rem;
    }
  }
</style>
