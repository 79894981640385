import { shape as shapeModule } from '@/config/pagebuilder/components/shape'
import { title as titleModule } from '@/config/pagebuilder/elements/title'
import { row as rowModule } from '@/config/pagebuilder/components/row'
import { column as columnModule } from '@/config/pagebuilder/components/column'
import { text as textModule } from '@/config/pagebuilder/elements/text'
import { contact as contactModule } from '@/config/pagebuilder/components/contact'

let shape = _.cloneDeep(shapeModule)
shape.position = 0
shape.section.marginBottom = '5'
shape.section.backgroundImage = 'https://scaleweb-local.s3.eu-west-3.amazonaws.com/website/1/files/5f7f16cd79d4f393146829.png'
shape.section.backgroundImageCover = false
shape.content['shape'] = 'custom'
shape.content['height'] = '250'
shape.content['unit'] = 'px'
shape.content = JSON.stringify(shape.content)
shape = { ...shape, ...shape.section }

let title = _.cloneDeep(titleModule)
title.position = 0
title['content'].text = 'Nous contacter'
title['content'].fontFamily = 'josefin'
title['content'].textShadow = 'true'
title['content'].textShadowColor = '#007BFF7A'
title['content'].alignment = 'center'
title.content = JSON.stringify(title.content)
title = { ...title, ...title.section }

shape.submodules = [
  title
]

let row = _.cloneDeep(rowModule)
row.position = 1
row.content = JSON.stringify(row.content)
row = { ...row, ...row.section }

let column = _.cloneDeep(columnModule)
column.position = 0
column['content'].smallSize = 12
column['content'].mediumSize = 12
column['content'].largeSize = 9
column.content = JSON.stringify(column.content)
column = { ...column, ...column.section }

let text = _.cloneDeep(textModule)
text.position = 0
text['content'].text = '<p><strong>Une question ? Une suggestion ? Vous pouvez nous contacter gr&acirc;ce &agrave; ce formulaire et nous vous r&eacute;pondrons dans les plus brefs d&eacute;lais.</strong></p>'
text.section.marginBottom = '5'
text.content = JSON.stringify(text.content)
text = { ...text, ...text.section }

let contact = _.cloneDeep(contactModule)
contact.position = 1
contact.section.marginBottom = '5'
contact.content = JSON.stringify(contact.content)
contact = { ...contact, ...contact.section }

column.submodules = [
  text,
  contact
]

row.submodules = [
  column
]

export const pageContactDefault = [
  shape,
  row
]
