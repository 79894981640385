var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-article-thumbnail-preview"},[_c('div',{class:[
      { 'container': _vm.content.content.size !== 'full' && _vm.hasContainer },
      'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
      'mb-' + _vm.content.section.marginBottom.replace('-', 'n')
    ]},[_c('div',{class:[
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"d-flex flex-column",class:{
          'align-items-start': !_vm.content.content.align || _vm.content.content.align === 'start',
          'align-items-center': _vm.content.content.align === 'center',
          'align-items-end': _vm.content.content.align === 'end',
        }},[(!_vm.content.content.ratio)?_c('img',{staticClass:"mw-100",class:{
            'rounded-lg': _vm.content.content.rounded === 'rounded',
            'rounded-circle': _vm.content.content.rounded === 'rounded-circle'
          },style:(_vm.imageStyle),attrs:{"src":"https://scaleweb-local.s3.eu-west-3.amazonaws.com/website/1/files/5f32ad8adf163530333131.png"}}):_vm._e(),(_vm.content.content.ratio)?_c('div',{class:[ 'embed-responsive embed-responsive-' + _vm.content.content.ratio, {
            'rounded-lg': _vm.content.content.rounded === 'rounded',
            'rounded-circle': _vm.content.content.rounded === 'rounded-circle'
          }],style:('background-position: center; background-repeat: no-repeat; background-size: cover; background-image: url(https://scaleweb-local.s3.eu-west-3.amazonaws.com/website/1/files/5f32ad8adf163530333131.png);' + _vm.imageStyle + (_vm.content.content.ratio === 'height' ? 'padding-top:' + _vm.content.content.height + 'px;' : ''))}):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }