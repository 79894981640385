<template>
  <div class="page-member-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des membres
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste des membres
        </h2>
        <span
          v-if="!loadedMembers || !loadedSettings"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <div v-if="!loadedMembers || !loadedSettings">
      <div
        v-if="checkPermission('member.create') && (user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'))"
        class="d-flex justify-content-center"
      >
        <button class="btn btn-fake px-3 rounded-pill">
          Ajouter un membre
        </button>
      </div>
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="items && loadedMembers && loadedSettings"
      :class="{ 'is-loading': isLoading }"
    >
      <div
        v-if="checkPermission('member.create') && (user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'))"
        class="d-flex justify-content-center"
      >
        <RouterLink
          :to="{ name: 'member-create' }"
          class="btn btn-primary px-3 rounded-pill"
        >
          Ajouter un membre
        </RouterLink>
      </div>

      <div class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div class="p-4 f-20 f-nunito font-weight-600 d-flex justify-content-between align-items-center">
          <div>
            {{ view.totalItems }} {{ view.totalItems > 1 ? 'membres' : 'membre' }}
          </div>
        </div>

        <div
          v-if="!user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro')"
          class="bg-light f-14 font-weight-600 text-center p-4"
        >
          <div>
            Cette fonctionnalité est réservée aux offres PREMIUM et PRO
          </div>
          <div class="mt-3">
            <RouterLink
              :to="{ name: 'website-subscription' }"
              class="btn btn-outline-primary btn-sm px-3 rounded-pill"
            >
              Souscrire une offre
            </RouterLink>
          </div>
        </div>

        <div
          class="bg-light-grey f-14 font-weight-600 p-4"
          :class="{ 'border-bottom': items.length > 0, 'opacity-40': !user.selectedWebsite.addons.includes('pro') && !user.selectedWebsite.addons.includes('premium') }"
        >
          <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
            Paramètres des membres
          </div>
          <div class="mt-2 d-flex">
            <i
              class="fas f-16 mr-2 mt-1"
              :class="[ websiteSetting.allowMember ? 'fa-check-circle text-success' : 'fa-times-circle text-danger' ]"
            />
            <div>
              Les membres peuvent se connecter
            </div>
          </div>
          <div class="mt-2 d-flex">
            <i
              class="fas f-16 mr-2 mt-1"
              :class="[ websiteSetting.allowRegister ? 'fa-check-circle text-success' : 'fa-times-circle text-danger' ]"
            />
            <div>
              Les visiteurs peuvent s'inscrire
            </div>
          </div>
          <div class="mt-2 d-flex">
            <i
              class="fas f-16 mr-2 mt-1"
              :class="[ websiteSetting.memberMustBeValidated ? 'fa-check-circle text-success' : 'fa-times-circle text-danger' ]"
            />
            <div>
              Les membres doivent être validés par un administrateur pour pouvoir se connecter
            </div>
          </div>

          <div
            v-if="user.selectedWebsite.addons.includes('premium')"
            class="mt-3"
          >
            <RouterLink
              :to="{ name: 'website-settings-members' }"
              class="btn btn-outline-success btn-sm px-3 rounded-pill"
            >
              Modifier les paramètres
            </RouterLink>
          </div>
        </div>

        <div
          v-if="!view.totalItems"
          class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
        >
          Aucun élément à afficher
        </div>

        <MemberItem
          v-for="(item, index) in items"
          :key="item.id"
          :index="index"
          :handle-select="toggleSelected"
          :handle-delete="removeMember"
          :member="item"
          :members="items"
          :selected="selected"
        />
      </div>

      <Pagination
        v-if="!Array.isArray(view)"
        v-model="page"
        :view="view"
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import MemberItem from '@/components/member/ListItem'
import permission from '../../utils/permission'
import Pagination from '../../components/applicationlayout/Pagination'

export default {
  components: {
    Pagination,
    MemberItem
  },

  data () {
    return {
      loadedMembers: false,
      loadedSettings: false,
      page: 1,
      deleting: null,
      selected: null
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('websitesetting/show', {
      websiteSetting: 'retrieved',
      websiteSettingIsLoading: 'isLoading'
    }),

    ...mapFields('member/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    })
  },

  watch: {
    websiteSetting (settings) {
      if (settings) {
        this.loadedSettings = true
      }
    },

    selected (selected, previousSelected) {
      if (selected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-member-' + selected)
      }

      if (previousSelected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-member-' + previousSelected)
      }
    },

    items (items) {
      if (items) {
        this.loadedMembers = true
        this.selected = null
      }
    },

    page (page) {
      // noinspection JSUnresolvedVariable
      this.getMembers({ page: 'websites/' + this.user.selectedWebsite.id + '/members', query: { page } })
    }
  },

  mounted () {
    this.getMembers({ page: 'websites/' + this.user.selectedWebsite.id + '/members' })
    this.getWebsiteSetting('websites/' + this.user.selectedWebsite.id + '/setting')
  },

  beforeDestroy () {
    this.resetMembers()
    this.resetSetting()
  },

  methods: {
    ...mapActions({
      resetSetting: 'websitesetting/show/reset',
      getWebsiteSetting: 'websitesetting/show/retrieve',
      getMembers: 'member/list/default',
      resetMembers: 'member/list/reset'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    toggleSelected (id) {
      if (this.selected === id) {
        this.selected = null

        return
      }

      this.selected = id
    },

    removeMember (member) {
      let self = this

      this.items.forEach((memberItem, index) => {
        if (memberItem.id === member.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
