var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-image-preview"},[_c('div',{class:{ 'container': _vm.content.content.size !== 'full' && _vm.hasContainer }},[_c('div',{class:[
        'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
        'mb-' + _vm.content.section.marginBottom.replace('-', 'n'),
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{staticClass:"d-flex flex-column",class:{
          'align-items-start': !_vm.content.content.align || _vm.content.content.align === 'start',
          'align-items-center': _vm.content.content.align === 'center',
          'align-items-end': _vm.content.content.align === 'end',
        }},[(_vm.content.content.showLink)?_c('a',{attrs:{"href":_vm.content.content.link,"target":"_blank"},on:{"click":function($event){$event.preventDefault();}}},[(_vm.content.content.url)?_c('img',{staticClass:"img-responsive mw-100",class:{
              'rounded-lg': _vm.content.content.rounded === 'rounded',
              'rounded-circle': _vm.content.content.rounded === 'rounded-circle',
              'img-fluid w-100': _vm.content.content.size === 'full' || _vm.content.content.size === 'container'
            },style:(_vm.imageStyle),attrs:{"src":_vm.content.content.url,"alt":_vm.content.content.name}}):_vm._e(),(!_vm.content.content.url)?_c('div',{staticClass:"bg-grey p-5 mw-100",class:{
              'rounded-lg': _vm.content.content.rounded,
              'd-flex w-100': _vm.content.content.size === 'full' || _vm.content.content.size === 'container'
            },style:(_vm.imageStyle)}):_vm._e()]):_vm._e(),(_vm.content.content.url && !_vm.content.content.showLink)?_c('img',{staticClass:"img-responsive mw-100",class:{
            'rounded-lg': _vm.content.content.rounded === 'rounded',
            'rounded-circle': _vm.content.content.rounded === 'rounded-circle',
            'img-fluid w-100': _vm.content.content.size === 'full' || _vm.content.content.size === 'container'
          },style:(_vm.imageStyle),attrs:{"src":_vm.content.content.url,"alt":_vm.content.content.name}}):_vm._e(),(!_vm.content.content.url && !_vm.content.content.showLink)?_c('div',{staticClass:"bg-grey p-5 mw-100",class:{
            'rounded-lg': _vm.content.content.rounded === 'rounded',
            'rounded-circle': _vm.content.content.rounded === 'rounded-circle',
            'd-flex w-100': _vm.content.content.size === 'full' || _vm.content.content.size === 'container'
          },style:(_vm.imageStyle)}):_vm._e(),(_vm.content.content.showName)?_c('div',{staticClass:"d-flex pt-2 page-editor-preview",class:{
            'justify-content-start': !_vm.content.content.align || _vm.content.content.align === 'start',
            'justify-content-center': _vm.content.content.align === 'center',
            'justify-content-end': _vm.content.content.align === 'end'
          }},[(_vm.content.content.showName && _vm.content.content.name)?_c('small',{staticClass:"text-muted"},[_vm._v("\n            "+_vm._s(_vm.content.content.name)+"\n          ")]):_vm._e(),(_vm.content.content.showName && !_vm.content.content.name)?_c('small',{staticClass:"text-muted"},[_vm._v("\n            Nom de l'image\n          ")]):_vm._e()]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }