import { APP_CONFIG } from './config/entrypoint'
import ArticleRoutes from './router/article'
import BillingRoutes from './router/billing'
import DashboardRoutes from './router/dashboard'
import DesignRoutes from './router/design'
import FileRoutes from './router/file'
import GalleryRoutes from './router/gallery'
import InvitationRoutes from './router/invitation'
import MemberRoutes from './router/member'
import MemberGroupRoutes from './router/membergroups'
import MenuRoutes from './router/menu'
import PageRoutes from './router/page'
import SecurityRoutes from './router/security'
import ShopPaymentRoutes from './router/shoppayment'
import ShopProducts from './router/shopproduct'
import ShopSetting from './router/shopsetting'
import UserRoutes from './router/user'
import WebsiteRoutes from './router/website'
import WebsiteUserRoutes from './router/websiteuser'
import WeddingRoutes from './router/wedding'
import WeddingListItemRoutes from './router/weddinglistitem'
import WeddingPayment from './router/weddingpayment'
import ErrorRoutes from './router/error'
import Router from 'vue-router'
import Vue from 'vue'
import permission from './utils/permission'
import store from './store'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...ArticleRoutes,
    ...BillingRoutes,
    ...DashboardRoutes,
    ...DesignRoutes,
    ...FileRoutes,
    ...GalleryRoutes,
    ...InvitationRoutes,
    ...MemberRoutes,
    ...MemberGroupRoutes,
    ...MenuRoutes,
    ...PageRoutes,
    ...SecurityRoutes,
    ...ShopPaymentRoutes,
    ...ShopProducts,
    ...ShopSetting,
    ...UserRoutes,
    ...WebsiteRoutes,
    ...WebsiteUserRoutes,
    ...WeddingRoutes,
    ...WeddingListItemRoutes,
    ...WeddingPayment,
    ...ErrorRoutes
  ]
})

const checkMeta = (to, from, next) => {
  // Must be logged in
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (!store.getters['application/auth/getField']('isLogged')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  }

  // Must be logged out
  if (to.matched.some(record => record.meta.requireLogout)) {
    if (store.getters['application/auth/getField']('isLogged')) {
      next({
        path: '/'
      })
    }
  }

  // Must have permission
  let matched = to.matched.find(record => record.meta.requirePermission)
  if (matched) {
    if (!permission(matched.meta.requirePermission)) {
      next({
        path: '/503'
      })

      return
    }
  }

  next()
}

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  let title = APP_CONFIG.name
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) title = nearestWithTitle.meta.title + ' | ' + title

  document.title = title

  // noinspection JSIgnoredPromiseFromCall
  store.dispatch('application/auth/refreshToken', { toggleLoading: false, forceRefresh: false, callback: () => { checkMeta(to, from, next) } })
})

export default router
