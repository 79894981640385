<template>
  <div :class="{ 'form-group': labelType === 'default', 'input-group pb-3': labelType !== 'default' }">
    <slot name="label">
      <div :class="{ 'input-group-prepend': labelType === 'prepend', 'input-group-append': labelType === 'append' }">
        <label
          v-if="label"
          :for="id"
          :class="{ 'form-control-label': labelType === 'default', 'input-group-text': labelType !== 'default' }"
        >{{ label }}</label>
      </div>
    </slot>

    <slot name="beforeInput" />

    <!--suppress HtmlFormInputWithoutLabel -->
    <select
      :id="id"
      v-model="content"
      :class="['form-control', !isValid || !isPreValid ? 'is-invalid' : '']"
      @change="updateValue($event.target.value)"
    >
      <option
        value=""
        disabled
        :selected="!content"
      >
        {{ placeholder ? placeholder : 'Choisir une valeur' }}
      </option>
      <option
        v-for="item in list"
        :key="item.id"
        :value="item.id"
        :selected="item.id === content"
      >
        {{ item.name }}
      </option>
    </select>

    <slot name="afterInput" />
    <slot name="validation" />
    <slot name="afterValidation" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    list: {
      type: Array,
      required: true
    },

    label: {
      type: String,
      default: ''
    },

    labelType: {
      type: String,
      default: 'default'
    },

    placeholder: {
      type: String,
      default: ''
    },

    isValid: {
      type: Boolean,
      default: true
    },

    isPreValid: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      content: this.value
    }
  },

  computed: {
    id () {
      // noinspection JSUnresolvedVariable
      return 'input-' + this._uid
    }
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value.trim())
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
