var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-member-socials-preview"},[_c('div',{class:[
      'mt-' + _vm.content.section.marginTop.replace('-', 'n'),
      'mb-' + _vm.content.section.marginBottom.replace('-', 'n')
    ]},[_c('div',{class:[
        'ml-' + _vm.content.section.marginLeft.replace('-', 'n'),
        'mr-' + _vm.content.section.marginRight.replace('-', 'n'),
        'pt-' + _vm.content.section.paddingTop,
        'pb-' + _vm.content.section.paddingBottom,
        'pl-' + _vm.content.section.paddingLeft,
        'pr-' + _vm.content.section.paddingRight,
        _vm.moduleClass
      ],style:(_vm.moduleStyle)},[_c('div',{class:{ 'container': _vm.hasContainer }},[_c('div',{staticClass:"d-flex",class:{
            'flex-column': _vm.content.content.vertical,
            'justify-content-center': _vm.content.content.align === 'center',
            'justify-content-end': _vm.content.content.align === 'end',
            'align-items-center': _vm.content.content.align === 'center' && _vm.content.content.vertical,
            'align-items-end': _vm.content.content.align === 'end' && _vm.content.content.vertical,
            'colored-icons': _vm.content.content.coloredIcons,
          }},[_c('div',{staticClass:"mr-3 d-flex align-items-center"},[_c('i',{staticClass:"fab fa-facebook-square",class:'f-' + _vm.content.content.size}),(_vm.content.content.displayName)?_c('span',{staticClass:"pl-2 font-weight-600"},[_vm._v("Facebook")]):_vm._e()]),_c('div',{staticClass:"mr-3 d-flex align-items-center"},[_c('i',{staticClass:"fab fa-instagram-square",class:'f-' + _vm.content.content.size}),(_vm.content.content.displayName)?_c('span',{staticClass:"pl-2 font-weight-600"},[_vm._v("Instagram")]):_vm._e()]),_c('div',{staticClass:"mr-3 d-flex align-items-center"},[_c('i',{staticClass:"fab fa-twitter-square",class:'f-' + _vm.content.content.size}),(_vm.content.content.displayName)?_c('span',{staticClass:"pl-2 font-weight-600"},[_vm._v("Twitter")]):_vm._e()]),_c('div',{staticClass:"mr-3 d-flex align-items-center"},[_c('i',{staticClass:"fab fa-linkedin",class:'f-' + _vm.content.content.size}),(_vm.content.content.displayName)?_c('span',{staticClass:"pl-2 font-weight-600"},[_vm._v("LinkedIn")]):_vm._e()]),_c('div',{staticClass:"mr-3 d-flex align-items-center"},[_c('i',{staticClass:"fab fa-youtube-square",class:'f-' + _vm.content.content.size}),(_vm.content.content.displayName)?_c('span',{staticClass:"pl-2 font-weight-600"},[_vm._v("Youtube")]):_vm._e()]),_c('div',{staticClass:"mr-3 d-flex align-items-center"},[_c('i',{staticClass:"fab fa-twitch",class:'f-' + _vm.content.content.size}),(_vm.content.content.displayName)?_c('span',{staticClass:"pl-2 font-weight-600"},[_vm._v("Twitch")]):_vm._e()]),_c('div',{staticClass:"mr-3 d-flex align-items-center"},[_c('i',{staticClass:"fas fa-link",class:'f-' + _vm.content.content.size}),(_vm.content.content.displayName)?_c('span',{staticClass:"pl-2 font-weight-600"},[_vm._v("Site internet")]):_vm._e()])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }