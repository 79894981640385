<template>
  <div class="component-member-presentation-preview">
    <div
      :class="[
        'mt-' + content.section.marginTop.replace('-', 'n'),
        'mb-' + content.section.marginBottom.replace('-', 'n')
      ]"
    >
      <div
        :class="[
          'ml-' + content.section.marginLeft.replace('-', 'n'),
          'mr-' + content.section.marginRight.replace('-', 'n'),
          'pt-' + content.section.paddingTop,
          'pb-' + content.section.paddingBottom,
          'pl-' + content.section.paddingLeft,
          'pr-' + content.section.paddingRight,
          moduleClass
        ]"
        :style="moduleStyle"
      >
        <div :class="{ 'container': hasContainer }">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel urna tincidunt, fermentum risus non, egestas lorem. Duis hendrerit orci sit amet metus condimentum malesuada. Fusce sit amet elit eu nisl molestie sagittis at ac mauris. Donec rhoncus condimentum quam sed ultrices. Sed maximus mattis ligula, at vehicula leo condimentum sed. In consequat eros massa, et mattis magna placerat ut. Donec sit amet mollis neque, et accumsan arcu.</p>
          <p>Etiam arcu libero, volutpat ac consectetur ut, posuere vitae lacus. Mauris aliquet pellentesque mi quis porta. Donec vitae vestibulum tellus, eu ultrices lacus. Morbi eget est venenatis, pretium lectus in, volutpat libero. Nunc laoreet arcu quis pellentesque iaculis. Sed cursus iaculis neque eu imperdiet. Nulla semper ullamcorper tellus sed imperdiet. Duis malesuada metus vel tortor blandit auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    moduleClass () {
      let classes = []

      if (this.content.section.shadow) {
        classes.push(this.content.section.shadow === 'normal' ? 'shadow' : 'shadow-' + this.content.section.shadow)
      }

      return classes
    },

    moduleStyle () {
      let style = ''

      if (this.content.section.backgroundColor) {
        style += 'background-color: ' + this.content.section.backgroundColor + ';'
      }

      if (this.content.section.backgroundImage) {
        style += 'background-image: url(' + this.content.section.backgroundImage + '); background-position: center;'
        style += 'background-size: ' + (this.content.section.backgroundImageCover ? 'cover' : 'contain') + '; '

        if (this.content.section.backgroundImageFixed) {
          style += 'background-attachment: fixed; '
        }

        if (!this.content.section.backgroundImageRepeat) {
          style += 'background-repeat: no-repeat; '
        }
      }

      if (this.content.section.borderWidth) {
        style += 'border-width: ' + this.content.section.borderWidth + 'px; border-style: solid;'
      }

      if (this.content.section.borderColor) {
        style += 'border-color: ' + this.content.section.borderColor + ';'
      }

      if (this.content.section.borderRadius) {
        style += 'border-radius: ' + this.content.section.borderRadius + 'px;'
      }

      return style
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
