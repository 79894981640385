<template>
  <div class="page-page-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des pages
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste des pages
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="!loadedPages || !loadedFixedPages"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="!loadedPages || !loadedFixedPages">
      <div
        v-if="checkPermission('page.create')"
        class="d-flex justify-content-center"
      >
        <button class="btn btn-fake px-3 rounded-pill">
          Ajouter une page
        </button>
      </div>
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
      <div class="py-5 my-3 rounded-lg bg-grey" />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="loadedPages && loadedFixedPages"
      class="mb-5"
      :class="{ 'is-loading': isLoading }"
    >
      <div class="d-flex justify-content-center">
        <router-link
          v-if="checkPermission('page.create') && (view.totalItems < 5 || user.selectedWebsite.addons.includes('premium') || user.selectedWebsite.addons.includes('pro'))"
          :to="{ name: 'page-create' }"
          class="btn btn-primary px-3 rounded-pill"
        >
          Ajouter une page
        </router-link>
      </div>

      <div class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative">
        <div
          class="p-4 f-20 f-nunito font-weight-600 d-flex justify-content-between align-items-center"
          :class="{ 'border-bottom': items.length > 0 }"
        >
          <div>
            {{ view.totalItems > 0 ? view.totalItems : 'Aucune' }} {{ view.totalItems > 1 ? 'pages' : 'page' }}
          </div>
        </div>

        <div
          v-if="view.totalItems >=5 && !user.selectedWebsite.addons.includes('premium') && !user.selectedWebsite.addons.includes('pro')"
          class="bg-light f-14 font-weight-600 text-center p-4"
        >
          <div>
            Vous avez atteint la limite gratuite de page.<br>Vous devez souscrire à l'offre PREMIUM ou l'offre PRO pour bénéficier d'un nombre de pages illimitées.
          </div>
          <div class="mt-3">
            <RouterLink
              :to="{ name: 'website-subscription' }"
              class="btn btn-outline-primary btn-sm px-3 rounded-pill"
            >
              Souscrire une offre
            </RouterLink>
          </div>
        </div>

        <div
          v-if="!view.totalItems"
          class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
        >
          Aucun élément à afficher
        </div>

        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="position-relative"
          :class="{ 'is-loading': deleting === item.id, 'border-bottom': index !== items.length - 1 }"
        >
          <div
            class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
            :class="{ 'pl-1': selected === item.id }"
          />
          <div
            class="d-flex justify-content-between bg-light-grey-hover align-items-center position-relative opacity-100-hover cursor-pointer transition-400"
            :class="{ 'opacity-30': selected && selected !== item.id, 'bg-light-grey': selected === item.id }"
            @click="toggleSelected(item.id)"
          >
            <div class="flex-grow-1 p-4">
              <div class="row align-items-center">
                <div class="col">
                  <div class="text-primary font-weight-600">
                    {{ item.title }}
                  </div>

                  <div
                    v-if="item.private && user.selectedWebsite.addons.includes('premium')"
                    class="text-warning f-14"
                  >
                    <div v-if="item.private && (!item.privateForGroups || item.authorizedGroups.length === 0)">
                      <i class="fas fa-exclamation-triangle mr-1" /> Page réservée aux membres
                    </div>
                    <div v-else>
                      <div>
                        <i class="fas fa-exclamation-triangle mr-1" /> Page réservée à certains groupes de membres :
                      </div>
                      <ul class="mb-0">
                        <li
                          v-for="group in item.authorizedGroups"
                          :key="group.id"
                          class="font-weight-700"
                        >"{{ group.name }}"</li>
                      </ul>
                    </div>
                  </div>

                  <div class="text-soft">
                    <a
                      :href="'//' + user.selectedWebsite.url + '/page/' + item.slug"
                      target="_blank"
                      class="text-soft text-decoration-none f-14"
                    >
                      <i class="fad fa-link mr-1" /> Voir la page
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="align-self-stretch px-4 d-flex align-items-center">
              <i
                class="text-soft far fa-angle-right f-18 transition-300"
                :class="{ 'fa-rotate-90': selected === item.id }"
              />
            </div>
          </div>
          <BCollapse
            :id="'collapse-page-' + item.id"
            accordion="page-list"
          >
            <div
              v-if="checkPermission('page.update') || checkPermission('page.delete')"
              class="p-3 d-flex flex-column flex-sm-row align-items-center justify-content-center border-bottom"
            >
              <RouterLink
                v-if="checkPermission('page.update')"
                :to="{name: 'page-update', params: { id: item.id }}"
                class="btn btn-outline-primary btn-sm rounded-pill px-3 mx-sm-3 my-2 my-sm-0"
              >
                Éditer la page
              </RouterLink>

              <button
                v-if="checkPermission('page.delete')"
                class="btn btn-outline-danger btn-sm px-3 my-2 my-sm-0 rounded-pill"
                @click.prevent="removePage(item)"
              >
                Supprimer la page
              </button>
            </div>
            <div class="px-4 pt-4 pb-2">
              <div class="row">
                <div class="col-12 col-md-6 pb-3">
                  <div class="font-weight-600 text-soft">
                    Créée le
                  </div>
                  <div class="text-capitalize">
                    {{ item.createdAt | moment( 'dddd Do MMMM YYYY') }}
                  </div>
                </div>
                <div class="col-12 col-md-6 pb-3">
                  <div class="font-weight-600 text-soft">
                    Dernière édition le
                  </div>
                  <div class="text-capitalize">
                    {{ item.updatedAt | moment( 'dddd Do MMMM YYYY') }}
                  </div>
                </div>
              </div>
            </div>
          </BCollapse>
        </div>
      </div>

      <Pagination
        v-if="!Array.isArray(view)"
        v-model="page"
        :view="view"
      />
    </div>

    <div
      v-if="loadedPages && loadedFixedPages"
      class="my-3 bg-white rounded-lg shadow-sm overflow-hidden position-relative"
    >
      <div class="p-4 f-20 f-nunito font-weight-600 d-flex justify-content-between align-items-center">
        <div>
          Pages spéciales
        </div>
      </div>

      <div class="py-3 px-4 bg-light f-14 f-nunito font-weight-600">
        Ces pages sont fixes, elles peuvent être activées ou désactivées et contiennent des fonctionnalités supplémentaires par rapport aux pages classiques.
        De la même façon que pour les pages classiques leur contenu peut être édité.
      </div>

      <div
        v-for="(item, index) in fixedPagesList"
        :key="item.slug"
        class="position-relative"
        :class="{ 'is-loading': (item.page && deleting === item.page.id) || creating === item.slug, 'border-bottom': index !== fixedPagesList.length - 1 }"
      >
        <div
          class="position-absolute top-0 left-0 bg-primary h-100 z-index-10 transition-200"
          :class="{ 'pl-1': item.page && selected === item.page.id }"
        />
        <div
          class="d-flex justify-content-between align-items-center position-relative opacity-100-hover transition-400"
          :class="{ 'bg-light-grey-hover cursor-pointer': item.page, 'opacity-30': selected && (!item.page || selected !== item.page.id), 'bg-light-grey': item.page && selected === item.page.id }"
          @click="toggleSelected(item.page.id)"
        >
          <div class="flex-grow-1 p-4">
            <div class="row align-items-center">
              <div class="col">
                <div class="text-primary font-weight-600">
                  {{ item.title }}
                </div>
                <div
                  v-if="item.page && item.page.private && user.selectedWebsite.addons.includes('premium')"
                  class="text-warning f-14"
                >
                  <i class="fas fa-exclamation-triangle mr-1" /> Seuls les membres peuvent voir cette page
                </div>
                <div class="text-soft">
                  <a
                    v-if="item.page && item.linkable"
                    :href="'//' + user.selectedWebsite.url + '/' + item.slug"
                    target="_blank"
                    class="text-success text-decoration-none f-14"
                  >
                    <i class="fad fa-link mr-1" /> Voir la page
                  </a>
                  <a
                    v-if="!item.page && !item.disable"
                    :href="'//' + user.selectedWebsite.url + '/' + item.slug"
                    target="_blank"
                    class="text-success text-decoration-none f-14"
                  >
                    <i class="fad fa-link mr-1" /> Voir la page <span class="text-soft font-weight-600">(non personnalisée)</span>
                  </a>
                </div>
                <div class="text-soft f-14 d-flex align-items-start">
                  <i class="fad fa-question-circle mr-1 mt-1" />
                  <div
                    class="pl-1"
                    style="max-width: 600px"
                  >
                    {{ item.description }}
                  </div>
                </div>
                <div
                  v-if="item.disabled && !item.page"
                  class="text-danger f-14 d-flex align-items-start"
                >
                  <i class="fad fa-exclamation-circle mr-1 mt-1" />
                  <div
                    class="pl-1"
                    style="max-width: 600px"
                  >
                    {{ item.disabled }}
                  </div>
                </div>
                <div
                  v-if="item.soon"
                  class="text-success f-14 d-flex align-items-start"
                >
                  <i class="fad fa-exclamation-circle mr-1 mt-1" />
                  <div
                    class="pl-1"
                    style="max-width: 600px"
                  >
                    {{ item.soon }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="align-self-stretch px-4 d-flex align-items-center">
            <button
              v-if="!item.page && checkPermission('page.create')"
              class="btn btn-outline-success btn-sm px-3 rounded-pill"
              @click="createFixedPage(item)"
            >
              {{ item.disable ? 'Activer' : 'Personnaliser' }}
            </button>
            <i
              v-if="item.page"
              class="text-soft far fa-angle-right f-18 transition-300"
              :class="{ 'fa-rotate-90': item.page && selected === item.page.id }"
            />
          </div>
        </div>
        <BCollapse
          v-if="item.page"
          :id="'collapse-page-' + item.page.id"
          accordion="page-list"
        >
          <div
            v-if="checkPermission('page.update') || checkPermission('page.delete')"
            class="p-3 d-flex flex-column flex-sm-row align-items-center justify-content-center border-bottom"
          >
            <RouterLink
              v-if="checkPermission('page.update')"
              :to="{name: 'page-update', params: { id: item.page.id }}"
              class="btn btn-outline-primary btn-sm rounded-pill px-3 mx-sm-3 my-2 my-sm-0"
            >
              Éditer la page
            </RouterLink>

            <button
              v-if="checkPermission('page.delete')"
              class="btn btn-outline-danger btn-sm px-3 my-2 my-sm-0 rounded-pill"
              @click.prevent="removePage(item.page)"
            >
              {{ item.disable ? 'Désactiver la page' : 'Supprimer la personnalisation' }}
            </button>
          </div>
          <div class="px-4 pt-4 pb-2">
            <div class="row">
              <div class="col-12 col-md-6 pb-3">
                <div class="font-weight-600 text-soft">
                  Créée le
                </div>
                <div class="text-capitalize">
                  {{ item.page.createdAt | moment( 'dddd Do MMMM YYYY') }}
                </div>
              </div>
              <div class="col-12 col-md-6 pb-3">
                <div class="font-weight-600 text-soft">
                  Dernière édition le
                </div>
                <div class="text-capitalize">
                  {{ item.page.updatedAt | moment( 'dddd Do MMMM YYYY') }}
                </div>
              </div>
            </div>
          </div>
        </BCollapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { pages } from '@/config/pagebuilder/pages'
import { fixedPages } from '@/config/enum/fixedPagesEnum'
import Pagination from '../../components/applicationlayout/Pagination'
import permission from '../../utils/permission'

export default {
  components: {
    Pagination
  },

  data () {
    return {
      pages,
      loadedPages: false,
      loadedFixedPages: false,
      page: 1,
      deleting: null,
      creating: null,
      selected: null,
      fixedPages
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('page/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('page/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('page/listfixed', {
      errorFixed: 'error',
      itemsFixed: 'items',
      isFixedLoading: 'isLoading',
      viewFixed: 'view'
    }),

    ...mapFields('page/create', {
      createError: 'error',
      createLoading: 'isLoading',
      created: 'created',
      createViolations: 'violations'
    }),

    fixedPagesList () {
      let pages = []
      let self = this

      this.fixedPages.forEach((model) => {
        if (model.editable) {
          pages.push({
            title: model.title,
            slug: model.slug,
            description: model.description,
            linkable: model.linkable,
            disable: model.disable,
            soon: model.soon ? model.soon : null,
            disabled: model.disabled ? model.disabled : null,
            page: self.itemsFixed.find(item => item.slug === model.slug)
          })
        }
      })

      return pages
    }
  },

  watch: {
    selected (selected, previousSelected) {
      if (selected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-page-' + selected)
      }

      if (previousSelected) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-page-' + previousSelected)
      }
    },

    itemsFixed (items) {
      if (items) {
        this.loadedFixedPages = true
      }
    },

    items (items) {
      if (items) {
        this.loadedPages = true
      }
    },

    created (created) {
      if (!created) {
        return
      }

      this.$router.push({ name: 'page-update', params: { id: created.id } })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      this.selected = null
      this.deleting = null

      let self = this

      this.items.forEach((page, index) => {
        if (page.id === deleted.id) {
          self.items.splice(index, 1)
          self.view.totalItems--
        }
      })

      this.itemsFixed.forEach((page, index) => {
        if (page.id === deleted.id) {
          self.itemsFixed.splice(index, 1)
          self.viewFixed.totalItems--
        }
      })
    },

    page (page) {
      // noinspection JSUnresolvedVariable
      this.getPages({ page: 'websites/' + this.user.selectedWebsite.id + '/pages', query: { fixed: false, pagination: true, page } })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.getPages({ page: 'websites/' + this.user.selectedWebsite.id + '/pages', query: { fixed: false, pagination: true } })
    // noinspection JSUnresolvedVariable
    this.getFixedPages({ page: 'websites/' + this.user.selectedWebsite.id + '/pages', query: { fixed: true, pagination: false } })
  },

  beforeDestroy () {
    this.resetPages()
    this.resetFixedPages()
  },

  methods: {
    ...mapActions({
      getPages: 'page/list/default',
      resetPages: 'page/list/reset',
      getFixedPages: 'page/listfixed/default',
      resetFixedPages: 'page/listfixed/reset',
      deletePage: 'page/del/del',
      createPage: 'page/create/create'
    }),

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    toggleSelected (id) {
      if (this.selected === id) {
        this.selected = null

        return
      }

      this.selected = id
    },

    createFixedPage (page) {
      this.creating = page.slug
      // noinspection JSUnresolvedVariable
      let payload = {
        title: page.title,
        slug: page.slug,
        content: JSON.stringify([]),
        fixed: true,
        linkable: page.linkable,
        private: false,
        website: '/api/websites/' + this.user.selectedWebsite.id
      }

      if (this.pages[page.slug]) {
        payload.modules = this.pages[page.slug]
      }

      this.createPage(payload)
    },

    removePage (page) {
      this.deleting = page.id
      this.deletePage(page)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
