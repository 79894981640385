<template>
  <div class="page-invitation-create">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <router-link
          :to="{ name: 'invitation-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des invitations
        </router-link>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Créer une invitation
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div :class="{ 'is-loading' : isLoading }">
      <InvitationForm
        :handle-submit="onSendForm"
        :values="item"
        :errors="violations"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import InvitationForm from '../../components/invitation/Form'

export default {
  components: {
    InvitationForm
  },

  data () {
    return {
      item: {
        content: []
      }
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('invitation/create', {
      error: 'error',
      isLoading: 'isLoading',
      created: 'created',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return
      }

      this.$router.push({ name: 'invitation-list' })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.item.website = '/api/websites/' + this.user.selectedWebsite.id
  },

  beforeDestroy () {
    this.createReset()
  },

  methods: {
    ...mapActions('invitation/create', {
      createInvitation: 'create',
      createReset: 'reset'
    }),

    onSendForm () {
      let payload = {
        email: this.item.email,
        permissions: this.item.permissions,
        website: this.item.website
      }

      this.createInvitation(payload)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
