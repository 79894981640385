import { options } from '../elements/_default'
import { text } from '../elements/text'

export const shape = {
  type: 'shape',
  position: null,
  variant: 'default',
  content: {
    shape: '16by9',
    rounded: false,
    height: '100',
    width: '100',
    unit: '%',
    horizontalCenter: true,
    verticalCenter: true,
    fullWidth: true
  },
  submodules: [
    text
  ],
  dependencies: [],
  section: _.cloneDeep(options)
}
