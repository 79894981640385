<template>
  <div class="form-website-setting-member">
    <div class="bg-white shadow-sm rounded-lg overflow-hidden">
      <div class="p-4">
        <div class="mb-3 text-primary font-weight-700 text-uppercase f-14">
          Paramètres de membres
        </div>
        <FormCheckbox
          v-model="item.allowMember"
          :is-valid="isValid('allowMember')"
          :is-switch="true"
          class="mb-1"
          label="Autoriser les membres à se connecter"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('allowMember')"
              class="invalid-feedback"
            >
              {{ violations.allowMember }}
            </div>
          </template>
        </FormCheckbox>
        <FormCheckbox
          v-model="item.allowRegister"
          :is-valid="isValid('allowRegister')"
          :is-switch="true"
          :is-disabled="!item.allowMember"
          class="mb-1"
          label="Autoriser les visiteurs à s'inscrire"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('allowRegister')"
              class="invalid-feedback"
            >
              {{ violations.allowRegister }}
            </div>
          </template>
        </FormCheckbox>
        <FormCheckbox
          v-model="item.memberMustBeValidated"
          :is-valid="isValid('memberMustBeValidated')"
          :is-switch="true"
          label="Les membres doivent être validés par un administrateur pour pouvoir se connecter"
        >
          <template v-slot:validation>
            <div
              v-if="!isValid('memberMustBeValidated')"
              class="invalid-feedback"
            >
              {{ violations.memberMustBeValidated }}
            </div>
          </template>
        </FormCheckbox>

        <div class="mb-3 mt-5 text-primary font-weight-700 text-uppercase f-14">
          Champs personnalisés
        </div>
        <div class="f-14 text-muted mb-3">
          Les champs personnalisés permettent aux membres d'ajouter des informations sur leur profil.<br>
          Vous pouvez par exemple ajouter des champs Facebook, Twitter ou Instagram pour que vos membres renseignent leurs réseaux sociaux.
        </div>

        <div class="mb-3">
          <button
            class="btn btn-outline-primary btn-sm rounded-pill px-3"
            @click.prevent="addField"
          >Ajouter un champ</button>
        </div>

        <div class="rounded-lg border overflow-hidden">
          <FormField
            v-for="(field, index) in item.memberFields"
            :key="index"
            :index="index"
            :fields="item.memberFields"
            :groups="groups"
            :values="field"
            :new="!field.id"
            :errors="violations"
            :handle-remove="removeField"
            :handle-move="moveField"
          />

          <div
            v-if="!item.memberFields.length"
            class="p-4 f-14 font-weight-600 text-center text-soft"
          >
            Aucun champ personnalisé
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-primary px-3 rounded-pill"
        @click.prevent="submit()"
      >
        Mettre à jour les paramètres
      </button>
    </div>
  </div>
</template>

<script>
import { get, isUndefined } from 'lodash'
import FormCheckbox from '../applicationform/FormCheckbox'
import FormInput from '../applicationform/FormInput'
import FormTextarea from '../applicationform/FormTextarea'
import FormField from '../websitesettingmemberfield/Form'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'

export default {
  components: {
    FormTextarea,
    FormCheckbox,
    FormInput,
    FormField
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('membergroup/list', {
      error: 'error',
      groups: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    },

    allowMember () {
      return this.item.allowMember
    }
  },

  watch: {
    allowMember (allowMember) {
      if (!allowMember) {
        this.item.allowRegister = false
      }
    }
  },

  mounted () {
    this.item.memberFields.sort(this.orderFields)
    this.getGroups({ page: 'websites/' + this.user.selectedWebsite.id + '/member_groups?pagination=false' })
  },

  beforeDestroy () {
    this.resetGroups()
  },

  methods: {
    ...mapActions({
      getGroups: 'membergroup/list/default',
      resetGroups: 'membergroup/list/reset'
    }),

    isValid (key) {
      return isUndefined(get(this.violations, key))
    },

    computePosition () {
      this.item.memberFields.forEach((field, index) => {
        field.position = index
      })
    },

    orderFields (a, b) {
      return (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)
    },

    addField () {
      this.item.memberFields.push({
        name: null,
        description: null,
        type: 'text',
        choices: null,
        defaultValue: null,
        required: false,
        requiredForGroups: [],
        active: true,
        activeForGroups: [],
        displayed: false,
        displayedForGroups: [],
        icon: null,
        position: 0
      })
      this.computePosition()
    },

    removeField (field) {
      this.item.memberFields.splice(this.item.memberFields.indexOf(field), 1)
      this.computePosition()
    },

    moveField (from, value = 0) {
      this.item.memberFields.splice(from + value, 0, this.item.memberFields.splice(from, 1)[0])
      this.computePosition()
    },

    submit () {
      // noinspection JSCheckFunctionSignatures
      this.handleSubmit(this.item)
    }
  }
}
</script>
