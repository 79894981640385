<template>
  <div class="page-shoppayment-show">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        <RouterLink
          :to="{ name: 'shoppayment-list' }"
          class="text-reset text-decoration-none"
        >
          <i class="fa fa-chevron-left mr-1" /> Revenir à la liste des paiements
        </RouterLink>
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Voir un paiement
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading && !retrieved"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="isLoading && !retrieved">
      <div
        class="py-5 my-3 rounded-lg bg-grey"
        style="height: 320px"
      />
    </div>

    <div
      v-if="retrieved"
      class="bg-white shadow-sm rounded-lg my-3"
    >
      <div class="p-4">
        <div class="f-14 font-weight-700 text-primary text-uppercase pb-3">
          Nom et informations
        </div>
        <div class="d-flex flex-column">
          <span>{{ retrieved.firstname }} {{ retrieved.lastname }}</span>
          <span>{{ retrieved.email }}</span>
          <span class="text-muted">{{ retrieved.address }} {{ retrieved.postalCode }} {{ retrieved.city }} {{ retrieved.country }}</span>
        </div>
      </div>

      <div class="p-4 bg-light-grey text-center">
        <div
          class="f-24"
          :class="'text-' + statusColor[status]"
        >
          {{ retrieved.amount }} €
        </div>

        <div
          class="f-14 text-uppercase font-weight-700 pb-2"
          :class="'text-' + statusColor[status]"
        >
          {{ statusText[status] }}
        </div>

        <div class="f-14 text-muted">
          Paiement créé le <span class="font-weight-600">{{ retrieved.createdAt | moment('DD MMMM YYYY à HH:mm') }}</span>
        </div>
        <div class="f-14 pt-3">
          <a
            :href="'//dashboard.stripe.com/payments/' + retrieved.paymentIntentId"
            target="_blank"
            class="text-muted text-decoration-none"
          >
            <span class="border border-dark px-2 rounded-pill text-white-hover bg-dark-hover">Voir sur stripe</span>
          </a>
        </div>
      </div>

      <div class="py-4 table-responsive">
        <table class="table mb-0">
          <thead>
          <tr class="text-uppercase text-muted">
            <th class="border-top-0" scope="col">{{ retrieved.products.length > 1 ? 'Produits' : 'Produit' }}</th>
            <th class="border-top-0" scope="col">Prix</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="product in retrieved.products">
            <th class="font-weight-600" scope="row">{{ product.name }} <small class="text-muted font-weight-600">{{ product.label }}</small></th>
            <td>{{ product.price > 0 ? product.price + '€' : 'Gratuit' }}</td>
          </tr>
          <tr class="text-primary">
            <th class="text-right font-weight-normal" scope="row">Total {{ retrieved.paid ? 'payé' : 'à payer' }} :</th>
            <td class="font-weight-600">{{ retrieved.amount > 0 ? retrieved.amount + '€' : 'Gratuit' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  data () {
    return {
      statusColor: {
        'refund': 'info',
        'disputed': 'danger',
        'paid': 'success',
        'pending': 'warning'
      },
      statusText: {
        'refund': 'Paiement remboursé',
        'disputed': 'Paiement contesté',
        'paid': 'Paiement complet',
        'pending': 'Paiement en attente'
      }
    }
  },

  computed: {
    ...mapFields('shoppayment/show', {
      isLoading: 'isLoading',
      retrieved: 'retrieved'
    }),

    status () {
      if (this.retrieved) {
        if (this.retrieved.refund) {
          return 'refund'
        }

        if (this.retrieved.disputed) {
          return 'disputed'
        }

        if (this.retrieved.paid) {
          return 'paid'
        }
      }

      return 'pending'
    }
  },

  watch: {
    error (error) {
      if (error) {
        this.$router.push({ name: 'error-404' })
      }
    },

    retrieved (retrieved) {
      if (!retrieved) {
        this.$router.push({ name: 'notfound' })
      }
    }
  },

  created () {
    this.retrieve('shop_payments/' + decodeURIComponent(this.$route.params.id))
  },

  beforeDestroy () {
    this.reset()
  },

  methods: {
    ...mapActions({
      retrieve: 'shoppayment/show/retrieve',
      reset: 'shoppayment/show/reset'
    }),
  }
}
</script>

<style lang="scss" scoped>

</style>
