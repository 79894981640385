<template>
  <div class="page-page-list">
    <div class="page-header">
      <div class="page-header-sub mt-5 pt-4 f-11 text-soft font-weight-700 mb-3 text-uppercase">
        Gestion des menus
      </div>
      <div class="page-header-title d-flex mb-5">
        <h2 class="f-nunito mb-0">
          Liste des menus
        </h2>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="isLoading || !loadedMenus"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Chargement
        </span>
        <!--suppress JSUnresolvedVariable -->
        <span
          v-if="createLoading || updateLoading"
          class="ml-4 bg-light px-3 py-1 f-nunito f-14 font-weight-600 rounded-pill align-self-center"
        >
          Enregistrement
        </span>
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="!loadedMenus">
      <div
        v-if="checkPermission('menu.update')"
        class="d-flex justify-content-center"
      >
        <button class="btn btn-fake px-3 rounded-pill">
          Ajouter un menu
        </button>
      </div>
      <div
        class="my-3 rounded-lg bg-grey"
        style="height: 400px"
      />
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div v-if="loadedMenus && checkPermission('menu.update')">
      <div
        v-if="!creating"
        class="d-flex justify-content-center"
      >
        <button
          class="btn btn-primary px-3 rounded-pill"
          @click.prevent="addMenu()"
        >
          Ajouter un menu
        </button>
      </div>

      <!--suppress JSUnresolvedVariable -->
      <div
        v-if="creating && !creatingParent"
        class="my-3 bg-white shadow-sm rounded-lg"
        :class="{ 'is-loading': createLoading }"
      >
        <!--suppress JSUnresolvedVariable -->
        <MenuForm
          :handle-submit="submitCreateMenu"
          :handle-close="closeForms"
          :values="creatingMenu"
          :errors="createViolations"
          :pages="pageList"
        />
      </div>
    </div>

    <!--suppress JSUnresolvedVariable -->
    <div
      v-if="!isLoading || loadedMenus"
      class="bg-white rounded-lg shadow-sm overflow-hidden my-3"
      :class="{ 'is-loading': createLoading || isLoading || updateLoading || deleteLoading }"
    >
      <div
        v-if="checkPermission('menu.update')"
        class="p-3 bg-light f-nunito font-weight-600 f-14"
      >
        Les menus peuvent être ordonnés grâce à un système de drag-and-drop.
      </div>

      <div
        v-if="!view.totalItems"
        class="px-4 py-5 f-14 font-weight-600 text-center text-soft border-top"
      >
        Aucun élément à afficher
      </div>

      <Draggable
        v-model="items"
        :disabled="!checkPermission('menu.update')"
        @change="onOrder"
      >
        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="d-flex align-items-center"
          :class="{ 'border-bottom': index !== items.length - 1 }"
        >
          <div
            v-if="checkPermission('menu.update')"
            v-onhover="['bg-light-grey-hover']"
            class="d-flex align-self-stretch align-items-center p-3 f-20 cursor-grab"
          >
            <i class="fas fa-grip-vertical text-soft" />
          </div>
          <div class="flex-grow-1 py-3 px-3">
            <div
              v-if="updatingId !== item.id"
              class="text-soft font-weight-600"
            >
              {{ item.text }}
            </div>
            <div
              v-if="updatingId !== item.id && item.page && item.page.private && user.selectedWebsite.addons.includes('premium')"
              class="text-warning f-14"
            >
              <div v-if="item.page.private && (!item.page.privateForGroups || item.page.authorizedGroups.length === 0)">
                <i class="fas fa-exclamation-triangle mr-1" /> Menu affiché uniquement aux membres
              </div>
              <div v-else>
                <div>
                  <i class="fas fa-exclamation-triangle mr-1" /> Menu affiché uniquement à certains groupes de membres :
                </div>
                <ul class="mb-0">
                  <li
                    v-for="group in item.page.authorizedGroups"
                    :key="group.id"
                    class="font-weight-700"
                  >"{{ group.name }}"</li>
                </ul>
              </div>
            </div>
            <div
              v-if="updatingId !== item.id && !hasRequiredAddon(item)"
              class="text-warning f-14"
            >
              <i class="fas fa-exclamation-triangle mr-1" /> Ce menu ne sera pas affiché avec l'offre actuelle
            </div>

            <!--suppress JSUnresolvedVariable -->
            <MenuForm
              v-if="updating && updatingId === item.id"
              :handle-submit="submitUpdateMenu"
              :handle-close="closeForms"
              :values="updatingMenu"
              :errors="updateViolations"
              :pages="pageList"
            />

            <!--suppress JSUnresolvedVariable -->
            <Draggable
              v-model="item.childrens"
              :disabled="!checkPermission('menu.update')"
              @change="onOrder"
            >
              <!--suppress JSUnresolvedVariable -->
              <div
                v-for="child in item.childrens"
                :key="child.id"
                class="ml-5 my-3 bg-light-grey rounded d-flex align-items-center overflow-hidden"
              >
                <div
                  v-if="checkPermission('menu.update')"
                  v-onhover="['bg-light-grey-hover']"
                  class="d-flex align-self-stretch align-items-center p-3 f-20 cursor-grab"
                >
                  <i class="fas fa-grip-vertical text-soft" />
                </div>
                <div class="flex-grow-1 py-3 px-3">
                  <div
                    v-if="updatingId !== child.id"
                    class="text-soft font-weight-600"
                  >
                    {{ child.text }}
                  </div>

                  <!--suppress JSUnresolvedVariable -->
                  <MenuForm
                    v-if="updating && updatingId === child.id"
                    :handle-submit="submitUpdateMenu"
                    :handle-close="closeForms"
                    :values="updatingMenu"
                    :errors="updateViolations"
                    :pages="pageList"
                  />
                </div>

                <div
                  v-if="checkPermission('menu.update')"
                  class="menu-edit bg-success-light-hover align-self-stretch cursor-pointer px-3 d-flex align-items-center"
                  :class="{ 'opacity-0' : updatingId === child.id }"
                  @click.prevent="editMenu(child)"
                >
                  <i class="fas fa-pen-square text-white f-22" />
                </div>
                <div
                  v-if="checkPermission('menu.update')"
                  class="menu-delete bg-danger-light-hover align-self-stretch cursor-pointer px-3 d-flex align-items-center"
                  @click.prevent="deleteMenu(child)"
                >
                  <i class="fas fa-trash text-white f-18" />
                </div>
              </div>

              <div
                v-if="creating && creatingParent === item.id"
                class="ml-5"
              >
                <!--suppress JSUnresolvedVariable -->
                <MenuForm
                  :handle-submit="submitCreateMenu"
                  :handle-close="closeForms"
                  :values="creatingMenu"
                  :errors="createViolations"
                  :pages="pageList"
                />
              </div>

              <div
                v-if="checkPermission('menu.update')"
                v-onhover="['bg-light-hover']"
                class="ml-5 my-3 p-3 bg-light rounded d-flex align-items-center cursor-pointer"
                @click.prevent="addMenu(item.id)"
              >
                Ajouter un sous-menu
              </div>
            </Draggable>
          </div>
          <div
            v-if="checkPermission('menu.update')"
            class="menu-edit bg-success-light-hover align-self-stretch cursor-pointer px-3 d-flex align-items-center"
            :class="{ 'opacity-0' : updatingId === item.id }"
            @click.prevent="editMenu(item)"
          >
            <i class="fas fa-pen-square text-white f-22" />
          </div>
          <div
            v-if="checkPermission('menu.update')"
            class="menu-delete bg-danger-light-hover align-self-stretch cursor-pointer px-3 d-flex align-items-center"
            @click.prevent="deleteMenu(item)"
          >
            <i class="fas fa-trash text-white f-18" />
          </div>
        </div>
      </Draggable>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { fixedPages } from '@/config/enum/fixedPagesEnum'
import Draggable from 'vuedraggable'
import MenuForm from '../../components/menu/Form'
import permission from '../../utils/permission'

export default {
  components: {
    Draggable,
    MenuForm
  },

  data () {
    return {
      loadedMenus: false,
      creating: false,
      creatingMenu: {},
      creatingParent: null,
      updating: false,
      updatingId: null,
      updatingMenu: {},
      fixedPages
    }
  },

  computed: {
    ...mapFields('application/auth', {
      user: 'user'
    }),

    ...mapFields('page/list', {
      errorPage: 'error',
      pages: 'items',
      isPageLoading: 'isLoading',
      viewPage: 'view'
    }),

    ...mapFields('menu/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view'
    }),

    ...mapFields('menu/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    ...mapFields('menu/update', {
      updateError: 'error',
      updateLoading: 'isLoading',
      updated: 'updated',
      updateViolations: 'violations'
    }),

    ...mapFields('menu/create', {
      createError: 'error',
      createLoading: 'isLoading',
      created: 'created',
      createViolations: 'violations'
    }),

    pageList () {
      let list = []
      let self = this

      this.pages.forEach((page) => {
        list.push({
          id: page['@id'],
          name: page.title,
          slug: page.slug,
          existing: true
        })
      })

      this.fixedPages.forEach((page) => {
        if (page.linkable && !page.disable && self.pages.filter(e => e.slug === page.slug).length === 0) {
          list.push({
            id: page.slug,
            name: page.title,
            slug: page.slug,
            existing: false
          })
        }
      })

      return list
    }
  },

  watch: {
    items: function (items) {
      if (items) {
        this.loadedMenus = true
        this.closeForms()
      }
    },

    created: function (created) {
      if (!created) {
        return
      }

      // noinspection JSUnresolvedVariable
      this.getMenus({ page: 'websites/' + this.user.selectedWebsite.id + '/menuses', query: { 'exists[parent]': false } })
    },

    updated: function (updated) {
      if (!updated) {
        return
      }

      let child = null

      if (updated.parent) {
        let parent = this.items.find(item => item.id === updated.parent.id)
        // noinspection JSUnresolvedVariable
        child = parent.childrens.find(item => item.id === updated.id)
      }

      let menu = updated.parent ? child : this.items.find(item => item.id === updated.id)

      // noinspection JSUnresolvedVariable
      if (menu.position === updated.position) {
        menu.page = updated.page
        this.closeForms()

        return
      }

      // noinspection JSUnresolvedVariable
      this.getMenus({ page: 'websites/' + this.user.selectedWebsite.id + '/menuses', query: { 'exists[parent]': false } })
    },

    deleted (deleted) {
      if (!deleted) {
        return
      }

      // noinspection JSUnresolvedVariable
      this.getMenus({ page: 'websites/' + this.user.selectedWebsite.id + '/menuses', query: { 'exists[parent]': false } })
    }
  },

  mounted () {
    // noinspection JSUnresolvedVariable
    this.getPages({ page: 'websites/' + this.user.selectedWebsite.id + '/pages', query: { pagination: false, linkable: true } })
    // noinspection JSUnresolvedVariable
    this.getMenus({ page: 'websites/' + this.user.selectedWebsite.id + '/menuses', query: { 'exists[parent]': false } })
  },

  beforeDestroy () {
    this.resetPages()
    this.resetMenus()
  },

  methods: {
    ...mapActions({
      getPages: 'page/list/default',
      resetPages: 'page/list/reset',
      getMenus: 'menu/list/default',
      resetMenus: 'menu/list/reset',
      deleteMenu: 'menu/del/del',
      createMenu: 'menu/create/create',
      updateMenu: 'menu/update/update'
    }),

    hasRequiredAddon (menu) {
      let page = null

      this.fixedPages.forEach((pageItem) => {
        if (menu.link === '/' + pageItem.slug) {
          page = pageItem
        }
      })

      return !(page && page.requiredAddon && !this.user.selectedWebsite.addons.includes(page.requiredAddon));
    },

    checkPermission (toCheck) {
      return permission(toCheck)
    },

    addMenu (parent) {
      this.closeForms()

      this.creating = true
      this.creatingMenu = {
        parent: parent ? '/api/menus/' + parent : null
      }
      this.creatingParent = parent
    },

    editMenu (menu) {
      this.closeForms()

      let page = null

      if (menu.page) {
        page = menu.page['@id']
      }

      if (!menu.page && this.pageList.filter(e => '/' + e.slug === menu.link).length > 0) {
        page = menu.link.replace('/', '')
      }

      this.updating = true
      this.updatingId = menu.id
      this.updatingMenu = {
        id: menu.id,
        position: menu.position,
        page,
        text: menu.text,
        link: menu.link
      }
    },

    closeForms () {
      this.creating = false
      this.creatingMenu = {}
      this.creatingParent = null
      this.updating = false
      this.updatingId = null
      this.updatingMenu = {}
    },

    submitCreateMenu (menu) {
      // noinspection JSUnresolvedVariable
      menu.website = '/api/websites/' + this.user.selectedWebsite.id

      this.createMenu(menu)
    },

    submitUpdateMenu (menu) {
      this.updateMenu({
        id: 'menus/' + this.updatingId,
        payload: menu
      })
    },

    onOrder (change) {
      let payload = {
        position: change.moved.newIndex
      }

      this.updateMenu({
        id: 'menus/' + change.moved.element.id,
        payload
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
